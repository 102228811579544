const initialData = {
  tasks: {
    "task-1": { id: "task-1", content: "Texans" },
    "task-2": { id: "task-2", content: "Seahawks" },
    "task-3": { id: "task-3", content: "Titans" },
    "task-4": { id: "task-4", content: "Browns" },
    "task-5": { id: "task-5", content: "Raiders" },
    "task-6": { id: "task-6", content: "Bills" },
    "task-7": { id: "task-7", content: "Packers" },
    "task-8": { id: "task-8", content: "Rams" },
    "task-9": { id: "task-9", content: "Vikings" },
    "task-10": { id: "task-10", content: "Colts" },
    "task-11": { id: "task-11", content: "Buccaneers" },
    "task-12": { id: "task-12", content: "Chiefs" },
    "task-13": { id: "task-13", content: "Broncos" },
    "task-14": { id: "task-14", content: "Chargers" },
    "task-15": { id: "task-15", content: "Lions" },
    "task-16": { id: "task-16", content: "Panthers" },
    "task-17": { id: "task-17", content: "Giants" },
    "task-18": { id: "task-18", content: "Ravens" },
    "task-19": { id: "task-19", content: "Washington" },
    "task-20": { id: "task-20", content: "Cowboys" },
    "task-21": { id: "task-21", content: "Jaguars" },
    "task-22": { id: "task-22", content: "Jets" },
    "task-23": { id: "task-23", content: "49ers" },
    "task-24": { id: "task-24", content: "Falcons" },
    "task-25": { id: "task-25", content: "Bears" },
    "task-26": { id: "task-26", content: "Bengals" },
    "task-27": { id: "task-27", content: "Dolphins" },
    "task-28": { id: "task-28", content: "Eagles" },
    "task-29": { id: "task-29", content: "Steelers" },
    "task-30": { id: "task-30", content: "Patriots" },
    "task-31": { id: "task-31", content: "Saints" },
    "task-32": { id: "task-32", content: "Cardinals" },
  },
  columns: {
    "column-1": {
      id: "column-1",
      title: "Tier 1",
      taskIds: [
        "task-21",
        "task-22",
        "task-23",
        "task-24",
        "task-25",
        "task-26",
        "task-27",
        "task-28",
      
      ],
    },
    "column-2": {
      id: "column-2",
      title: "Tier 2",
      taskIds: [
     
        "task-13",
        "task-14",
        "task-15",
        "task-16",
        "task-17",
        "task-18",
        "task-19",
        "task-20",
      ],
    },
    "column-3": {
      id: "column-3",
      title: "Tier 3",
      taskIds: [
        "task-3",
        "task-4",
        "task-5",
        "task-6",
        "task-7",
        "task-8",
        "task-9",
        "task-10",
      ],
    },
    "column-4": {
      id: "column-4",
      title: "Tier 4",
      taskIds: [  "task-29",
      "task-30","task-31", "task-32", "task-1", "task-2", "task-11",
      "task-12",],
    },
  },
  // Facilitate reordering of the columns
  columnOrder: ["column-1", "column-2", "column-3", "column-4"],
};

export default initialData;
