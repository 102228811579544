import React, { useState, useContext, useEffect } from "react";
import "./Wheels.scss"
import RandomWheel from "../../components/RandomWheel"
import globalContext from "../../context/context";

function Wheels () {
    const { user, clipboardData, setClipboardData } = useContext(globalContext);

    return (
        <div className='wheels-container'>
          <div className="premium-section-top" id="myHeader">
          <div className="premium-section-top-logo">
            <h1 className='razz-filler-header'> Random Wheel</h1>
          </div>
          {user?.username && (
            <div className="premium-section-info">
              <img
                className="seller-logo-img"
                alt="Profile"
                src={user.photoUrl}
              />
              <p className="seller-username">{user.username}</p>
            </div>
          )}
        </div>    

            <RandomWheel/>
        </div>
    )
}

export default Wheels;

