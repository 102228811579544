const initialData = {
    tasks: {
        "task-1": { id: "task-1", content: "Argentina" },
        "task-2": { id: "task-2", content: "Belgium" },
        "task-3": { id: "task-3", content: "Brazil" },
        "task-4": { id: "task-4", content: "Colombia" },
        "task-5": { id: "task-5", content: "Croatia" },
        "task-6": { id: "task-6", content: "England" },
        "task-7": { id: "task-7", content: "France" },
        "task-8": { id: "task-8", content: "Germany" },
        "task-9": { id: "task-9", content: "Italy" },
        "task-10": { id: "task-10", content: "Mexico" },
        "task-11": { id: "task-11", content: "Netherlands" },
        "task-12": { id: "task-12", content: "Norway" },
        "task-13": { id: "task-13", content: "Poland" },
        "task-14": { id: "task-14", content: "Portugal" },
        "task-15": { id: "task-15", content: "Spain" },
        "task-16": { id: "task-16", content: "Korea Republic" },
        "task-17": { id: "task-17", content: "Sweeden" },
        "task-18": { id: "task-18", content: "USA" },
        "task-19": { id: "task-19", content: "Uruguay" },
        // "task-20": { id: "task-20", content: "Wolverhampton Wanderers" },
        
    },
    columns: {
      "column-1": {
        id: "column-1",
        title: "Tier 1",
        taskIds: [
          "task-1",
          "task-2",
          "task-3",
          "task-4",
          "task-5",
          "task-6",
          "task-7",
          "task-8",
          "task-9",
          "task-10",
        ],
      },
      "column-2": {
        id: "column-2",
        title: "Tier 2",
        taskIds: [
          "task-11",
          "task-12",
          "task-13",
          "task-14",
          "task-15",
          "task-16",
          "task-17",
          "task-18",
          "task-19",
        //   "task-20",
        ],
      },
      "column-3": {
        id: "column-3",
        title: "Tier 3",
        taskIds: [],
      },
    },
    // Facilitate reordering of the columns
    columnOrder: ["column-1", "column-2", "column-3"],
  };
  
  export default initialData;
  