import React from 'react'
import Mailchimp from 'react-mailchimp-form'
import "./Mailchimp.css"

function MailchimpForm () {
    return (
        <div className='mailchimp'>
            <h1 className='mailchimp-header'>💎 Gems of the Week - Hobby Newsletter</h1>
   <Mailchimp
   action="https://gemint.us2.list-manage.com/subscribe/post?u=fa713ac6670e27309f5b6a02e&amp;id=0ad4101d77&amp;f_id=00a84ee0f0"
//   action='https://<YOUR-USER>.us16.list-manage.com/subscribe/post?u=XXXXXXXXXXXXX&amp;id=XXXXXX' 
  
  //Adding multiple fields:
  fields={[
    {
      name: 'EMAIL',
      placeholder: 'Email Address',
      type: 'email',
      required: true,
    },
    // {
    //   name: 'FNAME',
    //   placeholder: 'Name',
    //   type: 'text',
    //   required: true
    // }
  ]}
  // Change predetermined language
  messages = {
    {
      sending: "Sending...",
      success: "Thank you for subscribing!",
      error: "An unexpected internal error has occurred.",
      empty: "You must write an e-mail.",
      duplicate: "Too many subscribe attempts for this email address",
      button: "Subscribe"    }
  }
  // Add a personalized class
  className='mailchimp-container'
  />
  <button>Subscribe</button>
        </div>
    )
}

export default MailchimpForm;