import React, { useEffect, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import PopUp from "../../components/PopUp";
import { cancelSubscription } from "../../store/subscription";
import FreeLogo from "../../img/Free.png";
import PremiumLogo from "../../img/Business.png";
import globalContext from "../../context/context";
import "./Pricing.css";

const Pricing = () => {
  const { user, userState, setUser } = useContext(globalContext);
  const [openPopup, setOpenPopup] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const subscription = useSelector((state) => state.subscription);

  const handleClickCancel = () => {
    setOpenPopup(true);
  };

  const handleCancelSubscription = () => {
    const payload = {
      email: user.email,
      subscriptionId: user.subscriptionId,
    };
    dispatch(cancelSubscription(payload));
    setOpenPopup(false);
  };

  useEffect(() => {
    if (subscription.updatedUser) {
      if (subscription.updatedUser.plan !== user.plan) {
        setUser(subscription.updatedUser);
      }
    }
    if (subscription.error) {
      setError(subscription.error.raw.message);
      setOpenPopup(true);
    }
  }, [subscription]);

  return (
    <div className="pricing section">
      <h1 className="pricing-header">Subscribe And Save</h1>
      <div className="pricing-desc">
        Tools for breaks + breakers of all sizes
      </div>
      <div className="pricing-tabs" id="pills-tabContent">
        <div
          className={`pricing-tab free py-5 px-4 ${
            user?.plan === "free" ? "current" : ""
          }`}
        >
          <div className="pricing-tab-logo">
            <img src={FreeLogo} alt="" />
          </div>
          <div className="pricing-tab-title">Freemium</div>
          <div className="pricing-tab-price">$0/month</div>
          {!userState ? (
            <Link to="/signup" className="pricing-free-sign-up">
              Register for access
            </Link>
          ) : (
            <div className="price-action-panel">Registered</div>
          )}
          <ul>
            <li>- Randomize faster than random.org.</li>
            <li>- Integrated dice roller.</li>
            <li>- Time stamped dice rolls + randomizations.</li>
            <li>- Reset, Undo, and Redo buttons.</li>
            <li>- Cryptography-secured randoms.</li>
          </ul>
          {!userState ? (
            <Link to="/signup" className="pricing-free-sign-up">
              Register for access
            </Link>
          ) : (
            <div className="price-action-panel">Registered</div>
          )}
        </div>
        <div
          className={`pricing-tab monthly py-5 px-4 ${
            user?.plan === "monthly" ? "current" : ""
          }`}
        >
          <div className="pricing-tab-logo">
            <img src={PremiumLogo} alt="" />
          </div>
          <div className="pricing-tab-title">Premium</div>
          <div className="pricing-tab-price">FREE for 7 days + $10/month</div>
          {!userState ? (
            <Link
              to="/premium?plan=monthly"
              className="pricing-premium-sign-up"
            >
              Free Trial
            </Link>
          ) : user?.plan === "free" ? (
            <Link
              to="/premium?plan=monthly"
              className="pricing-premium-sign-up"
            >
              Free Trial
            </Link>
          ) : user?.plan === "monthly" ? (
            <button
              className="price-action-panel cancel-button"
              onClick={() => handleClickCancel()}
              disabled={subscription.isLoading}
            >
              Cancel Subscrption
            </button>
          ) : (
            <div className="price-action-panel">Registered</div>
          )}
          <ul>
            <li>- Branded suspense curtain.</li>
            <li>- Fully customizable dice + pip colors.</li>
            <li>- Tiered randoms.</li>
            <li>- Preloaded Division + Team randomizers.</li>
            <li>- Copy/Paste lists into spreadsheets.</li>
          </ul>
          {!userState ? (
            <Link
              to="/premium?plan=monthly"
              className="pricing-premium-sign-up"
            >
              Free Trial
            </Link>
          ) : user?.plan === "free" ? (
            <Link
              to="/premium?plan=monthly"
              className="pricing-premium-sign-up"
            >
              Free Trial
            </Link>
          ) : user?.plan === "monthly" ? (
            <button
              className="price-action-panel cancel-button"
              onClick={() => handleClickCancel()}
              disabled={subscription.isLoading}
            >
              Cancel Subscrption
            </button>
          ) : (
            <div className="price-action-panel">Registered</div>
          )}
        </div>
        <div
          className={`pricing-tab yearly py-5 px-4 ${
            user?.plan === "yearly" ? "current" : ""
          }`}
        >
          <div className="pricing-tab-logo">
            <img src={PremiumLogo} alt="" />
          </div>
          <div className="pricing-tab-title">Pro</div>
          <div className="pricing-tab-price">$90/year</div>
          {!userState ? (
            <Link to="/premium?plan=yearly" className="pricing-premium-sign-up">
              Go Pro
            </Link>
          ) : user?.plan === "free" ? (
            <Link to="/premium?plan=yearly" className="pricing-premium-sign-up">
              Go Pro
            </Link>
          ) : user?.plan === "monthly" ? (
            <Link to="/premium?plan=yearly" className="pricing-premium-sign-up">
              Go Pro
            </Link>
          ) : user?.plan === "yearly" ? (
            <button
              className="price-action-panel cancel-button"
              onClick={() => handleClickCancel()}
              disabled={subscription.isLoading}
            >
              Cancel Subscrption
            </button>
          ) : null}
          <ul>
            <li>- Premium access.</li>
            <li>- 3 months free — pay once and save 25% vs. monthly subscription.</li>
            <li>- Support continued innovation of tools.</li>
            <li>- Exclusive Pro-only surprises!</li>
            <li>- Early access to new tools + functions.</li>
          </ul>
          {!userState ? (
            <Link to="/premium?plan=yearly" className="pricing-premium-sign-up">
              Go Pro
            </Link>
          ) : user?.plan === "free" ? (
            <Link to="/premium?plan=yearly" className="pricing-premium-sign-up">
              Go Pro
            </Link>
          ) : user?.plan === "monthly" ? (
            <Link to="/premium?plan=yearly" className="pricing-premium-sign-up">
              Go Pro
            </Link>
          ) : user?.plan === "yearly" ? (
            <button
              className="price-action-panel cancel-button"
              onClick={() => handleClickCancel()}
              disabled={subscription.isLoading}
            >
              Cancel Subscrption
            </button>
          ) : null}
        </div>
      </div>
      {openPopup && !error && (
        <PopUp
          content={
            <>
              <div className="popup-title">
                {user?.plan === "monthly"
                  ? "Are you sure you want to cancel? Get 3 months free - Save 25% compared to monthly subscription with Annual"
                  : "Are you sure you want to cancel? Go monthly for $10/month"}
              </div>
              <div className="popup-actions">
                <div className="no-btn" onClick={() => setOpenPopup(false)}>
                  No
                </div>
                <div
                  className="yes-btn"
                  onClick={() => handleCancelSubscription()}
                >
                  Yes
                </div>
              </div>
            </>
          }
          handleClose={() => setOpenPopup(false)}
        />
      )}
      {openPopup && error && (
        <PopUp
          content={<div className="popup-title">{error}</div>}
          handleClose={() => {
            setOpenPopup(false);
            setError(null);
          }}
        />
      )}
    </div>
  );
};

export default Pricing;
