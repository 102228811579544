const initialData = {
  tasks: {
    "task-1": { id: "task-1", content: "NFC West" },
    "task-2": { id: "task-2", content: "NFC East" },
    "task-3": { id: "task-3", content: "NFC South" },
    "task-4": { id: "task-4", content: "NFC North" },
    "task-5": { id: "task-5", content: "AFC West" },
    "task-6": { id: "task-6", content: "AFC East" },
    "task-7": { id: "task-7", content: "AFC South" },
    "task-8": { id: "task-8", content: "AFC North" },
  },
  columns: {
    "column-1": {
      id: "column-1",
      title: "Divisions",
      taskIds: [
        "task-1",
        "task-2",
        "task-3",
        "task-4",
        "task-5",
        "task-6",
        "task-7",
        "task-8",
      ],
    },
  },
  // Facilitate reordering of the columns
  columnOrder: ["column-1"],
};

export default initialData;
