const initialData = {
  tasks: {
    "task-1": { id: "task-1", content: "Heavyweight" },
    "task-2": { id: "task-2", content: "Light Heavyweight" },
    "task-3": { id: "task-3", content: "Middleweight" },
    "task-4": { id: "task-4", content: "Welterweight" },
    "task-5": { id: "task-5", content: "Lightweight" },
    "task-6": { id: "task-6", content: "Featherweight" },
    "task-7": { id: "task-7", content: "Bantamweight" },
    "task-8": { id: "task-8", content: "Flyweight" },
    "task-9": { id: "task-9", content: "Strawweight" },
  },
  columns: {
    "column-1": {
      id: "column-1",
      title: "Divisions",
      taskIds: ["task-1", "task-2", "task-3", "task-4", "task-5", "task-6", "task-7", "task-8", "task-9"],
    },
  },
  // Facilitate reordering of the columns
  columnOrder: ["column-1"],
};

export default initialData;
