import React, { useEffect, useState } from "react";
import globalContext from "./context";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC5VE9hJuHjdmB3WkJlQUUb9MoYMzz5E1M",
  authDomain: "gemint-prod.firebaseapp.com",
  projectId: "gemint-prod",
  storageBucket: "gemint-prod.appspot.com",
  messagingSenderId: "111373351207",
  appId: "1:111373351207:web:44d2fa506899bb51a69102",
  measurementId: "G-59N8QXJ4SG",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

function GlobalState({ children }) {
  const [username, setUsername] = useState("");
  const [user, setUser] = useState(null);
  const [userState, setUserState] = useState(false);
  const [clipboardData, setClipboardData] = useState([
    [1, "", ""],
    [2, "", ""],
    [3, "", ""],
    [4, "", ""],
    [5, "", ""],
    [6, "", ""],
    [7, "", ""],
    [8, "", ""],
    [9, "", ""],
    [10, "", ""],
  ]);

  useEffect(() => {
    console.log(typeof user);
    firebase.auth().onAuthStateChanged((loggedInUser) => {
      if (loggedInUser && user == null) {
        const uid = loggedInUser.uid;
        console.log("user signed in: " + uid);

        db.collection("users")
          .doc(uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              console.log("Document data:", doc.data());
              setUser(doc.data());
              setUserState(true);
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      } else if (loggedInUser && user) {
        console.log("logged in with user data.");
      } else {
        // User is signed out
        // ...
        setUser(null);
        setUserState(false);
        console.log("user signed out");
      }
    });
  });

  return (
    <globalContext.Provider
      value={{
        username,
        setUsername,
        user,
        setUser,
        firebase,
        db,
        auth,
        userState,
        setUserState,
        storage,
        clipboardData,
        setClipboardData,
      }}
    >
      {children}
    </globalContext.Provider>
  );
}

export default GlobalState;
