const initialData = {
  tasks: {
    "task-1": { id: "task-1", content: "NL West" },
    "task-2": { id: "task-2", content: "NL Central" },
    "task-3": { id: "task-3", content: "NL East" },
    "task-4": { id: "task-4", content: "AL West" },
    "task-5": { id: "task-5", content: "AL Central" },
    "task-6": { id: "task-6", content: "AL East" },
  },
  columns: {
    "column-1": {
      id: "column-1",
      title: "Divisions",
      taskIds: ["task-1", "task-2", "task-3", "task-4", "task-5", "task-6"],
    },
  },
  // Facilitate reordering of the columns
  columnOrder: ["column-1"],
};

export default initialData;
