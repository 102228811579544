import React from "react";
import "./Blog.css";
import { Link } from "react-router-dom";
import defaultBlog from "../../img/default-blog.png";

const BlogTileSide = (props) => {
  const post = props.post;

  function blogPost() {
    const image = post._embedded["wp:featuredmedia"]
      ? post._embedded["wp:featuredmedia"]["0"].source_url
      : defaultBlog;
    return (
      <a href={`/news/${post.slug}`} className="blog-link" rel="noopener">
        <div className="post">
          <img
            src={`${image}`}
            className="post-image"
            alt={truncateText(cleanTitle(post.title.rendered), 0, 60)}
          />
          <div
            className="post-head"
            dangerouslySetInnerHTML={{ __html: post.title.rendered }}
          ></div>
        </div>
      </a>
    );
  }

  function cleanTitle(checkTitle) {
    checkTitle = checkTitle.replace("amp;", "");
    return checkTitle;
  }

  function truncateText(text, start, len) {
    return text.length > len ? text.slice(start, len) : text;
  }

  function toText(block) {
    let tag = document.createElement("div");
    tag.innerHTML = block;
    block = tag.innerText;
    return block;
  }

  function convertDate(date) {
    let dateArray = date.slice(0, 10).split("-");
    let year = dateArray.shift();
    dateArray.push(year);
    console.log(date);
    return `Published: ${dateArray.join("/")}`;
  }

  return (
    <>
      {/* <h1 className='blog-header'>Latest Gemint News </h1> */}
      {blogPost()}
    </>
  );
};

export default BlogTileSide;
