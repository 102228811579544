import React, {useState} from "react";
import * as Common from "../../../components/Common";
import {checkLogin} from "./LoginRequired";

const SeasonFollow = (props) => {
  const [showSuccess, setShowSuccess] = useState(false);

  const followSeason = () => {
    if (!checkLogin()) {
      return false;
    }
    props.setShowLoader(true);
    Common.axiosInstance()
      .post("/seasons/follow", {season_id: props.season.id, follow: props.follow})
      .then((res) => {
        let following = props.following;
        if (following && Array.isArray(following)) {
          if (props.follow) {
            following.push(props.season.id);
          } else {
            while (following.indexOf(props.season.id) !== -1) {
              delete following[following.indexOf(props.season.id)];
            }
          }
          props.setFollowing([...following]);
        } else {
          props.setFollowing(props.follow);
        }
        setShowSuccess(true);
        props.setShowLoader(false);
        setTimeout(() => {
          setShowSuccess(false);
        }, 2000)
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }

  return (
    <>

      <li
        key={props.season.id}
        className={`follow-button`}>
          <span
            style={{padding: '0 10px'}}
            className={`${!props.follow ? 'followed' : ''}`}
            onClick={() => followSeason()}>{props.follow ? '+' : '✔'}</span>
        {
          props.name &&
          <span
            onClick={() => {
              if (props.handleSeasonClick) {
                props.handleSeasonClick(props.season.id);
              }
            }}
          >{props.season.year} {props.season.name}</span>
        }
        {
          showSuccess && <div className="follow-response">{!props.follow ? 'Follow' : 'Unfollow'} successful</div>
        }
      </li>
    </>
  );
};

export default SeasonFollow;
