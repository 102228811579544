const initialData = {
    tasks: {
      "task-1": { id: "task-1", content: "Pacers" },
      "task-2": { id: "task-2", content: "Celtics" },
      "task-3": { id: "task-3", content: "Spurs" },
      "task-4": { id: "task-4", content: "Suns" },
      "task-5": { id: "task-5", content: "Blazers" },
      "task-6": { id: "task-6", content: "Nets" },
      "task-7": { id: "task-7", content: "Clippers" },
      "task-8": { id: "task-8", content: "Jazz" },
      "task-9": { id: "task-9", content: "Raptors" },
      "task-10": { id: "task-10", content: "Rockets" },
      "task-11": { id: "task-11", content: "Wizards" },
      "task-12": { id: "task-12", content: "Hawks" },
      "task-13": { id: "task-13", content: "Bucks" },
      "task-14": { id: "task-14", content: "76ers" },
      "task-15": { id: "task-15", content: "Pelicans" },
      "task-16": { id: "task-16", content: "Grizzlies" },
      "task-17": { id: "task-17", content: "Heat" },
      "task-18": { id: "task-18", content: "Cavaliers" },
      "task-19": { id: "task-19", content: "Nuggets" },
      "task-20": { id: "task-20", content: "Bulls" },
      "task-21": { id: "task-21", content: "Kings" },
      "task-22": { id: "task-22", content: "Magic" },
      "task-23": { id: "task-23", content: "Knicks" },
      "task-24": { id: "task-24", content: "Mavericks" },
      "task-25": { id: "task-25", content: "Pistons" },
      "task-26": { id: "task-26", content: "Thunder" },
      "task-27": { id: "task-27", content: "Lakers" },
      "task-28": { id: "task-28", content: "Timberwolves" },
      "task-29": { id: "task-29", content: "Hornets" },
      "task-30": { id: "task-30", content: "Warriors" },
    },
    columns: {
        "column-1": {
          id: "column-1",
          title: "Teams",
          taskIds: [
              "task-1", 
              "task-2", 
              "task-3",
              "task-4",
              "task-5",
              "task-6",
              "task-7",
              "task-8",
              "task-9",
              "task-10",
              "task-11",
              "task-12",
              "task-13",
              "task-14",
              "task-15",
              "task-16",
              "task-17",
              "task-18",
              "task-19",
              "task-20",
            "task-21",
            "task-22",
            "task-23",
            "task-24",
            "task-25",
            "task-26",
            "task-27",
            "task-28",
            "task-29",
            "task-30", 
          
          
          ],
        },
    
      },
      // Facilitate reordering of the columns
      columnOrder: ["column-1"],
    };
    
    export default initialData;
    