import React, {useEffect, useState} from "react";
import "./Checkoff.css";
import loader from "../Hit/includes/loader.gif";
import * as Helper from "../Hit/includes/Helper";
import * as Common from "../../components/Common";
import shareIcon from "../../img/share.svg";
import CardImage from "../Hit/includes/CardImage";
import {Link} from "react-router-dom";
import {RWebShare} from "react-web-share";
import SeasonFollow from "../Hit/componenets/SeasonFollow";
import ShowCard from "../Hit/componenets/ShowCard";
import LoginRequired, {checkLogin} from "../Hit/componenets/LoginRequired";
import expandAccordion from "../../img/expand-accordion.png";
import Advertisement from "../../components/Advertisement";
import searchIcon from "../../img/magnifying-glass.svg";

function Checkoff() {
  const [seasonKeyword, setSeasonKeyword] = useState("");
  const [seasonId, setSeasonId] = useState("");
  const [season, setSeason] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [following, setFollowing] = useState([]);
  const [allSeasons, setAllSeasons] = useState([]);
  const [htmlSeasons, setHtmlSeasons] = useState(null);

  const [playerKeyword, setPlayerKeyword] = useState("");
  const [selPlayer, setSelPlayer] = useState({});
  const [players, setPlayers] = useState([]);
  const [allPlayers, setAllPlayers] = useState([]);
  const [htmlPlayers, setHtmlPlayers] = useState(null);

  const [cardKeyword, setCardKeyword] = useState("");
  const [cards, setCards] = useState([]);
  const [selCard, setSelCard] = useState({});
  const [showCard, setShowCard] = useState({});
  const [htmlCards, setHtmlCards] = useState(null);
  const [cardNumber, setCardNumber] = useState(null);

  const [latestUploads, setLatestUploads] = useState([]);
  const [htmlLatestUploads, setHtmlLatestUploads] = useState(null);
  const [uploadedCard, setUploadedCard] = useState([]);
  const [uploadCount, setUploadCount] = useState([]);
  const [cardUploads, setCardUploads] = useState([]);
  const [htmlCardUploads, setHtmlCardUploads] = useState(null);
  const [uploadKeyword, setUploadKeyword] = useState("");


  const [frontPreview, setFrontPreview] = useState(null);
  const [backPreview, setBackPreview] = useState(null);
  const [frontImage, setFrontImage] = useState([]);
  const [backImage, setBackImage] = useState([]);
  const [notes, setNotes] = useState('');

  const [uploadUnknown, setUploadUnknown] = useState(false);

  const [page, setPage] = useState(1);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [error, setError] = useState([]);
  const [saveLoader, setSaveLoader] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showToggleClass, setShowShowToggleClass] = useState(false);

  const perPage = 10; // 10 Mb
  const maxFileSizeMB = 10; // 10 Mb
  const maxFileSize = 1024 * 1024 * maxFileSizeMB; // 10 Mb

  useEffect(() => {
    setSeasonKeyword("");
    getSeasons();

  }, []);

  useEffect(() => {
    getLatestUploads();
  }, [page]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if(page > 1){
        setPage(1);
      }else{
        getLatestUploads();
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [uploadKeyword]);

  const getLatestUploads = () => {
    setShowLoader(true);
    Common.axiosInstance()
      .get(`card-uploads/latest?limit=${perPage}&page=${page}&s=${uploadKeyword}`)
      .then((res) => {
        setShowLoadMore(!(!res.data.length || res.data.length < perPage));
        setLatestUploads(page === 1 ? res.data : latestUploads.concat(res.data));
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  }

  useEffect(() => {
    listSeasons();
  }, [seasons, following]);

  useEffect(() => {
    listLatestUploads();
  }, [latestUploads]);

  useEffect(() => {
    setSeasonKeyword("");
    if (seasonId || Common.getQueryParam('sid')) {
      Helper.openPopup("checkoff-popup");
      getSeason();
    }
  }, [seasonId]);

  useEffect(() => {
    listPlayers();
  }, [players, selPlayer]);

  useEffect(() => {
    listCards();
  }, [cards, cardUploads, uploadCount, cardKeyword]);

  useEffect(() => {
    if (Object.keys(selCard).length) {
      listCardUploads();
    }
  }, [selCard, cardUploads]);

  useEffect(() => {
    setSelCard({});
    console.log('selPlayer change', selPlayer)
    getPlayerCards();
  }, [selPlayer]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (seasonKeyword) {
        searchSeason();
      } else {
        if (allSeasons.length) {
          setSeasons(allSeasons);
        }
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [seasonKeyword]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (playerKeyword) {
        searchPlayer();
      } else {
        if (allPlayers.length) {
          setPlayers(allPlayers);
        }
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [playerKeyword]);

  useEffect(() => {
    let err = [];
    if (frontImage[0] && frontImage[0].size > maxFileSize) {
      err.push(<p className="error-text"> Front image size should not be more than {maxFileSizeMB} MB.</p>);
    }
    if (backImage[0] && backImage[0].size > maxFileSize) {
      err.push(<p className="error-text"> Serial number image size should not be more than {maxFileSizeMB} MB. </p>);
    }
    setError(err);
  }, [frontImage, backImage]);

  const getSeasons = () => {
    setShowLoader(true);
    Common.axiosInstance()
      .get(`seasons/get-list`)
      .then((res) => {
        setSelPlayer({});
        setSeasons(res.data.seasons);
        setAllSeasons(res.data.seasons);
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  const handleSeasonClick = (sid) => {
    window.location.href = window.location.origin + '/checkoff?sid=' + sid;
  }

  const listSeasons = () => {
    let rows = [];
    if (seasons.length) {
      seasons.map((s) => {
        rows.push(
          <li onClick={() => handleSeasonClick(s.id)}>
            {s.year} - {s.name}
          </li>
        );
      })
    } else {
      rows.push(
        <li>We couldn't find that product.</li>
      );
    }
    setHtmlSeasons(<ul>{rows}</ul>);
  };

  const listLatestUploads = () => {
    let rows = [];
    if (latestUploads.length) {
      latestUploads.map((uploadedCard) => {
        rows.push(
          <div className="card">
            <img
              src={process.env.REACT_APP_STORAGE_URL + uploadedCard.front_image}
              alt=""
              className="card-image"
              onClick={() => {
                setShowCard(uploadedCard);
                Helper.openPopup("Card-show");
              }}
            />
            <div className="card-desc">

              <div className="player-name"><Link
                to={`/checklists?sid=${uploadedCard.season_id}&pid=${uploadedCard.player_id}`}> {uploadedCard.player_name}</Link>
              </div>
              <div
                className="card-name"> {uploadedCard.card_name} {uploadedCard.unknown ? '?' : uploadedCard.card_number}/{uploadedCard.total_cards}</div>
              <div className="season-name"><Link
                to={`/checklists?sid=${uploadedCard.season_id}`}>{uploadedCard.season_name} - {uploadedCard.season_year}</Link>
              </div>
              <div className="checkoff-card-notes">{uploadedCard.notes}</div>
              <Link to={`/user/profile/${uploadedCard.username}`}
                    className="uploadedBy"> @{uploadedCard.username} </Link>

              <div className="last-check-off">
                {Helper.timeSince(uploadedCard.created_at)} ago
              </div>
            </div>
          </div>
        );
      });
    }else{
      rows.push(<div>No record found.</div>)
    }
    setHtmlLatestUploads(
      <div className="checkOffList">{rows}
        {showLoadMore && <button className="load-more primary-btn" onClick={() => setPage(page + 1)}>Load More</button>}
      </div>);
  };

  const getPlayerCards = () => {
    if (selPlayer.id) {
      console.log('selPlayer.id', selPlayer.id)
      setShowLoader(true);
      Common.axiosInstance()
        .get(`/players/cards?season_id=${season.id}&player_id=${selPlayer.id}`)
        .then((res) => {
          setUploadCount(res.data.uploadCount);
          setCards(res.data.cards);
          setCardUploads(res.data.cardUploads);
          setShowLoader(false);
          Common.setQueryParam('pid', selPlayer.id);
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
  };

  const getSeason = () => {
    setShowLoader(true);
    Common.axiosInstance()
      .get(`seasons/home?id=${seasonId ? seasonId : Common.getQueryParam('sid') ? Common.getQueryParam('sid') : ''}`)
      .then((res) => {
        setSelPlayer({});
        setSeason(res.data.season);
        setPlayers(res.data.players);
        setAllPlayers(res.data.players);
        setShowLoader(false);
        Common.setQueryParam('sid', res.data.season.id);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  const searchSeason = () => {
    setShowLoader(false);
    Common.axiosInstance()
      .get(`seasons/get-list?s=${seasonKeyword}`)
      .then((res) => {
        setSeasons(res.data.seasons);
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  const searchPlayer = () => {
    setShowLoader(false);
    Common.axiosInstance()
      .get(`players/search?s=${playerKeyword}&s_id=${season.id}`)
      .then((res) => {
        setPlayers(res.data.data);
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  const listPlayers = () => {
    let rows = [], selPlayerIndex = 0;
    console.log('selPlayer', selPlayer)
    console.log('players', players)
    if (players.length) {
      players.map((player, i) => {
        if (parseInt(Common.getQueryParam('pid')) === player.id) {
          selPlayerIndex = i;
        }
        rows.push(
          <li
            key={player.id}
            onClick={() => setSelPlayer(player)}
            className={selPlayer.id && selPlayer.id === player.id ? 'active' : ''}
          >
            {player.name}
          </li>
        );
      });
      if (!Object.keys(selPlayer).length && players[selPlayerIndex]) {
        setSelPlayer(players[selPlayerIndex]);
        // Common.setQueryParam('pid', players[selPlayerIndex].id)
      }
    } else {
      rows.push(
        <li>No record found.</li>
      );
    }
    setHtmlPlayers(<ul>{rows}</ul>);
  };

  const listCards = () => {
    let rows = [];
    if (cards.length) {
      cards.map((card) => {
        if (!cardKeyword || card.name.toLowerCase().indexOf(cardKeyword.toLowerCase()) !== -1) {
          rows.push(
            <li key={card.id} onClick={() => setSelCard(Object.assign({}, card))}>
              {card.name} {uploadCount[card.id] ?? 0} / {card.total_cards}{" "}
            </li>
          );
        }
      });
    } else {
      rows.push(
        <li>No record found.</li>
      );
    }
    setHtmlCards(<ul>{rows}</ul>);
  };

  const listCardUploads = () => {
    let rows = [],
      id,
      pendingCls,
      disable,
      unCard;
    const card = selCard;
    //Normal cards
    for (let i = 1; i <= card.total_cards; i++) {
      disable = !!(cardUploads[card.id] && cardUploads[card.id][i]);
      if (disable) {
        pendingCls = cardUploads[card.id][i].status ? `approved` : `pending`;
      } else {
        pendingCls = "";
      }
      id = `card${card.id}-${i}`;
      //normal cards
      rows.push(
        <div className="card-input-check" key={id}>
          <input
            onChange={(e) => setUploadId(card, i)}
            type="checkbox"
            id={id}
            className={`card-check ${pendingCls}`}
            checked={disable}
            defaultChecked={disable}
          />
          <label htmlFor={id}>
            {i}/{card.total_cards}
          </label>
        </div>
      );
    }
    //Unknown cardData
    unCard = cardUploads[card.id] && cardUploads[card.id]["unknown"] ? cardUploads[card.id]["unknown"] : {};
    for (let i = 1; i <= Object.keys(unCard).length; i++) {
      id = `unknown-card${card.id}-${i}`;
      pendingCls = unCard[i].status ? `approved` : `pending`;

      rows.push(
        <div key={id} className="card-input-check">
          <input
            onChange={(e) => showUnknown(unCard[i], i)}
            type="checkbox"
            id={id}
            className={`card-check ${pendingCls}`}
            checked={true}
            defaultChecked={true}
          />
          <label htmlFor={id} className="card-check-label">
            ?/{card.total_cards}
          </label>
        </div>
      );
    }
    //unknown new
    id = `unknown-card${card.id}-new`;
    rows.push(
      <div className="card-input-check">
        <input
          onChange={(e) => e.target.checked && showUpload(card, "?", true, id)}
          type="checkbox"
          id={id}
          className={`card-check`}
        />
        <label htmlFor={id} className="card-check-label">
          ?/{card.total_cards}
        </label>
      </div>
    );
    //END - unknown
    setHtmlCardUploads(<div className="cards-container">{rows}</div>);
    Helper.openPopup("idCardUploads");
  };

  const showUnknown = (card, num) => {
    if (
      cardUploads[card.card_id] &&
      cardUploads[card.card_id]["unknown"] &&
      cardUploads[card.card_id]["unknown"][num] &&
      cardUploads[card.card_id]["unknown"][num].status
    ) {
      showPlayer(cardUploads[card.card_id]["unknown"][num]);
    } else {
      Helper.openPopup("pending-approval");
    }
  };

  const showPlayer = (card) => {
    setUploadedCard(card);
    Helper.openPopup("show-player");
  };

  const showUpload = (card, num, unknown, id) => {
    if (checkLogin()) {
      sessionStorage.setItem("selId", id);
      setSelCard(card);
      setCardNumber(num);
      setUploadUnknown(unknown);
      Helper.openPopup("upload-player");
    }
  };

  const setUploadId = (card, num) => {
    if (checkLogin()) {
      const id = `card${card.id}-${num}`;
      if (!!(cardUploads[card.id] && cardUploads[card.id][num])) {
        setCardNumber(num);
        if (cardUploads[card.id][num] && cardUploads[card.id][num].status) {
          showPlayer(cardUploads[card.id][num]);
        } else {
          Helper.openPopup("pending-approval");
        }
      } else {
        showUpload(card, num, false, id);
      }
    }
  };

  const resetForm = () => {
    setFrontPreview(frontImage[0].preview);
    setBackPreview(backImage[0].preview);
    setFrontImage([]);
    setBackImage([]);
    setNotes("");
  };

  async function saveImages() {
    let err = [];
    if (notes && notes.length > 200) {
      err.push(<p className="error-text">Notes should not be more than 200 characters.</p>);
    }
    if (frontImage.length && backImage.length) {
      if (frontImage[0].size > maxFileSize) {
        err.push(<p className="error-text"> Front image size should not be more than {maxFileSizeMB} MB.</p>);
      }
      if (backImage[0].size > maxFileSize) {
        err.push(<p className="error-text"> Serial number image size should not be more than {maxFileSizeMB} MB. </p>);
      }
      if (!err.length) {
        setSaveLoader(true);

        const data = new FormData();
        data.append("front_image", frontImage[0]);
        data.append("back_image", backImage[0]);
        data.append("notes", notes);
        data.append(
          "card_number",
          uploadUnknown
            ? cardUploads[selCard.id] && cardUploads[selCard.id]["unknown"]
            ? Object.keys(
            cardUploads[selCard.id] && cardUploads[selCard.id]["unknown"]
            ).length + 1
            : 1
            : cardNumber
        );
        data.append("unknown", uploadUnknown ? 1 : 0);
        data.append("season_id", season.id);
        data.append("player_id", selPlayer.id);
        data.append("card_id", selCard.id);

        Common.axiosInstance()
          .post("card-uploads", data)
          .then((res) => {
            if (res.data.success) {
              getPlayerCards();
              resetForm();
              Helper.closePopup("upload-player");
              Helper.openPopup("success-popup");
              setSaveLoader(false);
            } else {
              setError(Common.parseErrors(res.data.error));
              Common.ScrollTop();
            }
            setSaveLoader(false);
          })
          .catch((error) => {
            Helper.closePopup("upload-player");
            Helper.openPopup("error-popup");
            console.error("Error adding document: ", error);
          });
      }
    } else {
      err.push(<p>Upload images of the card's front and serial number</p>);
    }
    setError(err);
  }

  return (
    <div className="check-page-container">
      <div className="checkoff-container">
        <div className="checkOffCards">
          <div className="containerDesc accordionToggle"
               onClick={() => setShowShowToggleClass(!showToggleClass)}>Check-Off A Card <span
            className="accordin-toggle">
              <img src={expandAccordion}/>
            </span></div>
          <div className={`checkOffs ${showToggleClass ? 'toggle' : ''}`}>
            Which product is your card from?
            <input
              type="text"
              id="search-card"
              placeholder="Enter product name (e.g. Optic Basketball)"
              value={seasonKeyword}
              onChange={(e) => setSeasonKeyword(e.target.value)}
            />
            <div class="checkoffs-list">
              {htmlSeasons}
            </div>
          </div>
        </div>
        <div className="recentCheckOffs">
          <div className="containerDesc withSearch">
            <div className="checkoffsheading">Check-Offs Feed</div>
            <div className="searchInputContainer">
              <input className="search" placeholder="Search by card, product or player"
                     value={uploadKeyword}
                     onChange={(e) => setUploadKeyword(e.target.value)}
              />
              <img className="searchImg" src={searchIcon}/>
            </div>
          </div>
          <div className="checkOffListContainer">
            {htmlLatestUploads}

          </div>
        </div>
      </div>
      <Advertisement/>


      <LoginRequired page={'checkoff'} seasonId={season.id} playerId={selPlayer.id}/>

      <div className="popup-overlay" id="success-popup">
        <div className="player-popup">
          <h2 className="card-name">Success!</h2>
          <div className="card-upload-area">
            <div id="bang">
              ✅ NICE! You checked off: {selPlayer.name} - {selCard.name} {cardNumber}/{selCard.total_cards}
              <div className="uploadedImages">
                <img src={frontPreview} alt=''/>
                <img src={backPreview} alt=''/>
              </div>
              Your points will appear in your profile once we verify a few things
              <div className="button-group">
                <a href='javascript:void(0)' onClick={() => window.location.reload()}>Back to Check-Offs</a>
                <a href={"/profile"}>View Your Profile</a>
              </div>
            </div>
          </div>
          <button
            className="close-button"
            onClick={(event) => Helper.closePopup("success-popup")}
          >
            X
          </button>
        </div>
      </div>

      <div className="popup-overlay" id="error-popup">
        <div className="player-popup">
          <h2>Error</h2>
          <div className="card-upload-area">
            <div>
              There is some issue in uploading the images, please try after
              sometime.
            </div>
          </div>
          <button
            className="close-button"
            onClick={(event) => Helper.closePopup("error-popup")}
          >
            X
          </button>
        </div>
      </div>

      <div className="popup-overlay" id="show-player">
        <div className="player-popup">
          {uploadedCard.front_image ? (
            <>
              <h2>
                {uploadedCard.player_name} - {uploadedCard.card_name}{" "}
                {uploadedCard.unknown ? "?" : uploadedCard.card_number}/
                {uploadedCard.total_cards}
              </h2>
              <div className="popup-hit-image-container">
                <img
                  src={
                    process.env.REACT_APP_STORAGE_URL + uploadedCard.front_image
                  }
                  alt=""
                  className="uploaded-card"
                />
                <img
                  src={
                    process.env.REACT_APP_STORAGE_URL + uploadedCard.back_image
                  }
                  alt=""
                  className="uploaded-card"
                />
              </div>

              <div className="top-hits-card-details">
                {uploadedCard.player_name} <br/>
                {uploadedCard.card_name}{" "}
                {uploadedCard.unknown ? "?" : uploadedCard.card_number}/
                {uploadedCard.total_cards} <br/>
                published {Helper.timeSince(uploadedCard.created_at)} ago by @
                {uploadedCard.username} <br/>
              </div>
              <p className="submitted-notes">
                {uploadedCard.notes ? `Note: ${uploadedCard.notes}` : ''}
              </p>
              <button
                className="close-button"
                onClick={(event) => Helper.closePopup("show-player")}
              >
                X
              </button>
            </>
          ) : null}
        </div>
      </div>

      <div className="popup-overlay" id="pending-approval">
        <div className="player-popup">
          <h2>
            {selPlayer.name} - {selCard.name} {cardNumber}/{selCard.total_cards}
          </h2>
          <h2>PENDING</h2>
          <div className="card-upload-area">
            <div id="pending">
              This hit will appear with a green checkmark once approved. Thank
              you!
            </div>
          </div>
          <button
            className="close-button"
            onClick={(event) => Helper.closePopup("pending-approval")}
          >
            X
          </button>
        </div>
      </div>

      <div className="popup-overlay" id="checkoff-popup">
        <div className="checkoff-popup">
          {
            showLoader ? <img
                src={loader}
                alt={"Loading..."}
                className="pagination-loader"
              />
              : <>
                <div className="followDiv">
                  <div className="checkoff-name">{season.year} - {season.name}</div>
                  <SeasonFollow season={season} following={!!following} follow={!following} setFollowing={setFollowing}
                                setShowLoader={() => {
                                }}/>
                </div>
                <div className="link"><Link to={`/checklists/?sid=${season.id}`}>-View Full Checklist</Link></div>
                <div className="popupCheckOffContainer">
                  <div className="popup-checkOffs">
                    <input
                      type="text"
                      name="search"
                      id="althlete-name"
                      placeholder="Athlete’s name? (e.g. Luka Doncic)"
                      value={playerKeyword}
                      onChange={(e) => setPlayerKeyword(e.target.value)}
                    />
                    <div className="list-container">
                      {htmlPlayers}
                    </div>
                  </div>

                  <div className="check-off-search-list">
                    <input
                      type="text"
                      name="search"
                      id="search-card"
                      placeholder="Search by card e.g. gold, logoman"
                      onKeyUp={(e) => setCardKeyword(e.target.value)}
                    />
                    {htmlCards}
                  </div>
                </div>

                <button
                  className="close-button"
                  onClick={(event) => Helper.closePopup("checkoff-popup")}
                >
                  X
                </button>
              </>
          }
        </div>
      </div>
      <div className="popup-overlay" id="idCardUploads">
        <div className="season-player-popup">
          <div className="card-name">
            {selPlayer.name} - {selCard.name} #/{selCard.total_cards}
          </div>
          {htmlCardUploads}
          <div className="follow">
            <RWebShare
              data={{
                text: `Gemint - ${season.year} ${season.name}`,
                url: window.location.href,
                title: `Gemint - ${season.year} ${season.name}`,
              }}
            >
              <img src={shareIcon} alt="" className="shareImage"/>
            </RWebShare>
          </div>
          <button
            className="close-button"
            onClick={(event) => Helper.closePopup("idCardUploads")}
          >
            X
          </button>
        </div>
      </div>
      <div className="popup-overlay" id="upload-player">
        <div className="player-popup upload-popup">
          <h2>
            {selPlayer.name} - {selCard.name} {cardNumber}/{selCard.total_cards}
          </h2>
          <div style={{color: "#fda130"}}>
            {error}
          </div>
          <div className="card-upload-area">
            <div>
              <CardImage
                files={frontImage}
                setFiles={setFrontImage}
                desc={"Upload Front"}
              />
            </div>
            <div>
              <CardImage
                files={backImage}
                setFiles={setBackImage}
                desc={"Serial Number"}
              />
            </div>
          </div>

          <textarea
            name="Card Notes"
            rows="5"
            className="card-notes"
            placeholder="Enter Details"
            onChange={(e) => setNotes(e.target.value)}
            value={notes}
          />
          <div style={{color: "#FFF"}}>Max 200 characters</div>
          <div className="button-container">
            {saveLoader ? (
              <img
                src={loader}
                alt={"Loading..."}
                className="pagination-loader"
              />
            ) : null}
            <button className="submit-button" onClick={() => saveImages()}>
              Submit
            </button>
            <button
              className="ebay-popup-button"
              onClick={(event) => Helper.openPopup("ebay-coming-soon")}
            >
              Upload From Ebay
            </button>
            <button
              className="reset-button"
              onClick={() => Helper.closePopup("upload-player")}
            >
              Cancel
            </button>
          </div>

          <button
            className="close-button"
            onClick={(event) => Helper.closePopup("upload-player")}
          >
            X
          </button>
        </div>
      </div>

      <ShowCard card={showCard}/>

      <div className="popup-overlay" id="ebay-coming-soon">
        <div className="player-upload-popup">
          <h5 className="upload-desc">
            Coming soon! <br/>Copy & Paste your eBay listing onto Gemint!
          </h5>

          <button
            className="close-button"
            onClick={(event) => Helper.closePopup("ebay-coming-soon")}
          >
            X
          </button>
        </div>
      </div>
    </div>
  );
}

export default Checkoff;
