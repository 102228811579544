import React, { useState, useEffect, useContext } from "react";
import { useDropzone } from "react-dropzone";
import globalContext from "../context/context";
import styled from "styled-components";

const Container = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  .dropzone {
    color: black !important;
    display: inline-block;
    background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 50px;
    margin-bottom: 20px;
  }
  .dropzone > p {
    color: black;
    padding: 30px 70px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-family: "Work Sans", sans-serif;
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 20px;

    @media (max-width: 768px) {
      padding: 5px 10px;
    }
  }

  .upload-image-button {
    margin-bottom: 10px;
  }

  .upload-image-button:disabled {
    border-color: gray;
    cursor: not-allowed;
  }
`;

const UploadStatus = styled.div`
  color: white;
  text-align: center;
  font-size: 20px;
`;

const ThumbPreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const thumb = {
  display: "flex",
  borderRadius: 2,
  alignItems: "center",
  justifyContent: "center",
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function ImageDrop(props) {
  const [files, setFiles] = useState([]);
  const { user, setUser, firebase, db } = useContext(globalContext);
  const [imageUploadStatus, setImageUploadStatus] = useState("");

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <img alt="Preview" src={file.preview} style={img} />
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  function uploadImage(e) {
    e.preventDefault();
    // Upload image

    const file = files[0];
    // Upload file and metadata to the object 'images/mountains.jpg'
    console.log(file);
    console.log(file.name);

    var storageRef = firebase.storage().ref();
    var userImagesRef = storageRef.child("users/" + file.name);
    userImagesRef.put(file).then((snapshot) => {
      console.log("Uploaded a blob or file!");
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        console.log("File available at", downloadURL);
        updateUserPhoto(downloadURL);
      });
    });
  }

  function updateUserPhoto(downloadURL) {
    console.log(typeof downloadURL);
    db.collection("users")
      .doc(user.userId)
      .set(
        {
          photoUrl: downloadURL,
        },
        { merge: true }
      )
      .then(() => {
        setUser({
          ...user,
          photoUrl: downloadURL,
        });
        setImageUploadStatus("Your image was updated.");
        console.log("Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
        setImageUploadStatus(
          "There was a problem updating your image. Please try again."
        );
      });
  }

  return (
    <Container>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p>Drop an image, or click to select files</p>
      </div>
      <ThumbPreview>{thumbs}</ThumbPreview>
      {files.length > 0 ? (
        <>
          <button
            className="btn abtn upload-image-button"
            onClick={(e) => uploadImage(e)}
            disabled={files.length < 1}
          >
            Save
          </button>
          <UploadStatus>{imageUploadStatus}</UploadStatus>
        </>
      ) : null}
    </Container>
  );
}

export default ImageDrop;
