const initialData = {
    tasks: {
      "task-1": { id: "task-1", content: "Atlantic" },
      "task-2": { id: "task-2", content: "Central" },
      "task-3": { id: "task-3", content: "Metropolitan" },
      "task-4": { id: "task-4", content: "Pacific" },
    //   "task-5": { id: "task-5", content: "Pacific" },
    //   "task-6": { id: "task-6", content: "Southwest" },
    },
    columns: {
      "column-1": {
        id: "column-1",
        title: "Divisions",
        taskIds: ["task-1", "task-2", "task-3", "task-4"],
      },
    },
    // Facilitate reordering of the columns
    columnOrder: ["column-1"],
  };
  
  export default initialData;
  