import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { Droppable } from "react-beautiful-dnd";
import Task from "./task";
import { DateTime } from "luxon";
import globalContext from "../../context/context";

let dt = DateTime.now();

const LockedColumnWrapper = styled.div`
  width: 25%;
  margin: 8px;
  color: white;

  @media only screen and (max-width: 768px) {
    flex: 1;
    margin: 0;
  }
`;

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  width: 100%;
  background-color: #303030;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  color: white;
`;

const Title = styled.h3`
  padding: 8px;
  color: white;
  text-align: center;
  font-size: 25px;
`;

const TaskList = styled.div`
  padding: 8px;
  background-color: ${(props) => (props.isDraggingOver ? "black" : "#303030")};
  flex-grow: 1;
  min-height: 100px;
`;

const Button = styled.button`
  @media only screen and (max-width: 768px) {
    font-size: 15px;
    padding: 0px;
    width: 100%;
  }
`;

const Label = styled.label`
  font-size: 25px;
  margin-left: 10px;
  margin-top: 4px;
  color: white;

  @media only screen and (max-width: 768px) {
    font-size: 15px;
    margin-left: 0px;
  }
`;

const Label2 = styled.label`
  font-size: 25px;
  margin-left: 10px;
  margin-top: 4px;
  color: white;

  @media only screen and (max-width: 768px) {
    font-size: 15px;
    margin-left: 0px;
  }
`;

export default function LockedColumn({
  column,
  tasks,
  updateData,
  disabled,
  showPopup,
}) {
  const [lockedTasks, setLockedTasks] = useState(tasks);
  const [timestamp, setTimestamp] = useState(
    DateTime.now().toLocaleString() +
      " " +
      dt.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
  );
  const [count, setCount] = useState(0);
  const { clipboardData, setClipboardData } = useContext(globalContext);

  useEffect(() => {
    console.log('tasks = ', tasks)
    console.log('locked tasks = ', lockedTasks)

    if (tasks !== lockedTasks) {
      setLockedTasks(tasks);
    }
  }, [tasks]);

  const randomizeColumn = () => {
    if (disabled) {
      showPopup();
    }
    let array = tasks;

    const mixedArray = array.map(function (x, i) {
      var cryptoRandom = window.crypto.getRandomValues(new Uint32Array(1))[0];
      return { name: x, value: cryptoRandom };
    });

    mixedArray.sort((a, b) => (a.value > b.value ? 1 : -1));

    const randomizedArray = mixedArray.map((name, i) => {
      let cleanName = name.name;
      return cleanName;
    });

    //update column
    setLockedTasks(randomizedArray);
    const newTaskIds = randomizedArray.map((item) => item.id);
    const newColumn = {
      ...column,
      taskIds: [...newTaskIds],
    };
    updateData(newColumn);
    const tierColumn = Number(column.id.slice(-1));
    const cleanClipboardArray = clipboardData;

    randomizedArray.map((team, index) => {
      //For the number of spots available map the team to the corresponding spot's array
      cleanClipboardArray[index][tierColumn] = team.content;
    });

    setClipboardData(cleanClipboardArray);

    let dtnow = DateTime.now();
    let time =
      DateTime.now().toLocaleString() +
      " " +
      dtnow.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);

    setTimestamp(time);
    setCount(count + 1);
    return;
  };

  const randomizeAll = () => {
    alert("randomize all")
    if (disabled) {
      showPopup();
    }
    let array = tasks;
    console.log('array = ', array)

    const mixedArray = array.map(function (x, i) {
      var cryptoRandom = window.crypto.getRandomValues(new Uint32Array(1))[0];
      return { name: x, value: cryptoRandom };
    });

    mixedArray.sort((a, b) => (a.value > b.value ? 1 : -1));

    const randomizedArray = mixedArray.map((name, i) => {
      let cleanName = name.name;
      return cleanName;
    });

    //update column
    setLockedTasks(randomizedArray);
    const newTaskIds = randomizedArray.map((item) => item.id);
    const newColumn = {
      ...column,
      taskIds: [...newTaskIds],
    };
    updateData(newColumn);
    const tierColumn = Number(column.id.slice(-1));
    const cleanClipboardArray = clipboardData;

    randomizedArray.map((team, index) => {
      //For the number of spots available map the team to the corresponding spot's array
      cleanClipboardArray[index][tierColumn] = team.content;
    });

    setClipboardData(cleanClipboardArray);

    let dtnow = DateTime.now();
    let time =
      DateTime.now().toLocaleString() +
      " " +
      dtnow.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);

    setTimestamp(time);
    setCount(count + 1);
    return;
    
    };

  return (
    <LockedColumnWrapper>
      <Container>
        <Title>{column.title}</Title>
        <Droppable droppableId={column.id} isDropDisabled>
          {(provided, snapshot) => (
            <TaskList
              ref={provided.innerRef}
              innerRef={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {lockedTasks.map((task, index) => (
                <Task key={task.id} task={task} index={index} />
              ))}
              {provided.placeholder}
            </TaskList>
          )}
        </Droppable>
      </Container>
      <Button
        className="btn-outline"
        onClick={(e) => randomizeColumn()}
        disabled={disabled}
      >
        Randomize
      </Button>
      {/* <Button
        className="btn-outline"
        onClick={(e) => randomizeAll()}
        disabled={disabled}
      >
        All
      </Button> */}
      <Label>Randomize counter: {count}</Label>
      <Label2>{timestamp}</Label2>
    </LockedColumnWrapper>
  );
}
