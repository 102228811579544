const initialData = {
    tasks: {
      "task-1": { id: "task-1", content: "Borussia Dortmund" },
      "task-2": { id: "task-2", content: "RB Leipzig" },
      "task-3": { id: "task-3", content: "Atalanta B.C." },
      "task-4": { id: "task-4", content: "AFC Ajax" },
      "task-5": { id: "task-5", content: "Club Atlético de Madrid" },
      "task-6": { id: "task-6", content: "VfL Wolfsburg" },
      "task-7": { id: "task-7", content: "Barcelona" },
      "task-8": { id: "task-8", content: "Paris Saint-Germain" },
      "task-9": { id: "task-9", content: "LOSC Lille" },
      "task-10": { id: "task-10", content: "FC Salzburg RC" },
      "task-11": { id: "task-11", content: "Real Madrid" },
      "task-12": { id: "task-12", content: "Chelsea" },
      "task-13": { id: "task-13", content: "Inter Milan" },
      "task-14": { id: "task-14", content: "Liverpool" },
      "task-15": { id: "task-15", content: "Club Brugge" },
      "task-16": { id: "task-16", content: "Juventus" },
      "task-17": { id: "task-17", content: "Manchester United" },
      "task-18": { id: "task-18", content: "Manchester City" },
      "task-19": { id: "task-19", content: "Sporting Clube De Portugal" },
      "task-20": { id: "task-20", content: "FC Porto" },
      "task-21": { id: "task-21", content: "Sevilla FC" },
      "task-22": { id: "task-22", content: "AC Milan" },
      "task-23": { id: "task-23", content: "Bayern Munich" },

        
    },
    columns: {
      "column-1": {
        id: "column-1",
        title: "Tier 1",
        taskIds: [
          "task-1",
          "task-2",
          "task-3",
          "task-4",
          "task-5",
          "task-6",
          "task-7",
          "task-8",
       
        ],
      },
      "column-2": {
        id: "column-2",
        title: "Tier 2",
        taskIds: [
          "task-9",
          "task-10",
          "task-11",
          "task-12",
          "task-13",
          "task-14",
          "task-15",
          "task-23",

         
        ],
      },
      "column-3": {
        id: "column-3",
        title: "Tier 3",
        taskIds: [
          "task-16",
          "task-17",
          "task-18",
          "task-19",
        "task-20",
        "task-21",
        "task-22",
        ],
      },
    },
    // Facilitate reordering of the columns
    columnOrder: ["column-1", "column-2", "column-3"],
  };
  
  export default initialData;
  