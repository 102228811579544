import React, { useState } from "react";
import styled from "styled-components";
import { Droppable } from "react-beautiful-dnd";
import Task from "./task";

const Container = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  width: 25%;
  background-color: #303030;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  display: flex;
  flex-direction: column;
  color: white;

  @media only screen and (max-width: 768px) {
    flex: 1;
    margin: 0;
  }
`;
const Title = styled.h3`
  padding: 8px;
  color: white;
  text-align: center;
  font-size: 25px;
`;

const TaskList = styled.div`
  padding: 8px;
  background-color: ${(props) => (props.isDraggingOver ? "black" : "#303030")};
  flex-grow: 1;
  min-height: 100px;
`;

export default function Column(props) {
  return (
    <Container>
      <Title>{props.column.title}</Title>

      <Droppable droppableId={props.column.id} isDropDisabled={props.disabled}>
        {(provided, snapshot) => (
          <TaskList
            ref={provided.innerRef}
            innerRef={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {props.tasks.map((task, index) => (
              <Task key={task.id} task={task} index={index} />
            ))}
            {provided.placeholder}
          </TaskList>
        )}
      </Droppable>
    </Container>
  );
}
