import React, { useState, useContext } from "react";
import styled from "styled-components";
import globalContext from "../../context/context";
import PlusImage from "../../img/plus.png";
import MinusImage from "../../img/minus.png";
import "./SpotColumn.css"
import { MdFiberNew } from "react-icons/md";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Container = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  width: 25%;
  display: flex;
  flex-direction: column;
  color: white;
  background-color: #303030;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;

  @media only screen and (max-width: 730px) {
    width: 100%;
  }
`;
const Title = styled.h3`
  padding: 8px;
  color: white;
  text-align: center;
  font-size: 25px;
`;
const TaskList = styled.div`
  padding: 8px;
  flex-grow: 1;
  min-height: 100px;
  color: white;

  & p {
    border-bottom: 1px solid lightgrey;
    padding-top: 4px;
    font-size: 0.8em;
    color: white;
  }
`;

const Input = styled.input`
  background-color: transparent;
  border: none;
  color: white;
  max-width: 100%;
  width: 100%;
  padding-right: 15%;
  margin-bottom: 8px;
  height: 42px;
  box-sizing: border-box;
  font-size: 25px;
  border: 1px solid white;
  text-align: left;
  padding-left: 5px;
  
`;

const Button = styled.button`
  border: 1px solid #3fd999;
  width: 36px;
  padding: 0;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;

  img {
    width: 100%;
    height: 100%;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const ClearAllButton = styled.button`
  padding: 4px 8px;
  background: transparent;
  font-size: 14px;
  font-weight: 900;
  height: 36px;
  box-sizing: border-box;
  border: 1px solid #3fd999;
  cursor: pointer;
 
    border: 1px solid #fda130 !important;
    color: #fda130 !important;

  &:disabled {
    cursor: not-allowed;
  }
`;

export default function SpotColumn(props) {
  const { clipboardData, setClipboardData } = useContext(globalContext);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [toggle, setToggle] = useState(false)
  const [checked, setChecked] = useState([]);



  const handlePaste = (e) => {
    e.preventDefault();

    const excelData = e.clipboardData.getData("Text");
    const excelDataArray = excelData
      .split("\n")
      .map((item) => item.replace("\t", ""));

    let newSpots =
      currentIndex + excelDataArray.length < props.spots.length
        ? props.spots
            .slice(0, currentIndex)
            .concat(excelDataArray)
            .concat(props.spots.slice(currentIndex + excelDataArray.length))
        : props.spots.slice(0, currentIndex).concat(excelDataArray);

    props.setSpots(newSpots);
    const updateClipboardArr = [];
    for (let i = 0; i < newSpots.length; i++) {
      updateClipboardArr.push([newSpots[i], " ", " "]);
    }
    setClipboardData(updateClipboardArr);
  };

  const handleClearAll = () => {
// reset checkboxes
var inputs = document.querySelectorAll('#checkbox');
for (var i = 0; i < inputs.length; i++) {
    inputs[i].checked = false;
}





    if (props.disabled) {
      props.showPopup();
      return;
    }
    let newSpots = [...props.spots];
    const updateClipboardArr = [];

    for (let i = 0; i < props.spots.length; i++) {
      newSpots[i] = "";
      updateClipboardArr.push(["", " ", " "]);
    }

    props.setSpots(newSpots);
    setClipboardData(updateClipboardArr);
  };

  function addSpot() {
    if (props.disabled) {
      props.showPopup();
      return;
    }
    const previousNo = props.spots.length;
    const newNo = previousNo + 1;
    props.setSpots([...props.spots, ""]);
    const updateClipboardArr = [];
    for (let i = 0; i < newNo; i++) {
      updateClipboardArr.push([props.spots[i], " ", " "]);
    }
    setClipboardData(updateClipboardArr);
  }

  function removeSpot() {
    if (props.disabled) {
      props.showPopup();
      return;
    }
    let newSpots = [...props.spots];
    newSpots.pop();
    props.setSpots(newSpots);
    const updateClipboardArr = [];
    for (let i = 0; i < newSpots.length; i++) {
      updateClipboardArr.push([newSpots[i], " ", " "]);
    }
    setClipboardData(updateClipboardArr);
  }

  function spotChange(value, index) {
    if (props.disabled) {
      props.showPopup();
      return;
    }
    const cleanClipboardArray = clipboardData;
    cleanClipboardArray[index][0] = value;
    setClipboardData(cleanClipboardArray);

    let customSpots = props.spots.slice();
    customSpots[index] = value;
    props.setSpots(customSpots);
  }

  const handleEnter = (e, index) => {
    if (e.key === "Enter") {
      const nextInput = document.querySelector(`.spot-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
        nextInput.select();
      }
    }
  };
  function spotPaymentToggle () {
   
  // toggle true/false between randomize list and collect payment
    setToggle(!toggle)
    var checkboxContainer = document.querySelector('.checkbox-container')
  
    // randomize list toggle 
    if (toggle == true) {
   
      checkboxContainer.style.display = "none"


     
  
    }
    // collect payment toggle 
    else {

      checkboxContainer.style.width = "15%"
      checkboxContainer.style.marginLeft = "85%"
      checkboxContainer.style.display = "flex"

    
  
    }
  }

  return (
    <Container>
      <Title>Spot</Title>
     
      <TaskList>
      <div className='checkbox-container'>
      {props.spots.map((spot, index) => (
          <input
            type='checkbox'
            id='checkbox'
          
          />
        ))}
        </div>
        {props.spots.map((spot, index) => (
          <Input
          id='input-id'
            className={`spot-${index}`}
            placeholder={`${index + 1}`}
            key={index}
            value={spot}
            contenteditable="true"
            onChange={(e) => spotChange(e.target.value, index)}
            onKeyDown={(e) => handleEnter(e, index)}
            onPaste={(e) => handlePaste(e)}
            onClick={() => setCurrentIndex(index)}
          
          />
        ))}
       
        <ButtonWrapper>
          <Button onClick={() => removeSpot()}>
            <img src={MinusImage} alt="" />
          </Button>
          <Button onClick={() => addSpot()}>
            <img src={PlusImage} alt="" />
          </Button>
          <ClearAllButton onClick={() => handleClearAll()}>
            Clear
          </ClearAllButton>
         
         </ButtonWrapper>
         <div className='spot-column-toggle'>
         <p className='payment-p'>
                    Randomize <br/>list
                    </p>
                    <label className="switch">
                    <input 
                    onClick={() => spotPaymentToggle()} 
                    type="checkbox" />

  <span className="randomizer-slider round"></span>
</label>
<p className='payment-p'>
Collect <br/>payment
                    </p>
                    {/* <div className='new-icon-wrapper'>
                    <MdFiberNew size={35} color='lightgreen' />
                    </div> */}
                    </div >
      </TaskList>
    </Container>
  );
}
