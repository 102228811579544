import React, { useState, useEffect, useRef } from "react";
import "./CustomDropDown.css";

const CustomDropDown = ({
  items,
  headerTitle,
  handleItemClick,
}) => {
  const [openDropdown, setOpendDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [header, setHeader] = useState(headerTitle);
  const handleClick = (item) => {
    handleItemClick(item.data);
    setHeader(item.title);
    setOpendDropdown(false);
  };
  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        setOpendDropdown(!openDropdown);
      }
    };

    // If the item is active (ie open) then listen for clicks
    if (openDropdown) {
      window.addEventListener("click", pageClickEvent);
    }

    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [openDropdown]);

  return (
    <div className="custom-dropdown">
      <div
        className="custom-dropdown-header"
        onClick={() => setOpendDropdown(!openDropdown)}
      >
        {header}
      </div>
      {openDropdown && (
        <div
          className={`custom-dropdown-items ${
            openDropdown ? "active" : "inactive"
          }`}
          ref={dropdownRef}
        >
          {items.map((item, index) => (
            <div
              className="custom-dropdown-item"
              key={index}
              onClick={() => handleClick(item)}
            >
              {item.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropDown;
