import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../utils/API";

const initialState = {
  isLoading: false,
  updatedUser: null,
  error: null,
  sendResult: null,
};

const isLoading = (action) => {
  return [
    "subscription/createSubscription/pending",
    "subscription/cancelSubscription/pending",
    "subscription/saveTierData/pending",
    "subscription/share/pending",
  ].includes(action.type);
};

const isFinishLoading = (action) => {
  return [
    "subscription/createSubscription/fulfilled",
    "subscription/createSubscription/rejected",
    "subscription/cancelSubscription/fulfilled",
    "subscription/cancelSubscription/rejected",
    "subscription/saveTierData/fulfilled",
    "subscription/saveTierData/rejected",
    "subscription/share/fulfilled",
    "subscription/share/rejected",
  ].includes(action.type);
};

const isRejected = (action) => {
  return [
    "subscription/createSubscription/rejected",
    "subscription/cancelSubscription/rejected",
    "subscription/saveTierData/rejected",
    "subscription/share/rejected",
  ].includes(action.type);
};

export const createSubscription = createAsyncThunk(
  "subscription/createSubscription",
  async (values, { rejectWithValue }) => {

    try {
      const { data } = await API.post(`subscription`, values
      );
    
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const welcomeEmail = createAsyncThunk(
  "subscription/welcome",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await API.post(`subscription/welcome`, values);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "subscription/cancelSubscription",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await API.post(`subscription/cancel`, values);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const shareResult = createAsyncThunk(
  "subscription/share",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await API.post(`subscription/share`, values);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const saveTierData = createAsyncThunk(
  "subscription/saveTierData",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await API.put(`subscription/saveTierData`, values);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createSubscription.fulfilled, (state, action) => {
        state.updatedUser = action.payload;
      })
      .addCase(welcomeEmail.fulfilled, (state, action) => {
        state.sendResult = action.payload;
      })
      .addCase(shareResult.fulfilled, (state, action) => {
        state.sendResult = action.payload;
      })
      .addCase(saveTierData.fulfilled, (state, action) => {
        state.updatedUser = action.payload;
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        state.updatedUser = action.payload;
      })
      .addMatcher(isLoading, (state, action) => {
        state.isLoading = true;
      })
      .addMatcher(isRejected, (state, action) => {
        state.error = action.payload;
      })
      .addMatcher(isFinishLoading, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default subscriptionSlice.reducer;
