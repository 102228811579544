import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import globalContext from "../../../../context/context";
import * as Helper from "../../includes/Helper";
import loader from "../../includes/loader.gif";
import "../../includes/Hit.css";
import jquery from "jquery";
import * as Common from "../../../../components/Common";
import { useParams } from "react-router";

const AddEditCard = () => {
  const [formValues, setFormValues] = useState([{ name: "", total_cards: "" }]);
  const [error, setError] = useState([]);
  const [season, setSeason] = useState([]);
  const [players, setPlayers] = useState([]);
  const [playerId, setPlayerId] = useState([]);
  const [playerHtml, setPlayerHtml] = useState([
    "Select a season to show the player list.",
  ]);
  const [seasonPlayers, setSeasonPlayers] = useState([]);
  const { db, firebase } = useContext(globalContext);
  const [showLoader, setShowLoader] = useState(false);
  const { season_id } = useParams();
  const { card_id } = useParams();
  let history = useHistory();

  useEffect(() => {
    //redirect if user is not admin
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        history.push("/");
      }
      db.collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const u = doc.data();
            if (!u.isAdmin) {
              history.push("/");
            }
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    });
    Helper.onLoad();
    setShowLoader(true);

    Common.axiosInstance()
      .get(`cards/meta/${season_id}/${card_id}`)
      .then((res) => {
        setPlayers(res.data.players);
        setSeason(res.data.season);
        setPlayerId(
          Object.values(res.data.selected_players).map((elem) => String(elem))
        );
        setFormValues([
          { name: res.data.card.name, total_cards: res.data.card.total_cards },
        ]);
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  }, []);

  useEffect(() => {
    console.log("playerId", playerId);
    listPlayers();
  }, [playerId]);

  const checkPlayers = (chk) => {
    jquery(".player").prop("checked", chk);
    if (chk) {
      const sp = Object.keys(players);
      setPlayerId([...sp]);
    } else {
      setPlayerId([]);
    }
  };

  const listPlayers = () => {
    let rows = [];
    if (players) {
      rows.push(
        <>
          <input
            type="checkbox"
            id={"check-all"}
            onChange={(e) => checkPlayers(e.target.checked)}
          />
          &nbsp;&nbsp;
          <label htmlFor={"check-all"}>Select All</label>
          <br />
        </>
      );
      let sortList = [];
      for (let pId in players) {
        sortList.push({ id: pId, name: players[pId] });
      }
      sortList.sort((a, b) => a.name.localeCompare(b.name));

      for (let x in sortList) {
        rows.push(
          <>
            <input
              type="checkbox"
              key={sortList[x].id}
              id={sortList[x].id}
              value={sortList[x].id}
              checked={playerId.includes(sortList[x].id)}
              onChange={(e) => setSelectedPlayers(e)}
              className={"player-check"}
            />
            &nbsp;&nbsp;
            <label htmlFor={sortList[x].id}>{sortList[x].name}</label>
            <br />
          </>
        );
      }
    }
    setPlayerHtml(rows);
  };

  const setSelectedPlayers = (e) => {
    const checked = e.target.checked;
    const id = e.target.value;
    let p = playerId;
    if (checked && !p.includes(id)) {
      p.push(id);
    }
    if (!checked) {
      const index = p.indexOf(id);
      if (index > -1) {
        // only splice array when item is found
        p.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    setPlayerId([...p]);
  };

  const saveCard = async () => {
    let err = [];
    if (playerId.length === 0) {
      err.push(<p>Please select the players.</p>);
    }

    if (err.length) {
      setError(err);
      window.scrollTo(0, 0);
    } else {
      let name = [];
      for (let x in formValues) {
        if (formValues[x].name && formValues[x].total_cards) {
          if (
            isNaN(formValues[x].total_cards) ||
            formValues[x].total_cards < 1
          ) {
            err.push(`Total cards should be a positive number.`);
          }
          if (name.includes(formValues[x].name.toLowerCase())) {
            err.push(`Card name should be unique.`);
          }
          name.push(formValues[x].name.toLowerCase());
        } else {
          err.push(`Enter card name and total in all cards.`);
        }
      }
      if (err.length) {
        setError(err);
        window.scrollTo(0, 0);
        return false;
      }

      setShowLoader(true);
      Common.axiosInstance()
        .post("cards", {
          data: formValues,
          season_id,
          player_id: playerId,
          card_id,
        })
        .then((res) => {
          if (res.data.success) {
            history.push(
              `/cards/list/${season_id}?action=${card_id ? "edit" : "add"}`
            );
          } else {
            setError(Common.parseErrors(res.data.error));
            Common.ScrollTop();
          }
          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
  };

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { name: "", total_cards: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  return (
    <div className="login-page container">
      <h1>
        {season.name} - {card_id ? "Edit" : "Add"} Card
      </h1>
      <div className="pagination" style={{ color: "red" }}>
        {error}
      </div>
      <div className="pagination">
        {showLoader && (
          <img src={loader} alt={"Loading..."} className="pagination-loader" />
        )}
      </div>
      <form>
        <div className="sign-in-form-wrapper">
          <label>Players</label>
          <br />
          {playerHtml}
        </div>
        {formValues.map((element, index) => (
          <>
            <div className="input-field-wrapper d-flex">
              <div className="sign-in-form-wrapper card-name">
                <label>Card Name</label>
                <input
                  autoFocus
                  type="text"
                  name="name"
                  value={element.name || ""}
                  onChange={(e) => handleChange(index, e)}
                  placeholder="Enter Card's Name"
                  required
                />
              </div>
              <div className="sign-in-form-wrapper total-cards">
                <label>Total Cards</label>
                <input
                  autoFocus
                  type="text"
                  name="total_cards"
                  value={element.total_cards || ""}
                  onChange={(e) => handleChange(index, e)}
                  placeholder="Enter Total Cards"
                  required
                />
              </div>

              <div className="sign-in-form-wrapper remove-btn">
                {index ? (
                  <button
                    type="button"
                    className="primary-btn  view-card-btn remove-player"
                    onClick={() => removeFormFields(index)}
                  >
                    Remove
                  </button>
                ) : null}
              </div>
            </div>
          </>
        ))}
        {!card_id ? (
          <div className="button-section">
            <button
              className="primary-btn view-card-btn add-player"
              type="button"
              onClick={() => addFormFields()}
            >
              Add More
            </button>
          </div>
        ) : null}
      </form>
      <div className="sign-in-actions">
        <button
          className="btn-primary"
          id="create-account-btn"
          onClick={() => saveCard()}
          disabled={showLoader}
        >
          Submit
        </button>
        <button
          className="forgot-password"
          id="forgot-password"
          onClick={() => history.push(`/cards/list/${season_id}`)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
export default AddEditCard;
