import React, {useContext, useEffect, useState} from "react";
import loader from "./includes/loader.gif";
import "./includes/Hit.css";
import globalContext from "../../context/context";
import CardImage from "./includes/CardImage";
import {useParams} from "react-router";
import jquery from "jquery";
import SponsorBanner from "../../components/SponsorBanner";
import * as Helper from "./includes/Helper";

const PlayerCards = () => {
  const [error, setError] = useState([]);
  const [season, setSeason] = useState([]);
  const [keyword, setKeyword] = useState(null);
  const [selCard, setSelCard] = useState([]);
  const [selCardId, setSelCardId] = useState(null);
  const [selUploadCardId, setSelUploadCardId] = useState(null);
  const [player, setPlayer] = useState(null);
  const [notFound, setNotFound] = useState(null);
  const [cardNumber, setCardNumber] = useState(null);
  const [uploadedCard, setUploadedCard] = useState([]);
  const [frontImage, setFrontImage] = useState([]);
  const [backImage, setBackImage] = useState([]);
  const [backErr, setBackErr] = useState(null);
  const [frontErr, setFrontErr] = useState(null);
  const [notes, setNotes] = useState(null);
  const [data, setData] = useState([]);
  const [cardDataOrg, setCardDataOrg] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [uploadedCards, setUploadedCards] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [uploadUnknown, setUploadUnknown] = useState(false);
  const [defaultOpen, setDefaultOpen] = useState(true);
  const [unknownCards, setUnknownCards] = useState([]);
  const {user, firebase, db} = useContext(globalContext);
  const {season_slug, player_slug} = useParams();
  const maxFileSize = 1024 * 1024 * 5; // 5 Mb

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    jquery(document).mouseup(function (e) {
      if (
        jquery(e.target).closest(".player-popup").length === 0 &&
        jquery(".player-popup").is(":visible")
      ) {
        closePopup(sessionStorage.getItem("popup"));
      }
    });

    setShowLoader(true);

    db.collection("seasons")
      .where("slug", "==", season_slug)
      .get()
      .then((doc) => {
        setSeason({id: doc.docs[0].id, ...doc.docs[0].data()});
        const season_id = doc.docs[0].id;
        db.collection("players")
          .where("slug", "==", player_slug)
          .get()
          .then((docPlayer) => {
            if (docPlayer.docs.length > 0) {
              setPlayer({
                id: docPlayer.docs[0].id,
                ...docPlayer.docs[0].data(),
              });
              const player_id = docPlayer.docs[0].id;
              db.collection("cards")
                .where("player_id", "==", player_id)
                .where("season_id", "==", season_id)
                .orderBy("total_cards", "asc")
                .orderBy("name", "asc")
                .get()
                .then((doc) => {
                  let cards = [];
                  for (let x in doc.docs) {
                    let d = doc.docs[x].data();
                    cards.push({id: doc.docs[x].id, ...d});
                  }
                  setCardData(cards);
                  setCardDataOrg(cards);
                  db.collection("card_uploads")
                    .where("season_id", "==", season_id)
                    .where("player_id", "==", player_id)
                    .orderBy("created_at", "desc")
                    .get()
                    .then((doc) => {
                      let uploads = [],
                        top = [],
                        card,
                        unknown = [];
                      for (let x in doc.docs) {
                        let d = doc.docs[x].data();
                        card = {id: doc.docs[x].id, ...d};
                        if (d.unknown) {
                          if (!unknown[d.card_id]) {
                            unknown[d.card_id] = [];
                          }
                          unknown[d.card_id][d.card_number] = card;
                        } else {
                          if (!uploads[d.card_id]) {
                            uploads[d.card_id] = [];
                          }
                          uploads[d.card_id][d.card_number] = card;
                        }

                        if ((!d.status || d.status !== "P") && top.length < 3) {
                          top.push(card);
                        }
                      }
                      console.log("unknown", unknown);
                      setUnknownCards(unknown);
                      setUploadedCards(uploads);
                      setShowLoader(false);
                    })
                    .catch((error) => {
                      console.log("Error getting document:", error);
                    });
                })
                .catch((error) => {
                  console.log("Error getting document:", error);
                });
            } else {
              setNotFound('Player not found.');
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      });
  }, []);

  useEffect(() => {
    listCards();
  }, [cardData, uploadedCards, unknownCards]);

  useEffect(() => {
    if (!keyword) {
      searchCard();
    }
  }, [keyword]);

  useEffect(() => {
    if (frontImage.length && frontImage[0].size > maxFileSize) {
        setFrontErr(<p>Front image should not be more than 5MB.</p>);
    }else{
      setFrontErr('');
    }
  }, [frontImage]);

  useEffect(() => {
    if (backImage.length && backImage[0].size > maxFileSize) {
        setBackErr(<p>Back image should not be more than 5MB.</p>);
    }else{
      setBackErr('');
    }
  }, [backImage]);


  const searchCard = () => {
    let filtered = [];
    for (let i in cardDataOrg) {
      if (
        !keyword ||
        cardDataOrg[i].name.toLowerCase().search(keyword.toLowerCase()) >= 0
      ) {
        filtered.push(cardDataOrg[i]);
      }
    }
    setCardData(filtered);
  };

  const listCards = () => {
    let colData = [],
      card = [],
      unCard = [];
    const rowsPerCol = Math.ceil(cardData.length / 3);
    for (let j in cardData) {
      let n = [],
        disable,
        cardChecked = 0,
        id,
        pendingCls;
      //Normal card
      for (let k = 1; k <= cardData[j].total_cards; k++) {
        disable = !!(
          uploadedCards[cardData[j].id] && uploadedCards[cardData[j].id][k]
        );
        if (disable) {
          cardChecked++;
          if (
            uploadedCards[cardData[j].id][k].status &&
            uploadedCards[cardData[j].id][k].status === "P"
          ) {
            pendingCls = "pending";
          } else {
            pendingCls = `approved`;
          }
        } else {
          pendingCls = "";
        }
        id = `card${cardData[j].id}-${k}`;
        n.push(
          <div key={k} className="card-input-check">
            <input
              onChange={(e) => setUploadId(cardData[j], k)}
              type="checkbox"
              id={id}
              className={`card-check ${pendingCls}`}
              checked={disable}
              defaultChecked={disable}
            />
            <label htmlFor={id} className="card-check-label">
              {k}/{cardData[j].total_cards}
            </label>
          </div>
        );
      }
      //END - Normal card

      //Unknown cardData
      unCard = unknownCards[cardData[j].id] ?? [];
      if (Object.keys(unCard).length) {
        for (let k = 1; k <= Object.keys(unCard).length; k++) {
          // console.log('unknownCards[unCard[k].card_id]', unknownCards[unCard[k].card_id][k])
          id = `unknown-card${cardData[j].id}-${k}`;
          if (unCard[k].status && unCard[k].status === "P") {
            pendingCls = "pending";
          } else {
            pendingCls = `approved`;
            cardChecked++;
          }
          n.push(
            <div key={id} className="card-input-check">
              <input
                onChange={(e) => showUnknown(cardData[j], k)}
                type="checkbox"
                id={id}
                className={`card-check ${pendingCls}`}
                checked={true}
                defaultChecked={true}
              />
              <label htmlFor={id} className="card-check-label">
                ?/{cardData[j].total_cards}
              </label>
            </div>
          );
        }
      }
      id = `unknown-card${cardData[j].id}-new`;
      n.push(
        <div className="card-input-check">
          <input
            onChange={(e) =>
              e.target.checked && showUpload(cardData[j], "?", true, id)
            }
            type="checkbox"
            id={id}
            className={`card-check`}
          />
          <label htmlFor={id} className="card-check-label">
            ?/{cardData[j].total_cards}
          </label>
        </div>
      );
      //END - unknown

      card.push(
        <div key={j} className="ac">
          <div className="ac-sub">

            <input
              className="ac-input"
              id={`card-${cardData[j].id}`}
              name={`card-${cardData[j].id}`}
              type="checkbox"
              onClick={() => setSelCardId(`card-${cardData[j].id}`)}
            />
            <label className="ac-label" htmlFor={`card-${cardData[j].id}`}>
              {cardData[j].name} /{cardData[j].total_cards} ({cardChecked} hits)
            </label>
            <article className="ac-sub-text">{n}</article>
          </div>
        </div>
      );
      if (
        (parseInt(j) + 1) % rowsPerCol === 0 ||
        parseInt(j) + 1 === cardData.length
      ) {
        colData.push(<div>{card}</div>);
        card = [];
      }
    }
    if (defaultOpen) {
      if (params.c) {
        setTimeout(() => {
          document.getElementById(params.c).scrollIntoView();
          jquery("#" + params.c).click();
          document.getElementById(params.n).click();
          document.getElementById(params.n).checked = true;
        }, 5000);
        setDefaultOpen(false);
      }
    }
    setData(colData);
  };

  const openPopup = (popup) => {
    const loggedInUser = firebase.auth().currentUser;
    if (popup === "upload-player") {
      if (!loggedInUser) {
        popup = "login-popup";
      }
    }
    sessionStorage.setItem("popup", popup);
    document.getElementById(popup).style.display = "flex";
  };

  const closePopup = (popup) => {
    if (!saveLoader) {
      document.getElementById(popup).style.display = "none";
      if (popup === "upload-player") {
        resetForm();
      }
      if (sessionStorage.getItem("selId")) {
        // document.getElementById(sessionStorage.getItem("selId")).checked = false;
      }
    }
  };

  const resetForm = () => {
    setFrontImage([]);
    setBackImage([]);
    setNotes("");
  };

  const setUploadId = (card, num) => {
    const id = `card${card.id}-${num}`;
    if (!!(uploadedCards[card.id] && uploadedCards[card.id][num])) {
      if (
        uploadedCards[card.id][num].status &&
        uploadedCards[card.id][num].status === "P"
      ) {
        openPopup("pending-approval");
      } else {
        showPlayer(uploadedCards[card.id][num]);
      }
    } else {
      showUpload(card, num, false, id);
    }
  };

  const showUnknown = (card, num) => {
    if (
      unknownCards[card.id][num].status &&
      unknownCards[card.id][num].status === "P"
    ) {
      openPopup("pending-approval");
    } else {
      showPlayer(unknownCards[card.id][num]);
    }
  };

  const showPlayer = (card) => {
    setUploadedCard(card);
    openPopup("show-player");
  };

  const showUpload = (card, num, unknown, id) => {
    setSelUploadCardId(id);
    sessionStorage.setItem("selId", id);
    setSelCard(card);
    setCardNumber(num);
    setUploadUnknown(unknown);
    openPopup("upload-player");
  };

  async function saveImages() {
    let err = [];
    if (notes && notes.length > 200) {
      err.push(<p>Notes should not be more than 200 characters.</p>);
    }
    if (frontImage.length && backImage.length) {
      if (frontImage[0].size > maxFileSize) {
        err.push('');
      }
      if (backImage[0].size > maxFileSize) {
        err.push('');
      }
      if (!err.length) {
        setSaveLoader(true);
        uploadImage(frontImage, "front").then((f) => {
          uploadImage(backImage, "back").then(async (b) => {
          });
        });
      }
    } else {
      err.push(<p className="error-text">Upload images of the card's front and serial number</p>);
    }
    setError(err);
  }

  async function uploadImage(files, type) {
    const file = files[0];
    const storageRef = firebase.storage().ref();
    const imagesRef = storageRef.child("cards/" + Date.now() + file.name);
    await imagesRef.put(file).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        if (type === "front") {
          sessionStorage.setItem("front", downloadURL);
        } else {
          // Add a new document with a generated id.
          const data = {
            front_image: sessionStorage.getItem("front"),
            back_image: downloadURL,
            notes,
            card_id: selCard.id,
            card_name: selCard.name,
            total_cards: selCard.total_cards,
            user_id: user.userId,
            user_name: user.username,
            card_number: uploadUnknown
              ? unknownCards[selCard.id]
                ? Object.keys(unknownCards[selCard.id]).length + 1
                : 1
              : cardNumber,
            created_at: firebase.firestore.FieldValue.serverTimestamp(),
            season_id: season.id,
            player_id: player.id,
            unknown: uploadUnknown,
            player: player.name,
            status: "P",
          };
          console.log("data", data);
          db.collection("card_uploads")
            .add(data)
            .then((docRef) => {
              closePopup("upload-player");
              openPopup("success-popup");
              setSaveLoader(false);
              let upCards = uploadedCards,
                unCards = unknownCards;
              data.created_at = new Date();
              if (uploadUnknown) {
                if (!unCards[selCard.id]) {
                  unCards[selCard.id] = [];
                }
                if (!unCards[selCard.id][data.card_number]) {
                  unCards[selCard.id][data.card_number] = [];
                }
                unCards[selCard.id][data.card_number] = data;
                setUnknownCards(unCards);
                console.log("unCards", unCards);
              } else {
                if (!upCards[selCard.id]) {
                  upCards[selCard.id] = [];
                }
                if (!upCards[selCard.id][data.card_number]) {
                  upCards[selCard.id][data.card_number] = [];
                }
                upCards[selCard.id][data.card_number] = data;
                setUploadedCards(upCards);
              }
              resetForm();
              setTimeout(listCards, 1500);
            })
            .catch((error) => {
              closePopup("upload-player");
              openPopup("error-popup");
              console.error("Error adding document: ", error);
            });
        }
      });
    });
  }

  return (
    <>
      <SponsorBanner/>
      {
        player ?
          <>
            <div className="hit-cards-page">
              <a className="hit-card" id='img-link' href={`/hits/${season.slug}`}>
                <div className="hit-img-container" id='player-cards-img'>
                  {season.photo && (
                    <img
                      src={season.photo}
                      alt=""
                      srcSet=""
                      className="hit-card-image"
                    />
                  )}
                </div>
              </a>

              <p className="card-title text-align-center">
                Check Off & View {player.name} Hits - {season.year} {season.name}{" "}
                Checklist.
              </p>
              {cardDataOrg.length ? (
                <div className="filter-container mt-2">
                  <input
                    type="text"
                    className="filter"
                    placeholder="Search Player Card"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    onKeyUp={(e) => e.keyCode === 13 && searchCard()}
                  />
                  <div className="filter-button-container">
                    <button
                      className="submit-button primary-btn"
                      onClick={searchCard}
                    >
                      Search
                    </button>
                    <button
                      className="submit-button secondary-btn ml-1"
                      onClick={() => setKeyword("")}
                    >
                      Clear Search
                    </button>
                  </div>
                </div>
              ) : null}

              <div className="mt-4 player-accordins">
                {data.length ? (
                  data
                ) : !showLoader ? (
                  <div className="card-title">No record found</div>
                ) : null}
              </div>
              <div className="pagination">
                {showLoader ? (
                  <img
                    src={loader}
                    alt={"Loading..."}
                    className="pagination-loader"
                  />
                ) : null}
              </div>
            </div>
            <div className="popup-overlay" id="upload-player">
              <div className="player-popup upload-popup">
                <h2>
                  {player.name} - {selCard.name} {cardNumber}/{selCard.total_cards}
                </h2>
                <div style={{color: "#fda130"}}>{error}{frontErr}{backErr}</div>
                <div className="card-upload-area">
                  <div>
                    <CardImage files={frontImage} setFiles={setFrontImage} desc={"Upload Front"}/>
                  </div>
                  <div>
                    <CardImage files={backImage} setFiles={setBackImage} desc={"Upload Back"}/>
                  </div>
                </div>

                <textarea
                  name="Card Notes"
                  rows="5"
                  className="card-notes"
                  placeholder="Enter Details"
                  onChange={(e) => setNotes(e.target.value)}
                  value={notes}
                />
                <div style={{color: "#FFF"}}>Max 200 characters</div>
                <div className="button-container">
                  {saveLoader ? (
                    <img
                      src={loader}
                      alt={"Loading..."}
                      className="pagination-loader"
                    />
                  ) : null}
                  <button className="submit-button" onClick={() => saveImages()}>
                    Submit
                  </button>
                  <button
                    className="reset-button"
                    onClick={() => closePopup("upload-player")}
                  >
                    Cancel
                  </button>
                </div>

                <button
                  className="close-button"
                  onClick={(event) => closePopup("upload-player")}
                >
                  X
                </button>
              </div>
            </div>

            <div className="popup-overlay" id="login-popup">
              <div className="player-popup">
                <div id="login-container" className="card-upload-area">
                  <div className="login-wrapper">
                    <a
                      id="login-link"
                      href={`/login?r=${season.slug}&p=${player.slug}&c=${selCardId}&n=${selUploadCardId}`}
                    >
                      Login
                    </a>
                    to Gemint to check off hits!
                  </div>
                  <div className="login-wrapper">
                    Need an account?
                    <a
                      id="login-link"
                      href={`/signup?r=${season.slug}&p=${player.slug}&c=${selCardId}&n=${selUploadCardId}`}
                    >
                      Signup here!
                    </a>
                  </div>
                </div>
                <button
                  className="close-button"
                  onClick={(event) => closePopup("login-popup")}
                >
                  X
                </button>
              </div>
            </div>

            <div className="popup-overlay" id="success-popup">
              <div className="player-popup">
                <h2>Success!</h2>
                <div className="card-upload-area">
                  <div id="bang">
                    Bang! You’ve checked off a hit for {season.year} {season.name}.
                    Once this hit is verified, the Gemint Checklist will be updated.
                    Thank you for helping the Hobby know what’s live!
                  </div>
                </div>
                <button
                  className="close-button"
                  onClick={(event) => closePopup("success-popup")}
                >
                  X
                </button>
              </div>
            </div>

            <div className="popup-overlay" id="pending-approval">
              <div className="player-popup">
                <h2>
                  {player.name} - {selCard.name} {cardNumber}/{selCard.total_cards}
                </h2>
                <h2>PENDING</h2>
                <div className="card-upload-area">
                  <div id="pending">
                    This hit will appear with a green checkmark once approved. Thank
                    you!
                  </div>
                </div>
                <button
                  className="close-button"
                  onClick={(event) => closePopup("pending-approval")}
                >
                  X
                </button>
              </div>
            </div>

            <div className="popup-overlay" id="error-popup">
              <div className="player-popup">
                <h2>Error</h2>
                <div className="card-upload-area">
                  <div>
                    There is some issue in uploading the images, please try after
                    sometime.
                  </div>
                </div>
                <button
                  className="close-button"
                  onClick={(event) => closePopup("success-popup")}
                >
                  X
                </button>
              </div>
            </div>

            <div className="popup-overlay" id="show-player">
              <div className="player-popup">
                {uploadedCard.front_image ? (
                  <>
                    <h2>

                      {uploadedCard.player} - {uploadedCard.card_name}{" "}
                      {uploadedCard.unknown ? "?" : uploadedCard.card_number}/
                      {uploadedCard.total_cards}
                    </h2>
                    <div className="popup-hit-image-container">
                      <img
                        src={uploadedCard.front_image}
                        alt=""
                        className="uploaded-card"
                      />
                      <img
                        src={uploadedCard.back_image}
                        alt=""
                        className="uploaded-card"
                      />
                    </div>

                    <div className="top-hits-card-details">
                      {uploadedCard.player} <br/>
                      {uploadedCard.card_name}{" "}
                      {uploadedCard.unknown ? "?" : uploadedCard.card_number}/
                      {uploadedCard.total_cards} <br/>
                      published {Helper.timeSince(uploadedCard.created_at)} ago by @
                      {uploadedCard.user_name} <br/>
                      {/* published {Helper.timeSince(uploadedCard.created_at)} ago */}
                    </div>
                    <p className="submitted-notes">
                      {uploadedCard.notes ? `Note: ${uploadedCard.notes}` : null}
                    </p>
                    <button
                      className="close-button"
                      onClick={(event) => closePopup("show-player")}
                    >
                      X
                    </button>
                  </>
                ) : null}
              </div>
            </div>

            <div className="back-to-wrapper">
              <a href={`/hits/${season.slug}`} className="back-to-link">
                ← Back to {season.year} {season.name}
              </a>
              {/* <a href="/checklists" className="back-to-link ml-1">
        ← Back to all checklists
        </a> */}
            </div>
          </>
          : <div className="card-title">{notFound}</div>
      }


    </>
  );
};

export default PlayerCards;
