import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import CsvDownload from "react-json-to-csv";

import Dice from "../Dice";
// import RandomWheel from "../RandomWheel"
import { shareResult } from "../../store/subscription";
import DragMove from "../../components/DragMove";
import globalContext from "../../context/context";
import "./randomizer.css";
import { MdFiberNew } from "react-icons/md";

import Randomizer from "../../img/RANDOMIZER.png";
import TempAccountImage from "../../img/account.png";
import v from "../../img/v.png";
import "animate.css";
import PopUp from "../PopUp";
import SponsorBanner from "../SponsorBanner"

let dt = DateTime.now();

export default function PremiumRandomizer(props) {
  //all properties for dice
  const { user } = useContext(globalContext);
  const [translate, setTranslate] = useState({
    x: 0,
    y: 0,
  });
  //all properties for randomizer list
  const [count, setCount] = useState(0);
  const [names, setNames] = useState("");
  const [prevList, setPrevList] = useState([]);
  const [prevTimes, setPrevTimes] = useState([]);
  const [original, setOriginal] = useState("");
  const [redoList, setRedoList] = useState([]);
  const [redoCount, setRedoCount] = useState(0);
  const [redoTimes, setRedoTimes] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [fontSize, setFontSize] = useState(27);
  const [curtainUpSpeed, setCurtainUpSpeed] = useState(4);
  const [curtainDownSpeed, setCurtainDownSpeed] = useState(1.5);
  const [download, setDownload] = useState(null);
  const [shareEmail, setShareEmail] = useState("");
  const [share, setShare] = useState(false);
  const [enableShare, setEnableShare] = useState(false);
  const [toggle, setToggle] = useState(false)
  const [checked, setChecked] = useState([]);

  const dispatch = useDispatch();
  const subscription = useSelector((state) => state.subscription);


  useEffect(() => {
    if (!user?.email || user?.plan !== "monthy") {
      setOpenPopup(true);
    } else {
      setOpenPopup(false);
    }
  }, [user]);

  const [timestamp, setTimestamp] = useState(
    DateTime.now().toLocaleString() +
      " " +
      dt.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
  );

  const handleFontChange = () => {
    if (document.querySelector(".premium-text-area")) {
      document.querySelector(
        ".premium-text-area"
      ).style.fontSize = `${fontSize}px`;
      document.querySelector(
        ".paid-column-wrapper"
      ).style.fontSize = `${fontSize}px`;
    }
  };

  function shuffleNew() {
    if (!user?.email || user?.plan === "free") {
      setOpenPopup(true);
      return;
    }

    let array = names.split(`\n`);

    array = array.filter(function (e) {
      return e.replace(/(\r\n|\n|\r)/gm, "");
    });

    prevList.push(array);
    setPrevList(prevList);
    prevTimes.push(timestamp);
    setPrevTimes(prevTimes);
    setOriginal("");

    setRedoCount(0);
    setRedoList([]);
    setRedoTimes([]);

    //get array length and generate random number for each spot

    var mixedArray = array.map(function (x, i) {
      var cryptoRandom = window.crypto.getRandomValues(new Uint32Array(1))[0];
      return { name: x, value: cryptoRandom };
    });

    mixedArray.sort((a, b) => (a.value > b.value ? 1 : -1));

    console.log("mixed", mixedArray);

    let randomizedNameArray = [];
    mixedArray.map((name, i) => {
      let cleanName = name.name;
      console.log("clean name before = ", cleanName);
      cleanName = cleanName.split().pop();
      console.log("clean name after = ", cleanName);
      let order = i + 1;
      // randomizedNameArray.push(order + ". " + cleanName);
      randomizedNameArray.push(cleanName);
    });

    setCount(count + 1);

    const randomizedNames = randomizedNameArray.join(`\n`);

    setNames(randomizedNames);
    let dtnow = DateTime.now();
    setTimestamp(
      DateTime.now().toLocaleString() +
        " " +
        dtnow.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
    );
  }

  function undo() {
    if (!user?.email || user?.plan === "free") {
      setOpenPopup(true);
      return;
    }

    if (count == 0) {
      console.log("nothing happens");
    } else {
      //get prev entry
      let prevNames = prevList.pop().join(`\n`);
      setPrevList(prevList);
      let prevStamp = prevTimes.pop();
      setPrevTimes(prevTimes);

      //save current entry
      redoList.push(names.split(`\n`));
      setRedoList(redoList);
      redoTimes.push(timestamp);
      setRedoTimes(redoTimes);
      setRedoCount(redoCount + 1);

      //set state
      setNames(prevNames);
      setCount(count - 1);
      let dtnow = DateTime.now();
      setTimestamp(
        DateTime.now().toLocaleString() +
          " " +
          dtnow.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
      );
   
    }
  }

  
  function redo() {
    if (!user?.email || user?.plan === "free") {
      setOpenPopup(true);
    }
    if (redoCount == 0) {
      console.log("nothing happens");
    } else {
      //grab redo set
      let redoNames = redoList.pop().join(`\n`);
      setRedoList(redoList);
      let redoStamp = redoTimes.pop();
      setRedoTimes(redoTimes);

      //save to prev set
      prevList.push(names.split(`\n`));
      setPrevList(prevList);
      prevTimes.push(timestamp);
      setPrevTimes(prevTimes);

      //update state
      setNames(redoNames);
      setCount(count + 1);
      setRedoCount(redoCount - 1);
      let dtnow = DateTime.now();
      setTimestamp(
        DateTime.now().toLocaleString() +
          " " +
          dtnow.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
      );
      setOriginal("[ORIGINAL " + redoStamp + "]");
    }
  }

  function handleDragMove(e) {
    setTranslate({
      x: translate.x + e.movementX,
      y: translate.y + e.movementY,
    });
  }

  function resetTranslate() {
    setTranslate({
      x: 0,
      y: 0,
    });
  }

  function handleChange(e) {
    if (!user?.email || user?.plan === "free") {
      setOpenPopup(true);
      return;
    }
    setNames(e);
  }

  function resetRandomizer(e) {
    if (!user?.email || user?.plan === "free") {
      setOpenPopup(true);
      return;
    }
    setNames("");
    setCount(0);
    setPrevList([]);
    setPrevTimes([]);
    setRedoCount(0);
    setRedoList([]);
    setRedoTimes([]);
  }

  const handlePopUp = () => {
    setOpenPopup(!openPopup);
  };

  const handleShare = () => {
    if (!user?.email || user?.plan === "free") {
      setOpenPopup(true);
      return;
    }
    handleDownload(false);
    setShare(!share);
  };

  const sendResult = () => {
    const payload = {
      username: `${user.firstName} ${user.lastName}`,
      email: shareEmail,
      csvData: download,
      timestamp,
      isPremium: true,
    };
    dispatch(shareResult(payload));
    setEnableShare(false);
  };

  const handleDownload = (isDownload = true) => {
    if (!user?.email || user?.plan === "free") {
      setOpenPopup(true);
      return;
    }
    let finalResult = [];
    const customNames = names.split("\n");
    console.log(names);
    customNames.forEach((name, index) => {
      finalResult.push({
        value: name,
      });
    });
    finalResult[finalResult.length - 1].counter = count;
    finalResult[finalResult.length - 1].time_stamp = timestamp;

    setDownload(finalResult);
    if (isDownload) {
      setTimeout(() => {
        document.querySelector(".download-csv").click();
      }, 500);
    }
  };

  const hi = () => {
    if (!user?.email || user?.plan === "free") {
      setOpenPopup(true);
      return;
    }
    var curtain = document.querySelector(".hide-curtain");
    var breakerImg = document.querySelector(".hide-breaker-img");
    var watermarkImg = document.querySelector(".watermark-img");
    // // check to see if curtain is already down
    if (curtain.style.height == "451px") {
          // if it is down and the button is clicked, put it back up
          console.log("curtain going back up");
          curtain.style.height = "0px";
          curtain.style.transition = `all ${curtainUpSpeed}s`;
          curtain.style.visibility = "hidden";
          // hide breaker img
          
          breakerImg.style.transition = "all 1s";
          breakerImg.style.visibility = "hidden";
          breakerImg.style.height = '0px'
    
   
    } else {
           // else it isn't down and needs to go down
      // breakerImg.style.height = "200px";
      curtain.style.visibility = "visible";
      curtain.style.position = "absolute";
      console.log("curtain is revealed thoo");
      console.log(curtain.style.height);
      curtain.style.transition = `all ${curtainDownSpeed}s`;
      curtain.style.height = "451px";
      // display breaker img
      // breakerImg.style.opacity = "1";
      breakerImg.style.transitionDelay = `${curtainDownSpeed}s`;
      breakerImg.style.visibility = "visible";
      // breakerImg.style.display = 'flex'
      breakerImg.style.height = '300px';
      breakerImg.style.zIndex = "1";
      breakerImg.style.borderRadius = '50%'





    }
  };

  var divStyle = {
    // backgroundImage: 'url(user.photoUrl)',

    backgroundImage: 'url(' + user?.photoUrl || TempAccountImage + ')',
    // src={user && user.photoUrl ? user.photoUrl : avatar}

    backgroundSize: 'cover'
    
}

console.log('toggle state = ', toggle)


function paymentToggle () {
  // grabbing length of names array to match # of paid columns with it
  let array = names.split(`\n`);

array = array.filter(function (e) {
  return e.replace(/(\r\n|\n|\r)/gm, "");
});
console.log('array length = ', array.length)
console.log('array = ', array)

// toggle true/false between randomize list and collect payment
  setToggle(!toggle)
  var paidColumn = document.querySelector('.paid-column-wrapper')
  var textArea = document.querySelector('.premium-text-area')

  // randomize list toggle 
  if (toggle == true) {
    paidColumn.style.display = 'none'
    // paidColumn.style.width = '0'

    textArea.style.width = "100%"
    // textArea.style.transition = "all 1s";

   

  }
  // collect payment toggle 
  else {
    paidColumn.style.display = 'flex'
    paidColumn.style.flexDirection = 'column'
    textArea.style.width = "60%"
    
  

  }
}
 
  // Add/Remove checked item from list
  const handleCheck = (event) => {
    
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    console.log(updatedList)

    setChecked(updatedList);
  };

  // Generate string of checked items
  const checkedItems = checked.length
    ? checked.reduce((total, item) => {
        return total + ", " + item;
      })
    : "";

  // Return classes based on whether item is checked
  var isChecked = (item) =>
    checked.includes(item) ? "checked-item" : "not-checked-item";
    let array = names.split(`\n`);

    array = array.filter(function (e) {
      return e.replace(/(\r\n|\n|\r)/gm, "");
    });


  return (
    <div className="premium-section section">
      <SponsorBanner />
      <div className="premium-section-container">


        <div className="premium-section-top" id="myHeader">

          <div className="premium-section-top-logo">
            <img className="image-fluid" src={Randomizer} alt="" />
          </div>
          {user?.username && (
            <div className="premium-section-info">
              <img
                className="seller-logo-img"
                alt="Profile"
                src={user.photoUrl}
              />
              <p className="seller-username">{user.username}</p>
            </div>
          )}
        </div>
        <div className="premium-tool-wrapper">
          <div className="standslider">
            <input id="randomizer-title" placeholder=" Break title" />
            <DragMove onDragMove={handleDragMove}>
              <div className="premium-dice-wrapper">
                <Dice
                  faceColor={user?.color || "#000"}
                  pipColor={user?.pipColor || "#fff"}
                  disabled={!user?.email || user?.plan === "free"}
                  showPopup={() => setOpenPopup(true)}
                />
              </div>
              {/* <RandomWheel /> */}
            </DragMove>
            <div className="premium-bottom-panel">
              <div className="premium-bottom-text-panel">
                
                <div className="premium-arrow-button-wrapper">
                  <div className="premium-arrow-button" onClick={() => hi()}>
                    <img className="image-fluid pb-3" src={v} alt="" />
                  </div>
                </div>
                <div className="wrap-curtain-text">
               

                  <div className="hide-curtain">
                      <img
                        className="hide-breaker-img"
                        src={user?.photoUrl || TempAccountImage}
                        alt=""
                      />
                  </div>
                  
                  <div className='paid-column-wrapper' id='myList' >
                  <div className="checkList">
        {/* <div className="title">Collect payment:</div> */}
        <div className="list-container">
          {array.map((item, index) => (
            <div className='item-wrapper' key={index}>
              <input className='item-input' value={item} type="checkbox" onChange={handleCheck} />
              <span id='item-span' className={isChecked(item)}>{item}</span>
            </div>
          ))}
        </div>
      </div>

      {/* <div>
        {`Items checked are: ${checkedItems}`}
      </div> */}
                      </div>
                  <textarea
                    className="premium-text-area"
                    cols="30"
                    rows="20"
                    value={names}
                    onChange={(e) => handleChange(e.target.value)}
                  >

                    

                  </textarea>
                  <img
                        className="watermark-img"
                        style={divStyle}

                        alt=""
                     
                    onChange={(e) => handleChange(e.target.value)}
                      />
                </div>
                <div className='payment-toggle-container' >
                    <p className='payment-p'>
                    Randomize <br/>list
                    </p>
                    <label className="switch">
                    <input onClick={() => paymentToggle()} type="checkbox" />

  <span className="randomizer-slider round"></span>
</label>
<p className='payment-p'>
Collect <br/>payment
                    </p>
                    {/* <div className='new-icon-wrapper'>
                    <MdFiberNew size={35} color='lightgreen' />
                    </div> */}
                  </div>
              </div>
              <div className="premium-bottom-info">
                <div className="premium-dice-actions">
                  <div className="premium-dice-actions-top">
                    <button className="abtn btn" onClick={() => shuffleNew()}>
                      Randomize
                    </button>
                    <button
                      className="abtn btn"
                      id='reset-btn'
                      onClick={() => resetRandomizer()}
                    >
                      Reset
                    </button>
                    <button className="abtn btn" onClick={() => undo()}>
                      Undo
                    </button>
                    <button className="abtn btn" onClick={() => redo()}>
                      Redo
                    </button>
                    <CsvDownload
                      data={download}
                      className="download-csv"
                      filename={`premium-result-${timestamp}.csv`}
                    />
                  </div>
                  <div className="premium-dice-actions-bottom">
                    <button
                      className="abtn btn"
                      onClick={() => handleShare()}
                      disabled={!names}
                    >
                      Share
                    </button>
                    <button
                      className="abtn btn"
                      onClick={() => handleDownload()}
                      disabled={!names}
                    >
                      Download CSV
                    </button>
                  </div>
                  <div className="font-change-wrapper">
                    <input
                      placeholder="Font size"
                      type="number"
                      value={fontSize}
                      onChange={(e) => setFontSize(e.target.value)}
                    />
                    <button
                      className="abtn btn" 
                      onClick={() => handleFontChange()}
                    >
                      Change Font Size
                    </button>
                  </div>
                <div className="labels-container">
                  <label className="label-wrapper" id="counter-label">
                    Randomize Counter: {count}
                  </label>
                  <label className="label-wrapper" id="timestamp-label">
                    Timestamp: {timestamp}
                  </label>
                </div>
                <div className="curtain-speed-change-wrapper">
                  <div className="curtain-speed">
                    <div className="curtain-speed-label">Down Speed</div>
                    <input
                      placeholder="Down Speed"
                      type="number"
                      value={curtainDownSpeed}
                      onChange={(e) => setCurtainDownSpeed(e.target.value)}
                    />
                  </div>
                  <div className="curtain-speed">
                    <div className="curtain-speed-label">Up Speed</div>
                    <input
                      placeholder="Up Speed"
                      type="number"
                      value={curtainUpSpeed}
                      onChange={(e) => setCurtainUpSpeed(e.target.value)}
                    />
                  </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(!user?.email || user?.plan === "free") && openPopup && (
        <PopUp
          content={
            <>
               <div className="popup-title">Try It Today!</div>
               <br></br>
              <div className='signup-login-links'>
              <Link id='signup-login-link' to="/premium" >
                Go Premium</Link> - FREE for 7 days, then just $10/month. Streamline your breaks with Gemint!
              
              
                {/* <p className=''>Have an account? <Link id='signup-login-link' to="/login">
                  Login now.
              </Link></p> */}
              </div>
            </>
          }
          handleClose={handlePopUp}
        />
      )}
      {share && (
        <PopUp
          content={
            <>
              <div className="popup-title">Share the result</div>
              {(!subscription.isLoading && !subscription.sendResult) ||
              enableShare ? (
                <>
                  <input
                    className="share-input-email"
                    type="email"
                    placeholder="Type email to share the result"
                    value={shareEmail}
                    onChange={(e) => setShareEmail(e.target.value)}
                  />
                  <button
                    className="btn-outline"
                    onClick={() => sendResult()}
                    disabled={subscription.isLoading || !shareEmail}
                  >
                    Share
                  </button>
                </>
              ) : (
                <div className="popup-title">
                  {subscription?.sendResult
                    ? subscription?.sendResult?.message
                    : "Sending"}
                </div>
              )}
            </>
          }
          handleClose={() => {
            setEnableShare(true);
            setShare(!share);
            setShareEmail("");
          }}
        />
      )}
    </div>
  );
}

