import React from 'react';
import advertisementImage from "../img/dripHorizontal2.png"

function Advertisement() {
  return (
    <div className='advertismentImage'>
        <a href="https://www.dripshop.live/invite/Gemintcards?referral=gemint&utm_source=gemint&utm_medium=banner1&utm_campaign=sportsg15" target="_blank">
        <img src = {advertisementImage}/>
      </a>
    </div>
  )
}

export default Advertisement