import React,{useState, useContext } from 'react'
import globalContext from "../../context/context";
import account from "../../img/account.png";
import 'bootstrap/dist/js/bootstrap.js';

function Profile (props) {
        const [username, setUsername] = useState("");
        const { user, firebase } = useContext(globalContext);
   return (
        <div>
        {user ? (
            <>
        <div className="firsta section">
        <div data-aos="fade-up" data-aos-duration="1000"  className="container-fluid ">
            <div data-aos="fade-up" data-aos-duration="1000"  className="row">
                <div data-aos="fade-up" data-aos-duration="1000"  className="col-md-1"></div>
                <div data-aos="fade-up" data-aos-duration="1000"  className="col-md-10 standslider">
                    <div data-aos="fade-up" data-aos-duration="1000"  className="row">
                        <div data-aos="fade-up" data-aos-duration="1000"  className="col-md-3">
                            <div data-aos="fade-up" data-aos-duration="1000"  className="text-center">
                            <img className="image-fluid" src={account} alt=""></img>
                            <a href="#" className="text-center minii" style={{color:"#3FD999"}}><i className="fas fa-camera" style={{color:"#3FD999"}}></i> Change Profile Picture</a>
                        </div>
                            <h5 className="linn mt-4">lorem ipsum</h5>
                            <div data-aos="fade-up" data-aos-duration="1000"  className="mt-4">
                                <h4>LOREM IPSUM</h4>
                                <p className="minii">Lorem ipsum Lorem ipsum Lorem <br /> ipsum Lorem ipsum Lorem ipsum<br /> Lorem ipsum Lorem ipsum Lorem </p>
                            </div>
    
                            <div data-aos="fade-up" data-aos-duration="1000"  className="mt-4">
                                <h4>LOREM IPSUM</h4>
                                <p className="minii">
                                    Lorem ipsum Lorem ipsum Lorem<br />
                                    ipsum Lorem ipsum Lorem ipsum<br />
                                    Lorem ipsum Lorem ipsum Lorem
                                </p>
                            </div>  
    
                            <div data-aos="fade-up" data-aos-duration="1000"  className="mt-4">
                                <h5 className="linn">lorem ipsum</h5>
                                <p className="minii">
                                    lorem ipsum <br />
                                    lorem ipsum <br />
                                    lorem ipsum <br />
                                    lorem ipsum 
                                </p>
                            </div> 
                        </div>
                        <div data-aos="fade-up" data-aos-duration="1000"  className="col-md-9" style={{paddingLeft: "50px"}}>
                            <div data-aos="fade-up" data-aos-duration="1000"  className="my-5 py-3">
                            <h1 className="">{user.username}</h1> 
                            <h5>
                            <i className="fas fa-map-marked-alt"></i> lorem ipsum</h5>
                        <p >lorem ipsum</p>
                        </div>
                       {/* <!-- s --> */}
                       <div data-aos="fade-up" data-aos-duration="1000"  className="tab">
                        <button  className="tablinks" onclick="openCity(event, 'RandomizerResult')"><i  className="fas fa-user"></i> Randomizer Result</button>
                        <button  className="tablinks s" onclick="openCity(event, 'About')"><i   className="fas fa-user s"></i> About</button>
                        <hr className="grea" />
                      </div>
    
                      <div data-aos="fade-up" data-aos-duration="1000"  id="About" className="tabcontent">
                        <h5 className="mt-5 mb-4" style={{color:"gray"}}>Contact Information</h5>
                        <table>
                        <tr>
                            <td>Phone:</td>
                            <td>123 1234 1234 123</td>
                          </tr>
                          <tr>
                            <td>Address:</td>
                            <td>Your Address</td>
                          </tr>
                          <tr>
                            <td>Email:</td>
                            <td>{user.email}</td>
                          </tr>
                          <tr>
                            <td>Site:</td>
                            <td>www.xyz.com</td>
                          </tr>
                        </table>
    
                        <h5 className="mt-5 mb-4" style={{color:"gray"}}>Contact Information</h5>
                        <table>
                        <tr>
                            <td>Birthday:</td>
                            <td>Your Birthday</td>
                          </tr>
                          <tr>
                            <td>Gender:</td>
                            <td>Your Gender</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="1000"  className="col-md-1"></div>
            </div>
        </div>
      </div>
            </>
          ) : (
            <p className="text-white loading-text">Loading...</p>
          )}
     
        </div>
    )}


export default Profile;
