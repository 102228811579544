import React from "react";
import {
  FaInstagram,
  FaTwitter,
  FaTiktok,
} from "react-icons/fa";

import "./Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div className="fbot2">
          <div className="row  text-center">
            <div className="col">
              <a href="/help" className="mx-3">
                FAQ
              </a>
              <a href="/terms" className="mx-3 ">
                TERMS
              </a>
              <a href="/privacy" className="mx-3 ">
                PRIVACY
              </a>
            </div>
          </div>
          <br />
          <div className="row text-center">
            <div className="col">
              <a
                target="_blank"
                href={"https://www.instagram.com/gemint._/"}
                className="mx-3"
              >
                <FaInstagram size={25} />
              </a>
              <a
                target="_blank"
                href={
                  "https://www.tiktok.com/@gemintcards?fbclid=IwAR38-ZjvyBNgBPyjnAyRafFEkZ5vBQ-xaT9lcvtMMdeTirb5950IWUMDpt8"
                }
                className="mx-3"
              >
                <FaTiktok size={25} />
              </a>
              <a
                target="_blank"
                href={"https://twitter.com/gemint_cards"}
                className="mx-3"
              >
                <FaTwitter size={25} />
              </a>
           
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
