import React from "react";
import jquery from "jquery";

export const onLoad = (popup) => {
  window.scrollTo(0, 0);
  jquery(document).mouseup(function (e) {
    if (
      jquery(e.target).closest(".player-popup").length === 0 &&
      jquery(".player-popup").is(":visible")
    ) {
      closePopup(sessionStorage.getItem("popup"));
    }
  });
};

export const getPagination = (total, perPage, page, setPagination, setPage) => {
  let links = [],
    totalPage = Math.ceil(total / perPage),
    start = 1,
    end = totalPage,
    maxPage = 5;

  if (totalPage > maxPage) {
    end = maxPage + page < totalPage ? maxPage + page : totalPage;

    if (page + maxPage > totalPage) {
      start = totalPage - maxPage;
    } else {
      start = page;
    }
  }

  if (page > 1) {
    links.push(pageLink(1, "First", page, setPage));
    links.push(pageLink(page - 1, "Previous", page, setPage));
  }

  for (let i = start; i <= end; i++) {
    links.push(pageLink(i, i, page, setPage));
  }

  if (page < totalPage) {
    links.push(pageLink(page + 1, "Next", page, setPage));
    links.push(pageLink(parseInt(totalPage), "Last", page, setPage));
  }
  setPagination(links);
};

const pageLink = (i, label, page, setPage) => {
  return (
    <span
      key={label ?? i}
      onClick={() => setPage(i)}
      className={i === page ? "active" : "page"}
      style={{
        padding: "15px",
        color: "#fff",
        fontSize: "25px",
        cursor: "pointer",
      }}
    >
      {label ?? i}
    </span>
  );
};

export const timeSince = (date) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  let seconds = Math.floor((new Date() - date) / 1000);
  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
};

export const getSlug = (name) => name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');

export const openPopup = (popup) => {
  // console.log('open', popup)
  sessionStorage.setItem("popup", popup);
  document.getElementById(popup).style.display = "flex";
};

export const closePopup = (popup) => {
  // console.log('close', popup)
  document.getElementById(popup).style.display = "none";
};

export const SuccessPopup = (props) => (
  <div className="player-popup" style={{display: "none"}} id="success-popup">
    <h2>Success</h2>
    <div className="card-upload-area">
      <div>{props.message}</div>
    </div>
    <button
      className="close-button"
      onClick={(event) => closePopup("success-popup")}
    >
      X
    </button>
  </div>
);

export const ConfirmPopup = (props) => (
  <div className="player-popup" style={{display: "none"}} id="confirm-popup">
    <h2>Notice</h2>
    <div className="card-upload-area delete">
      <div>Do you really want to delete this record?</div>
      <div>
        <button onClick={() => props.callback()} className="primary-btn m-1">
          Yes
        </button>
        <button
          onClick={() => closePopup("confirm-popup")}
          className="secondary-btn m-1"
        >
          Cancel
        </button>
      </div>
    </div>
    <button
      className="close-button"
      onClick={(event) => closePopup("confirm-popup")}
    >
      X
    </button>
  </div>
);

export const ErrorPopup = () => (
  <div className="player-popup" style={{display: "none"}} id="error-popup">
    <h2>Error</h2>
    <div className="card-upload-area">
      <div>
        There is some issue in processing your request, please try after
        sometime.
      </div>
    </div>
    <button
      className="close-button"
      onClick={(event) => closePopup("success-popup")}
    >
      X
    </button>
  </div>
);


// This will parse a delimited string into an array of
// arrays. The default delimiter is the comma, but this
// can be overriden in the second argument.
export const  CSVToArray = (strData, strDelimiter) => {
  // Check to see if the delimiter is defined. If not,
  // then default to comma.
  strDelimiter = (strDelimiter || ",");

  // Create a regular expression to parse the CSV values.
  var objPattern = new RegExp(
    (
      // Delimiters.
      "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

      // Quoted fields.
      "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

      // Standard fields.
      "([^\"\\" + strDelimiter + "\\r\\n]*))"
    ),
    "gi"
  );


  // Create an array to hold our data. Give the array
  // a default empty first row.
  var arrData = [[]];

  // Create an array to hold our individual pattern
  // matching groups.
  var arrMatches = null;


  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  while (arrMatches = objPattern.exec(strData)) {

    // Get the delimiter that was found.
    var strMatchedDelimiter = arrMatches[1];

    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (
      strMatchedDelimiter.length &&
      (strMatchedDelimiter != strDelimiter)
    ) {

      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push([]);

    }


    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (arrMatches[2]) {

      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      var strMatchedValue = arrMatches[2].replace(
        new RegExp("\"\"", "g"),
        "\""
      );

    } else {

      // We found a non-quoted value.
      var strMatchedValue = arrMatches[3];

    }


    // Now that we have our value string, let's add
    // it to the data array.
    arrData[arrData.length - 1].push(strMatchedValue);
  }

  // Return the parsed data.
  return (arrData);
}