import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import globalContext from "../context/context";
import styled from "styled-components";
import "./navbar.css";
import { AiOutlineClose } from "react-icons/ai";
// import { MdFiberNew } from "react-icons/md";
import betaIcon from "./aa.svg";
import zIndex from "@material-ui/core/styles/zIndex";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-around;
  flex-flow: row nowrap;
  margin-bottom: 0;
  align-items: center;
  button {
    background-color: transparent;
    border: none;
    color: white;
    font-weight: 600;
    font-size: 20px;
    color: white;
    padding: 0;
    cursor: pointer;
    &:hover {
      color: #fda130;
    }
  }
  & ul {
    list-style-type: none;
  }
  & li {
    float: left;
    padding-right: 24px;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
    cursor: pointer;
    &.nav-mobile-item {
      display: none;
    }
  }
  .dropdown {
    display: block;
    p {
      margin-bottom: 0;
      color: white;
      border: none;
      text-align: left;
    }
  }
  & a {
    text-decoration: none;
    color: white;
  }
  & a:hover {
    color: #fda130;
  }
  flex-flow: column nowrap;
  background-color: #303030;
  justify-content: flex-start;
  position: fixed;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  top: 0;
  left: 0;
  height: 100vh;
  width: 300px;
  padding-top: 3.5rem;
  z-index: 111;
  margin: 0;
  transition: transform 0.3s ease-in-out;
  -webkit-box-shadow: -5px 0px 9px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -5px 0px 9px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -5px 0px 9px 0px rgba(0, 0, 0, 0.75);
  li {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    &.nav-mobile-item {
      display: flex;
    }
  }
  .dropdown {
    display: none;
  }
`;

const RightNav = ({ open, isNormal, setOpen }) => {
  const { auth, setUser, setUserState, user } = useContext(globalContext);

  let history = useHistory();

  useEffect(() => {
    setOpen(false);
  }, [history.location]);
  const logOutUser = (e) => {
    e.preventDefault();
    setOpen(false);
    auth.signOut().then(
      function () {
        // Sign-out successful.
        history.push("/login");
        setUser({});
        setUserState(false);
      },
      function (error) {
        // An error happened.
        console.log(error);
      }
    );
  };

  return (
    <Ul open={open} style={{ zIndex: 999999999 }}>
      {/* <li id="checklists-desktop">
        <a href="/checklists" className="link">
          Checklists
          <img src={betaIcon} alt="icon" className="beta-icon" />
        </a>
      </li> */}
      <li className="dropdown">
        <p>Randomizers</p>
        <div className="dropdown-content">
          <p>
            <Link to="/">Free</Link>
          </p>
          <p>
            <Link to="/randomizer/premium">Premium</Link>
          </p>
          <p>
            <Link to="/randomizer/tier">Teams</Link>
          </p>
          <p>
            <Link to="/randomizer/multilist">Multi-List</Link>
          </p>
          {/* <p>
            <Link to="/randomizer/wheels">Wheels</Link>
          </p> */}
        </div>
      </li>
      <div id="x-wrapper">
        <p id="x" onClick={() => setOpen(false)}>
          {" "}
          <AiOutlineClose />
        </p>
      </div>
      {isNormal && (
        <li className="nav-mobile-item">
          <Link to="/signup" className="link" onClick={() => setOpen(false)}>
            Signup
          </Link>
        </li>
      )}
      {isNormal && (
        <li className="nav-mobile-item">
          <Link to="/login" className="link" onClick={() => setOpen(false)}>
            Login
          </Link>
        </li>
      )}

      <li className="nav-mobile-item">
        <Link to="/free-randomizer" className="link" onClick={() => setOpen(false)}>
        Free Randomizer 
        </Link>
      </li>
      <li className="nav-mobile-item">
        <Link to="/randomizer/premium" className="link" onClick={() => setOpen(false)}>
        Premium Randomizer
          
        </Link>
      </li>
      <li className="nav-mobile-item">
        <Link
          to="/randomizer/tier"
          onClick={() => setOpen(false)}
          className="link"
        >
          Team Randomizer
        </Link>
      </li>
      <li className="nav-mobile-item">
        <Link
          to="/randomizer/multilist"
          onClick={() => setOpen(false)}
          className="link"
        >
          Multi-List Randomizer
        </Link>
      </li>
      {/* <li className="nav-mobile-item">
        <Link
          to="/randomizer/wheels"
          onClick={() => setOpen(false)}
          className="link"
        >
          Wheels
        </Link>
      </li> */}
      {/* <li>
        <Link className="link" to="/buildyourbrand" onClick={() => setOpen(false)}>
          Build Your Brand {""}    
                    <MdFiberNew size={30} color='lightgreen' />
                   
        </Link>
      </li> */}
      <li>
        <Link className="link" to="/pricing" onClick={() => setOpen(false)}>
          Products
        </Link>
      </li>
      {/* <li>
        <a href="/news" className="link">
          News
        </a>
      </li> */}

      <li>
        <a href="/contact" className="link">
          Contact
        </a>
      </li>

      {!isNormal && (
        <>
          {/* <li className="nav-mobile-item">
            <Link
              to={`/user/profile/${user.username}`}
              className="link"
              onClick={() => setOpen(false)}
            >
              My Profile
            </Link>
          </li> */}
          {/* <li className="nav-mobile-item">
            <Link
              to="/settings"
              className="link"
              onClick={() => setOpen(false)}
            >
              Settings
            </Link>
          </li> */}
        </>
      )}
      {/* {!isNormal && (
        <li className="nav-mobile-item">
          <button onClick={(e) => logOutUser(e)} className="link">
            Logout
          </button>
        </li>
      )} */}
    </Ul>
  );
};

export default RightNav;
