import React, { useState, useEffect, useContext } from "react";
import loader from "../../includes/loader.gif";
import "../../includes/Hit.css";
import globalContext from "../../../../context/context";
import Table from "react-bootstrap/Table";
import * as Helper from "../../includes/Helper";
import { useHistory } from "react-router-dom";
import * as Common from "../../../../components/Common";

const Players = () => {
  const [playerId, setPlayerId] = useState(null);
  const [message, setMessage] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [data, setData] = useState([]);
  const [html, setHtml] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [pagination, setPagination] = useState([]);
  const { db, firebase } = useContext(globalContext);
  let history = useHistory();
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  useEffect(() => {
    //redirect if user is not admin
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        history.push("/");
      }
      db.collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const u = doc.data();
            if (!u.isAdmin) {
              history.push("/");
            }
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    });
    if (params.action) {
      setMessage("Player saved successfully.");
      Helper.openPopup("success-popup");
    }
    Helper.onLoad();
    getPlayerList();
  }, []);

  useEffect(() => {
    getPlayerList();
  }, [page]);

  useEffect(() => {
    listPlayers();
    Helper.getPagination(total, perPage, page, setPagination, setPage);
  }, [data]);

  useEffect(() => {
    //when clear search is clicked
    if(!keyword){
      getPlayerList();
    }
  }, [keyword]);

  const getPlayerList = () => {
    setShowLoader(true);
    Common.axiosInstance().get(`players/list/${page}?s=${keyword}`)
      .then((res) => {
        //if last record on the page is deleted go to previous page
        if(res.data.total > 0 && res.data.data.length === 0){
          setPage(page-1);
        }
        setTotal(res.data.total);
        setPerPage(res.data.perPage);
        setData(res.data.data);
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  const deletePlayer = () => {
    setShowLoader(true);
    Helper.closePopup("confirm-popup");

    Common.axiosInstance().delete(`players/${playerId}`)
      .then((res) => {
        setMessage("Player deleted successfully.");
        Helper.openPopup("success-popup");
        setShowLoader(false);
        getPlayerList();
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  const confirmDelete = (id) => {
    setPlayerId(id);
    Helper.openPopup("confirm-popup");
  };

  const listPlayers = () => {
    let row = [],
      totalRec = Object.keys(data).length;
    if (totalRec) {
      let offset = (page - 1) * perPage;
      for (let j = 0; j < totalRec; j++) {
        row.push(
          <tr key={j} id={`row-${data[j].id}`}>
            <td>{offset+j+1}</td>
            <td>{data[j].name}</td>
            <td>{data[j].slug}</td>
            <td>
              <a
                className="primary-btn  view-card-btn "
                onClick={() => history.push(`/players/edit/${data[j].id}`)}
              >
                Edit
              </a>{" "}
              &nbsp;
              <a
                className="primary-btn  view-card-btn "
                onClick={() => confirmDelete(data[j].id)}
              >
                Delete
              </a>
            </td>
          </tr>
        );
      }
    }
    setHtml(row);
  };

  return (
    <>
      <div className="hit-cards-page">
        <div className="inner-top-cta-container">
          <h1 className="blog-header">Players List</h1>
          <a
            className="primary-btn addplayer"
            onClick={() => history.push(`/players/add`)}
          >
            Add New Player
          </a>
        </div>
        <div className="upload-list-table  mt-4">
          <input
            type="text"
            className="filter"
            placeholder="Search Player"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onKeyUp={(e) => e.keyCode === 13 && (page === 1 ? getPlayerList() : setPage(1))}
          />
          <div className="filter-button-container">
            <button
              className="submit-button primary-btn"
              onClick={()=>page === 1 ? getPlayerList() : setPage(1)}
            >
              Search
            </button>
            <button
              className="submit-button secondary-btn ml-1"
              onClick={() => setKeyword("")}
            >
              Clear Search
            </button>
            {showLoader && (
              <img
                src={loader}
                alt={"Loading..."}
                className="pagination-loader"
              />
            )}
          </div>
        </div>
        <div className="upload-list-table  mt-4" id="cards">
          {data.length ? (
            <Table bordered hover>
              <thead>
                <tr>
                <th>Sno.</th>
                  <th>Name</th>
                  <th>Slug</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{html}</tbody>
            </Table>
          ) : !showLoader ? (
            <div className="card-title">No record found</div>
          ) : null}
        </div>
        <div className="pagination">
          {showLoader && (
            <img
              src={loader}
              alt={"Loading..."}
              className="pagination-loader"
            />
          )}
          {pagination}
        </div>
      </div>
      <Helper.SuccessPopup message={message} />
      <Helper.ConfirmPopup callback={deletePlayer} />
      <Helper.ErrorPopup />
    </>
  );
};

export default Players;
