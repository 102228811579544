import React from "react";
import "./PopUp.css";

const PopUp = (props) => {
  return (
    <div className="popup-box">
      <div className="box">
        <div className="close-icon" onClick={props.handleClose}>
          <div />
          <div />
          <div />
        </div>
        {props.content}
      </div>
    </div>
  );
};

export default PopUp;