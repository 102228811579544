import React from "react";
import "./Blog.css";
import { Link } from "react-router-dom";
import defaultBlog from "../../img/default-blog.png";

const BlogTile = (props) => {
  const post = props.post;

  function blogPost() {
    const image = post._embedded["wp:featuredmedia"]
      ? post._embedded["wp:featuredmedia"]["0"].source_url
      : defaultBlog;
    return (
      <div className="blog-post">
        <a href={`/news/${post.slug}`} className="blog-link" rel="noopener">
          <div className="ImageContainer">
            <img
              src={`${image}`}
              className="Image"
              alt={truncateText(cleanTitle(post.title.rendered), 0, 60)}
            />
          </div>
          <div className="TDContainer">
            <h3
              className="blog-h"
              dangerouslySetInnerHTML={{ __html: post.title.rendered }}
            ></h3>
            <br />
            <p
              className="blog-p"
              dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
            ></p>
            <br />
            <p className="blog-p">{convertDate(post.date)}</p>
          </div>
        </a>
      </div>
    );
  }

  function cleanTitle(checkTitle) {
    checkTitle = checkTitle.replace("amp;", "");
    return checkTitle;
  }

  function truncateText(text, start, len) {
    return text.length > len ? text.slice(start, len) : text;
  }

  function toText(block) {
    let tag = document.createElement("div");
    tag.innerHTML = block;
    block = tag.innerText;
    return block;
  }

  function convertDate(date) {
    let dateArray = date.slice(0, 10).split("-");
    let year = dateArray.shift();
    dateArray.push(year);
    return `Published: ${dateArray.join("/")}`;
  }

  return (
    <div className="PostContainer">
      {/* <h1 className='blog-header'>Latest Gemint News </h1> */}
      {blogPost()}
    </div>
  );
};

export default BlogTile;
