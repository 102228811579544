import React from 'react';
import axios from 'axios';
import * as Storage from './Storage';

export const ScrollTop = () => {
  window.scrollTo({
    top: 0,
    left: 0
  });
};

export function arrayCompare(a, b) {
  return a[1].localeCompare(b[1]);
}


export const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${Storage.Get('user') ? Storage.Get('user').token : ''}`
};

export function axiosInstance() {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers
  })
};

export function parseErrors(errors) {
  let err = [];
  for (const key in errors) {
    err.push(errors[key][0]);
  }
  return err;
}

export function logout() {
  localStorage.clear();
}

//Generic reuest error
export const RequestError = () => (
  <div className="alert alert-danger mt-4">Your request can not be submitted at the moment, please try again
    later.</div>);


export const NoRecordFound = () => (<div variant="warning">No record found.</div>);
export const ErrorMsg = (props) => (props.msg ? <div className={"err_msg_blk"}>{props.msg}</div> : null);

//Upper case the words
export const UCWords = (str) => str.replace('-', ' ').replace(/\w\S*/g, function (txt) {
  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
});


//HTML entity decode
export const htmlDecode = (html) => {
  var e = document.createElement('div');
  e.innerHTML = html;
  //return e.childNodes[0].nodeValue;
  return e.innerHTML;
}

//Get URL param
export const checkUrlContains = (name) => {
  if (window.location.href.indexOf(name) > -1) {
    return true;
  } else {
    return null;
  }
}

export function isValidEmail(mail) {
  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail);
}

/**
 * Validate US and Canada Zip
 * @param mail
 * @returns {boolean}
 */
export function isValidZip(mail) {
  return /^[0-9]{5}$|^[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/.test(mail);
}

// To format date to MM/DD/YYYY
export function formatDate(date, type) {
  let monthNames = [
    "01", "02", "03",
    "04", "05", "06", "07",
    "08", "09", "10",
    "11", "12"
  ];

  let day = date.getDate(),
    monthIndex = date.getMonth(),
    year = date.getFullYear();
  day = day < 10 ? `0${day}` : day;
  if (type === 'checkKidExists') {
    return year + '-' + monthNames[monthIndex] + '-' + day;
  } else {
    return monthNames[monthIndex] + '/' + day + '/' + year;
  }
}

export const invalidPhone = phone => phone && phone != '(___) ___-____'
  && phone.replace(/_/g, "").replace('(', '').replace(')', '').replace('-', '').replace(' ', '').length < 10;

export const validPhone = phone => phone && phone.replace(/_/g, "").replace('(', '').replace(')', '').replace('-', '').replace(' ', '').length == 10;


export const objSize = (obj) => {
  let size = 0, key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) size++;
  }
  return size;
}

export const getQueryParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}

export function setQueryParam(key, value) {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  window.history.pushState({}, "", url);
}