export const Get = (key, parse=true) => (
  parse ? JSON.parse(localStorage.getItem(key)) : localStorage.getItem(key)
);

export const Set = (key ,val) => (
  localStorage.setItem(key, val)
);

export const Delete = (key) => (
  localStorage.removeItem(key)
);