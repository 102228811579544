import React, {useEffect, useState, useRef} from "react";
import loader from "../includes/loader.gif";
import * as Common from "../../../components/Common";
import searchIcon from "../includes/magnifying-glass.svg"

const SeasonSearch = (props) => {
  const [seasonKeyword, setSeasonKeyword] = useState("");
  const [htmlSeasonSearch, setHtmlSeasonSearch] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const searchRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (seasonKeyword) {
        searchSeason();
      } else {
        setHtmlSeasonSearch([]);
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [seasonKeyword]);

  const handleSeasonChange = (sid) => {
    props.setSeasonId(sid);
    setSeasonKeyword("");
    setHtmlSeasonSearch("");
    setShowSearch(false);
    props.setShowFollow && props.setShowFollow(true);
  }

  const handleClick = (sid) => {
    setShowSearch(true);
    props.setShowFollow && props.setShowFollow(false);
    setTimeout(()=>{
      searchRef.current.focus();
    }, 500)

  }

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSearch(false);
        props.setShowFollow && props.setShowFollow(true);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const searchSeason = () => {
    setShowLoader(false);
    Common.axiosInstance()
      .get(`seasons/search?s=${seasonKeyword}`)
      .then((res) => {
        const data = res.data.data;
        let rows = [];
        if (data.length) {
          data.map((row) => {
            rows.push(
              <li key={row.id} onClick={() => handleSeasonChange(row.id)}>
                {row.year} - {row.name}
              </li>
            );
          });
        }else{
          rows.push(
            <li>We couldn't find that product.</li>
          );
        }
        setHtmlSeasonSearch(rows);
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  return (
    <>
      {
        showSearch ?
          <div className="search-season" ref={wrapperRef}>
          <input
            type="text"
            id="search-season"
            value={seasonKeyword}
            onChange={(e) => setSeasonKeyword(e.target.value)}
            placeholder="Search Products"
            ref={searchRef}
          />
          <div className="search-results">{htmlSeasonSearch}</div>
        </div>
          :
          <div className="season">
            <h2 onClick={handleClick}>
              <img src = {searchIcon} className="seasonSearchIcon"/>
               {props.season.year} - {props.season.name}</h2>
          </div>
      }
      {showLoader && (
        <img
          src={loader}
          alt={"Loading..."}
          className="pagination-loader"
        />
      )}
    </>
  );
};

export default SeasonSearch;
