import React, {useEffect, useState} from "react";
import "./Leaders.css";
import loader from "../Hit/includes/loader.gif";
import * as Helper from "../Hit/includes/Helper";
import * as Common from "../../components/Common";
import SeasonSearch from "../Hit/componenets/SeasonSearch";
import shareImage from "../../img/share.svg";
import leaderImage from "../../img/demo-player.png";
import {Link} from "react-router-dom";
import SeasonFollow from "../Hit/componenets/SeasonFollow";
import {RWebShare} from "react-web-share";
import LoginRequired from "../Hit/componenets/LoginRequired";
import Advertisement from "../../components/Advertisement";

const Leaders = () => {
    const [preSeasonId, setPreSeasonId] = useState(0);
    const [seasonId, setSeasonId] = useState("");
    const [season, setSeason] = useState([]);
    const [leaders, setLeaders] = useState([]);
    const [selLeader, setSelLeader] = useState({});
    const [cardData, setCardData] = useState([]);
    const [following, setFollowing] = useState([]);
    const [htmlLeaderFollowing, setHtmlLeaderFollowing] = useState(null);
    const [leaderFollowing, setLeaderFollowing] = useState([]);
    const [htmlLeaders, setHtmlLeaders] = useState(null);
    const [noRecord, setNoRecord] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
      getSeason();
    }, [seasonId]);

    useEffect(() => {
      listLeaders();
    }, [leaders, selLeader]);

    useEffect(() => {
      listFollowing();
    }, [following, leaderFollowing]);

    useEffect(() => {
      if (selLeader.username) {
        getDetails();
      }
    }, [selLeader]);

    const getDetails = () => {
      setShowLoader(true);
      Common.axiosInstance()
        .get(`card-uploads/latest?limit=1&username=${selLeader.username}`)
        .then((res) => {
          setCardData(res.data.length ? res.data[0] : {});
          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
        });

      Common.axiosInstance()
        .get(`seasons/subscriptions?username=${selLeader.username}`)
        .then((res) => {
          setFollowing(res.data.following);
          setLeaderFollowing(res.data.data);
          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
        });
    };

    const listFollowing = () => {
      let rows = [];
      if (leaderFollowing.length) {
        leaderFollowing.map((s, i) => {
          rows.push(
            <SeasonFollow key={i} season={s} following={following} follow={!following.includes(s.id)}
                          setShowLoader={setShowLoader} setFollowing={setFollowing} name={true}/>
          );
        })
      } else {
        rows.push(
          <li className="no-record"><div>This user doesn't follow any checklists. Want to follow your own checklists? <Link to = "/checklists" className="checklist-link"> Click here to go to checklists page.</Link></div></li>
        );
      }
      setHtmlLeaderFollowing(<ul>{rows}</ul>);
    };

    const getSeason = () => {
      setShowLoader(true);
      setSelLeader({});
      Common.axiosInstance()
        .get(`seasons/leaders?id=${seasonId}`)
        .then((res) => {
          setSeason(res.data.season);
          setLeaders(res.data.leaders);
          setNoRecord(!res.data.leaders.length)
          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
        });
    };

    const listLeaders = () => {
      let rows = [];
      if (leaders.length) {
        leaders.map((leader, index) => {
          rows.push(
            <div
              key={leader.id}
              onClick={() => setSelLeader(leader)}
              className={`leader ${leader.id === selLeader.id ? "active" : ""}`}>
              <div className="leader-name"><span className="indexOfLeader">{index + 1}</span> @{leader.username}</div>
              <div className="score">{leader.total}</div>
            </div>
          );
        });
        if ((!Object.keys(selLeader).length || preSeasonId != season.id)&& leaders[0]) {
          setSelLeader(leaders[0]);
          setPreSeasonId(season.id)
        }
      }
      setHtmlLeaders(<div className="leaders-list">{rows}</div>);
    };

    return (
      <div className="leaders-container">
        <div className="leaders-season-container">
        <SeasonSearch season={season} setSeasonId={setSeasonId}/></div>
        {showLoader && (
          <img
            src={loader}
            alt={"Loading..."}
            className="pagination-loader"
          />
        )}
        {
          leaders.length ?
            <div className={`leaderboard`}>
              {htmlLeaders}
              <div className="current-leader">
                <div className="heading">LEADER</div>
                <div className="leader-info">
                  {/* <Link to={`/user/profile/${selLeader.username}`}> */}
                  <img onClick={() => Helper.openPopup("leader-popup")}
                       src={selLeader.photo ? selLeader.photo : leaderImage} alt="" className="leader-image"/>
                  {/* </Link> */}
                  <div className="leader-details">
                    <div className="leader-name" onClick={() => Helper.openPopup("leader-popup")}>@{selLeader.username}</div>
                    <div className="leader-points">💎 {selLeader.total}</div>
                  </div>
                </div>
                <div className="share">
                  <RWebShare
                    data={{
                      text: `Gemint - ${selLeader.username}`,
                      url: window.location.origin+'/user/profile/'+selLeader.username,
                      title: `Gemint - ${selLeader.username}`,
                    }}
                  >
                    <img src={shareImage} alt="" className="shareImage"/>
                  </RWebShare>
                </div>
              </div>
            </div>
            : null
        }
        {
          noRecord ?
            <div className={`leaderboard Empty`} style={{height: "280px"}}>
              <div className="leaders-list">
                <div className="login-overlay">
                Be first to <Link to={`/checklists?sid=${season.id}`}>check off cards</Link> for
                  this
                  checklist and claim Leader status!
                </div>
  <div className="leader active">
    <div className="leader-name">
      <span className="indexOfLeader">1</span> @Tester
    </div>
    <div className="score">3</div>
  </div>
  <div className="leader">
    <div className="leader-name">
      <span className="indexOfLeader">1</span> @Tester
    </div>
    <div className="score">3</div>
  </div>
  <div className="leader">
    <div className="leader-name">
      <span className="indexOfLeader">1</span> @Tester
    </div>
    <div className="score">3</div>
  </div>
  <div className="leader">
    <div className="leader-name">
      <span className="indexOfLeader">1</span> @Tester
    </div>
    <div className="score">3</div>
  </div>
  <div className="leader">
    <div className="leader-name">
      <span className="indexOfLeader">1</span> @Tester
    </div>
    <div className="score">3</div>
  </div>
  <div className="leader">
    <div className="leader-name">
      <span className="indexOfLeader">1</span> @Tester
    </div>
    <div className="score">3</div>
  </div>
</div>

<div className="current-leader">
<div className="leader-login-overlay">
                  No Leader
                  <div className="circle-text">
                    Leader’s picture goes here — <a className="text-orange">Will it be YOU?</a>
                  </div>
                </div>
  <div className="heading">LEADER</div>
  <div className="leader-info">
    <img
      src="https://firebasestorage.googleapis.com/v0/b/gemint-prod.appspot.com/o/users%2FIMG_3022.PNG?alt=media&token=2ef2d222-1df8-4d1d-adb3-d7a34ebedaea"
      alt=""
      className="leader-image"
    />
    <div className="leader-details">
      <div className="leader-name">@rahul7529</div>
      <div className="leader-points">💎 3</div>
    </div>
  </div>
  <div className="share">
    <img src="/static/media/share.9e22a6d3.svg" alt="" className="shareImage" />
  </div>
</div>

            </div>
            : null
        }
        <LoginRequired page={'leaders'} seasonId="" playerId="" />
        <div className="popup-overlay" id="leader-popup">
          <div className="leader-popup">
          <Link to={`/user/profile/${selLeader.username}`}>
            <img src={selLeader.photo ? selLeader.photo : leaderImage} className="leader-image"/>
            </Link>
            <Link to={`/user/profile/${selLeader.username}`}>
            <h4 className="leader-name">@{selLeader.username}</h4></Link>
            <p className="points">💎 {selLeader.total} Total Points</p>
            {
              leaderFollowing.length && <div className="info-profile">
                
                {/* <div className="checklist-instructions">
                  Checklist you both both follow are <span className="text-green"> highlighted in Green.</span>
                </div> */}
              </div>
            }

            <div className="leader-info">
              <div className="followers">
              <div className="mutuals">
                  Followed by @{selLeader.username}
                </div>
                {htmlLeaderFollowing}
              </div>
              <div className="checkoff-cards">
              <div className="mutuals">
                  Last Check-off:
                </div>
                <div className="card">
                  <img
                    src={process.env.REACT_APP_STORAGE_URL + cardData.front_image}
                    alt=""
                    className="card-image"
                  />
                  <div className="card-desc">
                    <div className="last-check-off">
                      {Helper.timeSince(cardData.created_at)} ago
                    </div>
                    <div className="profile-card-name">
                      {cardData.player_name}
                      {cardData.card_name} ({cardData.card_number}/{cardData.total_cards})
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="close-button"
                onClick={(event) => Helper.closePopup("leader-popup")}
              >
                X
              </button>
            </div>

          </div>

        </div>
        <Advertisement />
      </div>
    );
  }
;

export default Leaders;
