const initialData = {
    tasks: {
      "task-1": { id: "task-1", content: "Bruins" },
      "task-2": { id: "task-2", content: "Sabres" },
      "task-3": { id: "task-3", content: "Red Wings" },
      "task-4": { id: "task-4", content: "Panthers" },
      "task-5": { id: "task-5", content: "Canadiens" },
      "task-6": { id: "task-6", content: "Senators" },
      "task-7": { id: "task-7", content: "Lightning" },
      "task-8": { id: "task-8", content: "Maple Leafs" },
      "task-9": { id: "task-9", content: "Coyotes" },
      "task-10": { id: "task-10", content: "Blackhawks" },
      "task-11": { id: "task-11", content: "Avalance" },
      "task-12": { id: "task-12", content: "Stars" },
      "task-13": { id: "task-13", content: "Wild" },
      "task-14": { id: "task-14", content: "Predators" },
      "task-15": { id: "task-15", content: "Blues" },
      "task-16": { id: "task-16", content: "Jets" },
      "task-17": { id: "task-17", content: "Hurricanes" },
      "task-18": { id: "task-18", content: "Blue Jackets" },
      "task-19": { id: "task-19", content: "Devils" },
      "task-20": { id: "task-20", content: "Islanders" },
      "task-21": { id: "task-21", content: "Rangers" },
      "task-22": { id: "task-22", content: "Flyers" },
      "task-23": { id: "task-23", content: "Penguins" },
      "task-24": { id: "task-24", content: "Capitals" },
      "task-25": { id: "task-25", content: "Ducks" },
      "task-26": { id: "task-26", content: "Flames" },
      "task-27": { id: "task-27", content: "Oilers" },
      "task-28": { id: "task-28", content: "Kings" },
      "task-29": { id: "task-29", content: "Sharks" },
      "task-30": { id: "task-30", content: "Kraken" },
      "task-31": { id: "task-31", content: "Canucks" },
      "task-32": { id: "task-32", content: "Golden Knights" },
    },
    columns: {
      "column-1": {
        id: "column-1",
        title: "Tier 1",
        taskIds: [
          "task-21",
          "task-22",
          "task-23",
          "task-24",
          "task-25",
          "task-26",
          "task-27",
          "task-28",
        
        ],
      },
      "column-2": {
        id: "column-2",
        title: "Tier 2",
        taskIds: [
          "task-13",
          "task-14",
          "task-15",
          "task-16",
          "task-17",
          "task-18",
          "task-19",
          "task-20",
        ],
      },
      "column-3": {
        id: "column-3",
        title: "Tier 3",
        taskIds: [
          "task-3",
          "task-4",
          "task-5",
          "task-6",
          "task-7",
          "task-8",
          "task-9",
          "task-10",
        ],
      },
      "column-4": {
        id: "column-4",
        title: "Tier 4",
        taskIds: [  "task-29",
          "task-30", "task-31", "task-32", "task-1", "task-2", "task-11",
          "task-12",],
      },
    },
    // Facilitate reordering of the columns
    columnOrder: ["column-1", "column-2", "column-3", "column-4"],
  };
  
  export default initialData;
  