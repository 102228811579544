import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import globalContext from "../../../../context/context";
import * as Helper from "../../includes/Helper";
import loader from "../../includes/loader.gif";
import "../../includes/Hit.css";
import * as Common from "../../../../components/Common";

const AddPlayer = () => {
  const [formValues, setFormValues] = useState([{name: ""}]);
  const [error, setError] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [selectedSeasons, setSelectedSeasons] = useState([]);
  const [seasonHtml, setSeasonHtml] = useState([]);
  const {db, firebase} = useContext(globalContext);
  const [showLoader, setShowLoader] = useState(false);
  let history = useHistory();

  useEffect(() => {
    //redirect if user is not admin
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        history.push("/");
      }
      db.collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const u = doc.data();
            if (!u.isAdmin) {
              history.push("/");
            }
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    });
    Helper.onLoad();
    setShowLoader(true);

    Common.axiosInstance().get(`seasons/all`)
      .then((res) => {
        setSeasons(res.data);
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  }, []);

  useEffect(() => {
    listSeasons();
  }, [seasons, selectedSeasons]);

  const setSeasonList = (e) => {
    const checked = e.target.checked;
    const id = parseInt(e.target.value);
    if (checked && !selectedSeasons.includes(id)) {
      selectedSeasons.push(id);
    }
    if (!checked) {
      const index = selectedSeasons.indexOf(id);
      if (index > -1) {
        // only splice array when item is found
        selectedSeasons.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    setSelectedSeasons(selectedSeasons);
    listSeasons();
  };

  const listSeasons = () => {
    let rows = [],
      totalRec = Object.keys(seasons).length;
    if (totalRec) {
      for (let id in seasons) {
        id = parseInt(id);
        rows.push(
          <span key={id} id={`row-${seasons[id]}`}>
            <input
              type="checkbox"
              checked={selectedSeasons.includes(id)}
              id={id}
              onClick={(e) => setSeasonList(e)}
              value={id}
            />
            &nbsp;&nbsp;
            <label htmlFor={id}>{seasons[id]}</label>
            <br/>
          </span>
        );
      }
    }
    setSeasonHtml(rows);
  };

  const savePlayer = async () => {
    let err = [];
    if (!selectedSeasons.length) {
      err.push(<p>Please select the seasons.</p>);
    }
    if (err.length) {
      setError(err);
      window.scrollTo(0, 0);
    } else {
      let name = [], nameOrg = [];
      for (let x in formValues) {
        if (formValues[x].name) {
          if(name.includes(formValues[x].name.toLowerCase())){
            setError([`Player name should be unique.`]);
            Common.ScrollTop();
            return false;
          }
          name.push(formValues[x].name.toLowerCase());
          nameOrg.push(formValues[x].name);
        } else {
          setError([`Enter player name for all players.`]);
          Common.ScrollTop();
          return false;
        }
      }

      setShowLoader(true);
      Common.axiosInstance().post('players', {names: nameOrg, season_id: selectedSeasons})
        .then((res) => {
          history.push(`/players/list?action=add`);
          setShowLoader(false);
        })
        .catch((error) => {
        });
    }
  };

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, {name: ""}]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  return (
    <div className="login-page container">
      <h1>Add New Player</h1>
      <div className="pagination" style={{color: "red"}}>
        {error}
      </div>
      <div className="pagination">
        {showLoader && (
          <img src={loader} alt={"Loading..."} className="pagination-loader"/>
        )}
      </div>
      <form>
        {formValues.map((element, index) => (
          <div className="sign-in-form-wrapper" key={index}>
            <label>Name</label>
            <div className="input-wrapper">
              <input
                autofocus type="text"
                name="name"
                value={element.name || ""}
                onChange={(e) => handleChange(index, e)}
                placeholder="Enter Player's Name"
                required
              />

              {index ? (
                <button
                  type="button"
                  className="primary-btn  view-card-btn remove-player"
                  onClick={() => removeFormFields(index)}
                >
                  Remove
                </button>
              ) : null}
            </div>
          </div>
        ))}
        <div className="button-section">
          <button
            className="primary-btn view-card-btn add-player"
            type="button"
            onClick={() => addFormFields()}
          >
            Add
          </button>
        </div>
        <div className="sign-in-form-wrapper">{seasonHtml}</div>
      </form>
      <div className="sign-in-actions">
        <button
          className="btn-primary"
          id="create-account-btn"
          onClick={() => savePlayer()}
          disabled={showLoader}
        >
          Submit
        </button>
        <button
          className="forgot-password"
          id="forgot-password"
          onClick={() => history.push("/players/list")}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
export default AddPlayer;
