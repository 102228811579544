import React, {useContext, useEffect, useState} from "react";
import loader from "./includes/loader.gif";
import "./includes/Hit.css";
import globalContext from "../../context/context";
import {useParams} from "react-router";
import SponsorBanner from "../../components/SponsorBanner";
import * as Helper from "./includes/Helper";
import {Link} from "react-router-dom";

const SeasonUploads = () => {
  const [season, setSeason] = useState(null);
  const [topCards, setTopCards] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [data, setData] = useState([]);
  const [players, setPlayers] = useState([]);
  const [allPlayers, setAllPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const {db} = useContext(globalContext);
  const {season_slug} = useParams();
  const [keyword, setKeyword] = useState(null);
  const [seasonId, setSeasonId] = useState(null);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(true);
  const [firstRecId, setFirstRecId] = useState('');
  const [lastRecId, setLastRecId] = useState('');
  const [notFound, setNotFound] = useState(null);

  const perPage = 30;
  const sortField = "created_at";
  const pageStyle = {
    padding: "15px",
    color: "rgb(255, 255, 255)",
    fontSize: "25px; cursor: pointer"
  }

  useEffect(() => {
    setShowLoader(true);
    db.collection("seasons")
      .where("slug", "==", season_slug)
      .get()
      .then((doc) => {
        if (doc.docs.length > 0) {
          let p = [];
          db.collection("players")
            .get()
            .then((doc) => {
              for (let x in doc.docs) {
                let d = doc.docs[x].data();
                p[doc.docs[x].id] = { id: doc.docs[x].id, ...d };
              }
              setAllPlayers(p);
            });

          setSeason(doc.docs[0].data());
          const season_id = doc.docs[0].id;
          setSeasonId(season_id)

          //get last rec id
          db.collection("card_uploads")
            .where("season_id", "==", season_id)
            .where("status", "==", "A")
            .orderBy(sortField, "asc")
            .limit(1)
            .get()
            .then((doc) => {
              if (doc.docs[0]) {
                setLastRecId(doc.docs[0].id);
              }
            });

          db.collection("card_uploads")
            .where("season_id", "==", season_id)
            .where("status", "==", "A")
            .orderBy(sortField, "desc")
            .limit(perPage)
            .get()
            .then((doc) => {
              if (doc.docs[0]) {
                setFirstRecId(doc.docs[0].id);
              }
              setRawData(doc)
              setShowLoader(false);
            })
            .catch((error) => {
              console.log("Error getting document:", error);
            });
        } else {
          setNotFound('Season not found.');
          setShowLoader(false);
        }
      });
  }, []);

  const nextPage = (last) => {
    if (last) {
      setShowLoader(true);
      setIsFirstPage(false);
      db.collection("card_uploads")
        .where("season_id", "==", seasonId)
        .where("status", "==", "A")
        .orderBy(sortField, "desc")
        .startAfter(last[sortField])
        .limit(perPage)
        .get()
        .then((doc) => {
          setRawData(doc);
          setShowLoader(false);
        });
    } else {
      alert('Reached end of the list.');
    }
  };

  const prevPage = (first) => {
    if (first) {
      setShowLoader(true);
      db.collection("card_uploads")
        .where("season_id", "==", seasonId)
        .where("status", "==", "A")
        .orderBy(sortField, "desc")
        .endBefore(first[sortField])
        .limitToLast(perPage)
        .get()
        .then((doc) => {
          setRawData(doc);
          setShowLoader(false);
        });
    } else {
      alert('Reached beginning of the list.');
    }
  };

  const setListData = () => {
    let cards = [], firstPage = false, lastPage = false;
    for (let x in rawData.docs) {
      let d = rawData.docs[x].data();
      let id = rawData.docs[x].id;
      cards.push({id, ...d});
      if (id === firstRecId) {
        firstPage = true;
      }
      if (id === lastRecId) {
        lastPage = true;
      }
    }
    setIsFirstPage(firstPage);
    setIsLastPage(lastPage);
    setData(cards);
  }

  useEffect(() => {
    topUploaded();
  }, [data]);

  useEffect(() => {
    setListData();
  }, [rawData]);

  useEffect(() => {
    if (!keyword) {
      searchCard();
    }
  }, [keyword]);

  const searchCard = () => {
    let filtered = [];
    for (let i in players) {
      if (
        !keyword ||
        players[i].name.toLowerCase().search(keyword.toLowerCase()) >= 0
      ) {
        filtered.push(players[i]);
      }
    }
    setFilteredPlayers(filtered);
  };

  const topUploaded = () => {
    console.log('data', data)
    setTopCards(
      data.map((card) => (
        <div key={card.id} className="uploaded-container">
          <img
            src={card.front_image}
            alt=""
            srcSet=""
            className="hit-card-image"
            id='hit-card-2'
          />
          <div className="top-hits-card-details">
            <Link to={`/hits/${season_slug}/${allPlayers[card.player_id].slug}`}>{card.player}</Link><br/>
            {card.card_name} {card.card_number}/{card.total_cards} <br/>
            <div className='published-by'>
              published {Helper.timeSince(card.created_at)} ago by <Link to={`/user/profile/${card.user_name}`}>@
              {card.user_name}</Link> </div>
            <br/>
            <p className="submitted-notes" id='latest-hits-notes'>
              {card.notes ? `notes: ${card.notes}` : null}
            </p>
            {/* {card.notes ? (
              <>
                notes: {card.notes}
                <br/>
              </>
            ) : null} */}
            {/* published {timeSince(card.created_at)} ago */}
          </div>
        </div>
      ))
    );
  };

  return (
    <>
      <SponsorBanner/>
      {
        season ?
          <>
            <div className="hit-cards-page seasons-uploads">
              {/* <div id="select-card-img">
          {season.photo && (
            <img
              src={season.photo}
              alt=""
              srcSet=""
              className="hit-card-image"
            />
          )}
        </div> */}
              <a className="hit-card" id='img-link' href={`/hits/${season.slug}`}>
                <div className="hit-img-container" id='player-cards-img'>
                  {season.photo && (
                    <img
                      src={season.photo}
                      alt=""
                      srcSet=""
                      className="hit-card-image"

                    />
                  )}
                </div>
              </a>
              <p className="card-title text-align-center" id='hits-title'>
                {season.year} {season.name}{" "} Hits
              </p>
              {/*data.length ? (
          <>
            <div className="filter-container mt-2">
              <input
                id={"search"}
                type="text"
                className="filter"
                placeholder="Search Card"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                onKeyUp={(e) => e.keyCode === 13 && searchCard()}
              />
              <div className="filter-button-container">
                <button className="submit-button primary-btn" onClick={searchCard}>
                  Search
                </button>
                <button className="submit-button secondary-btn ml-1" onClick={() => setKeyword("")}>
                  Clear Search
                </button>
              </div>
            </div>
          </>
        ) : null*/}

              <div className="mt-4 player-accordins" id="cards">
                {data.length ? (
                  topCards
                ) : !showLoader ? (
                  <div className="card-title">No record found</div>
                ) : null}
              </div>
            </div>

            <div className="back-to-wrapper">
              <div className="button-pagination">
                {
                  !isFirstPage &&
                  <span className="page" onClick={() => prevPage(data[0])} style={pageStyle}>Previous</span>
                }
                {
                  !isLastPage &&
                  <span className="page" onClick={() => nextPage(data[data.length - 1])} style={pageStyle}>Next</span>
                }
              </div>
            </div>
          </>
          : <div className="card-title">{notFound}</div>
      }
      {showLoader ? (
        <div className="back-to-wrapper">
        <img
          src={loader}
          alt={"Loading..."}
          className="pagination-loader"
        /></div>
      ) : null}


    </>
  );
};
export default SeasonUploads;
