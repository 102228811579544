import React, {useState, useContext, useEffect} from "react";
import {useHistory} from "react-router-dom";
import globalContext from "../../../../context/context";
import PopUp from "../../../../components/PopUp";
import UploadsList from "../Card/Cards";
import * as Helper from "../../includes/Helper";
import loader from "../../includes/loader.gif";
import "../../includes/Hit.css";
import jquery from "jquery";
import {useParams} from "react-router";
import * as Common from "../../../../components/Common";

const BulkImport = () => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState([]);
    const [seasonId, setSeasonId] = useState(null);
    const [seasons, setSeasons] = useState([]);
    const {db, firebase} = useContext(globalContext);
    const [showLoader, setShowLoader] = useState(false);
    let history = useHistory();

    useEffect(() => {
      //redirect if user is not admin
      firebase.auth().onAuthStateChanged(function (user) {
        if (!user) {
          history.push("/");
        }
        db.collection("users")
          .doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const u = doc.data();
              if (!u.isAdmin) {
                history.push("/");
              }
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      });
      Helper.onLoad();
      setShowLoader(true);

      Common.axiosInstance().get(`seasons/all?limit=500`)
        .then((res) => {
          setSeasons(res.data);
          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
        });

    }, []);

    const listSeasons = () => {
      let rows = [];
      for (let id in seasons) {
        rows.push(
          <option key={id} value={id}>
            {seasons[id]}
          </option>
        );
      }
      return (
        <select value={seasonId} onChange={(e) => setSeasonId(e.target.value)}>
          <option value="">Select Season</option>
          {rows}
        </select>
      );
    };

    const importRecords = async () => {
      let err = [];
      if (!seasonId) {
        err.push(<p>Please select the season.</p>);
      }
      if (!file) {
        err.push(<p>Please select a CSV file.</p>);
      }

      if (err.length) {
        setError(err);
        window.scrollTo(0, 0);
      } else {
        setShowLoader(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('file', file.name);
        formData.append('season_id', seasonId);
        Common.axiosInstance().post('seasons/import', formData)
          .then((res) => {
            setShowLoader(false);
            if (res.data.success) {
              document.getElementById('importFrm').reset();
              alert('Data imported successfully.');
            } else {
              setError(Common.parseErrors(res.data.error));
              Common.ScrollTop();
            }

          })
          .catch((error) => {
            setShowLoader(false);
          });
      }
    }

    return (
      <div className="login-page container">
        <h1>Add New Card</h1>
        <div className="pagination" style={{color: "red"}}>
          {error}
        </div>
        <form id={"importFrm"}>
          <div className="sign-in-form-wrapper">
            <label>Seasons</label>
            {listSeasons()}
          </div>
          <div className="button-section">
            <input type={"file"} id={"inputfile"} onChange={(e) => setFile(e.target.files[0])} accept=".csv"/>
          </div>
        </form>
        <div className="sign-in-actions">
          {showLoader
            ? <div><h2>Do not refresh this page while cards are being uploaded...</h2><img
              src={loader}
              alt={"Loading..."}
              className="pagination-loader"
            /></div>
            : <>
              <button
                className="btn-primary"
                id="create-account-btn"
                onClick={() => importRecords()}
              >
                Submit
              </button>
              <button
                className="forgot-password"
                id="forgot-password"
                onClick={() => history.push("/cards/list")}
              >
                Cancel
              </button>
            </>
          }

        </div>
      </div>
    );
  }
;
export default BulkImport;
