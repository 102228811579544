import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useLocation, useHistory } from "react-router-dom";

import "./SignupPremium.css";
import { createSubscription, welcomeEmail } from "../../store/subscription";
import globalContext from "../../context/context";

export default function Signup() {
  const stripe = useStripe();
  const elements = useElements();
  const [plan, setPlan] = useState("monthly");
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const subscription = useSelector((state) => state.subscription);
  const [nextStep, setNextStep] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [usernameHelperText, setUsernameHelperText] = useState();
  const [passwordHelperText, setPasswordHelperText] = useState();
  const [emailHelperText, setEmailHelperText] = useState();
  const [signupDisable, setSignupDisable] = useState(false);
  const [cardError, setCardError] = useState(null);
  const { firebase, db, userState, user, setUser } = useContext(globalContext);

  useEffect(() => {
    const plan = new URLSearchParams(location.search).get("plan");
    if (plan) {
      setPlan(plan);
    }
  }, [location.search]);

  useEffect(() => {
    if (userState) {
      setNextStep(true);
    } else {
      setNextStep(false);
    }
  }, [userState]);

  useEffect(() => {
    if (subscription?.updatedUser && !user && !user.subscriptionId) {
      setUser(subscription.updatedUser);
      history.push("/success");
    }

    if (subscription.updatedUser && user) {
      if (subscription.updatedUser.plan !== user.plan) {
        setUser(subscription.updatedUser);
        history.push("/success");
      }
    }
  }, [subscription]);

  const style = {
    base: {
      iconColor: "#c4f0ff",
      color: "#fff",
      fontWeight: "500",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "30px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#87BBFD",
      },
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "#FFC7EE",
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setCardError("");

    if (elements == null) {
      return;
    }
    setLoading(true);
    const cardElement = elements.getElement("card");
    const res = await stripe.createToken(cardElement);
    setLoading(false);
    if (res?.token) {
      const payload = {
        stripeToken: res.token,
        email: nextStep ? user.email : email,
        plan,
      };
      dispatch(createSubscription(payload));
    } else {
      setCardError(res.error.message);
    }
  };

  useEffect(() => {
    const usernameRegex = /^([A-Za-z0-9]|[-._](?![-._])){4,20}$/;
    const validUsername = usernameRegex.test(username);
    console.log("valid", validUsername);
    if (validUsername) {
      db.collection("usernames")
        .doc(username)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setUsernameHelperText("This username is not available.");
          } else {
            // doc.data() will be undefined in this case
            setUsernameHelperText("");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          setUsernameHelperText("Sorry, we had an error. Please try again.");
        });
    } else {
      if (username.length < 1) {
        setUsernameHelperText("");
      } else {
        setUsernameHelperText("Sorry, this username is invalid, try another");
      }
    }
  }, [username, db]);

  useEffect(() => {
    db.collection("users")
      .where("email", "==", email)
      .get()
      .then((doc) => {
        if (doc.docs.length > 0) {
          setEmailHelperText("This email is already exists.");
        } else {
          // doc.data() will be undefined in this case
          setEmailHelperText("");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
        setEmailHelperText("Sorry, we had an error. Please try again.");
      });
  }, [email, db]);

  function passwordCheck(password) {
    setPassword(password);
    if (password.length < 6) {
      setPasswordHelperText("Password must be greater than 6 characters");
    } else setPasswordHelperText("");
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setSignupDisable(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const batch = db.batch();

        // TODO make this doc user ID instead of random
        const userId = userCredential.user.uid;
        const userRef = db.collection("users").doc(userId);
        batch.set(userRef, {
          firstName: firstName,
          lastName: lastName,
          username: username,
          email: email,
          userId: userId,
          plan: 'free',
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

        const usernameRef = db.collection("usernames").doc(username);
        batch.set(usernameRef, {
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

        // Commit the batch
        batch
          .commit()
          .then(() => {
            console.log("Document successfully written!");
            const payload = {
              email: email,
              username: username,
              isFree: false,
            };
            dispatch(welcomeEmail(payload));
            setNextStep(true);
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      })
      .catch((error) => {
        var errorCode = error.code;
        console.error("Error writing document: ", errorCode);
      });
  };

  return (
    <div className="sign-up-premium-section">
      {!nextStep ? (
        <>
          <h1>Create your account</h1>
          <p className="payment-wrapper-price">
            {plan === "monthly"
              ? "Sign up for a free 7-day trial of Gemint Premium Randomizer"
              : "Premium Access. Save 25% compared to Monthly Subscription."}
          </p>
          {/* <p>Sign up for a free 7-day trial of Gemint Premium Randomizer</p> */}
          <form>
            <div className="sign-in-form-wrapper">
              <div>
                <div>
                  <label className="label">Username</label>
                  <input
                    required
                    type="text"
                    name="username"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  ></input>
                  <label>{usernameHelperText}</label>
                </div>

                {/* <label>First name</label>
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                ></input>

                <label>Last name</label>
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                ></input> */}

                <div>
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  ></input>
                  <label>{emailHelperText}</label>
                </div>

                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  onChange={(e) => passwordCheck(e.target.value)}
                  required
                ></input>
                <label>{passwordHelperText}</label>
              </div>
              <button
                className="btn-primary"
                id="create-account-btn"
                onClick={(e) => onSubmit(e)}
                disabled={
                  signupDisable ||
                  usernameHelperText ||
                  emailHelperText ||
                  passwordHelperText ||
                  !firstName ||
                  !lastName ||
                  !email ||
                  !password
                }
              >
                Sign up
              </button>
            </div>
          </form>
        </>
      ) : (
        <div className="payment-wrapper">
          <div className="payment-wrapper-title">
            {plan === "monthly" ? "Premium" : "Pro"}
          </div>
          <div className="payment-wrapper-price">
            {plan === "monthly"
              ? "FREE for 7 days + $10/month"
              : "$90/year"}
          </div>
          <div className="payment-wrapper-details">
            {plan === "monthly" ? (
              <ul>
                <li>- Randomize faster than random.org.</li>
                <li>- Integrated dice roller.</li>
                <li>- Time stamped dice rolls + randomizations.</li>
                <li>- Reset, Undo, and Redo buttons.</li>
                <li>- Cryptography-secured randoms.</li>
              </ul>
            ) : (
              <ul>
                <li>- Branded suspense curtain.</li>
                <li>- Fully customizable dice + pip colors.</li>
                <li>- Tiered randoms.</li>
                <li>- Preloaded Division + Team randomizers.</li>
                <li>- Copy/Paste lists into spreadsheets.</li>
              </ul>
            )}
          </div>
          <form onSubmit={handleSubmit}>
            <CardElement options={{ style }} />
            <button
              className="abtn"
              type="submit"
              disabled={
                !stripe || !elements || loading || subscription.isLoading
              }
            >
              Pay
            </button>
            {subscription.error ? (
              <div className="error-panel">{subscription.error}</div>
            ) : null}
            {cardError ? <div className="error-panel">{cardError}</div> : null}
          </form>
        </div>
      )}
    </div>
  );
}
