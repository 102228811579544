import React, { useState } from "react";

const Test = () => {
  const [players, setPlayers] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const teams = [
    {
      id: 1,
      name: "NBA",
      players: [
        {
          id: 11,
          name: "Hamza",
        },
        {
          id: 12,
          name: "Owais",
        },
        {
          id: 13,
          name: "Hiba",
        },
      ],
    },
    {
      id: 12,
      name: "NFL",
      players: [
        {
          id: 21,
          name: "Jaffar",
        },
        {
          id: 22,
          name: "Shajil",
        },
        {
          id: 23,
          name: "Ayub",
        },
      ],
    },
    {
      id: 13,
      name: "MLB",
      players: [
        {
          id: 31,
          name: "Ah",
        },
        {
          id: 32,
          name: "Asss",
        },
        {
          id: 33,
          name: "ASASASAS",
        },
      ],
    },
  ];

  const getTeamPlayers = (id) => {
    const teamsPlayers = teams.find((d) => d.id === id);
    const { players, name } = teamsPlayers;
    setSelectedTeam(name);
    setPlayers(players);
  };

  return (
    <>
      <div>
        <h1>TEAMS</h1>
        {teams.map((d) => (
          <>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => getTeamPlayers(d.id)}
            >
              Team Name : {d.name}
            </div>
            <hr />
          </>
        ))}

        {/* Selected team players will map there by their name */}
        {selectedTeam && <h1>{selectedTeam} : Players</h1>}
        {players.length > 0 &&
          players.map((d) => (
            <>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => getTeamPlayers(d.id)}
              >
                Player Name : {d.name}
              </div>
              <hr />
            </>
          ))}
      </div>
    </>
  );
};

export default Test;
