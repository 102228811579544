import React, { useContext } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./App.css";
import GlobalState from "./context/GlobalState";
import freeRandomizer from "./pages/Landing/Landing";
// import ProspectCards2 from "./pages/Landing/ProspectCards2";
import SignUp from "./pages/SignUp";
import SignupConfirm from "./pages/SignupConfirm";
// import LargeRandomizer from "./pages/LargeRandomizer";
import Login from "./pages/Login/Login";
import SellerSettings from "./pages/SellerSettings/SellerSettings";
import Navbar from "./components/navbar";
import Home from "./pages/Home/Home";
// import AdvancedRandomizer from "./pages/AdvancedRandomizer";
import Help from "./pages/Help/Help";
import Success from "./pages/Success/Success";
import Privacy from "./pages/Privacy/Privacy";
import Terms from "./pages/Terms/Terms";
import Footer from "./components/Footer";
import SignupPremium from "./pages/SignupPremium/SignupPremium";
import globalContext from "./context/context";
import TierRandomizer from "./components/Randomizers/TierRandomizer";
import RazzRandomizer from "./components/Randomizers/RazzRandomizer";
// import DynamicChecklist from "./pages/Checklist/DynamicChecklist";
// import NearTail from "./pages/NearTail/NearTail";
import ReactGA from "react-ga";
import RouteChangeTracker from "../src/components/RouteChangeTracker";
// import Blog from "./pages/Blog/Blog";
// import BlogPost from "./pages/Blog/BlogPost";
// import OpenSea from "./pages/OpenSea/OpenSea";
// new checklist page jh
import "./style/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Profile from "./pages/Profile/Profile";
import Leaders from "./pages/Leaders/Leaders";
import Pricing from "./pages/Pricing/Pricing";
import Custom from "./components/Custom";
import "./style/style2.css";
import "./style/style3.css";
import "./style/Footer.css";
// import Test from "./components/Test";
// import MediumBlog from "./pages/Blog/MediumBlog";
// import BuildYourBrand from "./pages/BuildYourBrand/BuildYourBrand";
// import Breakers from "./pages/Breakers/Breakers"
import SeasonPlayers from "./pages/Hit/SeasonPlayers";
import PlayerCards from "./pages/Hit/PlayerCards";
import SeasonsContainer from "./pages/Hit/Seasons";
import SeasonsUploads from "./pages/Hit/SeasonUploads";
import UploadsList from "./pages/Hit/admin/Card/UploadsList";
import AdminSeasons from "./pages/Hit/admin/Season/AdminSeasons";
import Players from "./pages/Hit/admin/Player/Players";
import BulkImport from "./pages/Hit/admin/Season/BulkImport";
import AddPlayer from "./pages/Hit/admin/Player/AddPlayer";
import AddSeason from "./pages/Hit/admin/Season/AddEditSeason";
import EditPlayer from "./pages/Hit/admin/Player/EditPlayer";
import Cards from "./pages/Hit/admin/Card/Cards";
import AddEditCard from "./pages/Hit/admin/Card/AddEditCard";
import BlogContainer from "./pages/Blog/BlogContainer";
import BlogDetail from "./pages/Blog/BlogDetail";
import RandomWheel from "./pages/Wheels/Wheels";
// import WPBlog from "./pages/Blog/WPBlog"
import Checklists from "./pages/Hit/Checklists";
import Contact from "./pages/Contact/Contact";
import UserProfile from "./pages/Profile/UserProfile";
import UserProfile2 from "./pages/Profile/UserProfile2";
import Checkoff from "./pages/Checkoff/Checkoff";

const trackingId = "UA-211581095-2"; // Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);
<RouteChangeTracker />;

// const stripeApiKey =
//   "pk_test_51IKuexAM6Oz5BR0WfUXDZxb32N2zzX0ZiAmGF82Xh9zgdiWEBUe1TiyLr2IlgA3pwMmoJREfCWRVi5AreXN9jrUJ00qaEesaOG";
const stripeApiKey =
  "pk_live_51IKuexAM6Oz5BR0WlmyfPhzSI8vr4HAce9VvatrR6SVRO76FfqTI48faXi19PUWMuvpeVyhg2B0I9i4cgWHzaanT00x8ra9wBF";

const stripePromise = loadStripe(stripeApiKey);

function App() {
  const { user } = useContext(globalContext);
  let subscription = false;

  if (user) {
    subscription = user.subscription;
  }

  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <BrowserRouter>
          <GlobalState>
            <Navbar />
            {subscription ? (
              <div></div>
            ) : (
              <div>
                <script
                  data-ad-client="ca-pub-4905295131693319"
                  async
                  src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
                ></script>
              </div>
            )}
            <div className="">
              <Route path="/signup" component={SignUp} />
              <Route path="/Custom" component={Custom} />
              <Route exact path="/" component={Checkoff} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/thanks" component={SignupConfirm} />
              <Route exact path="/free-randomizer" component={freeRandomizer} />
              {/* <Route exact path="/randomizer" component={LargeRandomizer} /> */}

              {/* Ghost urls  */}
              {/* <Route exact path="/test" component={AdvancedRandomizer} />
              <Route exact path="/test2" component={Test} />
              <Route exact path="/ghost" component={OpenSea} />
              <Route exact path="/prospectcards" component={ProspectCards2} />
              <Route exact path="/checklist" component={DynamicChecklist} />

              <Route exact path="/breakers" component={Breakers} /> */}

              {/* End of ghost urls */}
              {/* <Route exact path="/wpblog" component={WPBlog} /> */}

              <Route exact path="/news" component={BlogContainer} />
              <Route exact path="/news/:slug" component={BlogDetail} />

              <Route exact path="/cards/uploads/list" component={UploadsList} />
              <Route exact path="/players/list" component={Players} />
              <Route exact path="/seasons/list" component={AdminSeasons} />
              <Route
                exact
                path="/players/edit/:player_id"
                component={EditPlayer}
              />
              <Route exact path="/players/add" component={AddPlayer} />
              <Route exact path="/seasons/add" component={AddSeason} />
              <Route
                exact
                path="/seasons/edit/:season_id"
                component={AddSeason}
              />
              <Route exact path="/cards/import" component={BulkImport} />
              <Route exact path="/cards/list/:season_id" component={Cards} />
              <Route
                exact
                path="/cards/edit/:season_id/:card_id"
                component={AddEditCard}
              />
              <Route
                exact
                path="/user/profile"
                component={UserProfile}
              />
              <Route
                exact
                path="/user/profile/:username"
                component={UserProfile}
              />
              <Route exact path="/profile2" component={UserProfile2} />
              <Route exact path="/leaders" component={Leaders} />
              <Route
                exact
                path="/cards/add/:season_id"
                component={AddEditCard}
              />
              <Route
                exact
                path="/uploads/:season_slug"
                component={SeasonsUploads}
              />
              <Route
                exact
                path="/hits/:season_slug/:player_slug"
                component={PlayerCards}
              />
              <Route
                exact
                path="/hits/:season_slug"
                component={SeasonPlayers}
              />
              <Route
                exact
                path="/checklists-old"
                component={SeasonsContainer}
              />
{/*
              <Route exact path="/checklists" component={Checklists2} />
*/}
              <Route exact path="/checklists" component={Checklists} />

              {/* <Route
                exact
                path="/blog/:id"
                render={(props) => <BlogPost {...props} />}
              /> */}
              {/* <Route exact path="/submityourhit" component={NearTail} /> */}
              {/* <Route exact path="/buildyourbrand" component={BuildYourBrand} /> */}

              <Route exact path="/login" component={Login} />
              <Route exact path="/settings" component={SellerSettings} />
              <Route exact path="/profile" component={UserProfile} />
              <Route exact path="/randomizer/premium" component={Home} />
              <Route exact path="/help" component={Help} />
              <Route exact path="/success" component={Success} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/premium" component={SignupPremium} />
              <Route exact path="/randomizer/tier" component={TierRandomizer} />
              <Route
                exact
                path="/randomizer/multilist"
                component={RazzRandomizer}
              />
              <Route exact path="/randomizer/wheels" component={RandomWheel} />

              <Route exact path="/pricing" component={Pricing} />
              <Route exact path="/checkoff" component={Checkoff} />
            </div>
            <Footer />
          </GlobalState>
        </BrowserRouter>
      </Provider>
    </Elements>
  );
}

export default App;
