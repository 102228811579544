import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import globalContext from "../../../../context/context";
import * as Helper from "../../includes/Helper";
import loader from "../../includes/loader.gif";
import "../../includes/Hit.css";
import { useParams } from "react-router";
import * as Common from "../../../../components/Common";

const EditPlayer = () => {
  const [topPlayer, setTopPlayer] = useState({});
  const [topPlayerColor, setTopPlayerColor] = useState({});
  const [name, setName] = useState("");
  const [error, setError] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [selectedSeasons, setSelectedSeasons] = useState([]);
  const [seasonHtml, setSeasonHtml] = useState([]);
  const { db, firebase } = useContext(globalContext);
  const [showLoader, setShowLoader] = useState(false);
  let history = useHistory();
  const { player_id } = useParams();

  useEffect(() => {
    //redirect if user is not admin
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        history.push("/");
      }
      db.collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const u = doc.data();
            if (!u.isAdmin) {
              history.push("/");
            }
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    });
    Helper.onLoad();
    setShowLoader(true);

    Common.axiosInstance()
      .get(`seasons/all`)
      .then((res) => {
        setSeasons(res.data);
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
    setShowLoader(true);
    Common.axiosInstance()
      .get(`players/${player_id}`)
      .then((res) => {
        setName(res.data.name);
        setSelectedSeasons(res.data.seasons);
        setTopPlayer(res.data.topPlayers);
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  }, []);

  useEffect(() => {
    listSeasons();
  }, [seasons, selectedSeasons, topPlayer]);

  const setSeasonList = (e) => {
    const checked = e.target.checked;
    const id = parseInt(e.target.value);
    if (checked && !selectedSeasons.includes(id)) {
      selectedSeasons.push(id);
    }
    if (!checked) {
      const index = selectedSeasons.indexOf(id);
      if (index > -1) {
        // only splice array when item is found
        selectedSeasons.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    setSelectedSeasons(selectedSeasons);
    listSeasons();
  };

  const setTopPlayers = (e) => {
    const checked = e.target.checked;
    const id = parseInt(e.target.value);
    const topPlayerId = Object.keys(topPlayer);
    if (checked && !topPlayerId.includes(id)) {
      topPlayer[id] = topPlayerColor[id] ?? "";
    } else {
      delete topPlayer[id];
    }
    setTopPlayer(topPlayer);
    listSeasons();
  };
  const setPlayerColor = (e, id) => {
    topPlayer[id] = e.target.value;
    setTopPlayer(topPlayer);
    listSeasons();
  };

  const listSeasons = () => {
    let rows = [],
      totalRec = Object.keys(seasons).length;
    if (totalRec) {
      for (let id in seasons) {
        id = parseInt(id);
        const topPlayerId = Object.keys(topPlayer);
        const topPlayerSelected = topPlayerId.includes(String(id));
        rows.push(
          <span key={id} id={`row-${seasons[id]}`}>
            <input
              style={{ marginLeft: "10px" }}
              type="checkbox"
              checked={selectedSeasons.includes(id)}
              id={id}
              onClick={(e) => setSeasonList(e)}
              value={id}
            />
            &nbsp;&nbsp;
            <label htmlFor={id}>{seasons[id]}</label>
            {selectedSeasons.includes(id) ? (
              <>
                <div className="top-player-options">
                  <input
                    type="checkbox"
                    checked={topPlayerSelected}
                    onChange={(e) => setTopPlayers(e)}
                    value={id}
                    id={id}
                    name={"topId"}
                  />
                  <label htmlFor={id}> Check to make it as a top player</label>
                </div>
                {topPlayerSelected && (
                  <input
                    type="text"
                    value={topPlayer[id] ?? ""}
                    maxLength={7}
                    className="color-input"
                    placeholder="Please Enter Color Hex Code"
                    onChange={(e) => setPlayerColor(e, id)}
                  />
                )}
              </>
            ) : null}
            <br />
          </span>
        );
      }
    }
    setSeasonHtml(rows);
  };

  const savePlayer = async () => {
    let err = [];
    if (!name) {
      err.push(<p>Please enter the card name.</p>);
    }
    if (!selectedSeasons.length) {
      err.push(<p>Please select the seasons.</p>);
    }
    if (err.length) {
      setError(err);
    } else {
      setShowLoader(true);
      Common.axiosInstance()
        .put(`players/${player_id}`, {
          name,
          topPlayer,
          season_id: selectedSeasons,
        })
        .then((res) => {
          if (res.data.success) {
            history.push(`/players/list?action=edit`);
          } else {
            setError(Common.parseErrors(res.data.error));
            Common.ScrollTop();
          }
          setShowLoader(false);
        })
        .catch((error) => {});
    }
  };

  return (
    <div className="login-page container">
      <h1>Edit Player</h1>
      <div className="pagination" style={{ color: "red" }}>
        {error}
      </div>
      <div className="pagination">
        {showLoader && (
          <img src={loader} alt={"Loading..."} className="pagination-loader" />
        )}
      </div>
      <form className="player-add-edit">
        <div className="sign-in-form-wrapper">
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter Player's Name"
            required
          />
          <label id="output" />
        </div>
        <div className="sign-in-form-wrapper">{seasonHtml}</div>
      </form>
      <div className="sign-in-actions">
        <button
          className="btn-primary"
          id="create-account-btn"
          onClick={() => savePlayer()}
          disabled={showLoader}
        >
          Submit
        </button>
        <button
          className="forgot-password"
          id="forgot-password"
          onClick={() => history.push("/players/list")}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
export default EditPlayer;
