import React from 'react'
// import DripHorizontal from "../img/dripSquare.jpg"
import DripHorizontal from "../img/dripHorizontal2.png"

import "./SponsorBanner.css"



function SponsorBanner () {
    return (
        <div className='sponsor-banner-container'>
            <div className='sponsor-img-wrapper'>
            <a id='sponsor-link' target="_blank" href="https://www.dripshop.live/invite/Gemintcards?referral=gemint&utm_source=gemint&utm_medium=banner1&utm_campaign=sportsg15"><img className='sponsor-img' src={DripHorizontal} alt="" /></a>
                {/* <a className='sponsor-link' href="https://www.get-airtime.com/products/black-card-holder"> */}
                {/* <Link to={{ pathname: "https://www.get-airtime.com/products/black-card-holder" }} target="_blank" > */}

         {/* <img className='sponsor-img' src={AirtimeImg} /> */}
         {/* </Link> */}
         {/* </a> */}
         </div>
        </div>
    )
}
export default SponsorBanner;