import React, { useState, useEffect, useContext } from "react";
import loader from "./includes/loader.gif";
import "./includes/Hit.css";
import card from "./includes/PngItem_2626659.png";
import globalContext from "../../context/context";
import jquery from "jquery";
import SponsorBanner from "../../components/SponsorBanner"

const SeasonsContainer = () => {
  const [seasons, setSeasons] = useState(null);
  const [seasons2, setSeasons2] = useState([]);
  const [data, setData] = useState(null);
  const [filterYear, setFilterYear] = useState("");
  const [filterYear2, setFilterYear2] = useState("");
  const [filterYearList, setFilterYearList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const { db } = useContext(globalContext);

  useEffect(() => {
    jquery(document).mouseup(function (e) {
      if (jquery(e.target).closest(".player-popup").length === 0 && jquery(".player-popup").is(':visible')) {
        closePopup(sessionStorage.getItem("popup"))
      }
    });
    setShowLoader(true);
    let seasonsRef = db.collection("seasons");
    if (filterYear) {
      seasonsRef = seasonsRef.where("year", "==", filterYear);
    }
    seasonsRef
      .get()
      .then((doc) => {
        setShowLoader(false);
        let years = [];
        setSeasons(
          doc.docs.map((doc) => {
            const d = doc.data();
            if (!years.includes(d.year)) {
              years.push(d.year);
            }
            return { id: doc.id, ...d };
          })
        );
        years.sort();
        years.reverse();
        if (filterYearList.length === 0) {
          setFilterYearList(years);
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, [filterYear]);

  useEffect(() => {
    setShowLoader(true);
    let seasonsRef = db.collection("seasons");
    if (filterYear2) {
      seasonsRef = seasonsRef.where("year", "==", filterYear2);
    }
    seasonsRef
      .get()
      .then((doc) => {
        let opt = [];
        opt.push(<option value="">Select A Product</option>);
        doc.docs.map((doc) => {
          const d = doc.data();
          opt.push(
            <option key={doc.id} value={d.slug}>
              {d.year} {d.name}
            </option>
          );
        });
        setSeasons2(
          <select
            className="filter"
            onChange={(e) => (window.location.href = "/hits/" + e.target.value)}
          >
            {opt}
          </select>
        );
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, [filterYear2]);

  useEffect(() => {
    listSeasons();
  }, [seasons]);

  const openPopup = (popup) => {
    sessionStorage.setItem("popup", popup);
    document.getElementById(popup).style.display = "flex";
  };

  const closePopup = (popup) => {
    document.getElementById(popup).style.display = "none";
  };

  function listSeasons(filterName = null) {
    let d = [];
    for (let i in seasons) {
      if (
        !filterName ||
        seasons[i].name.toLowerCase().search(filterName.toLowerCase()) !== -1
      ) {
        d.push(
          <a key={i} className="hit-card" href={`/hits/${seasons[i].slug}`}>
            <div className="hit-img-container">
              <img
                src={seasons[i].photo}
                alt=""
                srcSet=""
                className="hit-card-image"
              />
            </div>
            <div className="card-title">
              {seasons[i].year} {seasons[i].name}
            </div>
          </a>
        );
      }
    }
    setData(d);
  }

  function Years() {
    let opt = [];
    opt.push(<option value="">Select A Year</option>);
    filterYearList.map((year) => {
      opt.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    });
    return (
      <select
        className="filter"
        value={filterYear}
        onChange={(e) => setFilterYear(e.target.value)}
      >
        {opt}
      </select>
    );
  }

  function Years2() {
    let opt = [];
    opt.push(<option value="">Select A Year</option>);
    filterYearList.map((year) => {
      opt.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    });
    return (
      <select
        className="filter"
        value={filterYear2}
        onChange={(e) => setFilterYear2(e.target.value)}
      >
        {opt}
      </select>
    );
  }

  return (
    <>
    <SponsorBanner />
      <div className="top-cta-container">
        <h1 className="blog-header">Gemint Checklists </h1>
     
        <div className="TDContainer">
          <button onClick={() => openPopup("seasons")} className="hit-btn">
            Check off Hits
          </button>
        </div>
        <div className="filter-container">
          <input
            type="text"
            className="filter"
            placeholder="Search products"
            onKeyUp={(e) => listSeasons(e.target.value)}
          />
          <Years />
        </div>
        {/* <p class="card-title text-align-center">
          Click a product to see what’s been hit … and what’s still LIVE!
        </p> */}
        <div className="card-container">{data}</div>
        <div className="pagination">
          {showLoader ? (
            <img
              src={loader}
              alt={"Loading..."}
              className="pagination-loader"
            />
          ) : null}
        </div>
      </div>
      <div className="popup-overlay" id="seasons">
        <div className="player-popup">
          <div className="season-info">
            <div id='check-off'>Check Off Hits on Gemint Checklists - Help the Hobby!</div>
            <div className="filter-container">
              <Years2 />
              {seasons2}
            </div>
          </div>
          <button
            className="close-button"
            onClick={(event) => closePopup("seasons")}
          >
            X
          </button>
        </div>
      </div>
    </>
  );
};

export default SeasonsContainer;