import React from "react";
import * as Helper from "../includes/Helper";

const ShowCard = (props) => {
  return (
    <div className="popup-overlay" id="Card-show">
      <div className="player-popup">
        <h2 className="card-name"> {props.card.player_name} - {props.card.card_name} {props.card.unknown ? '?' : props.card.card_number}/{props.card.total_cards}</h2>
        <div className="card-upload-area">
          <div id="bang">
            <div className="uploadedImages">
              <img src={process.env.REACT_APP_STORAGE_URL + props.card.front_image} />
              <img src={process.env.REACT_APP_STORAGE_URL + props.card.back_image} />
            </div>
          </div>
        </div>
        <button
          className="close-button"
          onClick={(event) => Helper.closePopup("Card-show")}
        >
          X
        </button>
      </div>
    </div>
  );
};

export default ShowCard;
