import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  width: 90%;
  color: white;
  margin: auto;

  h1 {
    font-size: 50px;
    margin-bottom: 50px;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 25px;
    }
  }

  p {
    font-size: 25px;
  }

  & a {
    color: #3fd999;
  }
`;

function Success() {
  return (
    <div className="section">
      <Container>
        <h1 className="text-center">Thanks for trying Gemint Premium!</h1>
        <p>
          You can now customize your{" "}
          <Link to="/randomizer/premium">Gemint Premium Randomizer</Link> built for your
          breaks. <Link to="/settings">Change your settings</Link> to get
          started.
        </p>
      </Container>
    </div>
  );
}

export default Success;
