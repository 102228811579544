import React from "react"
import globalContext from "../context/context";
import "./RandomWheel.scss"

function RandomWheel () {
    return (
        <div className='wheel-container'>
           <textarea />
        </div>
    )
}

export default RandomWheel;