import "./Terms.css";

function Terms() {
  return (
    <div className="terms-section  section">
      <h1 className="terms-section-header text-white text-center">
        GEMINT USER AGREEMENT AND TERMS OF SERVICES
      </h1>
<p className='terms-p'>Welcome to Gemint.    

This User Agreement “Agreement” contains Terms of Service the “Terms of Service” which govern the use of our website www.gemint.com (“Website”) and Transactions consummated via our platform.  gemintcards.com is owned and operated by Gemint, LLC, a California Limited Liability Company ("Gemint", "we" / "us" / “our”). This Agreement, together with any documents expressly incorporated by reference, are a legally binding agreement governing our relationship with all Users and visitors of the Website and their use of any Content, functionality and services offered by Gemint.  

As this is a legally binding document, we ask that you please read it carefully and in full, and that if you do not consent to any of the provisions provided herein, that you please refrain from using our Website and any of the Services offered through it.  

By using the Gemint Website and/or the Services, and via your click-through consent for the same, you accept and agree to abide by this Agreement and our Privacy Policy available at www.gemint.com/privacy, which is incorporated herein by reference.

What We Are

Gemint is an online tool for conducting randomized drawings and selections in association with Box Breaks or other Breaking events for trading cards.  Our tool is fully customizable for our subscription members and may also be used free of charge in its standard format.  You can learn more about the specifics of our randomization tool and customizing features for subscription members in our FAQ.
Please note that our present Services do not provide any sort of guidance related to any transaction, such as pricing, shipping, listing, and sourcing, and is solely intended to be used at the discretion of our Users and thus, we take no part in any given Breaking transaction.  Gemint has no control over and does not guarantee: the existence, quality, authenticity, or legality of items shown or advertised, the truth or accuracy of Users' Content or listings, the ability of Sellers to sell items, the ability of Buyers to pay for items, or that a Buyer or Seller will actually complete a transaction or return an item. 
Definitions

“Box Break” or “Break” generally, shall mean and refer to the opening of a package, wrapper or box containing trading cards by a Seller via live video distribution feed or otherwise pre-disclosed, authorized and agreed means of transmission.  Unless otherwise specified, these terms shall be deemed interchangeable.  The Gemint randomizer tool is intended to be utilized by Users in association with conducting their own Breaks.

“Buyer” means a purchaser or prospective purchaser of any Item. 

"Content" means any material, existing or having existed on our Website or delivered through our Services in any fashion consistent with these Terms of Service from any origin and in any form whether digital, electronic, posted, deleted, archived, embedded, linked or contained in any subpage of our Website or existing as data, designs, text, images, graphics, video recording, or code whether object, source or otherwise, and without limitation may generally be considered material which we have provided on or as part of our Website or which Users have provided, posted or uploaded to it.

“Item” means any product listed for sale by a User through their own platform (sales transactions do not occur through Gemint at this time, although we are working on that for the future).

“Seller” means the seller or offeror of any Item listed for sale.  

"Services" means Gemint’s, all programs, resources, tools, and usable Content provided through our Website, including Gemint's randomizer tool. 

“Transaction” means the sale of an Item from a Seller to a Buyer in exchange for currency.

"User" means any and all Buyer and Sellers and any person or party using any of the Services or accessing the Website

“User Content” The Website may contain personal web pages or profiles, forums, bulletin boards, and other interactive features that allow Users to post, submit, publish, display or transmit to other Users or to Gemint various Content or materials (collectively, “User Content”) on or through the Website. All User Content must comply with the Content Standards set out in these Terms of Service (See Section XIV below).
 
Subscription Fees.

Current subscriptions for the Services available on paid User accounts are offered at a rate of $10.00 per month, with processing through Stripe.  Subscription account fees may be adjusted from time to time, in which case all subscribing Users will receive advance notice via their account registration email.

Taxes. 

All Sellers and Users are responsible for paying any and all taxes associated with any Transaction. If you are required to deduct or withhold any taxes from such payments or Transactions, then you must increase the sum payable as necessary.  Gemint has no involvement with nor responsibility for monitoring any such Transactions nor compliance with applicable taxes.

User Accounts

Accounts. To register, all Users will be prompted to confirm their accounts with us and to provide certain registration details. It is a condition for the use of our Services that all the information provided by Users upon confirmation of the concerned accounts must be correct, current and complete. All personal information provided by the Users shall be governed by our Privacy Policy.

Security of Username and Password. Users must treat their Username, password and other personal account information confidential, and must not disclose them to any other person or entity. Users agree to notify Gemint immediately of any unauthorized access to or use of their Username or password or any other breach of security.

Account Termination. We reserve the right to terminate/suspend any User account if we consider doing so necessary, for any reason or no reason, without limitation, including by way of any violation of these Terms of Service or any other agreement between Gemint and a User, or the order/request of any regulatory, investigatory, law enforcement, government or judicial agency/entity.  You agree to release us now and forever from any and all liability incurred by you as a result of our termination/suspension of your account and that you shall indemnify, defend and otherwise hold us harmless for any liability, claims, damages, liens, penalties, fines or fees (including attorney’s fees and/or accounting fees) which we incur or are brought against us by any party as a result, either directly or indirectly of our decision to terminate your account or that of any other. 

Minimum age requirement. Our Services are not intended for individuals who are under eighteen [18] years old. If you are under 18, you shall not use the Website nor our Services and we will not be responsible for your use of the Website and the Services.  Further, any party allowing any person who is under the age of 18 to utilize this Website or the Services shall be in material breach of these Terms of Service and may, without limitation, have any associated registration or other license which they hold for accessing and using our Website and Services, immediately revoked.  

Account Preferences. Upon account creation, Users’ email addresses and mobile phone numbers will be automatically placed in Company email and contact lists.  Gemint will use this information to provide Users with direct notices and updates regarding Users’ accounts, Transactions in which they participate, and such other notifications as are allowed by given users in their Account Preferences settings.  Users may unsubscribe from notifications to their email or mobile phone at any time by accessing their Account Preferences.  More information regarding how Gemint utilizes User information may be found at www.gemint.com/privacy.  
Right to Amend

Right to Amend the Website and Services. We reserve the right to amend the Website and the Services, and any feature, functionality or material that we provide through the Website and the Services, at our sole discretion. We will not be liable if for any reason our Website or Services are unavailable in whole or in part at any time or for any period. From time to time, we may restrict access to some parts of the Website and/or Services, or all of them.

Right to Disable Username and Password. We have the right to disable any Username, password or other identifier, whether chosen by you or provided by us, at any time if, in our opinion, you have violated any provision of these Terms of Service.


 Intellectual Property Rights

Intellectual Property Rights Ownership. The Services, the Website and their entire Contents, features and functionality are owned by Gemint, its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. 

License to Use. Subject to the above-described subscription license(s), these Terms of Service permit Buyer and Sellers and Users to use the Services and the Website. Users must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, store or transmit any of the materials provided through our Services and/or available on our Website, except as specifically described in these Terms of Service with respect to permitted uses and functions.

Trademarks

The name 'Gemint' is a trademark of Gemint, LLC and all related names, logos, product and service names, designs and slogans are owned by Gemint. 

You must not use such marks without our prior written consent. All other names, logos, product and service names, designs and slogans on this Website are the trademarks of their respective owners.

Prohibited Uses

	You may use our Services and Website only for lawful purposes and in accordance with this Agreement. 

You agree not to:

Use our Services in any way that violates any applicable federal, state, local or international law or regulation.

Use our Services for the purpose of exploiting, harming or attempting to exploit or harm minors in any way.

Post false, inaccurate, misleading, deceptive, defamatory, or libelous content.

Use our Services to stalk, abuse, use profanity, send sexually explicit communication, threaten, intimidate, act in a rude, vulgar, sexist, or derogatory manner, defame, insult, make racially offensive statements, or otherwise harass any User or other individual.

Use our Services to send, knowingly receive, upload, download on the Website, use or re-use any material which does not comply with the Content Standards set out in these Terms of Service.

Use our Services to transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any “junk mail”, “chain letter” or “spam” or any other similar solicitation.

Use our Services to impersonate or attempt to impersonate Gemint, another User or any other person or entity (including, without limitation, by using e-mail addresses associated with any of the foregoing).

Use our Services to engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of our Services and Website or which may harm Gemint or our Users or expose them to liability.

Use our Services to solicit other Users into engaging into an illegal and unlawful activities and not to conspire with other Users into soliciting or committing such activities.

Use our Services in any manner that could disable, overburden, damage, or impair the Website or interfere with any other party’s use of the Services.

Use our Services by use of any robot, spider or other automatic device, process or means granting access the Services and the Website.

Use our Services by use of any manual process to monitor or copy any of the material on the Website or for any other unauthorized purpose without our prior written consent.

Use our Services by way of any device, software or routine that interferes with the proper working of the Services and the Website.

Use our Services to introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.

Use our Services to attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Website or any server, computer or database connected to the Website.

Use our Services to attack the Website via a denial-of-service attack or a distributed denial-of-service attack or otherwise attempt to interfere with the proper working of the Website.

Use our Services to modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile or otherwise disassemble any portion of the Website and other proprietary information provided along with the Services or the Website, or cause others to do so.

Use our Services to harvest or otherwise collect information about users without their consent. 

Use our Services to circumvent any technical measures used to provide our Services.

Provide your account details and log-in information to third parties and that you will not log into the Website through another User's account.

There is zero tolerance for any Content creator or User who abuses our Website or Services or posts objectionable content to Gemint. You are solely responsible for your User Content and the consequences of posting or publishing it.  

Monitoring and Enforcement. Termination

Enforcement. We have the right to:

Remove any User Content for any reason in our sole discretion.

Take any action with respect to any User Content that we deem necessary or appropriate in our sole discretion, including if we believe that such User Content violates the Terms of Service, infringes any intellectual property right, right of publicity or other right of any person or entity, threatens the personal safety of Users of the Website or the public or could create liability for Gemint.

Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.

Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Services and the Website.

Terminate or suspend your access to all or part of the Website or your Subscription to the Services for any or no reason, including without limitation, any violation of these Terms of Service.

Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Website. YOU WAIVE AND HOLD HARMLESS GEMINT AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY GEMINT/ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER GEMINT/SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.

No Liability and General Liability Limitation. We do not undertake to review the material before it is posted on the Website and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications or Content provided by any User or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this Agreement. 

Furthermore, to the extent permitted by applicable law, in no event will Gemint (including our members, managers, agents and employees) be liable to you or any third party under any claim at law or in equity for any consequential damages or losses (including, but not limited to, loss of money, goodwill or reputation, profits, other intangible losses, or any special, indirect, or consequential damages), and all such damages or losses are expressly excluded by this agreement whether or not they were foreseeable or Gemint was advised of such damages or losses. Without limiting the generality of the foregoing, we are not liable, and you agree not to hold us responsible, for any damages or losses (including, but not limited to, loss of money, goodwill or reputation, profits, other intangible losses, or any special, indirect, or consequential damages) resulting directly or indirectly from:

the content you provide directly or indirectly using our Services;
your use of or your inability to use our Services;
delays or disruptions in our Services;
viruses or other malicious software obtained by accessing or linking to our Services;
glitches, bugs, errors, or inaccuracies of any kind in our Services;
damage to your hardware device from the use of any Gemint Service;
the content, actions, or inactions of third parties, including items listed using our Services or the destruction of allegedly fake Items;
a suspension or other action taken with respect to your account
your need to modify practices, content, or behavior, or your loss of or inability to do business, as a result of changes to this User Agreement or our policies.
Regardless of the previous paragraphs, if we are found to be liable, our liability to you or to any third party is limited to $100 with regard to any Transaction or series of related Transactions.

Content Standards

Content Standards. The Content standards specified herein apply to any and all User Content. They must in their entirety comply with all applicable federal, state, local and international laws and regulations. Without limiting the foregoing, User Content must not:

contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory or otherwise objectionable.

promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.

infringe any patent, trademark, trade secret, copyright or other intellectual property or other rights of any other person.

 violate the legal rights including the rights of publicity and privacy of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Service and our Privacy Policy.

be likely to deceive any person.

promote any illegal activity, or advocate, promote or assist any unlawful act.

cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any other person.

impersonate any person, or misrepresent your identity or affiliation with any person or organization.

involve commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter or advertising.

give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.

Content not meeting the standards. Any Content not meeting the above standards will be promptly removed.

Linking to the Website

Linking to Website. You may link to our Website, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part without our express written consent.

Unauthorized Framing or Linking. You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking permission without notice. We may disable all or any social media features and any links at any time without notice at our discretion.

Linking from the Website. We do not take responsibility and do not endorse any third-party websites, services and Content available on or linked to by the Website. 


Payment processing.

Payment Processors. Third-party payment services providers Stripe®, Stripe Marketplace Connect®, 2Checkout® and other processors or authenticators (“Payment Processors”) process payments for Service subscription fees. Payment Processors may obtain information about you from third-parties to verify your identity, comply with anti-money laundering and sanctions screening obligations, and for other purposes in connection with managing and processing payments.

Credit Card Information. Gemint, the payments entity or its affiliates may save payment information, such as credit card or debit card numbers, and card expiration dates, entered by you on our Services when you make a purchase or make any other transaction on our Services where card information is entered. Such stored payment information may be used as your default payment method for future transactions on our Services. At any time, you can update your card information or enter new card information, at which point the new card information shall be stored as your default payment method. You may make changes to your default payment method through the Account Settings section under the User Profiles on Gemint.com. You are responsible for maintaining the accuracy of information we have on file, and you consent to Gemint updating such stored information from time to time based on information provided by you, your bank or other payments services providers. You will only provide information about payment methods that you are authorized to use. 


Copyright Infringement.

	We take claims of copyright infringement seriously. We will respond to notices of alleged copyright infringement that comply with applicable law. If you believe any materials accessible on or from the Website infringe your copyright, you may request removal of those materials (or access thereto) from the Website by submitting written notification to our Copyright Agent (designated below), which must include substantially the following: 

	(a) Your physical or electronic signature.

	(b) Identification of the copyrighted work you believe to have been infringed or, if the claim involves multiple works on the Website, a representative list of such works.

	(c) Identification of the material you believe to be infringing in a sufficiently precise manner to allow us to locate that material.

	(d) Adequate information by which we can contact you (including your name, postal address, telephone number and, if available, e-mail address).

	(e) A statement that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner, its agent or the law.

	(f) A statement that the information in the written notice is accurate.

	(g) A statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.

	Our designated Copyright Agent to receive such written notifications is:

	Ian Bennett-Goldberg
	Sutter Law, PC
	61G Avenida de Orinda
	Orinda, CA 94563
	ian@sutterlegal.com

Please be aware that if you knowingly materially misrepresent that material or activity on the Website and the Application is infringing your copyright, you may be held liable for damages.

	15.2. Counter-Notification Procedures. If you believe that material you posted on the Website was removed or access to it was disabled by mistake or misidentification, you may file a counter-notification with us ("Counter-Notice") by submitting written notification to our copyright agent (identified below). The Counter-Notice must include substantially the following:

	(a) Your physical or electronic signature.

	(b) An identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access disabled.

	(c) Adequate information by which we can contact you (including your name, postal address, telephone number and, if available, e-mail address).

	(d) A statement under penalty of perjury by you that you have a good faith belief that the material identified above was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.

	(e) A statement that you will consent to the jurisdiction of any judicial district in which the Website and  the Application may be found and that you will accept service from the person (or an agent of that person) who provided the Website and the Application with the complaint at issue.

	Completed Counter-Notices should be sent to:

	Ian Bennett-Goldberg
	Sutter Law, PC
	61G Avenida de Orinda
	Orinda, CA 94563
	ian@sutterlegal.com

	We reserve the right to restore the removed content if the party filing the original Notice does not file a court action against you within ten business days of receiving the copy of your Counter-Notice.

	Please be aware that if you knowingly materially misrepresent that material or activity on the Website and the Application was removed or disabled by mistake or misidentification, you may be held liable for damages.

	Our designated Copyright Agent to receive such written notifications is:

	Ian Bennett-Goldberg
	Sutter Law, PC
	61G Avenida de Orinda
	Orinda, CA 94563
	ian@sutterlegal.com


Disclaimer of Warranties.

No Liability for Technologically Harmful Material. You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SERVICES AND THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.

Disclaimer of Warranties. YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER GEMINT NOR ANY PERSON ASSOCIATED WITH GEMINT MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER GEMINT NOR ANYONE ASSOCIATED WITH GEMINT REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.

GEMINT HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.

THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.

Additional Limitation of Liability. 

IN NO EVENT WILL GEMINT, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY CONTENT ON THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.

THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.

Indemnification. 

	You agree to defend, indemnify and hold harmless Gemint, its members, managers, agents, affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of this Agreement and Terms of Service or your use of the Website, including, but not limited to, your User Content, any use of the Website’s Content, services and products other than as expressly authorized in these Terms of Service or your use of any information obtained from the Website.  Every term of these Terms of Service are deemed to be material and you expressly agree that any violation of any provision of this Agreement by or allowed/caused by you, shall constitute a material breach.


Dispute Resolution.  

Dispute Resolution. In the event of any dispute, claim, question, or disagreement arising from or relating to this Agreement or the breach thereof (collectively “Dispute”), the Parties shall use their best efforts to amicably resolve the Dispute  
A.  Negotiations. The Parties will have individuals with authority to resolve the Dispute, negotiate with each other in good faith and, recognizing their mutual interests, attempt to reach a just and equitable solution satisfactory to both Parties. The individuals will meet in person or by video call one or more times as reasonably requested by either Party.
B.  Mediation. If the Parties do not resolve the Dispute pursuant to the foregoing paragraph within a period of 30 days, then, upon notice by either Party to the other, the Parties agree to mediate the Dispute in good faith according to the American Arbitration Association’s Commercial Mediation Procedures in a location mutually agreeable to the Parties. The Parties will work in good faith with the mediator to attempt to complete the mediation within 90 days of such notice.  If a Party refuses to participate in mediation, that Party shall be deemed to have irrevocably waived the right to claim or receive any award of attorney’s fees in any subsequent proceeding arising out of such Dispute.
C.  Arbitration. If the Parties do not resolve the Dispute through mediation, then, upon notice by either Party to the other, the Dispute will be finally settled by arbitration administered by JAMS San Francisco with the provisions of its Commercial Arbitration Rules controlling for purposes of procedure. The arbitration will be conducted on a confidential basis before a single arbitrator mutually agreed to by the Parties. The arbitrator will be experienced in contract and technology law.  If the Parties cannot agree upon an arbitrator, one shall be selected in accordance with then prevailing JAMS procedure. The arbitrator must issue a written decision or award which provides an explanation for all conclusions of law and fact. Unless otherwise ordered by the arbitrator for purposes of a final evidentiary hearing, the arbitration may be conducted by remote appearance for the convenience of the Parties.  An award of arbitration may be confirmed in a court of competent jurisdiction. The arbitrator shall award any prevailing Party on a claim or defense some or all of its reasonable pre-award expenses of the arbitration, including the arbitrators’ fees, administrative fees, out-of-pocket expenses such as copying and telephone, witness fees, and attorneys’ fees.  The arbitrator may decide all matters arising out of the dispute including the applicability of the arbitration provisions and issue orders resolving matters in discovery or dispositive motions as well as for injunctive or other equitable relief.  EACH OF THE PARTIES HERETO UNDERSTANDS AND AGREES THAT BY ENTERING INTO THIS AGREEMENT FOR ARBITRATION, THEY ARE IRREVOCABLY WAIVING ANY RIGHT TO A TRIAL BY JURY.

D.  Exception. Requirements of mediation and/or arbitration notwithstanding, the parties hereto each agree that where a need for emergency equitable relief such as a temporary injunction is required to prevent irreparable harm, any such part may bring an action for that express purpose only in a court of competent jurisdiction without violation of the requirements regarding mediation and arbitration.  Upon such matter being decided upon motion or otherwise, the court having jurisdiction thereof shall then order the matter stayed for mediation compliance, or transferred to arbitration, as the case may be.  Further, no provisions regarding mediation or arbitration shall apply to any matter which is brought before a Small Claims Court, provided that such court retains jurisdiction over and adjudicates the same.

E.  Governing Law and Jurisdiction. All matters relating to this Agreement and these Terms of Service and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of California without giving effect to any choice or conflict of law provision or rule (whether of the State of California or any other jurisdiction).


Miscellaneous.
Waiver and Severability. No waiver by Gemint of any term or condition set forth in these Terms of Service shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Gemint to assert a right or provision under these Terms of Service shall not constitute a waiver of such right or provision.  If any provision of these Terms of Service is held by a third party neutral, arbitrator, court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of this Agreement and Terms of Service will continue in full force and effect.

Entire Agreement. This Agreement, including these Terms of Service and our Privacy Policy constitute the sole and entire agreement between you and Gemint with respect to the Website and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Website.

Amendments.  Please note that we reserve the right to revise and update these Terms of Service from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Website thereafter.

Your Comments and Concerns. This website is operated by Gemint, LLC.  All feedback, comments, requests for technical support and other communications regarding the website should be sent to: info@gemintcards.com.
	
	Thank you for visiting gemint.com.

	Last Updated: September, 2021. 
</p>
      {/* <div className="accordion" id="accordionExample">
        <div className="accordion-item mb-3">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              AGREEMENT
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Welcome to Gemint. This User Agreement (“Agreement”) contains
                Terms of Service (the “Terms of Service”) which govern the use
                of our website www.gemint.com (“Website”) and Transactions
                consummated via our platform. gemintcards.com is owned and
                operated by Gemint, LLC, a California Limited Liability Company
                ("Gemint", "we" / "us" / “our”). This Agreement, together with
                any documents expressly incorporated by reference, are a legally
                binding agreement governing our relationship with all Users and
                visitors of the Website and their use of any Content,
                functionality and services offered by Gemint. As this is a
                legally binding document, we ask that you please read it
                carefully and in full, and that if you do not consent to any of
                the provisions provided herein, that you please refrain from
                using our Website and any of the Services offered through it. By
                using the Gemint Website and/or the Services, and via your
                click-through consent for the same, you accept and agree to
                abide by this Agreement and our Privacy Policy
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item mb-3">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              BUYER
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Your breaks deserve to run SMOOTHLY! Designed specifically for
                The Hobby, the Gemint.com dashboard makes your breaking process
                hyper-efficient … so you can spend more time ripping, and less
                time clicking! • Faster randomization than Random.org • Dice on
                the same page as the Randomizer • Timestamped dice rolls +
                randomizations • Big, clickable ‘Randomize’ + ‘Reset’ buttons
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item mb-3">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              SELLER
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Your Gemint Premium subscription puts you in full control of
                your Randomizing experience, with a host of features that
                increase the entertainment value of your break! • Change the
                color of the dice! Create engagement in your live streams by
                letting your audience pick the dice color. Ride hot dice, give
                last spot mojo … The choice is yours! Don’t like the dice color?
                Change it in your settings – use ANY hex code for a fully custom
                dice experience. • Brand the Randomizer with your logo – or any
                other image. Try posting pictures of your PC, or the cards you
                hope to pull in your next break. • Virtual curtain – ALSO
                branded with your logo or image – that conceals randomized list
                results, and slides up to reveal. Create suspense for your live
                audience without the need for clumsy blocker cards! • Customer +
                technical support from Gemint.com. DM us on Instagram @gemint._
                or email us{" "}
                <a href="mailto:info@gemintcards.com">info@gemintcards.com.</a>
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item mb-3">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              CONTENT
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Yes! Our free version will always exist and we will make
                occasional updates to the free version, but users using the free
                version will not have access to the randomizer features on
                Gemint.com available to premium subscribers for $10/month.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item mb-3">
          <h2 className="accordion-header" id="headingFive">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              OUR SERVICES
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                DM us on Instagram @gemint._ or email us{" "}
                <a href="mailto:info@gemintcards.com">info@gemintcards.com</a>
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Terms;
