import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext } from "react-beautiful-dnd";
import CsvDownload from "react-json-to-csv";
import { DateTime } from "luxon";

import globalContext from "../../../context/context";
import { saveTierData, shareResult } from "../../../store/subscription";

import NFLData from "../data/nflTeams";
import NFLNoTier from "../data/nflNoTier"

import NBAData from "../data/nbaTeams";
import NBANoTier from "../data/nbaNoTier";

import MLBData from "../data/mlbTeams";
import MLBNoTier from "../data/mlbNoTier"
import PremierNoTier from "../data/premierNoTier"
import PremierData from "../data/premierTeams"

import WorldCupData from "../data/worldCupTeams"
import WorldCupNoTier from "../data/worldCupNoTier"

import UEFANoTier from "../data/uefaNoTier"
import UEFAData from "../data/uefaTeams"


import NHLData from "../data/nhlTeams"
import NHLNoTier from "../data/nhlNoTier"
import F1Data from "../data/f1Teams";
import F1NoTier from "../data/f1NoTier"
import MLBDivisions from "../data/mlbDivisions";
import NFLDivisions from "../data/nflDivisions";
import NBADivisions from "../data/nbaDivisions";
import UFCDivisions from "../data/ufcDivisions";
import NHLDivisions from "../data/nhlDivisions"
import Column from "../column";
import LockedColumn from "../LockedColumn";
import SpotColumn from "../SpotColumn";
import styled from "styled-components";
import Dice from "../../Dice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FaRandom} from "react-icons/fa";

import "./TierRandomizer.css";
import PopUp from "../../PopUp";
import { Link } from "react-router-dom";
import CustomDropDown from "../../CustomDropDown";
import SponsorBanner from "../../SponsorBanner"

let dt = DateTime.now();

const Container = styled.div`
  display: flex;
  padding-bottom: 4%;
  @media only screen and (max-width: 730px) {
    flex-wrap: wrap;
  }
`;

const RandomizerContainer = styled.div`
  background-color: #1f1f1f;
  padding: 10px;
  margin: auto;
  color: white;
border-radius: 20px;
width: 90%;
  .locked-actions-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .locked-actions-wrapper-right {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    input {
      background-color: white;
      color: black;
      border: 1px solid #3fd999;
      width: 200px;
      height: 50px;
      font-size: 18px;
      
    }
  }

  @media (max-width: 768px) {
    .locked-actions-wrapper {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .locked-actions-wrapper-right {
        margin-top: 20px;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-start;
      }

      .btn-outline {
        margin-bottom: 10px;
      }

      .unlock-button {
        margin-bottom: 10px;
      }
    }
  }
`;

const Nav = styled.div`
  padding-bottom: 0.5vh;
  background-color: #1f1f1f;
  border-radius: 10px;
  & ul {
    list-style-type: none;
    
  }

  & li {
    float: right;
    margin-right: 40px;
    margin-top: 5vh;
    font-weight: 600;
    font-size: 0.8em;
    list-style-type: none;
    border: 1px solid lightgrey;
    padding: 2px 5px;
    text-align: center;
    border-radius: 10px;
    min-width: 100px;
    color: white;

  }

  & a {
    text-decoration: none;
    color: white;
  }

  & a:hover {
    color: #fda130;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: lightgrey;
  opacity: 10%;
  margin: 10px 0 40px 0;
`;

const NavDice = styled.div`
  width: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 2%;
  padding-right: 2%;

  @media (max-width: 768px) {
width: 100%;
  }

  .timestamp-p {
    text-align: left;
  }
`;

const Title = styled.h1`
  max-width: 100%;
  float: left;
  margin-left: 12px;
  color: white;
  font-size: 4rem;
  margin-top: 25px;
  margin-bottom: 50px;
  padding-left: 2%;
  padding-right: 2%;

  @media (max-width: 768px) {
    margin: 0;
    margin-top: 15px;
    font-size: 3rem;

  }
`;

const Button = styled.button`
  margin-left: 10px;
  color: white;
`;

const teamTiers = [
  {
    title: "MLB",
    data: "MLB Tier",
  },
  {
    title: "NBA",
    data: "NBA Tier",
  },
  {
    title: "NFL",
    data: "NFL Tier",
  },
  {
    title: "NHL",
    data: "NHL Tier",
  },
  {
    title: "F1",
    data: "F1 Tier",
  },
  {
    title: "Premier",
    data: "Premier Tier",
  },
  {
    title: "World Cup",
    data: "World Cup Tier",
  },
  {
    title: "UEFA",
    data: "UEFA Tier",
  },
];

const teamNoTiers = [
  {
    title: "MLB",
    data: "MLB No Tier",
  },
  {
    title: "NBA",
    data: "NBA No Tier",
  },
  {
    title: "NFL",
    data: "NFL No Tier",
  },
 
  {
    title: "NHL",
    data: "NHL No Tier",
  },
  {
    title: "F1",
    data: "F1 No Tier",
  },
  {
    title: "Premier",
    data: "Premier No Tier",
  },
  {
    title: "World Cup",
    data: "World Cup No Tier",
  },
  {
    title: "UEFA",
    data: "UEFA No Tier",
  },
];

const divisionItems = [
  {
    title: "MLB",
    data: "MLB Division",
  },
  {
    title: "NBA",
    data: "NBA Division",
  },
  {
    title: "NFL",
    data: "NFL Division",
  },
  {
    title: "NHL",
    data: "NHL Division",
  },
  {
    title: "UFC",
    data: "UFC Division",
  },
];

const TierRandomizer = () => {
  const [data, setData] = useState(MLBData);
  const [colLock, setColLock] = useState(false);
  const [league, setLeague] = useState("MLB");
  const { user, clipboardData, setClipboardData, setUser } =
    useContext(globalContext);
  const [saveTier, setSaveTier] = useState(false);
  const [spots, setSpots] = useState(["", "", "", "", "", "", "", "", "", ""]);
  const [tierName, setTierName] = useState("");
  const [tiers, setTiers] = useState(user?.tiers);
  const dispatch = useDispatch();
  const subscription = useSelector((state) => state.subscription);
  const [openPopup, setOpenPopup] = useState(false);
  const [download, setDownload] = useState(null);
  const [shareEmail, setShareEmail] = useState("");
  const [share, setShare] = useState(false);
  const [enableShare, setEnableShare] = useState(false);
  const [timestamp, setTimestamp] = useState(
    DateTime.now().toLocaleString() +
      " " +
      dt.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
  );

  useEffect(() => {
    if (subscription?.updatedUser && subscription.updatedUser.tiers) {
      if (user?.tiers !== subscription.updatedUser.tiers) {
        setUser(subscription.updatedUser);
      }
    }
  }, [subscription]);

  useEffect(() => {
    if (!user?.email || user.plan === "free") {
      setOpenPopup(true);
    } else {
      setOpenPopup(false);
    }
    if (user && user.tiers) {
      setTiers(user.tiers);
    }
  }, [user]);

  useEffect(() => {
    if (data.columnOrder) {
      data.columnOrder.forEach((columnId) => {
        const column = data.columns[columnId];
        const tasks = column.taskIds.map((taskId) => data.tasks[taskId]);
        let array = tasks;
        const mixedArray = array.map(function (x, i) {
          var cryptoRandom = window.crypto.getRandomValues(
            new Uint32Array(1)
          )[0];
          return { name: x, value: cryptoRandom };
        });
        // mixedArray.sort((a, b) => (a.value > b.value ? 1 : -1));
        const randomizedArray = mixedArray.map((name, i) => {
          let cleanName = name.name;
          return cleanName;
        });
        const tierColumn = Number(column.id.slice(-1));
        const cleanClipboardArray = clipboardData;

        const clipboardLength = cleanClipboardArray.length;
        const dataLength = randomizedArray.length;
        if (clipboardLength < dataLength) {
          const diff = dataLength - clipboardLength;
          for (var i = 0; i < diff; i++) {
            cleanClipboardArray.push([cleanClipboardArray.length + 1, "", ""]);
          }
        }

        randomizedArray.map((team, index) => {
          //For the number of spots available map the team to the corresponding spot's array
          cleanClipboardArray[index][tierColumn] = team.content;
        });
        setClipboardData(cleanClipboardArray);
      });
    }
  }, [data, colLock]);

  const handlePopUp = () => {
    setOpenPopup(!openPopup);
  };

  const onDragStart = () => {};

  const onDragUpdate = () => {};

  const onDragEnd = (result) => {
    document.body.style.color = "inherit";

    // TODO: reorder our column
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = data.columns[source.droppableId];
    const finish = data.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      const newState = {
        ...data,
        columns: {
          ...data.columns,
          [newColumn.id]: newColumn,
        },
      };

      setData(newState);
      return;
    }

    // Moving from one list to another
    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);

    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...data,
      columns: {
        ...data.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    setData(newState);
    return;
  };

  // function RandomAll() {
  //   alert("Randomize all function ");
  //   data.columnOrder.map((columnId) => {
  //     const column = data.columns[columnId];
  //     const tasks = column.taskIds.map((taskId) => data.tasks[taskId]);
  //   });
  // }

  const updateData = (column) => {
    const newState = {
      ...data,
      columns: {
        ...data.columns,
        [column.id]: column,
      },
    };
    setData(newState);
  };

  const saveCurrentTiers = () => {
    if (!user?.email || user.plan === "free" || (saveTier && tierName === "")) {
      setOpenPopup(true);
      return;
    }
    setSaveTier(!saveTier);
    if (saveTier) {
      let customData = {
        ...data,
        spots,
      };
      const payload = {
        email: user.email,
        tierData: {
          title: tierName,
          data: JSON.stringify(customData),
        },
      };
      dispatch(saveTierData(payload));
    }
  };

  const changeCurrentData = (tierData) => {
    const customTierData = JSON.parse(tierData);
    setData(customTierData);
    if (customTierData.spots && customTierData.spots.length > 0) {
      setSpots(JSON.parse(tierData).spots);
    }
  };

  const randomizeAll = () => {
  alert("randomize all")
  
  };

  const RandomizerHeader = () => {
    return (
      <Nav>
        <div className="nav-wrapper">
          <Title>{league} Break</Title>
          <div className="custom-dropdowns">
            <CustomDropDown
              headerTitle="Team Tiers"
              items={teamTiers}
              handleItemClick={changeLeague}

            />
              <CustomDropDown
              headerTitle="Team No Tiers"
              items={teamNoTiers}
              handleItemClick={changeLeague}
              // setSpots={setSpotsChange}
            />
            <CustomDropDown
              headerTitle="Division"
              items={divisionItems}
              handleItemClick={changeLeague}
            />
            {tiers && tiers.length > 0 && (
              <CustomDropDown
                headerTitle="My Saved Tiers"
                items={tiers}
                handleItemClick={changeCurrentData}
              />
          
            )}
          </div>
        </div>
      </Nav>
    );
  };

  // const initialState = {
  //   spots: ["", "", "", "", "", "", "", "", "", ""]
  // };

  const changeLeague = (league) => {
    //TODO: change active link state
    //update state of league
    setColLock(false);
    setLeague(league);
    const newClipboard = [];

    const length = clipboardData.length;

    for (let i = 0; i < length; i++) {
      newClipboard.push([i + 1, "", "", "", ""]);
    }

  

    setClipboardData(newClipboard);

    // Team Tiers
    if (league === "NBA Tier") {
      setData(NBAData);
      // set spots back to initial state of 10
      setSpots(["", "", "", "", "", "", "", "", "", ""]);
    } else if (league === "NFL Tier") {
      setData(NFLData);
      setSpots(["", "", "", "", "", "", "", "", "", ""]);

    } else if (league === "MLB Tier") {
      setData(MLBData);
      setSpots(["", "", "", "", "", "", "", "", "", ""]);

    } else if (league === "NHL Tier") {
      setData(NHLData);
      setSpots(["", "", "", "", "", "", "", "", "", ""]);

    } 
    else if (league === "Premier Tier") {
      setData(PremierData);
      setSpots(["", "", "", "", "", "", "", "", "", ""]);

    } 
    else if (league === "World Cup Tier") {
      setData(WorldCupData);
      setSpots(["", "", "", "", "", "", "", "", "", ""]);

    }
    else if (league === "UEFA Tier") {
      setData(UEFAData);
      setSpots(["", "", "", "", "", "", "", "", "", ""]);

    }
    else if (league === "F1 Tier") {
      setData(F1Data);
      setSpots(["", "", "", "", "", "", "", "", "", ""]);

     
    } 

     // Team No Tiers
     if (league === "NBA No Tier") {
      setData(NBANoTier);
      // console.log(NBANoTier.tasks)
      // set spots to equal the number of teams per each league
      setSpots(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "","", "","", "","", "", "", "", "", ""]);
      setSaveTier(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "","", "","", "","", "", "", "", "", ""]);

    } else if (league === "NFL No Tier") {
      setData(NFLNoTier);
      setSpots(["", "","", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "","", "","", "","", "", "", "", "", ""]);
      setSaveTier(["", "","", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "","", "","", "","", "", "", "", "", ""]);

    } 
    else if (league === "Premier No Tier") {
      setData(PremierNoTier);
      setSpots(["", "","", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",  ]);
      setSaveTier(["", "","", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",  ]);

    }
    else if (league === "World Cup No Tier") {
      setData(WorldCupNoTier);
      setSpots(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",  ]);
      setSaveTier(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",  ]);

    }
    else if (league === "UEFA No Tier") {
      setData(UEFANoTier);
      setSpots(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",  ]);
      setSaveTier(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",  ]);

    }
    else if (league === "MLB No Tier") {
      setData(MLBNoTier);
      setSpots(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "","", "","", "","", "", "", "", "", ""]);
      setSaveTier(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "","", "","", "","", "", "", "", "", ""]);

    } else if (league === "NHL No Tier") {
      setData(NHLNoTier);
      setSpots(["", "","", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "","", "","", "","", "", "", "", "", ""]);
      setSaveTier(["", "","", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "","", "","", "","", "", "", "", "", ""]);

    } 
    else if (league === "F1 No Tier") {
      setData(F1NoTier);
      setSpots(["", "", "", "", "", "", "", "", "", "","", "","", "","", "", "", "", "", ""]);
      setSaveTier(["", "", "", "", "", "", "", "", "", "","", "","", "","", "", "", "", "", ""]);

     
    } 
   
      // Divisions
    else if (league === "MLB Division") {
      setData(MLBDivisions);
      setSpots(["", "", "", "", "", ""]);

    } else if (league === "NBA Division") {
      setData(NBADivisions);
      setSpots(["", "", "", "", "", ""]);

    } else if (league === "NFL Division") {
      setData(NFLDivisions);
      setSpots(["", "", "", "", "", "", "", ""]);

    } 
    else if (league === "NHL Division") {
      setData(NHLDivisions);
      setSpots(["", "", "", ""]);

    }
    else if (league === "UFC Division") {
      setData(UFCDivisions);
      setSpots(["", "", "", "", "", "", "", "", ""]);

    }
  };

  function onTest() {
    if (!user?.email || user.plan === "free") {
      setOpenPopup(true);
      return;
    }
    copy2DToClipboard(clipboardData);
    if (document.getElementById("test")) {
      document.getElementById("test").innerText = "Copied!";
    }
  }

  function copy2DToClipboard(array) {
    if (array) {
      const excelData = array.map((lines) => lines.join("\t")).join("\n");
      copyTextToClipboard(excelData);
    } else {
      alert("Try randomizing a column first.");
    }
  }

  // copied from https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      // ('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  }
  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("copy success", clipboardData);
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  const handleColLock = () => {
    if (!user?.email || user.plan === "free") {
      setOpenPopup(true);
      return;
    }
    setColLock(!colLock);
  };

  const downloadCSV = (isDownload = true) => {
    if (!user?.email || user?.plan === "free") {
      setOpenPopup(true);
      return;
    }
    onTest();
    let finalResult = [];
    clipboardData.forEach((item) => {
      let resultItem = {
        spot: item[0],
      };
      item.forEach((subItem, index) => {
        if (index > 0 && subItem) {
          resultItem[`tier${index}`] = subItem;
        }
      });
      finalResult.push(resultItem);
    });
    setDownload(finalResult);

    let dtnow = DateTime.now();
    let time =
      DateTime.now().toLocaleString() +
      " " +
      dtnow.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);

    setTimestamp(time);
    if (isDownload) {
      setTimeout(() => {
        document.querySelector(".download-csv").click();
      }, 500);
    }
  };

  const sendResult = () => {
    const payload = {
      username: `${user.firstName} ${user.lastName}`,
      email: shareEmail,
      csvData: download,
      timestamp,
    };
    dispatch(shareResult(payload));
    setEnableShare(false);
  };
  const handleTierName = (name) => {
    if (!user?.email || user.plan === "free") {
      setOpenPopup(true);
      return;
    }
    setTierName(name);
  };

  const handleShareResult = () => {
    if (!user?.email || user?.plan === "free") {
      setOpenPopup(true);
      return;
    }
    downloadCSV(false);
    setShare(!share);
  };

  return (
    <div className="tier-randomizer section">
      <SponsorBanner />
      <RandomizerContainer>
        <RandomizerHeader />
        <NavDice>
          <Dice
            faceColor={user?.color || "#000"}
            pipColor={user?.pipColor || "#fff"}
            disabled={!user?.email || user.plan === "free"}
            showPopup={() => setOpenPopup(true)}
          />
        </NavDice>
        <Divider />
        {colLock ? (
          <div className="locked-actions-wrapper">
            <div className="locked-actions-wrapper-left">
              <Button
                className="btn-outline unlock-button"
                onClick={(e) => handleColLock()}
              >
                Unlock to drag
              </Button>
              <Button
                className="btn-outline"
                onClick={(e) => onTest()}
                id="test"
              >
                <FontAwesomeIcon icon={faCopy} />
              </Button>
              {/* <Button
                className="btn-outline"
                onClick={(e) => randomizeAll()}
                id="test"
              >
              <FaRandom /> All
              </Button> */}
           
            </div>
            {/* <Button
                className="btn-outline"
                onClick={(e) => onTest()}
                id="test"
              >
                Randomize All
              </Button> */}
            <div className="locked-actions-wrapper-right">
        
              <Button
                className="btn-outline"
                onClick={() => handleShareResult()}
              >
                Share
              </Button>
              <Button className="btn-outline" onClick={() => downloadCSV()}>
                Download CSV
              </Button>
              <CsvDownload
                data={download}
                className="download-csv"
                filename={`team-result-${timestamp}.csv`}
              />
              {saveTier ? (
                <input
                  type="text"
                  name="tier-name"
                  value={tierName}
                  onChange={(e) => handleTierName(e.target.value)}
                />
              ) : null}
              <Button
                className="btn-outline"
                onClick={() => saveCurrentTiers()}
              >
                {saveTier ? "Save" : "Save to My Tiers"}
              </Button>
            </div>
          </div>
        ) : (
          <div className="locked-actions-wrapper">
            <Button
              className="btn-outline"
              onClick={(e) => handleColLock(!colLock)}
            >
              Lock to randomize
            </Button>
            <div className="locked-actions-wrapper-right">
              <Button
                className="btn-outline"
                onClick={() => handleShareResult()}
              >
                Share
              </Button>
              <Button className="btn-outline" onClick={() => downloadCSV()}>
                Download CSV
              </Button>
              <CsvDownload
                data={download}
                className="download-csv"
                filename={`team-result-${timestamp}.csv`}
              />
              {saveTier ? (
                <input
                  type="text"
                  name="tier-name"
                  value={tierName}
                  onChange={(e) => setTierName(e.target.value)}
                />
              ) : null}
              <Button
                className="btn-outline"
                onClick={() => saveCurrentTiers()}
              >
                {saveTier ? "Save" : "Save to My Tiers"}
              </Button>
            </div>
          </div>
        )}
        <DragDropContext
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          onDragUpdate={onDragUpdate}
        >
          <Container>
            <SpotColumn
              disabled={!user?.email || user.plan === "free"}
              showPopup={() => setOpenPopup(true)}
              setSpots={setSpots}
              spots={spots}
            />
            {data.columnOrder.map((columnId, index) => {
              const column = data.columns[columnId];
              const tasks = column.taskIds.map((taskId) => data.tasks[taskId]);
              if (colLock) {
                return (
                  <LockedColumn
                    key={column.id}
                    column={column}
                    tasks={tasks}
                    updateData={updateData}
                    disabled={!user?.email || user.plan === "free"}
                    showPopup={() => setOpenPopup(true)}
                    index={index}
                  />
                );
              } else {
                return (
                  <Column
                  
                    key={column.id}
                    column={column}
                    tasks={tasks}
                    disabled={!user?.email || user.plan === "free"}
                  />
                );
              }
            })}
          </Container>
        </DragDropContext>
      </RandomizerContainer>
      {(!user?.email || user.plan === "free") && openPopup && (
        <PopUp
          content={
            <>
               <div className="popup-title">Try It Today!</div>
               <br></br>
              <div className='signup-login-links'>
              <Link id='signup-login-link' to="/premium" >
                Go Premium</Link> - FREE for 7 days, then just $10/month. Streamline your breaks with Gemint!
              
              
                {/* <p className=''>Have an account? <Link id='signup-login-link' to="/login">
                  Login now.
              </Link></p> */}
              </div>
            </>
          }
          handleClose={handlePopUp}
        />
      )}
      {share && (
        <PopUp
          content={
            <>
              <div className="popup-title">Share the result</div>
              {(!subscription.isLoading && !subscription.sendResult) ||
              enableShare ? (
                <>
                  <input
                    className="share-input-email"
                    type="email"
                    placeholder="Type email to share the result"
                    value={shareEmail}
                    onChange={(e) => setShareEmail(e.target.value)}
                  />
                  <Button
                    className="btn-outline"
                    onClick={() => sendResult()}
                    disabled={subscription.isLoading || !shareEmail}
                  >
                    Share
                  </Button>
                </>
              ) : (
                <div className="popup-title">
                  {subscription?.sendResult
                    ? subscription.sendResult?.message
                    : "Sending"}
                </div>
              )}
            </>
          }
          handleClose={() => {
            setEnableShare(true);
            setShare(!share);
            setShareEmail("");
          }}
        />
      )}
    </div>
  );
};

export default TierRandomizer;
