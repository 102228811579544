import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import globalContext from "../../../../context/context";
import * as Helper from "../../includes/Helper";
import * as Common from "../../../../components/Common";
import loader from "../../includes/loader.gif";
import "../../includes/Hit.css";
import { useParams } from "react-router";

const AddPlayer = () => {
  const [name, setName] = useState("");
  const [photo, setPhoto] = useState("");
  const [year, setYear] = useState("");
  const [top, setTop] = useState(0);
  const [error, setError] = useState([]);
  const { db, firebase } = useContext(globalContext);
  const [showLoader, setShowLoader] = useState(false);
  const { season_id } = useParams();
  let history = useHistory();

  useEffect(() => {
    //redirect if user is not admin
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        history.push("/");
      }
      db.collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const u = doc.data();
            if (!u.isAdmin) {
              history.push("/");
            }
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    });
    Helper.onLoad();
    if (season_id) {
      setShowLoader(true);
      Common.axiosInstance()
        .get(`seasons/${season_id}`)
        .then((res) => {
          setName(res.data.name);
          setPhoto(res.data.photo);
          setYear(res.data.year);
          setTop(res.data.top);
          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
  }, []);

  const saveSeason = async () => {
    let err = [];

    if (!name) {
      err.push("Please enter the name.");
    }
    if (!photo) {
      err.push("Please enter the photo URL.");
    }
    if (!year) {
      err.push("Please enter the name.");
    }

    if (err.length) {
      setError(err);
      Common.ScrollTop();
    } else {
      setError([]);
      setShowLoader(true);
      Common.axiosInstance()
        .post("seasons", { name, photo, year, top, season_id })
        .then((res) => {
          if (res.data.success) {
            history.push(`/seasons/list?action=${season_id ? "add" : "edit"}`);
          } else {
            setError(Common.parseErrors(res.data.error));
            Common.ScrollTop();
          }
          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
        });
      setShowLoader(false);
    }
  };

  return (
    <div className="login-page container">
      <h1>{season_id ? "Edit" : "Add"} Season</h1>
      <div className="pagination" style={{ color: "red" }}>
        {error}
      </div>
      <div className="pagination">
        {showLoader && (
          <img src={loader} alt={"Loading..."} className="pagination-loader" />
        )}
      </div>

      <div className="sign-in-form-wrapper">
        <label>Name</label>
        <div className="input-wrapper">
          <input
            autofocus
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter Season's Name"
            required
          />
        </div>
      </div>
      <div className="sign-in-form-wrapper">
        <label>Year</label>
        <div className="input-wrapper">
          <input
            autoFocus
            type="text"
            name="year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            placeholder="Enter Season's Year"
            required
          />
        </div>
      </div>
      <div className="sign-in-form-wrapper">
        <label>Photo URL</label>
        <div className="input-wrapper">
          <input
            autoFocus
            type="text"
            name="photo"
            value={photo}
            onChange={(e) => setPhoto(e.target.value)}
            placeholder="Enter Season's Photo URL"
            required
          />
        </div>
      </div>
      <div className="sign-in-form-wrapper top-checkbox">
        <div className="input-wrapper">
          <input
            type="checkbox"
            checked={!!top}
            onChange={(e) => setTop(e.target.checked ? 1 : 0)}
          />
        </div>
        <label>Make it as Top Season</label>
      </div>
      <div className="sign-in-actions">
        <button
          className="btn-primary"
          id="create-account-btn"
          onClick={() => saveSeason()}
          disabled={showLoader}
        >
          Submit
        </button>
        <button
          className="forgot-password"
          id="forgot-password"
          onClick={() => history.push("/seasons/list")}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
export default AddPlayer;
