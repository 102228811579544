import React, { useContext, useState, useEffect } from "react";
import Slider from "react-slick";
import { DateTime } from "luxon";
import globalContext from "../../context/context";
import "./Landing.css";

import Logo from "../../img/logo.png";
import img2 from "../../img/img2.png";
import Dice from "../../components/Dice";
import TheBreakBoys from "../../img/TheBreakBoys.png";
import RatedRips from "../../img/RatedRips.jpg";
import KGASports from "../../img/KGASports.jpg";
import CBCsportscards from "../../img/CBCsportscards.png";
import brainsbreaks from "../../img/brainsbreaks.jpg";
import RoyalRichCards from "../../img/RoyalRichCards.png";
import LoudpackBreaks from "../../img/LoudpackBreaks2.PNG";
import Starstock from "../../img/Starstock.png";
import ProspectCardsBlack from "../../img/ProspectCardsBlack.png";
import ProspectCardsWhite from "../../img/ProspectCardsWhite.jpg";

import MailchimpForm from "../../components/Mailchimp"

// import { MdFiberNew } from "react-icons/md";

import ProspectNoBg from "../../img/ProspectNoBg.png";
import v from "../../img/v.png";

import PopUp from "../../components/PopUp";
import { Link } from "react-router-dom";

let dt = DateTime.now();

function ProspectCards2() {
  const { user } = useContext(globalContext);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  };
  const [count, setCount] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [names, setNames] = useState("");
  const [prevList, setPrevList] = useState([]);
  const [prevTimes, setPrevTimes] = useState([]);
  const [original, setOriginal] = useState("");
  const [redoList, setRedoList] = useState([]);
  const [redoCount, setRedoCount] = useState(0);
  const [redoTimes, setRedoTimes] = useState([]);
  const [toggle, setToggle] = useState(false)
  const [checked, setChecked] = useState([]);
  const [timestamp, setTimestamp] = useState(
    DateTime.now().toLocaleString() +
      " " +
      dt.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
  );

  useEffect(() => {
    if (!user?.email) {
      console.log(user);
      setOpenPopup(true);
    } else {
      setOpenPopup(false);
    }
  }, [user]);

  const sliderItems = [
    {
      header: "TESTIMONIALS",
      image: TheBreakBoys,
      desc: '"Gemint is the best all in one breaking tool. The Gemint team has the best support and really care about the hobby as a whole. Whenever we have any issues or any ways that we think can benefit us breakers, they take initiative and really listen to us and what we have to say. Not only do they listen, but they provide immediate support whenever needed. 10/10 would highly recommend."',
      title: "- TheBreakBoys",
    },
    {
      image: RatedRips,
      desc: '"Gemint has made randomization for breaking smooth and convenient. The small added details they have incorporated to randomizing such as tiers, customization of colors and brand logos have been a huge hit with our community members!"',
      title: "- RatedRips",
    },
    {
      image: KGASports,
      desc: '"Gemint has proved time and time again that they stand with the community, always willing to help both novice and experienced collectors. Their website has helped provide my customers a seamless randomization process that is both entertaining and professional!"',
      title: "- KGASports",
    },
    {
      image: Starstock,
      desc: '"The randomizer has been fantastic for us! Really enjoying the experience."',
      title: "- StarStock",
    },
    {
      image: CBCsportscards,
      desc: '"Gemint is second to none in the way they help breakers efficiently manage breaks on their platform. The snappy user interface makes Gemint unparalleled to any other program on the market."',
      title: "- CBCSportscards",
    },
    {
      image: brainsbreaks,
      desc: '"Fastest Randomizer in the game!"',
      title: "- Brainsbreaks",
    },
    {
      image: RoyalRichCards,
      desc: '"Fast, Quick and Efficient - Gemint makes our life easier."',
      title: "- RoyalRich",
    },
    {
      image: LoudpackBreaks,
      desc: '"Gemint’s randomizer is simply second to none. It’s faster than Usain Bolt and the whole aesthetic is 10/10. Loudpack tested and approved!"',
      title: "- Loudpackbreaks",
    },
  ];

  function shuffleNew() {
    if (!user?.email) {
      setOpenPopup(!openPopup);
      return;
    }

    let array = names.split(`\n`);

    array = array.filter(function (e) {
      return e.replace(/(\r\n|\n|\r)/gm, "");
    });

    prevList.push(array);
    setPrevList(prevList);
    prevTimes.push(timestamp);
    setPrevTimes(prevTimes);
    setOriginal("");

    setRedoCount(0);
    setRedoList([]);
    setRedoTimes([]);

    //get array length and generate random number for each spot

    var mixedArray = array.map(function (x, i) {
      var cryptoRandom = window.crypto.getRandomValues(new Uint32Array(1))[0];
      return { name: x, value: cryptoRandom };
    });

    mixedArray.sort((a, b) => (a.value > b.value ? 1 : -1));

    console.log("mixed", mixedArray);

    let randomizedNameArray = [];
    mixedArray.map((name, i) => {
      randomizedNameArray.push(name.name);
      console.log(name);
    });

    console.log("finalorder", randomizedNameArray);

    setCount(count + 1);
    const randomizedNames = randomizedNameArray.join(`\n`);
    setNames(randomizedNames);
    let dtnow = DateTime.now();
    setTimestamp(
      DateTime.now().toLocaleString() +
        " " +
        dtnow.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
    );
  }

  function undo() {
    if (!user?.email) {
      setOpenPopup(!openPopup);
      return;
    }

    if (count == 0) {
      console.log("nothing happens");
    } else {
      //get prev entry
      let prevNames = prevList.pop().join(`\n`);
      setPrevList(prevList);
      let prevStamp = prevTimes.pop();
      setPrevTimes(prevTimes);

      //save current entry
      redoList.push(names.split(`\n`));
      setRedoList(redoList);
      redoTimes.push(timestamp);
      setRedoTimes(redoTimes);
      setRedoCount(redoCount + 1);

      //set state
      setNames(prevNames);
      setCount(count - 1);
      let dtnow = DateTime.now();
      setTimestamp(
        DateTime.now().toLocaleString() +
          " " +
          dtnow.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
      );
      setOriginal("[ORIGINAL " + prevStamp + "]");
    }
  }

  function redo() {
    if (!user?.email) {
      setOpenPopup(!openPopup);
      return;
    }

    if (redoCount == 0) {
      console.log("nothing happens");
    } else {
      //grab redo set
      let redoNames = redoList.pop().join(`\n`);
      setRedoList(redoList);
      let redoStamp = redoTimes.pop();
      setRedoTimes(redoTimes);

      //save to prev set
      prevList.push(names.split(`\n`));
      setPrevList(prevList);
      prevTimes.push(timestamp);
      setPrevTimes(prevTimes);

      //update state
      setNames(redoNames);
      setCount(count + 1);
      setRedoCount(redoCount - 1);
      let dtnow = DateTime.now();
      setTimestamp(
        DateTime.now().toLocaleString() +
          " " +
          dtnow.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
      );
      setOriginal("[ORIGINAL " + redoStamp + "]");
    }
  }

  function handleChange(e) {
    if (!user?.email) {
      setOpenPopup(!openPopup);
      return;
    }
    setNames(e);
  }

  function resetRandomizer(e) {
    if (!user?.email) {
      setOpenPopup(!openPopup);
      return;
    }

    setNames("");
    setCount(0);
    setPrevList([]);
    setPrevTimes([]);
    setRedoCount(0);
    setRedoList([]);
    setRedoTimes([]);
  }

  const handlePopUp = () => {
    if (!user?.email) {
      setOpenPopup(!openPopup);
    }
  };

  function hi () {
    var curtain = document.querySelector(".hide-curtain");
    var breakerImg = document.querySelector(".hide-breaker-img");
    // // check to see if curtain is already down
    if (curtain.style.height == "405px") {
      // if it is down and the button is clicked, put it back up
      console.log("curtain going back up");
      curtain.style.height = "0px";
      curtain.style.transition = `all 2s`;
      curtain.style.zIndex = "0";
      curtain.style.visibility = "hidden";
     

      // hide breaker img
     breakerImg.style.transition = "all 1s";
          breakerImg.style.visibility = "hidden";
          breakerImg.style.height = '0px'
      // setTimeout(() => {
      //   breakerImg.style.height = "0px";
      // }, 4000);
    } else {
      // else it isn't down and needs to go down
      // breakerImg.style.height = "200px";
      curtain.style.visibility = "visible";
      curtain.style.zIndex = "1";
      curtain.style.position = "absolute";
      console.log("curtain is revealed thoo");
      console.log(curtain.style.height);
      curtain.style.transition = `all 2s`;
      curtain.style.height = "405px";
      curtain.style.marginTop = '15px';
      // display breaker img
      breakerImg.style.visibility = "visible";
      breakerImg.style.opacity = "1";
      breakerImg.style.transitionDelay = `2s`;
      breakerImg.style.height = '300px'
    }
  };

  function freePaymentToggle () {
    // grabbing length of names array to match # of paid columns with it
    let array = names.split(`\n`);
  
  array = array.filter(function (e) {
    return e.replace(/(\r\n|\n|\r)/gm, "");
  });
  console.log('array length = ', array.length)
  console.log('array = ', array)
  
  // toggle true/false between randomize list and collect payment
    setToggle(!toggle)
    var paidColumn = document.querySelector('.free-column-wrapper')
    var textArea = document.querySelector('.homte')
  
    // randomize list toggle 
    if (toggle == true) {
      paidColumn.style.display = 'none'
      paidColumn.style.width = '0'
  
      textArea.style.width = "100%"
      // textArea.style.transition = "all 1s";
  
  
    }
    // collect payment toggle 
    else {
      paidColumn.style.display = 'flex'
      paidColumn.style.flexDirection = 'column'
      paidColumn.style.width= '40%'
      textArea.style.width = "60%"
  

  
     
    }
  }
  const handleCheck = (event) => {
    
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    console.log(updatedList)

    setChecked(updatedList);
  };

  // Generate string of checked items
  const checkedItems = checked.length
    ? checked.reduce((total, item) => {
        return total + ", " + item;
      })
    : "";

  // Return classes based on whether item is checked
  var isChecked = (item) =>
    checked.includes(item) ? "checked-item" : "not-checked-item";
    let array = names.split(`\n`);

    array = array.filter(function (e) {
      return e.replace(/(\r\n|\n|\r)/gm, "");
    });


  return (
    <div className="landing-page">
      <div className="landing-page-header">
        <h4 className="text-center">Break Safer + Faster</h4>
        <h1 className="landing-page-title text-center">
          Tools Designed for The Hobby
        </h1>
        <div className="landing-page-desc">
          <div className="landing-age-desc-top text-center">
            Join Gemint Premium and get access to our full breaker’s toolset
          </div>
          <div className="landing-age-desc-bottom text-center">
            - free for 7 days + $10/month
          </div>
        </div>
        <div className="landing-page-action">
          <a href="/pricing" className="get-premium-button">
            Go Premium
          </a>
        </div>
      </div>

      <div className="dice-container">
        <div className="randomizer">
          <div className="randomizer-form randomizer-home">
            <input
              id="randomizer-title"
              placeholder="Break title"
              onClick={() => handlePopUp()}
            ></input>
              {/* <div className="arrow-wrapper" 
             >
                    <img 
                      onClick={() => hi()}
                     className="arrow" src={v} alt="" />
                  </div> */}
                  <div className="wrap-curtain-text">
                  <div className="hide-curtain">
                    <div className="hide-breaker-img-container">
                    <a target="_blank" href="https://prospect.cards/">

                      <img
                        className="hide-breaker-img"
                        src={ProspectNoBg}
                        alt=""
                      />
                      </a>
                    </div>
                  </div>
                  </div>
                  <div className='paid-text-area-container'>
                  <div className='free-column-wrapper' id='myList' >
                  <div className="checkList">
        {/* <div className="title">Collect payment:</div> */}
        <div className="list-container">
          {array.map((item, index) => (
            <div className='item-wrapper' key={index}>
              
              <span id='item-span' className={isChecked(item)}>
                <input className='item-input' type="checkbox" onChange={handleCheck} />
                {item}</span>
            </div>
          ))}
        </div>
      </div>

      {/* <div>
        {`Items checked are: ${checkedItems}`}
      </div> */}
                      </div>


            <textarea
              className="homte"
              id="randomizer-input"
              rows="23"
              cols="auto"
              value={names}
              placeholder="Type to add items."
              onChange={(e) => handleChange(e.target.value)}
              onClick={() => handlePopUp()}
            ></textarea>
            </div>
 <div className='payment-toggle-container' >
                    <p className='payment-p'>
                    Randomize <br/>list
                    </p>
                    <label className="switch">
                    <input onClick={() => freePaymentToggle()} type="checkbox" />

  <span className="randomizer-slider round"></span>
</label>
<p className='payment-p'>
Collect <br/>payment
                    </p>
                    {/* <div className='new-icon-wrapper'>
                    <MdFiberNew size={35} color='lightgreen' />
                    </div> */}
                  </div>
            <div className="randomizer-bottom flex-container-between full-width">

              <div className="randomize-counter">
                <label>
                  Randomize counter: <span>&nbsp;{count}</span>
                </label>
              </div>
              <div className="time-stamp">
                <label> Timestamp: {timestamp}</label>
              </div>
            </div>
          </div>
        </div>
        <div className='landing-right'>
        {/* <div className='sponsorship-wrapper'>
            <h3 className='presented-by'>
            Gemint Free Tool Presented By:
            </h3>
            <div className='sponsor-img-wrapper'>
            <a target="_blank" href="https://prospect.cards/">

                <img className='sponsor-img' alt='sponsor logo' src={ProspectNoBg} />
                </a>
            </div>
        </div> */}
        <div className="roll-dice-wrapper" id="homdac">
          <Dice
            faceColor={"#000"}
            pipColor={"fff"}
            disabled={!user?.email}
            showPopup={() => setOpenPopup(true)}
          />
          <div className="flex-container-between full-width mb-4">
            <button
              className="abtn abtn4 btn-dark"
              onClick={() => shuffleNew()}
            >
              Randomize
            </button>
            <button
              className="abtn abtn4 btn-dark"
              id='reset-btn'
              onClick={() => resetRandomizer()}
            >
              Reset
            </button>
          </div>
          <div className="flex-container-between full-width">
            <button className="abtn abtn4 btn-dark" onClick={() => undo()}>
              Undo
            </button>
            <button className="abtn abtn4 btn-dark" onClick={() => redo()}>
              Redo
            </button>
          </div>
          </div>
        </div>
      </div>
      {/* <div id='mailchimp' className="section landing-page-tiers flex-container-center full-width">
<MailchimpForm />
</div> */}

      <div className="section landing-page-slider">
        <div className="full-width">
          <div className="row" data-aos="fade-up" data-aos-duration="2000">
            <div className="col-md-12">
              <h1 className="text-center slider-header">TESTIMONIALS</h1>
              <div className="row slider mt-5">
                <Slider {...settings}>
                  {sliderItems.map((item, index) => (
                    <div className="col-md-12" key={index}>
                      <div className="row">
                        <div className="col-md-4 testi-i">
                          <img
                            className="image-fluid"
                            src={item.image}
                            alt=""
                          ></img>
                        </div>
                        <div className="col-md-8 testi-p">
                          <p className="text-left">{item.desc}</p>
                          <br />
                          <span className="testi-n">{item.title}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section landing-page-tiers flex-container-center full-width">
        <div className="landing-page-tiers-left">
          <h4>RANDOMIZE YOUR</h4>
          <h1>
            BREAKS BY <span> TIERS </span>
          </h1>
          <p>
            Gemint supports popular breaking formats like random tiers, teams,
            and divisions – Premium Only! Sign up for Gemint Premium today for
            access to our full breaker's toolset and rip more wax – clean and
            simple.{" "}
          </p>
        </div>
        <div className="landing-page-tiers-right">
          <img className="img-fluid" src={img2} alt=""></img>
        </div>
      </div>

      <div className="row section price-section">
        <img
          className="rounded mx-auto d-block img-fluid"
          src={Logo}
          height="50"
          alt=""
        />
        <h1 className="text-center">
          TRY GEMINT PREMIUM 7 DAYS FREE <br /> THEN <s>$15.00</s>{" "}
          <span>$10.00 </span>PER MONTH.{" "}
        </h1>
        <p className="text-center">
          Premium access to Gemint sales tools and services.
        </p>
        <a href="/pricing" className="abtn">
          Get Premium
        </a>
      </div>
      {/* {!user?.email && openPopup && (
        <PopUp
          content={
            <>
              <div className="popup-title">Sign Up!</div>
              <div className='signup-login-links'>
              <Link id='signup-login-link' to="/signup" >
                Register today</Link> for access to Gemint Freemium tools!
              
              
                <p className=''>Have an account? <Link id='signup-login-link' to="/login">
                  Login now.
              </Link></p>
              </div>
            </>
          }
          handleClose={handlePopUp}
        />
      )} */}
    </div>
  );
}

export default ProspectCards2;
