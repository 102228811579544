import React from "react";
import "./UserProfile2.css";
import avatar from "../../img/product4.png";

function UserProfile2() {
  return (
    <div className="profile-container">
      <div className="user-details">
        <div className="profile-picture-container">
          <div className="profile-picture">
            Add a profile picture <br />
            💎 +1
          </div>
        </div>
        <div className="profile-info">
          @Khart <br />
          💎 0 Points
        </div>
      </div>

      <div className="profile-details">
        <div className="profile-season-container">
          <div className="profile-actions">Lists | Follow</div>
          <div className="profile-seasons">
            <div className="cta-add">Add</div>
            <div className="seasons-list">
              <ul>
                <li>OPTIC NFL 2O22</li>
                <li>OPTIC NFL 2O22</li>
                <li>OPTIC NFL 2O22</li>
                <li>OPTIC NFL 2O22</li>
                <li>OPTIC NFL 2O22</li>
                <li>OPTIC NFL 2O22</li>
                <li>OPTIC NFL 2O22</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="profile-cards-container">
          <div class="profile-card-actions">
            <span className="active">Overview</span>
            <span>Badges</span>
            <span>History</span>
          </div>
          <div className="profile-cards">
            <div className="cards-list">
              <div className="card">
                <img src={avatar} alt="" className="card-image" />
                <div className="card-desc">
                  <div className="last-check-off">
                    Last check-off: Today 2:22 PM
                  </div>
                  <div className="profile-card-name">
                    LeBron James 21/22 Immaculate 1-of-1 Logoman
                  </div>
                </div>
              </div>
              <div className="card">
                <img src={avatar} alt="" className="card-image" />
                <div className="card-desc">
                  <div className="last-check-off">
                    Last check-off: Today 2:22 PM
                  </div>
                  <div className="profile-card-name">
                    LeBron James 21/22 Immaculate 1-of-1 Logoman
                  </div>
                </div>
              </div>
              <div className="card">
                <img src={avatar} alt="" className="card-image" />
                <div className="card-desc">
                  <div className="last-check-off">
                    Last check-off: Today 2:22 PM
                  </div>
                  <div className="profile-card-name">
                    LeBron James 21/22 Immaculate 1-of-1 Logoman
                  </div>
                </div>
              </div>
              <div className="card">
                <img src={avatar} alt="" className="card-image" />
                <div className="card-desc">
                  <div className="last-check-off">
                    Last check-off: Today 2:22 PM
                  </div>
                  <div className="profile-card-name">
                    LeBron James 21/22 Immaculate 1-of-1 Logoman
                  </div>
                </div>
              </div>
              <div className="card">
                <img src={avatar} alt="" className="card-image" />
                <div className="card-desc">
                  <div className="last-check-off">
                    Last check-off: Today 2:22 PM
                  </div>
                  <div className="profile-card-name">
                    LeBron James 21/22 Immaculate 1-of-1 Logoman
                  </div>
                </div>
              </div>
              <div className="card">
                <img src={avatar} alt="" className="card-image" />
                <div className="card-desc">
                  <div className="last-check-off">
                    Last check-off: Today 2:22 PM
                  </div>
                  <div className="profile-card-name">
                    LeBron James 21/22 Immaculate 1-of-1 Logoman
                  </div>
                </div>
              </div>
              <div className="card">
                <img src={avatar} alt="" className="card-image" />
                <div className="card-desc">
                  <div className="last-check-off">
                    Last check-off: Today 2:22 PM
                  </div>
                  <div className="profile-card-name">
                    LeBron James 21/22 Immaculate 1-of-1 Logoman
                  </div>
                </div>
              </div>
              <div className="card">
                <img src={avatar} alt="" className="card-image" />
                <div className="card-desc">
                  <div className="last-check-off">
                    Last check-off: Today 2:22 PM
                  </div>
                  <div className="profile-card-name">
                    LeBron James 21/22 Immaculate 1-of-1 Logoman
                  </div>
                </div>
              </div>
              <div className="card">
                <img src={avatar} alt="" className="card-image" />
                <div className="card-desc">
                  <div className="last-check-off">
                    Last check-off: Today 2:22 PM
                  </div>
                  <div className="profile-card-name">
                    LeBron James 21/22 Immaculate 1-of-1 Logoman
                  </div>
                </div>
              </div>
              <div className="card">
                <img src={avatar} alt="" className="card-image" />
                <div className="card-desc">
                  <div className="last-check-off">
                    Last check-off: Today 2:22 PM
                  </div>
                  <div className="profile-card-name">
                    LeBron James 21/22 Immaculate 1-of-1 Logoman
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile2;
