import React from "react";
import ReactDice from "react-dice-complete";
import "react-dice-complete/dist/react-dice-complete.css";
import { DateTime } from "luxon";
import styled from "styled-components";

const DiceWrapper = styled.div`
  width: auto;
  z-index: 0 !important;

  .timestamp-p {
    font-size: 20px;
    text-align: center;
    color: white;
  }
  .rol {
    width: 100%;
  }

  .dice {
    display: flex;
    z-index: 0 !important;
   justify-content: center;
    align-items: center;  

  }

  .dice-wrapper {
   
    z-index: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  #react-dice {
    z-index: 0 !important;
  }

  .die-container {
    z-index: -1;
  }
`;

let dt = DateTime.now();

class Dice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timestamp:
        DateTime.now().toLocaleString() +
        " " +
        dt.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET),
    };
  }
  render() {
    return (
      <DiceWrapper>
        <div className="dice-wrapper">
          <ReactDice
            id="react-dice"
            style={{ zIndex: 99 }}
            numDice={2}
            outline="true"
            outlineColor="#000"
            faceColor={this.props.faceColor}
            dotColor={this.props.pipColor}
            dieSize="80"
            disableIndividual="true"
            rollDone={this.rollDoneCallback}
            ref={(dice) => (this.reactDice = dice)}
          />
          <button className="abtn rol " onClick={() => this.rollAll()}>
            Roll
          </button>
        </div>
        <p className="timestamp-p">Timestamp: {this.state.timestamp}</p>
      </DiceWrapper>
    );
  }

  rollAll() {
    if (this.props.disabled) {
      this.props.showPopup();
      return;
    }
    let dtnow = DateTime.now();
    let time =
      DateTime.now().toLocaleString() +
      " " +
      dtnow.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);
    this.reactDice.rollAll();
    this.setState({ timestamp: time });
  }

  rollDoneCallback(num) {
    console.log(`You rolled a ${num}`);
  }
}

export default Dice;
