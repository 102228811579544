import React from "react";
import * as Helper from "../includes/Helper";
import {openPopup} from "../includes/Helper";
import * as Storage from "../../../components/Storage";
import {Link} from "react-router-dom";

export const checkLogin = () => {
  if (Storage.Get('user')) {
    return true;
  }else{
    openPopup("login-popup");
    return false;
  }
};

const LoginRequired = (props) => {
  return (
    <div className="popup-overlay" id="login-popup"  >
      <div className="player-popup">
        <div id="login-container" className="card-upload-area">
          <div className="login-wrapper">
            <Link id="login-link" to={`/login?p=${props.page}&sid=${props.seasonId ?? ''}&pid=${props.playerId ?? ''}`}>
              Login
            </Link>
            to Gemint to check off hits!
          </div>
          <div className="login-wrapper">
            Need an account?
            <Link id="login-link" to={`/signup?p=${props.page}&sid=${props.seasonId}&pid=${props.playerId}`}>
              Signup here!
            </Link>
          </div>
        </div>
        <button
          className="close-button"
          onClick={(event) => Helper.closePopup("login-popup")}
        >
          X
        </button>
      </div>
    </div>
  );
};
export default LoginRequired;

