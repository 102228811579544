import React, { useContext, useEffect, useState } from "react";
import ReactDice from "react-dice-complete";
import "react-dice-complete/dist/react-dice-complete.css";
import globalContext from "../context/context";
import { TwitterPicker } from "react-color";
import styled from "styled-components";

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .dice-wrapper {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .dice {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .update-dice-color-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }

  & p {
    margin-left: 2%;
    font-size: 20px;
    padding: 20px 0;
  }

  .dice-color-picker {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .face-color-picker {
    margin-right: 20px;
  }
`;

function Die() {
  const { user, setUser, db } = useContext(globalContext);
  const [color, setColor] = useState("000");
  const [pipColor, setPipColor] = useState("fff");
  const [faceColorStatus, setfaceColorStatus] = useState("");

  useEffect(() => {
    if (user) {
      setColor(user.color);
      setPipColor(user.pipColor);
    }
  }, [user]);

  function updateDiceColor() {
    db.collection("users")
      .doc(user.userId)
      .set(
        {
          color: color,
          pipColor: pipColor,
        },
        { merge: true }
      )
      .then(() => {
        const updatedUser = user;
        updatedUser.color = color;
        setUser(updatedUser);
        console.log("Document successfully written!");
        setfaceColorStatus("Your dice have been updated.");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
        setfaceColorStatus(
          "There was a problem updating your dice. Please try again."
        );
      });
  }

  function colorChange(color, event) {
    setColor(color.hex);
  }

  function pipColorChange(color, event) {
    setPipColor(color.hex);
  }

  return (
    <Container className="dice">
      <div className="dice-wrapper">
        {user ? (
          <>
            <ReactDice
              numDice={1}
              outline="true"
              outlineColor="#000"
              faceColor={color}
              dotColor={pipColor}
              dieSize="80"
            />

            <div data-aos="fade-up" data-aos-duration="1000" className="row">
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                className="col-md-5 mt-5 text-left"
              >
                <h5>Dip face color</h5>
                <TwitterPicker color={color} onChange={(e) => colorChange(e)} />
              </div>
              <div className="col-md-2"></div>
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                className="col-md-5 my-5"
              >
                <h5>Dip pip color</h5>
                <TwitterPicker
                  color={pipColor}
                  onChange={(e) => pipColorChange(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <p className="text-white loading-text">Loading...</p>
        )}
        <div className="update-dice-color-button">
          <button className="btn abtn" onClick={(e) => updateDiceColor()}>
            Save
          </button>
          <label>{faceColorStatus}</label>
        </div>
      </div>
    </Container>
  );
}

export default Die;
