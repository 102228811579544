import React, { useState } from "react";
import Test from "../pages/Test";

const Custom = (props) => {
  var [name, setName] = useState("");

  return (
    <div>
      <form>
        <input
          placeholder="team player 1"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input className="formsubmit" type="submit" value="Enter"></input>
      </form>
      <Test />
    </div>
  );
};

export default Custom;
