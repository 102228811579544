import React, { useState, useContext, useEffect } from "react";

import globalContext from "../../context/context";
import NBAData from "./data/nbaTeams";
import MLBData from "./data/mlbTeams";
import F1Data from "./data/f1Teams";

import MLBDivisions from "./data/mlbDivisions";
import NFLDivisions from "./data/nflDivisions";
import NBADivisions from "./data/nbaDivisions";
import UFCDivisions from "./data/ufcDivisions";
import Column from "./column";
import LockedColumn from "./LockedColumn";
import RazzColumn from "./RazzColumn";
import RazzColumn2 from "./RazzColumn2";
import RazzColumn3 from "./RazzColumn3";
import RazzColumn4 from "./RazzColumn4";

import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import Dice from "../Dice";
import DragMove from "../../components/DragMove";
// import Randomizer from "../../img/RANDOMIZER.png";
import "../Randomizers/randomizer.css"
import PopUp from "../PopUp/index"
import { Link } from "react-router-dom";
import SponsorBanner from "../SponsorBanner"

const Container = styled.div`
  display: flex;
  padding-bottom: 4%;
  width: 100%;



  @media (max-width:1000px) {
    flex-direction: column;
  }
  
`;
const RazzColumnWrapper = styled.div`
  width: 20%;
  margin-right: 50px;

  @media (max-width:1000px) {
    width: 80%;
    margin-left: 10%;
    border: 1px solid gray;
    margin-top: 50px;
    border-radius: 15px;
    box-shadow: 1px 1px 1px 1px gray;
  }
  @media (max-width:500px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;

  }
`;

const RandomizerContainer = styled.div`
  background-color: #1f1f1f;
  padding: 0.1% 2% 0% 2%;
  margin: 0;
  border-radius: 20px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;


`;

const Nav = styled.div`
  padding-bottom: 0.5vh;
  background-color: #1f1f1f;
  border-radius: 10px;
  & ul {
    list-style-type: none;
  }

  & li {
    float: right;
    margin-right: 40px;
    margin-top: 5vh;
    font-weight: 600;
    font-size: 0.8em;
    list-style-type: none;
    border: 1px solid lightgrey;
    padding: 2px 5px;
    text-align: center;
    border-radius: 10px;
    min-width: 100px;
  }

  & a {
    text-decoration: none;
    color: white;
  }

  & a:hover {
    color: #fda130;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: lightgrey;
  opacity: 10%;
  margin: 10px 0 40px 0;
`;

const NavDice = styled.div`
  max-width: 24vw;
`;

const Title = styled.h1`
  max-width: 100%;
  float: left;
  margin-left: 12px;
`;

const Button = styled.button`
  margin-left: 10px;
`;

export default function RazzRandomizer() {
  const [data, setData] = useState(MLBData);
  const [colLock, setColLock] = useState(false);
  const [league, setLeague] = useState("MLB");
  const { user, clipboardData, setClipboardData } = useContext(globalContext);
  const [openPopup, setOpenPopup] = useState(false);
  const [translate, setTranslate] = useState({
    x: 0,
    y: 0,
  });
  
  useEffect(() => {
    if (!user?.email || user?.plan !== "monthy") {
      setOpenPopup(true);
    } else {
      setOpenPopup(false);
    }
  }, [user]);

  const onDragStart = () => {};

  const onDragUpdate = () => {};

  function handleDragMove(e) {
    setTranslate({
      x: translate.x + e.movementX,
      y: translate.y + e.movementY,
    });
  }
  const handlePopUp = () => {
    setOpenPopup(!openPopup);
  };
  const onDragEnd = (result) => {
    document.body.style.color = "inherit";

    // TODO: reorder our column
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = data.columns[source.droppableId];
    const finish = data.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      const newState = {
        ...data,
        columns: {
          ...data.columns,
          [newColumn.id]: newColumn,
        },
      };

      setData(newState);
      return;
    }

    // Moving from one list to another
    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);

    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...data,
      columns: {
        ...data.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };

    setData(newState);
    return;
  };

  function RandomizeAll() {
    alert("Randomize all function ");
    console.log("data column order = ", data.columnOrder);
    data.columnOrder.map((columnId) => {
      const column = data.columns[columnId];
      console.log("column = ", column);
      const tasks = column.taskIds.map((taskId) => data.tasks[taskId]);
      console.log("tasks = ", tasks);
    });
  }

  function onTest() {
    copy2DToClipboard(clipboardData);
    document.getElementById("test").innerText = "Copied!";
  }

  function copy2DToClipboard(array) {
    if (array) {
      const excelData = array.map((lines) => lines.join("\t")).join("\n");
      copyTextToClipboard(excelData);
    } else {
      alert("Try randomizing a column first.");
    }
  }

  // copied from https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
  function fallbackCopyTextToClipboard(text) {
    console.log("fallbackcopy");
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      // console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  }
  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        // console.log('Async: Copying to clipboard was successful!');
        console.log("copy success", clipboardData);
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  return (
    <>
        <div>
        <SponsorBanner />

          <RandomizerContainer>
          <div className="premium-section-top" id="myHeader">
          <div className="premium-section-top-logo">
            <h1 className='razz-filler-header'> Multi-List </h1>
          </div>
          {/* <SponsorBanner /> */}
          {user?.username && (
            <div className="premium-section-info">
              <img
                className="seller-logo-img"
                alt="Profile"
                src={user.photoUrl}
              />
              <p className="seller-username">{user.username}</p>
            </div>
          )}
        </div>            <DragMove onDragMove={handleDragMove}>
              <div className="premium-dice-wrapper">
                <Dice
                  faceColor={user?.color || "#000"}
                  pipColor={user?.pipColor || "#fff"}
                  disabled={!user?.email || user?.plan === "free"}
                  showPopup={() => setOpenPopup(true)}
                />
              </div>
            </DragMove>
            <Divider />

            <Container>
              <RazzColumnWrapper>
                <RazzColumn />
              </RazzColumnWrapper>
              <RazzColumnWrapper>
                <RazzColumn2 />
              </RazzColumnWrapper>
              <RazzColumnWrapper>
                <RazzColumn3 />
              </RazzColumnWrapper>
              <RazzColumnWrapper>
                <RazzColumn4 />
              </RazzColumnWrapper>
            </Container>
          </RandomizerContainer>
        {(!user?.email || user.plan === "free") && openPopup && (
          <PopUp
          content={
            <>
              <>
               <div className="popup-title">Try It Today!</div>
               <br></br>
              <div className='signup-login-links'>
              <Link id='signup-login-link' to="/premium" >
                Go Premium</Link> - FREE for 7 days, then just $10/month. Streamline your breaks with Gemint!
              
              
                {/* <p className=''>Have an account? <Link id='signup-login-link' to="/login">
                  Login now.
              </Link></p> */}
              </div>
            </>
            </>
          }
          handleClose={handlePopUp}
          />
          )}
          </div>
    </>
  );
  
}
