import React, { useState, useContext } from "react";
import ImageDrop from "../../components/ImageDrop";
import globalContext from "../../context/context";
import Die from "../../components/Die";
import { API } from "../../utils/API";
import avatar from "../../img/avatar.png";

import "./SellerSettings.css";
import { Link } from "react-router-dom";

function SellerSettings() {
  const [username, setUsername] = useState("");
  const { user, firebase } = useContext(globalContext);

  function updateUsername(e) {
    e.preventDefault();
    const loggedInUser = firebase.auth().currentUser;

    loggedInUser
      .updateProfile({
        displayName: username,
      })
      .then(() => {
        // Update successful
        // ...
        console.log("successful update");
      })
      .catch((error) => {
        // An error occurred
        // ...
        console.log(error);
      });
  }

  const openPortal = async () => {
    const loggedInUser = firebase.auth().currentUser;
    if (loggedInUser !== null) {
      const customerId = user.customerId;

      await API.post('subscription/portal', {
        customerId: customerId
      })
      .then(function(response){
        window.location = response.data.url;
      })
      .catch(function(error){
        console.log(error)
      })
    }

  }

  const paymentLink = "https://buy.stripe.com/6oE9Cye7o0ib4s8289";

  return (
    <div className="seller-settings">
      <div className="seller-settings-header">Account</div>
      <div className="support-panel">
        <div className="text-center support-panel-text">
          {user && user?.plan !== "free" ? (
            <span>
              You're subscribed to Gemint premium. You have access to a custom
              randomizer and <Link to="/help">support</Link>.
            </span>
          ) : (
            <span>
              Join Gemint premium for $10 a month for access to a custom
              randomizer and <Link to="/help">support</Link>.
            </span>
          )}
        </div>
        {user && user?.plan !== "free" ? (
          <div>
            <Link to="/pricing" className="support-link">
              My Subscription
            </Link>
            {user.customerId &&
              <Link to="#" onClick={openPortal} className="portal-link">
              Manage Billing
              </Link>
            }
          </div>
        ) : (
          <Link to="/pricing" className="support-link">
            Subscribe
          </Link>
        )}
      </div>
      {user ? (
        <form className="seller-settings-form text-center">
          <img
            className="my-3 profile-image"
            alt="Profile"
            src={user && user.photoUrl ? user.photoUrl : avatar}
          />
          <div className="text-center text-white seller-settings-form-label">
            Profile image
          </div>
          <div className="text-center text-white seller-settings-form-label">
            Username
          </div>
          <div className="username-input-wrapper">
            <input
              placeholder={user.username}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <button className="abtn btn" onClick={(e) => updateUsername(e)}>
              Save
            </button>
          </div>
          <ImageDrop />
        </form>
      ) : (
        <p className="text-white loading-text">Loading...</p>
      )}
      <div className="seller-settings-randomizer">
        <h1>Dice</h1>
        <Die />
      </div>
    </div>
  );
}

export default SellerSettings;
