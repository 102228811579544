import "./Privacy.css";

function Privacy() {
  return (
    <div className="privacy-section section">
      <h1 className="text-center text-white privacy-section-header">
        PRIVACY POLICY
      </h1>
   <p className='terms-p'>
   This Privacy Policy (the "Privacy Policy" or the "Policy") provides our policies on the collection, storage, processing, use, and disclosure of information about users established by Gemint, Inc. ("Gemint", "we", "our" or "us") regarding the information that we collect through the website https://Gemint.com (the "Website"), through e-mail and/or other electronic communication between you and us.

Before using the Website, you need to consent to our collection, use, and disclosure of information about you as described in this Policy. If you do not agree with the terms and conditions of this Privacy Policy, please do not use our Website. 

Minimum Required Age.
The Website and our Services are not intended for use by users under 18 years of age. No one under age 18 may provide any personal information through the Website. If we learn we have collected or received personal information from a user under 18, we will delete it immediately.

Information we collect.

1. Personal information and Non-personally identifiable information defined.

Personal information is any information that can be used to identify you as an individual. 

Non-personally identifiable information - information that cannot be used to identify you as an individual, such as personal interests, dietary requirements, special requests and others.

We collect both personal and non-personally identifiable information through the Website.

2. Account information. In order to create an account, you will be asked to provide personal information, including your name and address, shipping address, e-mail address, and Credit/Debit Card information. Upon your account creation you will be prompted to create a screen name, which depending on the name, may or may not be used to identify you as an individual. We may also ask you to provide optional information such as your social media handles and regarding your personal preferences (such as your favorite sports teams). 

3. Transactions. While you use/ navigate throughout our Website, we may automatically collect certain information about your equipment, browsing actions and patterns, including but not limited to: details of your visits to our Website; your search queries; communication data; information about your device and internet connection, including your IP address and location, operating system and browser type.

4. Publicly available content. Some of the contributions you submit to our Website, are intended for public use and are therefore viewable by the public, this may include your photos (if you have provided such), reviews, tips, lists, promotions, business listing information, etc. You may limit the public nature of some of these activities through your account settings, however you will not be able to completely remove them.

5. Activity. While you use the services provided by our Website, we may store information about your use of our services, such as your search activity, the pages you view, the date and time of your visit, and purchases, or transactions you make through our Website. We may also store information that your computer or mobile device may provide to us in connection with your use of our services, WiFi information such as SSID, mobile carrier, phone number and other unique device identifiers.

Cookies and other tracking technologies
We collect information about you using "cookies." Cookies are small data files stored on your hard drive by a Website. The cookies that we use are for authentication purposes (to facilitate your log-in) and session cookies (to recognize your preferences while navigating through the Website). 

Usually the information that we collect automatically through cookies is statistical data and cannot be used to identify you as an individual. It helps us to improve our App(s), Website and Services and to deliver a better and more personalized service.

Additionally, there might be third party cookies used on our Website by third party advertisers, those cookies are not related to your profile, they cannot identify who you are and therefore are not linked to your personal data. 

When you first visit our Website, we ask you whether you wish us to use cookies. If you choose not to accept them, we shall not use them for your visit except to record that you have not consented to their use for any other purpose. If you choose not to use cookies or you prevent their use through your browser settings, you will not be able to use all the functionality of our Website or Services.

How we collect your personal information.
Gemint collects information upon your subscription to our services and also when you interact with us electronically or in person, when you access and use our Website and when we provide our services to you.

Why we collect and process your personal information. 
We collect your personal information based on your explicit consent you have provided for the processing of your personal data and for the purpose of providing you with our services.

Additionally, when you visit our Website, buy a product listed on our Website, or otherwise agree to our User Agreement and Terms and Conditions (See www.gemint.com/terms), a contract is formed between you and us. In order to carry out our obligations under that contract we must process the information you give us. We process this information on the basis of the contract between us. We will continue processing this information until the contract between us ends or is terminated by either party. We may also process personal information where such processing is in our legitimate interests and is not overridden by your data protection interests or fundamental rights and freedoms.

How we use your information
1 We will use your personal information:
- to respond to your queries;
- to provide you with our services;
- to administer your account with us;
- to notify you about changes to our Website, including this Privacy Policy and our User Agreement and Terms of Service;
- to evaluate, improve and enhance the quality of our services;
- to detect and prevent fraud and investigate, or identify possible wrongdoing;

2 We will use your non-personally identifiable information:
- to customize our services for you and to personalize and improve your experience with our Website and the services we provide. 
- to analyze and understand how the visitors use the Website and estimate their usage patterns;
- to enhance the performance of our platform;
- to recognize the visitors when they return to our Website;

How long we keep your information. 
We will retain your personal information for as long as you use our Website.  You have the right to request that your personal information is permanently removed from our system and database at any time. We will accommodate such requests within 90 days.

Information Sharing
We may disclose personal and non-personally identifiable information with third-party service providers, engaged by us to provide technical support, hosting services, testing, network security or statistical services, provided that any such disclosure will be subject to confidentiality obligations. 

We reserve the right to disclose information from or about you if we have a good faith belief that such investigation or disclosure: (a) is reasonably necessary to comply with legal processes and law enforcement instructions and orders, such as a search warrant, subpoena, statute, judicial proceeding, or other legal process or law enforcement requests served on us; (b) is helpful to prevent, investigate, or identify possible wrongdoing in connection with the Website and the services we provide; or (c) protects our rights, reputation, property, or that of our users, affiliates, or the public. If you complain to us about content submitted through the Website, we may share the substance of your complaint with the contributor of that content in order to provide an opportunity for the contributor to respond.

Security
We take reasonable measures and precautions designed to secure your personal information from accidental loss and from unauthorized access, use, alteration and disclosure. All information you provide to us encrypted and stored on our secure servers behind firewalls. We restrict access to personal information to employees, contractors and agents who need to know that information in order to operate, develop or improve our services. These individuals are bound by confidentiality obligations and may be subject to discipline, including termination, if they fail to meet these obligations. However, we are unable to guarantee that the security measures we take will not be penetrated or compromised or that your information will remain secure under all circumstances.

The safety and security of your information also depends on you. If you have a password for access to certain parts of our Website or Application, you are responsible for keeping this password confidential and not sharing it with anyone. We urge you to be careful about giving out information in public areas of the Website like message boards. The information you share in public areas may be viewed by other users of Gemint.

Although we do our best to protect your personal information, the transmission of information via the internet is not completely secure and we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk.

Right to Access and Control Your Data
We provide you with many choices about the collection, use and sharing of your data, from deleting or correcting data you include in your profile and controlling the visibility of your posts and communication controls. We will provide you with access to your personal information that we store and allow you to:

1 Delete your data - you can request that we erase or delete all or some of your personal data (e.g., if it is no longer necessary to provide Services to you);

2 Change or correct your data - you have the option to edit some of your personal data through your account. You can also ask us to change, update or fix your data in certain cases, particularly if it’s inaccurate;

3 Object to, or limit or restrict, use of data - you can ask us to stop using all or some of your personal data (e.g., if we have no legal right to keep using it) or to limit our use of it (e.g., if your personal data is inaccurate or unlawfully held);

4 Right to access and/or take your data - you can ask us for a copy of your personal data and can ask for a copy of personal data you provided in machine readable form.

You may send us an e-mail at info@gemint.com to request any or all of the above.

Linking to other websites and services
Our Website may provide links to other websites and services. Please be aware that if you access a website or a service through a link we provide, you will be subject to the privacy policy of that site and we will not be responsible for the practices and policies adopted by those websites and services and we do not endorse their products and services. 

California Residents’ Privacy Rights
California Civil Code Section § 1798.83 and California Business and Professions Code §§ 22575-22579 permit the users of this Website that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an e-mail to info@gemint.com or write us at: 39070 Zacate Avenue, Fremont, CA 94539.

PRIVACY NOTICE FOR CALIFORNIA RESIDENTS UNDER THE CALIFORNIA CONSUMER PRIVACY ACT.

Effective date: June 1, 2021

Last Reviewed: June 1, 2021

This Privacy Notice for California residents supplements the information contained in our privacy policy above and applies solely to all visitors, users, and others who reside in the State of California ("consumers" or "you"). We adopt this notice to comply with the California Consumer Privacy Act of 2018 (CCPA) and any terms defined in the CCPA have the same meaning when used in this notice.

1. Categories of Information We Collect. Our Website, App(s) and Services collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device ("personal information"). In particular, our Website, Services and App(s) have collected the following categories of personal information from its consumers within the last twelve (12) months: 

1. Identifiers, such as: names, e-mail addresses, Internet Protocol addresses, or other similar identifiers.

Collected:    YES    

2 Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)) such as: a name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.

Collected:    YES    

3 Protected classification characteristics under California or federal law such as: Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).

Collected:    NO    

4 Commercial information obtained, purchased or considered, such as records of personal property, records of products or Services purchased or other purchasing or use histories or tendencies

Collected:    YES    

5 Biometric information

Collected:    NO    

6 Activity information relating to internet or other electronic networks such as browsing or searching history, or interaction with a Website, ad, or app

Collected:   YES    

7 Geolocation information

Collected:    NO    

8 Audio, visual, thermal, electronic, olfactory or other similar information

Collected:   NO    

9 Information related to employment or other professional standings

Collected:    NO    

10 Information related to education

Collected:    NO    

11 Any inferences drawn using any of the above information in order to profile a consumer and reflect the consumer’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities and aptitudes. 

Collected:    NO    

2. Under this Privacy Policy, personal information does not include:

- Publicly available information from government records.

- De-identified or aggregated consumer information.

- Information excluded from the CCPA’s scope and covered by certain sector-specific privacy laws, including but not limited to Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data; the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver’s Privacy Protection Act of 1994.

3. We obtain the categories of personal information listed above from the following categories of sources:

 - Directly from you. For example, from forms you complete on our Website or through our App(s) or Services.

- Indirectly from you. For example, from observing your actions on our Website or through the use of our App(s) or Services or interactions with our advertisers.

- From social media websites that you use to log in or subscribe to our Website, App(s), or Services.

4. Use of Personal Information. We may use, or disclose the personal information we collect for one or more of the following business purposes:

- To fulfill or meet the reason you provided the information. For example, if you share your name and contact information to request a price quote or ask a question about our products or Services, we will use that personal information to respond to your inquiry. If you provide your personal information to purchase a product or service, we will use that information to process your payment and facilitate delivery. We may also save your information to facilitate new product or Service orders.

- To provide you with support and to respond to your inquiries, including to investigate and address your concerns and monitor and improve our responses.

- To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.

- As described to you when collecting your personal information or as otherwise set forth in the CCPA.

- We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.

5. Sharing Personal Information. We may disclose your personal information to a third party for a business purpose. When we disclose personal information for a business purpose, we enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract.

We share your personal information with the following categories of third parties:

	- Payment processors e.g. Paypal, Stripe, Plaid, App Store, etc. for purposes of payment
- Google Analytics for Website metrics for App metrics
- Hosting such as Amazon Web Services.  

However, we don’t explicitly share any information with web service providers.

Disclosures of Personal Information for a Business Purpose. In the preceding twelve 12 months, Gemint has not explicitly disclosed personal information for a business purpose.

Sales of Personal Information in the preceding twelve 12 months – Gemint has not sold personal information in the preceding twelve (12) months.

6. Your Rights and Choices. The CCPA provides California residents with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.

1 Access to Specific Information and Data Portability Rights. You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request, we will disclose to you:

- The categories of personal information we collected about you.

- The categories of sources for the personal information we collected about you.

- Our business or commercial purpose for collecting or selling that personal information.

- The categories of third parties with whom we share that personal information.

- The specific pieces of personal information we collected about you (also called a data portability request).

- If we sold or disclosed your personal information for a business purpose, two separate lists disclosing: (i) sales, identifying the personal information categories that each category of recipient purchased; and (ii) disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.

2 Deletion Request Rights. You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.

- We may deny your deletion request if retaining the information is necessary for us or our service provider(s) to:

- Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you.

- Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.

- Debug products to identify and repair errors that impair existing intended functionality.

- Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.

- Comply with the California Electronic Communications Privacy Act Cal. Penal Code § 1546 et. seq.

- Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information’s deletion may likely render impossible or seriously impair the research’s achievement, if you previously provided informed consent.

- Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.

- Comply with a legal obligation.

- Make other internal and lawful uses of that information that are compatible with the context in which you provided it.

3 Exercising Access, Data Portability, and Deletion Rights. To exercise the access, data portability, and deletion rights described above, you need to submit a verifiable consumer request to us by sending us a message to our customer service at info@gemint.com. Only you, or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child.

You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:

- Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.

- Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.

- We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. We will only use personal information provided in a verifiable consumer request to verify the requestor’s identity or authority to make the request.

4 Response Timing and Format. We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time, we will inform you of the reason and extension period in writing. We will deliver our written response by mail or electronically, at your option. Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request’s receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance, specifically by electronic mail communication.

We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.

7. Personal Information Sales Opt-Out and Opt-In Rights. If you are 16 years of age or older, you have the right to direct us to not sell your personal information at any time (the "right to opt-out"). We do not sell the personal information of consumers we actually know are less than 18 years of age; such underage parties are in any case not authorized to utilize the Website and may have their accounts terminated by us wherever an underage user is discovered. Consumers who opt-in to personal information sales may opt-out of future sales at any time. To exercise the right to opt-out, you (or your authorized representative) may submit a request to us at: info@gemint.com

Once you make an opt-out request, we will wait at least twelve (12) months before asking you to reauthorize personal information sales. However, you may change your mind and opt back into personal information sales at any time by visiting our Website and sending us a message. We will only use personal information provided in an opt-out request to review and comply with the request.

8. Non-Discrimination. We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:

- Deny you goods or Services.

- Charge you different prices or rates for goods or Services, including through granting discounts or other benefits, or imposing penalties.

- Provide you a different level or quality of goods or Services.

- Suggest that you may receive a different price or rate for goods or Services or a different level or quality of goods or Services.

However, we may offer you certain financial incentives permitted by the CCPA that can result in different prices, rates, or quality levels. Any CCPA-permitted financial incentive we offer will reasonably relate to your personal information’s value and contain written terms that describe the program’s material aspects. Participation in a financial incentive program requires your prior opt in consent, which you may revoke at any time.

Changes to this Privacy Policy
Please note that this privacy policy may change from time to time. We will keep you informed of any amendments that we make to this privacy policy. We will post any changes that we make to our privacy policy on the Website home page with a notice that the privacy policy has been updated. 

If we make material changes to how we treat our users' personal information, we will notify you by e-mail to the primary e-mail address specified in your account and/or through a notice on the website home page. It is your responsibility to provide us with an up-to-date e-mail address for you, and for periodically visiting our website and this privacy policy to check for any changes.


Contact information
If you have any additional questions or concerns about this privacy policy, please feel free to contact us any time through this Website or by writing to us at the following email address:
info@gemintcards.com.

Last updated June, 2021

   </p>
    </div>
  );
}

export default Privacy;
