import React, { useState, useEffect } from "react";
import BlogTile from "./BlogTile";
import loader from "./loader.gif";
import "./Blog.css";
import { Pagination } from "react-bootstrap";
import SponsorBanner from "../../components/SponsorBanner"

const BlogContainer = () => {
  // const mediumUrl = "https://medium.com/feed/@joshuagauthreaux"
  const rss2json =
    "https://gemint390292500.wpcomstaging.com//wp-json/wp/v2/posts?_embed&per_page=9&page=";

  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    setShowLoader(true);
    fetch(rss2json + page)
      .then((res) => {
        getPagination(res.headers.get("X-WP-TotalPages"));
        return res.json();
      })
      .then((data) => {
        listPosts(data);
        setShowLoader(false);
        window.scrollTo(0, 0);
      });
  }, [page]);

  function listPosts(posts) {
    setPosts(
      posts &&
        posts.map((blog) => {
          return <BlogTile key={blog.id} post={blog} />;
        })
    );
  }
  function pageLink(i, label) {
    return (
      <span
        key={label ?? i}
        onClick={() => setPage(i)}
        className={i === page ? "active" : "page"}
        style={{
          padding: "15px",
          color: "#fff",
          fontSize: "25px",
          cursor: "pointer",
        }}
      >
        {label ?? i}
      </span>
    );
  }
  function getPagination(totalPage) {
    let links = [],
      start = 1,
      end = totalPage,
      maxPage = 5;

    if (totalPage > maxPage) {
      end = maxPage + page < totalPage ? maxPage + page : totalPage;

      if (page + maxPage > totalPage) {
        start = totalPage - maxPage;
      } else {
        start = page;
      }
    }

    if (page > 1) {
      links.push(pageLink(1, "First"));
      links.push(pageLink(page - 1, "Previous"));
    }

    for (let i = start; i <= end; i++) {
      links.push(pageLink(i));
    }

    if (page < totalPage) {
      links.push(pageLink(page + 1, "Next"));
      links.push(pageLink(parseInt(totalPage), "Last"));
    }
    setPagination(links);
  }

  return (
    <>
    <SponsorBanner />
      <h1 className="blog-header">Latest Sports Card News </h1>
      <div className="blog-container">{posts}</div>
      <div className="pagination">
        {showLoader && (
          <img src={loader} alt={"Loading..."} className="pagination-loader" />
        )}
        {pagination}
      </div>
    </>
  );
};

export default BlogContainer;
