import React from "react";
import "./Help.css";

function Help() {
  return (
    <div className="faq-section section">
      <h1 className="text-center text-white faq-section-header">FAQ</h1>
      <div className="accordion" id="accordionExample">
        <div className="accordion-item mb-3">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              HOW DOES GEMINT.COM WORK?
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
              Gemint tracks the biggest hits coming out of all your favorite sports card products. Live checklists are totally user-generated and updated daily.
              </p>
              <p>
              Users earn points with each serial numbered card you check off/upload. Climb the leaderboards and show off your knowledge in all your favorite products!
              </p>

              <ul>
            <li> Gemint unites Social Media and Sports Card Checklists — now the entire Hobby can track what's been hit … and what's still live! A single source of useful data, powered by the Hobby.</li>

            <li> Traditional checklists are just stacks of text — boring to read and hard to navigate. Gemint checklists allow you to sort, filter, and search by product, player, and sport so you can find out if your chaser is still LIVE.</li>

            <li>  Follow your favorite products to track what's been hit and what's still live.</li>

            <li>  Uploads and checklists are currently for serial numbered cards only.</li>

            <li>  Live feed is updated daily; hits are verified by Gemint moderators. </li>

            <li>  Leaderboards track the top contributors in the Hobby: check off the most cards and claim your place as a Hobby expert in all of your favorite products. </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="accordion-item mb-3">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              WHY USE THE GEMINT.COM RANDOMIZER?
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Your breaks deserve to run SMOOTHLY! Designed specifically for
                The Hobby, the Gemint.com dashboard makes your breaking process
                hyper-efficient … so you can spend more time ripping, and less
                time clicking! • Faster randomization than Random.org • Dice on
                the same page as the Randomizer • Timestamped dice rolls +
                randomizations • Big, clickable ‘Randomize’ + ‘Reset’ buttons
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item mb-3">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              WHAT DO I GET WITH GEMINT PREMIUM?
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Your Gemint Premium subscription puts you in full control of
                your Randomizing experience, with a host of features that
                increase the entertainment value of your break! • Change the
                color of the dice! Create engagement in your live streams by
                letting your audience pick the dice color. Ride hot dice, give
                last spot mojo … The choice is yours! Don’t like the dice color?
                Change it in your settings – use ANY hex code for a fully custom
                dice experience. • Brand the Randomizer with your logo – or any
                other image. Try posting pictures of your PC, or the cards you
                hope to pull in your next break. • Virtual curtain – ALSO
                branded with your logo or image – that conceals randomized list
                results, and slides up to reveal. Create suspense for your live
                audience without the need for clumsy blocker cards! • Customer +
                technical support from Gemint.com. DM us on Instagram @gemint._
                or email us{" "}
                <a href="mailto:info@gemintcards.com">info@gemintcards.com.</a>
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item mb-3">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              WILL A FREE VERSION STILL EXIST ON GEMINT.COM?
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Yes! Our free version will always exist and we will make
                occasional updates to the free version, but users using the free
                version will not have access to the randomizer features on
                Gemint.com available to premium subscribers for $10/month.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item mb-3">
          <h2 className="accordion-header" id="headingFive">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              ADDDITIONAL QUESTIONS OR SUGGESTIONS?
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                DM us on Instagram @gemint._ or email us{" "}
                <a href="mailto:info@gemintcards.com">info@gemintcards.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Help;
