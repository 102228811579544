const initialData = {
    tasks: {
        "task-1": { id: "task-1", content: "Arsenal" },
        "task-2": { id: "task-2", content: "Aston Villa" },
        "task-3": { id: "task-3", content: "Brentford" },
        "task-4": { id: "task-4", content: "Brighton and Hove Albion" },
        "task-5": { id: "task-5", content: "Burnley" },
        "task-6": { id: "task-6", content: "Chelsea" },
        "task-7": { id: "task-7", content: "Crystal Palace" },
        "task-8": { id: "task-8", content: "Everton" },
        "task-9": { id: "task-9", content: "Leeds United" },
        "task-10": { id: "task-10", content: "Leicester City" },
        "task-11": { id: "task-11", content: "Liverpool" },
        "task-12": { id: "task-12", content: "Manchester City" },
        "task-13": { id: "task-13", content: "Manchester United" },
        "task-14": { id: "task-14", content: "Newcastle United" },
        "task-15": { id: "task-15", content: "Norwich City" },
        "task-16": { id: "task-16", content: "Southampton" },
        "task-17": { id: "task-17", content: "Tottenham Hotspur" },
        "task-18": { id: "task-18", content: "Watford" },
        "task-19": { id: "task-19", content: "West Ham United" },
        "task-20": { id: "task-20", content: "Wolverhampton Wanderers" },
        
    },
    columns: {
      "column-1": {
        id: "column-1",
        title: "Tier 1",
        taskIds: [
          "task-1",
          "task-2",
          "task-3",
          "task-4",
          "task-5",
          "task-6",
          "task-7",
          "task-8",
          "task-9",
          "task-10",
        ],
      },
      "column-2": {
        id: "column-2",
        title: "Tier 2",
        taskIds: [
          "task-11",
          "task-12",
          "task-13",
          "task-14",
          "task-15",
          "task-16",
          "task-17",
          "task-18",
          "task-19",
          "task-20",
        ],
      },
      "column-3": {
        id: "column-3",
        title: "Tier 3",
        taskIds: [],
      },
    },
    // Facilitate reordering of the columns
    columnOrder: ["column-1", "column-2", "column-3"],
  };
  
  export default initialData;
  