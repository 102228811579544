import React from "react";

import PremiumRandomizer from "../../components/Randomizers/premiumRandomizer";
import SponsorBanner from "../../components/SponsorBanner"
function Home() {
  return (
    <div>
      {/* <SponsorBanner /> */}
  <PremiumRandomizer />
  </div>
  )
}

export default Home;
