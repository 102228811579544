import React, { useContext } from "react";
import { IoLogoInstagram } from "react-icons/io";
import { Link } from "react-router-dom";
import globalContext from "../../context/context";

import "./SignupConfirm.css";

function SignupConfirm() {
  const { username } = useContext(globalContext);

  return (
    <div className="signup-confirm-wrapper section">
      <div className="follow-us-wrapper">
        <h3 className="follow-us-header">Follow us on Instagram!</h3>
        <a
          href="https://www.instagram.com/gemint._"
          className="insta-icon"
          target="_blank"
          rel="noreferrer"
          id="instagram"
        >
          <IoLogoInstagram className="logo-icon" size={35} color="white" />
        </a>
      </div>
      <div className="signup-confirm-content">
        <h2 className="signup-confirm-content-top">
          Thanks for joining Gemint,{" "}
          <span className="highlight">@{username}</span>.
        </h2>
        <div className="signup-confirm-content-bottom">
          <p>
            We'll let you know when we're ready to make it easier than ever for
            you to join breaks.
          </p>
          <p>See you soon!</p>
          <div className="signup-confirm-content-logo">
            <Link to="/">
              <h3 className="logo">
                GE<span className="highlight-mint">MINT</span>
              </h3>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupConfirm;
