import React, {useContext, useEffect, useState} from "react";
import loader from "./includes/loader.gif";
import "./includes/Hit.css";
import globalContext from "../../context/context";
import {useParams} from "react-router";
import SponsorBanner from "../../components/SponsorBanner";
import * as Helper from "./includes/Helper";
import {Link} from "react-router-dom";

const SeasonPlayers = () => {
  const [season, setSeason] = useState(null);
  const [topCards, setTopCards] = useState([]);
  const [data, setData] = useState([]);
  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [topUploadedCards, setTopUploadedCards] = useState([]);
  const [totalUploads, setTotalUploads] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [notFound, setNotFound] = useState(null);
  const {db} = useContext(globalContext);
  const {season_slug} = useParams();
  const [keyword, setKeyword] = useState(null);

  useEffect(() => {
    setShowLoader(true);
    db.collection("seasons")
      .where("slug", "==", season_slug)
      .get()
      .then((doc) => {
        if (doc.docs.length > 0) {
          setSeason(doc.docs[0].data());
          const season_id = doc.docs[0].id;
          let allPlayers = [];
          db.collection("players")
            .get()
            .then((docPlayer) => {
              docPlayer.docs.map((doc) => {
                allPlayers[doc.id] = {id: doc.id, ...doc.data()};
              });
              db.collection("season_players")
                .where("season_id", "==", season_id)
                .get()
                .then((docSP) => {
                  let pId, uploadCount = [];
                  db.collection("card_uploads")
                    .where("season_id", "==", season_id)
                    .where("status", "==", "A")
                    .get()
                    .then((docCU) => {
                      let selPlayers = [], uploadCount = [];
                      docSP.docs.map((doc) => {
                        selPlayers.push(allPlayers[doc.data().player_id]);
                      });
                      selPlayers.sort((a, b) =>
                        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                      );

                      docCU.docs.map((doc) => {
                        pId = doc.data().player_id;
                        if (!uploadCount[pId]) {
                          uploadCount[pId] = 0;
                        }
                        uploadCount[pId]++;
                      });
                      setTotalUploads(uploadCount);

                      setPlayers(selPlayers);
                      setFilteredPlayers(selPlayers);
                      setShowLoader(false);
                    })
                    .catch((error) => {
                      console.log("Error getting document:", error);
                    });
                });
            })
            .catch((error) => {
              console.log("Error getting document:", error);
            });

          db.collection("card_uploads")
            .where("season_id", "==", season_id)
            .where("status", "==", "A")
            .orderBy("created_at", "desc")
            .limit(3)
            .get()
            .then((doc) => {
              console.log("doc.docs", doc.docs);
              let top = [];
              for (let x in doc.docs) {
                let d = doc.docs[x].data();
                top.push({id: doc.docs[x].id, ...d});
              }
              setTopUploadedCards(top);
              // setShowLoader(false);
            })
            .catch((error) => {
              console.log("Error getting document:", error);
            });
        }else{
          setNotFound('Season not found.');
        }

      });
  }, []);

  useEffect(() => {
    listPlayers();
  }, [filteredPlayers]);

  useEffect(() => {
    topUploaded();
  }, [topUploadedCards]);

  useEffect(() => {
    if (!keyword) {
      searchPlayer();
    }
  }, [keyword]);

  const searchPlayer = () => {
    let filtered = [];
    for (let i in players) {
      if (
        !keyword ||
        players[i].name.toLowerCase().search(keyword.toLowerCase()) >= 0
      ) {
        filtered.push(players[i]);
      }
    }
    setFilteredPlayers(filtered);
  };

  const listPlayers = () => {
    let p = [],
      row = [],
      colData = [];
    if (filteredPlayers.length) {
      const rowsPerCol = Math.ceil(filteredPlayers.length / 3);
      for (let i in filteredPlayers) {
        p.push(
          <div className="ac">
            {filteredPlayers[i].slug && (
              <Link
                className="ac-label"
                to={`/hits/${season_slug}/${filteredPlayers[i].slug}`}
              >
                {filteredPlayers[i].name} ({totalUploads[filteredPlayers[i].id] ? totalUploads[filteredPlayers[i].id] : 0} hits)
              </Link>
            )}
          </div>
        );
        if (
          (parseInt(i) + 1) % rowsPerCol === 0 ||
          parseInt(i) + 1 === filteredPlayers.length
        ) {
          colData.push(<div key={i}>{p}</div>);
          p = [];
        }
      }
    }
    setData(colData);
  };

  const topUploaded = () => {
    setTopCards(
      topUploadedCards.map((card) => (
        <div key={card.id} className="inner-hit-img-container">

          <img
            src={card.front_image}
            alt=""
            srcSet=""
            className="hit-card-image"
            id='hit-card-2'
          />
          <div className="top-hits-card-details">
            {card.player} <br/>
            {card.card_name} {card.card_number}/{card.total_cards} <br/>
            <div className='published-by'>
              published {Helper.timeSince(card.created_at)}{" "}
              ago by: @{card.user_name}  </div>
            {card.notes ? (
              <div className='submitted-notes' id='latest-hits-notes'>
                notes: {card.notes}
                <br/>
              </div>
            ) : null}
            {/* published {timeSince(card.created_at)} ago */}
          </div>
        </div>
      ))
    );
  };

  return (
    <>
      <SponsorBanner/>
      {
        season ?
          <div className="hit-cards-page">
            <div id="select-card-img">
              {season.photo && (

                <img
                  src={season.photo}
                  alt=""
                  srcSet=""
                  className="hit-card-image"
                />
              )}
            </div>
            <p className="card-title text-align-center">
              Check Off & View Hits by Player - {season.year} {season.name}{" "}
              Checklist.
            </p>
            {players.length ? (
              <>
                {/* <div className="card-title">Base</div> */}
                <div className="filter-container mt-2">
                  <input
                    id={"search"}
                    type="text"
                    className="filter"
                    placeholder="Search Player"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    onKeyUp={(e) => e.keyCode === 13 && searchPlayer()}
                  />
                  <div className="filter-button-container">
                    <button
                      className="submit-button primary-btn"
                      onClick={searchPlayer}
                    >
                      Search
                    </button>
                    <button
                      className="submit-button secondary-btn ml-1"
                      onClick={() => setKeyword("")}
                    >
                      Clear Search
                    </button>
                  </div>
                </div>
              </>
            ) : null}

            <div className="inner-season player-accordins mt-4" id="cards">
              {/* accordin */}
              {data.length ? (
                data
              ) : !showLoader ? (
                <div className="card-title">No record found</div>
              ) : null}
            </div>
            <div className="pagination">
              {showLoader ? (
                <img
                  src={loader}
                  alt={"Loading..."}
                  className="pagination-loader"
                />
              ) : null}
            </div>
            {topUploadedCards.length ? (
              <>
                <div className='top-wrapper'>
                  <div id="trending-hits" className="latest-hits-header">
                    Latest Hits
                  </div>
                  <Link className="uploads-btn-season" to={`/uploads/${season_slug}`}>
                    <div id='uploads-btn'>View uploads</div>
                  </Link>
                </div>
                <p id="last-update" className="sub-heading mt-2">
                  Last Update: {topUploadedCards[0].player}{" "}
                  {topUploadedCards[0].card_name} {topUploadedCards[0].card_number}/
                  {topUploadedCards[0].total_cards}
                  {", "}
                  {topUploadedCards[0].created_at.toDate().toLocaleString("en-US", {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}{" "}
                  published by: @{topUploadedCards[0].user_name}
                </p>
                <div className="inner-card-container">
                  <div className="inner-card-data">{topCards}</div>
                </div>
              </>
            ) : null}
          </div>
          : <div className="card-title">{notFound}</div>
      }

      <div className="back-to-wrapper">
        {/* <Link to="/checklists" className="back-to-link"> */}
        <a href="/checklists" className="back-to-link">
          ← Back to all checklists
        </a>
        {/* </Link> */}
      </div>
    </>
  );
};
export default SeasonPlayers;
