import React, {useState, useEffect, useContext} from "react";
import loader from "../../includes/loader.gif";
import "../../includes/Hit.css";
import globalContext from "../../../../context/context";
import jquery from "jquery";
import Table from "react-bootstrap/Table";
import {useHistory} from "react-router-dom";
import * as Helper from "../../includes/Helper";
import * as Common from "../../../../components/Common";

const UploadsList = () => {
  const [keyword, setKeyword] = useState('');
  const [message, setMessage] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [data, setData] = useState([]);
  const [html, setHtml] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [uploadedCard, setUploadedCard] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const {db, firebase} = useContext(globalContext);
  let history = useHistory();

  useEffect(() => {
    //redirect if user is not admin
    firebase.auth().onAuthStateChanged(function (user) {
      db.collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const u = doc.data();
            if (!u.isAdmin) {
              history.push("/");
            }
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    });
    jquery(document).mouseup(function (e) {
      if (
        jquery(e.target).closest(".player-popup").length === 0 &&
        jquery(".player-popup").is(":visible")
      ) {
        closePopup(sessionStorage.getItem("popup"));
      }
    });
    getUploadList();
  }, []);

  useEffect(() => {
    getUploadList();
  }, [page]);

  useEffect(() => {
    if(!keyword){
      getUploadList();
    }
  }, [keyword]);

  useEffect(() => {
    listCards();
    Helper.getPagination(total, perPage, page, setPagination, setPage);
  }, [data]);

  const getUploadList = () => {
    setShowLoader(true);
    Common.axiosInstance().get(`card-uploads/list/${page}?s=${keyword}`)
      .then((res) => {
        console.log('res.data', res.data)
        //if last record on the page is deleted go to previous page
        if(res.data.total > 0 && res.data.data.length === 0){
          setPage(page-1);
        }
        setTotal(res.data.total);
        setPerPage(res.data.perPage);
        setData(res.data.data);
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  const approveCard = async () => {
    Common.axiosInstance().put(`card-uploads/approve`, {id: uploadedCard.id})
      .then((res) => {
        console.log('res.data', res.data)
        setMessage(res.data.message);
        if (res.data.success) {
          getUploadList();
        }
        Helper.closePopup("show-player");
        Helper.openPopup("success-popup");
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  const deleteCard = async () => {
    setShowLoader(true);
    Helper.closePopup("confirm-popup");

    // First perform the query
    Common.axiosInstance().delete(`card-uploads/${uploadedCard.id}`)
      .then((res) => {
        if (res.data.success) {
          getUploadList();
        }
        setMessage(res.data.message);
        Helper.openPopup("success-popup");
        Helper.closePopup("show-player");
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  const confirmDelete = async () => {
    openPopup("confirm-popup");
  };

  const listCards = (filterName = null) => {
    let card = [],
      totalRec = Object.keys(data).length;
    if (totalRec) {
      let offset = (page - 1) * perPage;
      for (let j = 0; j < totalRec; j++) {
        card.push(
          <tr key={j} id={`row-${data[j].id}`}>
            <td>{offset + j + 1}</td>
            <td>{data[j].upload_date}</td>
            <td>{data[j].username}</td>
            <td>{data[j].season_year} - {data[j].season_name}</td>
            <td>{data[j].player_name}</td>
            <td>{data[j].card_name}</td>
            <td>
              {data[j].unknown ? "?" : data[j].card_number}/
              {data[j].total_cards}
            </td>
            <td id={data[j].id}>{data[j].status ? "Approved" : "Pending"}</td>
            <td>
              <a
                className="primary-btn  view-card-btn "
                onClick={() => showPlayer(data[j])}
              >
                View
              </a>
            </td>
          </tr>
        );
      }
    }
    setHtml(card);
  };

  const openPopup = (popup) => {
    sessionStorage.setItem("popup", popup);
    document.getElementById(popup).style.display = "flex";
  };

  const closePopup = (popup) => {
    document.getElementById(popup).style.display = "none";
  };

  const showPlayer = (card) => {
    setUploadedCard(card);
    openPopup("show-player");
  };

  return (
    <>
      <div className="hit-cards-page">
        <div className="inner-top-cta-container">
          <h1 className="blog-header">Uploaded Cards</h1>
        </div>
        <div className="upload-list-table  mt-4">
          <input
            type="text"
            className="filter"
            placeholder="Search Card/Player/Season"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onKeyUp={(e) => e.keyCode === 13 && (page === 1 ? getUploadList() : setPage(1))}
          />
          <div className="filter-button-container">
            <button
              className="submit-button primary-btn"
              onClick={()=>page === 1 ? getUploadList() : setPage(1)}
            >
              Search
            </button>
            <button
              className="submit-button secondary-btn ml-1"
              onClick={() => setKeyword("")}
            >
              Clear Search
            </button>
            {showLoader && (
              <img
                src={loader}
                alt={"Loading..."}
                className="pagination-loader"
              />
            )}
          </div>
        </div>
        <div className="upload-list-table  mt-4" id="cards">
          {/* accordin */}
          {data.length ? (
            <Table bordered hover>
              <thead>
              <tr>
                <th>S. No.</th>
                <th>Upload Date</th>
                <th>Uploaded By</th>
                <th>Season</th>
                <th>Player</th>
                <th>Card</th>
                <th>Card Number</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>{html}</tbody>
            </Table>
          ) : !showLoader ? (
            <div className="card-title">No record found</div>
          ) : null}
        </div>
        <div className="pagination">
          {showLoader && (
            <img
              src={loader}
              alt={"Loading..."}
              className="pagination-loader"
            />
          )}
          {pagination}
        </div>
      </div>
      <div
        className="player-popup"
        style={{display: "none"}}
        id="success-popup"
      >
        <h2>Note</h2>
        <div className="card-upload-area">
          <div>{message}</div>
        </div>
        <button
          className="close-button"
          onClick={(event) => closePopup("success-popup")}
        >
          X
        </button>
      </div>
      <div
        className="player-popup"
        style={{display: "none"}}
        id="error-popup"
      >
        <h2>Error</h2>
        <div className="card-upload-area">
          <div>
            There is some issue in processing your request, please try after
            sometime.
          </div>
        </div>
        <button
          className="close-button"
          onClick={(event) => closePopup("success-popup")}
        >
          X
        </button>
      </div>
      <div
        className="player-popup"
        style={{display: "none", "z-index": 9999}}
        id="confirm-popup"
      >
        <h2>Notice</h2>
        <div className="card-upload-area">
          <div>Do you really want to delete this record?</div>
          <div>
            <button className="primary-btn m-1" onClick={() => deleteCard()}>Yes</button>
            <button className="secondary-btn m-1" onClick={() => closePopup("confirm-popup")}>Cancel</button>
          </div>
        </div>
        <button
          className="close-button"
          onClick={(event) => closePopup("confirm-popup")}
        >
          X
        </button>
      </div>
      <div className="popup-overlay" id="show-player" style={{"z-index": 9}}>
        <div className="player-popup">
          {uploadedCard.front_image ? (
            <>
              <h2>Player Card</h2>
              <div className="popup-hit-image-container">
                <img
                  src={process.env.REACT_APP_STORAGE_URL + uploadedCard.front_image}
                  alt=""
                  className="uploaded-card"
                />
                <img
                  src={process.env.REACT_APP_STORAGE_URL + uploadedCard.back_image}
                  alt=""
                  className="uploaded-card"
                />
              </div>
              <div className="top-hits-card-details">
                {uploadedCard.player_name} <br/>
                {uploadedCard.card_name}{" "}
                {uploadedCard.unknown ? "?" : uploadedCard.card_number}/
                {uploadedCard.total_cards} <br/>
                Notes: {uploadedCard.notes ?? 'NA'} <br/>
                published by @{uploadedCard.username} <br/>
                published {Helper.timeSince(uploadedCard.created_at)} ago
              </div>
              <p className="submitted-notes">
                {uploadedCard.notes ? `Note: ${uploadedCard.notes}` : null}
              </p>
              <div>
                {showLoader ? (
                  <img
                    src={loader}
                    alt={"Loading..."}
                    className="pagination-loader"
                  />
                ) : null}
                {
                  !uploadedCard.status &&
                  <button
                    onClick={() => approveCard()}
                    className="primary-btn m-1"
                  >
                    Approve
                  </button>
                }
                <button
                  onClick={() => confirmDelete()}
                  className="secondary-btn m-1"
                >
                  Delete
                </button>
              </div>
              <button
                className="close-button"
                onClick={(event) => closePopup("show-player")}
              >
                X
              </button>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default UploadsList;
