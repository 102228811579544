import React, {useState, useContext} from "react";
import {useHistory} from "react-router-dom";
import { Link } from 'react-router-dom';
import globalContext from "../../context/context";
import styled from "styled-components";
import "./Login.css";
import PopUp from "../../components/PopUp";
import * as Common from "../../components/Common";
import * as Storage from "../../components/Storage";

// const Link = styled.a`
//   color: #3fd999;
//   text-decoration: underline;
//   border-bottom: 1px solid #3fd999;
// `;

const SignUpText = styled.span`
  font-size: 20px;
  color: white;
`;

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordHelperText, setPasswordHelperText] = useState();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [resetLinktSent, setResetLinkSent] = useState(false);

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const {setUser, setUserState, auth, db, firebase} =
    useContext(globalContext);

  let history = useHistory();

  const handleForgotPassword = () => {
    setForgotPassword(!forgotPassword);
    setResetLinkSent(false);
  };

  const sendForgotPasswordLink = () => {
    auth
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        auth
          .sendPasswordResetEmail(forgotPasswordEmail)
          .then(() => {
            setResetLinkSent(true);
          })
          .catch((error) => {
            console.log("--------", error);
            setResetLinkSent(false);
          });
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(
          "An error occured in forgot password",
          errorCode + " " + errorMessage
        );
        console.log("error message", errorMessage);
      });
  };

  function loginUser(e) {
    e.preventDefault();

    auth
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        auth
          .signInWithEmailAndPassword(email, password)
          .then((userCredential) => {
            // Signed in
            const activeUser = {
              email: userCredential.user.email,
              username: userCredential.user.displayName,
              uid: userCredential.user.uid,
              photoUrl: userCredential.user.photoURL,
              token: userCredential.user.refreshToken,
            };

            db.collection("users")
              .doc(userCredential.user.uid)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  const data = doc.data();
                  Common.axiosInstance().post('login', {
                    user_id: data.userId,
                    email: data.email,
                    first_name: data.firstName,
                    last_name: data.lastName,
                    username: data.username,
                    photo: data.photoUrl ?? '',
                  })
                    .then((res) => {
                      Storage.Set('user', JSON.stringify(res.data));
                      if (params.p) {
                        window.location.href = `/${params.p}?sid=${params.sid ? params.sid : ''}&pid=${params.pid ? params.pid : ''}`;
                      } else {
                        window.location.href = "/";
                      }
                    })
                    .catch((error) => {
                    });
                } else {
                  // doc.data() will be undefined in this case
                  console.log("No such document!");
                }
              })
              .catch((error) => {
                console.log("Error getting document:", error);
              });
            setUserState(true);

            // if (activeUser.username == null) {
            //   history.push("/");
            // } else {
            //   history.push("/randomizer/premium");
            // }
          })
          .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log("An error occured", errorCode + " " + errorMessage);
            console.log("error message #1 = ", errorMessage);
            // Email form error handling
            var output = document.getElementById("output");
            output.innerHTML =
              "Oops! We had an error with that email address. Please try again. Need help? Email us at info@gemintcards.com";
          });
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log("An error occured", errorCode + " " + errorMessage);
        console.log("error message #2 = ", errorMessage);
      });
  }

  function passwordCheck(password) {
    setPassword(password);
    if (password.length < 6) {
      setPasswordHelperText("Password must be greater than 6 characters");
    } else setPasswordHelperText("");
  }

  return (
    <div className="login-page container">
      <h1>Login</h1>
      <div className="login-page-sub-header">Login to your Gemint account.</div>

      <SignUpText>
        Don't have a login? <a
        href={`/signup?p=${params.p ? params.p : ''}&sid=${params.sid ? params.sid : ''}&pid=${params.pid ? params.pid : ''}`}>Sign
        up</a>
      </SignUpText>
      <form>
        <div className="sign-in-form-wrapper">
          <label>Email</label>
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          ></input>
          <label id="output"></label>

          <label>Password</label>
          <input
            type="password"
            name="password"
            id="password"
            value={password}
            onChange={(e) => passwordCheck(e.target.value)}
            required
          ></input>
          <label>{passwordHelperText}</label>
        </div>
      </form>
      <div className="sign-in-actions">
        <button
          className="btn-primary"
          id="create-account-btn"
          onClick={(e) => loginUser(e)}
        >
          Sign in
        </button>
        <button
          className="forgot-password"
          id="forgot-password"
          onClick={() => handleForgotPassword()}
        >
          Forgot Password
        </button>
      </div>
      {forgotPassword && (
        <PopUp
          content={
            <>
              <div className="forgot-password-dialog-header">
              Enter the email address associated with your Gemint account, and we’ll send you an email with a link to reset your password.
              </div>
              <input
                type="email"
                name="forgot-password-email"
                id="forgot-password-email"
                value={forgotPasswordEmail}
                placeholder="Email"
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
              ></input>
              {!resetLinktSent ? (
                <div>
                <button
                  className="primary-btn"
                  disabled={!forgotPasswordEmail}
                  onClick={() => sendForgotPasswordLink()}
                >
                  Reset Password
                </button>
                <div className="needHelp">Don't have an email or need help logging in? <Link to="/contact" style = {{color: "#3fd999"}}>Contact Us</Link></div>
                </div>
                
              ) : (
                <div className="reset-link-sent">Thanks! If your email address is associated with a Gemint account, you'll get an email with a link to reset your password shortly.</div>
              )}
            </>
          }
          handleClose={handleForgotPassword}
        />
      )}
    </div>
  );
}
