import React, { useState, useContext, useEffect } from "react";
import { DateTime } from "luxon";
import globalContext from "../../context/context";
import "./randomizer.css";
import "animate.css";
// import PopUp from "../PopUp";
import { MdFiberNew } from "react-icons/md";



let dt = DateTime.now();

export default function RazzColumn4(props) {
  //all properties for dice
  const { user } = useContext(globalContext);
  const [translate, setTranslate] = useState({
    x: 0,
    y: 0,
  });
  //all properties for randomizer list
  const [count, setCount] = useState(0);
  const [names4, setNames4] = useState("");
  const [prevList, setPrevList] = useState([]);
  const [prevTimes, setPrevTimes] = useState([]);
  const [original, setOriginal] = useState("");
  const [redoList, setRedoList] = useState([]);
  const [redoCount, setRedoCount] = useState(0);
  const [redoTimes, setRedoTimes] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [toggle, setToggle] = useState(false)
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    if (!user?.email || user?.plan !== "monthy") {
      setOpenPopup(true);
    } else {
      setOpenPopup(false);
    }
  }, [user]);

  const [timestamp, setTimestamp] = useState(
    DateTime.now().toLocaleString() +
      " " +
      dt.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
  );

  function shuffleNew() {
    if (!user?.email || user?.plan === "free") {
      setOpenPopup(true);
      return;
    }
    let array = names4.split(`\n`);

    array = array.filter(function (e) {
      return e.replace(/(\r\n|\n|\r)/gm, "");
    });

    prevList.push(array);
    setPrevList(prevList);
    prevTimes.push(timestamp);
    setPrevTimes(prevTimes);
    setOriginal("");

    setRedoCount(0);
    setRedoList([]);
    setRedoTimes([]);

    //get array length and generate random number for each spot

    var mixedArray = array.map(function (x, i) {
      var cryptoRandom = window.crypto.getRandomValues(new Uint32Array(1))[0];
      return { name: x, value: cryptoRandom };
    });

    mixedArray.sort((a, b) => (a.value > b.value ? 1 : -1));

    console.log("mixed", mixedArray);

    let randomizedNameArray = [];
    mixedArray.map((name, i) => {
      let cleanName = name.name;
      console.log("clean name before = ", cleanName);
      cleanName = cleanName.split().pop();
      console.log("clean name after = ", cleanName);
      let order = i + 1;
      // randomizedNameArray.push(order + ". " + cleanName);
      randomizedNameArray.push(cleanName);
    });

    setCount(count + 1);

    const randomizedNames = randomizedNameArray.join(`\n`);

    setNames4(randomizedNames);
    let dtnow = DateTime.now();
    setTimestamp(
      DateTime.now().toLocaleString() +
        " " +
        dtnow.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
    );
  }

  function undo() {
    if (!user?.email || user?.plan === "free") {
      setOpenPopup(true);
      return;
    }
    if (count == 0) {
      console.log("nothing happens");
    } else {
      //get prev entry
      let prevNames = prevList.pop().join(`\n`);
      setPrevList(prevList);
      let prevStamp = prevTimes.pop();
      setPrevTimes(prevTimes);

      //save current entry
      redoList.push(names4.split(`\n`));
      setRedoList(redoList);
      redoTimes.push(timestamp);
      setRedoTimes(redoTimes);
      setRedoCount(redoCount + 1);

      //set state
      setNames4(prevNames);
      setCount(count - 1);
      let dtnow = DateTime.now();
      setTimestamp(
        DateTime.now().toLocaleString() +
          " " +
          dtnow.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
      );
     
    }
  }

 

  function redo() {
    if (!user?.email || user?.plan === "free") {
      setOpenPopup(true);
      return;
    }
    if (redoCount == 0) {
      console.log("nothing happens");
    } else {
      //grab redo set
      let redoNames = redoList.pop().join(`\n`);
      setRedoList(redoList);
      let redoStamp = redoTimes.pop();
      setRedoTimes(redoTimes);

      //save to prev set
      prevList.push(names4.split(`\n`));
      setPrevList(prevList);
      prevTimes.push(timestamp);
      setPrevTimes(prevTimes);

      //update state
      setNames4(redoNames);
      setCount(count + 1);
      setRedoCount(redoCount - 1);
      let dtnow = DateTime.now();
      setTimestamp(
        DateTime.now().toLocaleString() +
          " " +
          dtnow.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
      );
      setOriginal("[ORIGINAL " + redoStamp + "]");
    }
  }

  function handleDragMove(e) {
    setTranslate({
      x: translate.x + e.movementX,
      y: translate.y + e.movementY,
    });
  }

  function resetTranslate() {
    setTranslate({
      x: 0,
      y: 0,
    });
  }

  function handleChange(e) {
    console.log(e);
    setNames4(e);
  }

  function resetRandomizer(e) {
    if (!user?.email || user?.plan === "free") {
      setOpenPopup(true);
      return;
    }
    setNames4("");
    setCount(0);
    setPrevList([]);
    setPrevTimes([]);
    setRedoCount(0);
    setRedoList([]);
    setRedoTimes([]);
  }

  const handlePopUp = () => {
    setOpenPopup(!openPopup);
  };

  // Add/Remove checked item from list
  const handleCheck = (event) => {
    
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    console.log(updatedList)

    setChecked(updatedList);
  };

  // Generate string of checked items
  const checkedItems = checked.length
    ? checked.reduce((total, item) => {
        return total + ", " + item;
      })
    : "";

  // Return classes based on whether item is checked
  var isChecked = (item) =>
    checked.includes(item) ? "checked-item" : "not-checked-item";
    let array = names4.split(`\n`);

    array = array.filter(function (e) {
      return e.replace(/(\r\n|\n|\r)/gm, "");
    });

  function shuffleNew() {
    if (!user?.email || user?.plan === "free") {
      setOpenPopup(true);
      return;
    }
    let array = names4.split(`\n`);

    array = array.filter(function (e) {
      return e.replace(/(\r\n|\n|\r)/gm, "");
    });

    prevList.push(array);
    setPrevList(prevList);
    prevTimes.push(timestamp);
    setPrevTimes(prevTimes);
    setOriginal("");

    setRedoCount(0);
    setRedoList([]);
    setRedoTimes([]);

    //get array length and generate random number for each spot

    var mixedArray = array.map(function (x, i) {
      var cryptoRandom = window.crypto.getRandomValues(new Uint32Array(1))[0];
      return { name: x, value: cryptoRandom };
    });

    mixedArray.sort((a, b) => (a.value > b.value ? 1 : -1));

    console.log("mixed", mixedArray);

    let randomizedNameArray = [];
    mixedArray.map((name, i) => {
      randomizedNameArray.push(name.name);
      console.log(name);
    });

    console.log("finalorder", randomizedNameArray);

    setCount(count + 1);
    const randomizedNames = randomizedNameArray.join(`\n`);
    setNames4(randomizedNames);
    let dtnow = DateTime.now();
    setTimestamp(
      DateTime.now().toLocaleString() +
        " " +
        dtnow.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
    );
  }
  function razzPaymentToggle () {
    // grabbing length of names array to match # of paid columns with it
    let array = names4.split(`\n`);

    array = array.filter(function (e) {
      return e.replace(/(\r\n|\n|\r)/gm, "");
    });
  console.log('array length = ', array.length)
  console.log('array = ', array)
    // toggle true/false between randomize list and collect payment
  // toggle true/false between randomize list and collect payment
  setToggle(!toggle)
  var paidColumn = document.querySelector('.paid-column-wrapper-multi4')
  var textArea = document.querySelector('.razz-textarea4')

  // randomize list toggle 
  if (toggle == true) {
    paidColumn.style.display = 'none'
    // paidColumn.style.width = '0'

    textArea.style.width = "100%"
    // textArea.style.transition = "all 1s";

   

  }
  // collect payment toggle 
  else {
    paidColumn.style.display = 'flex'
    paidColumn.style.flexDirection = 'column'
    textArea.style.width = "50%"
  

  }
    }



  return (
<div>
<input id="randomizer-title" placeholder="Title"></input>
<div className='list-payment-container'>
<div className='paid-column-wrapper-multi4' id='myList' >
                  <div className="checkList">
        <div className="list-container">
          {array.map((item, index) => (
            <div className='item-wrapper' key={index}>
              <input className='item-input' value={item} type="checkbox" onChange={handleCheck} />
              <span id='item-span' className={isChecked(item)}>{item}</span>
            </div>
          ))}
        </div>
      </div>

     
                      </div>
<textarea
                className="razz-textarea4"
                cols="30"
                rows="10"
                value={names4}
                onChange={(e) => handleChange(e.target.value)}
              ></textarea>
                   {/* <div className='new-icon-wrapper'>
                    <MdFiberNew size={35} color='lightgreen' />
                    </div> */}
                 <div className='payment-toggle-container' >
                    <p className='payment-p'>
                    Randomize <br/>list
                    </p>
                    <label className="switch">
                    <input onClick={() => razzPaymentToggle()} type="checkbox" />

  <span className="randomizer-slider round"></span>
</label>
<p className='payment-p'>
Collect <br/>payment
                    </p>
                  
                  </div>
                               </div>

               
                    
                            <p className='razz-counter'>Randomize Counter: {count} </p>
                            <p className='razz-timestamp'>Timestamp: {timestamp} </p>

        <div className='btn-column-wrapper'>
                        <button id='razz-btn' className="abtn abtn4 btn-outline" onClick={() => shuffleNew()}>
              Randomize
            </button> <button id='reset-btn-razz' className="abtn abtn4 btn-dark" onClick={() => resetRandomizer()}>
              Reset
            </button>
                    

            <button className="abtn abtn4 btn-dark" onClick={() => undo()}>
              Undo
            </button>
            <button className="abtn abtn4 btn-dark" onClick={() => redo()}>
              Redo
            </button>
            </div>
            
</div>
  );
}
