import React, { useState } from "react";
import styled from "styled-components";
import RightNav from "./RightNav";

const RightNavWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const StyledBurger = styled.div`
  width: 25px;
  height: 25px;
  top: -15px;
  left: 0;
  z-index: 112;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;

  @media (max-width: 1052px) {
    left: 0;
  }

  div {
    position: relative;
    width: 25px;
    height: 3px;
    background-color: ${({ open }) => (open ? "#ccc" : "white")};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
    &:nth-child(1) {
      display: ${({ open }) => (open ? "none" : "flex")};
    }
    &:nth-child(2) {
      display: ${({ open }) => (open ? "none" : "flex")};
    }
    &:nth-child(3) {
      display: ${({ open }) => (open ? "none" : "flex")};
    }
  }
`;

const Burger = ({ isNormal }) => {
  const [open, setOpen] = useState(false);

  return (
    <RightNavWrapper>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <RightNav open={open} isNormal={isNormal} setOpen={setOpen} />
    </RightNavWrapper>
  );
};

export default Burger;
