import React, {useState, useEffect, useContext, useRef} from "react";
import "./includes/checklist3.css";
import shareIcon from "../../img/share.svg";
import loader from "./includes/loader.gif";
import * as Helper from "./includes/Helper";
import * as Common from "../../components/Common";
import * as SeasonHelper from "../../components/SeasonHelper";
import SeasonSearch from "./componenets/SeasonSearch";
import SeasonFollow from "./componenets/SeasonFollow";
import demoCard from "./includes/Rectangle 16.png";
import CardImage from "./includes/CardImage";
import {RWebShare} from "react-web-share";
import {Link} from "@material-ui/core";
import LoginRequired, {checkLogin} from "./componenets/LoginRequired";
import Advertisement from "../../components/Advertisement";

const Checklists = () => {
  const [seasonId, setSeasonId] = useState("");
  const [season, setSeason] = useState([]);
  const [following, setFollowing] = useState(false);

  const [playerKeyword, setPlayerKeyword] = useState("");
  const [htmlPlayerSearch, setHtmlPlayerSearch] = useState(null);
  const [selPlayer, setSelPlayer] = useState({});
  const [players, setPlayers] = useState([]);
  const [htmlPlayers, setHtmlPlayers] = useState(null);

  const [cardKeyword, setCardKeyword] = useState("");
  const [cards, setCards] = useState([]);
  const [selCard, setSelCard] = useState({});
  const [htmlCards, setHtmlCards] = useState(null);
  const [cardNumber, setCardNumber] = useState(null);

  const [uploadedCard, setUploadedCard] = useState([]);
  const [uploadCount, setUploadCount] = useState([]);
  const [cardUploads, setCardUploads] = useState([]);
  const [htmlCardUploads, setHtmlCardUploads] = useState(null);

  const [frontPreview, setFrontPreview] = useState(null);
  const [backPreview, setBackPreview] = useState(null);
  const [frontImage, setFrontImage] = useState([]);
  const [backImage, setBackImage] = useState([]);
  const [notes, setNotes] = useState('');

  const [uploadUnknown, setUploadUnknown] = useState(false);

  const [error, setError] = useState([]);
  const [saveLoader, setSaveLoader] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showFollow, setShowFollow] = useState(true);

  const maxFileSizeMB = 10; // 10 Mb
  const maxFileSize = 1024 * 1024 * maxFileSizeMB; // 10 Mb

  const listCardRef = useRef();

  useEffect(() => {
    Common.ScrollTop();
    getSeason(Common.getQueryParam('pid'));
    if (seasonId) {
      Common.setQueryParam('pid', '');
      getSeason();
    }
  }, [seasonId]);

  useEffect(() => {
    listPlayers();
  }, [players]);

  useEffect(() => {
    listCards();
  }, [cards, cardUploads, uploadCount, cardKeyword]);

  useEffect(() => {
    if (Object.keys(selCard).length) {
      listCardUploads();
    }
  }, [selCard, cardUploads]);

  useEffect(() => {
    setSelCard({})
    setPlayerKeyword("");
    setHtmlPlayerSearch("");
    getPlayerCards();
  }, [selPlayer]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (playerKeyword) {
        searchPlayer();
      } else {
        setHtmlPlayerSearch([]);
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [playerKeyword]);

  useEffect(() => {
    let err = [];
    if (frontImage[0] && frontImage[0].size > maxFileSize) {
      err.push(<p className="error-text"> Front image size should not be more than {maxFileSizeMB} MB.</p>);
    }
    if (backImage[0] && backImage[0].size > maxFileSize) {
      err.push(<p className="error-text"> Serial number image size should not be more than {maxFileSizeMB} MB. </p>);
    }
    setError(err);
  }, [frontImage, backImage]);

  const getPlayerCards = () => {
    if (selPlayer.id) {
      setShowLoader(true);
      Common.axiosInstance()
        .get(`/players/cards?season_id=${season.id}&player_id=${selPlayer.id}`)
        .then((res) => {
          setUploadCount(res.data.uploadCount);
          setCards(res.data.cards);
          setCardUploads(res.data.cardUploads);
          setShowLoader(false);
          Common.setQueryParam('pid', selPlayer.id);
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
  };

  const getSeason = (pid) => {
    setShowLoader(true);
    Common.axiosInstance()
      .get(`seasons/home?id=${seasonId ? seasonId : Common.getQueryParam('sid') ? Common.getQueryParam('sid') : ''}&pid=${pid ?? ''}`)
      .then((res) => {
        setSelPlayer({});
        setSeason(res.data.season);
        setPlayers(res.data.players);
        setFollowing(!!res.data.following);
        setShowLoader(false);
        Common.setQueryParam('sid', res.data.season.id)
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  const searchPlayer = () => {
    setShowLoader(false);
    Common.axiosInstance()
      .get(`players/search?s=${playerKeyword}&s_id=${season.id}`)
      .then((res) => {
        const data = res.data.data;
        let rows = [];
        if (data.length) {
          data.map((row) => {
            rows.push(
              <li key={row.id} onClick={() => setSelPlayer(row)}>
                {row.name}
              </li>
            );
          });
        } else {
          rows.push(
            <li>No record found.</li>
          );
        }
        setHtmlPlayerSearch(rows);
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  const listPlayers = () => {
    let rows = [], selPlayerIndex = 0;
    if (players) {
      players.map((player, index) => {
        if (parseInt(Common.getQueryParam('pid')) === player.id) {
          selPlayerIndex = index;
        }
        rows.push(
          <div
            key={player.id}
            onClick={() => setSelPlayer(player)}
            className={`player ${player.id === selPlayer.id ? "active" : ""}`}
            style={{background: `linear-gradient(0deg, #000 0%, ${player.color ? player.color : "#000000"} 100%)`}}
          >
            <div className="player-name">{player.name}</div>
          </div>
        );
      });
      if (!Object.keys(selPlayer).length && players[selPlayerIndex]) {
        setSelPlayer(players[selPlayerIndex]);
      }
    }
    setHtmlPlayers(<div className="players-scroller">{rows}</div>);
  };

  const listCards = () => {
    let rows = [];
    if (cards) {
      cards.map((card) => {
        if (!cardKeyword || card.name.toLowerCase().indexOf(cardKeyword.toLowerCase()) !== -1) {
          rows.push(
            <li onClick={() => setSelCard(Object.assign({}, card))}>
              {card.name} {uploadCount[card.id] ?? 0}/ {card.total_cards}{" "}
            </li>
          );
        }
      });
    }
    listCardRef.current.scroll({
      top: 0,
      behavior: 'smooth'
    });
    setHtmlCards(<ul>{rows}</ul>);
  };

  const listCardUploads = () => {
    let rows = [],
      id,
      pendingCls,
      disable,
      unCard;
    const card = selCard;
    //Normal cards
    for (let i = 1; i <= card.total_cards; i++) {
      disable = !!(cardUploads[card.id] && cardUploads[card.id][i]);
      if (disable) {
        pendingCls = cardUploads[card.id][i].status ? `approved` : `pending`;
      } else {
        pendingCls = "";
      }
      id = `card${card.id}-${i}`;
      //normal cards
      rows.push(
        <div className="card-input-check" key={id}>
          <input
            onChange={(e) => setUploadId(card, i)}
            type="checkbox"
            id={id}
            className={`card-check ${pendingCls}`}
            checked={disable}
            defaultChecked={disable}
          />
          <label htmlFor={id}>
            {i}/{card.total_cards}
          </label>
        </div>
      );
    }
    //Unknown cardData
    unCard = cardUploads[card.id] && cardUploads[card.id]["unknown"] ? cardUploads[card.id]["unknown"] : {};
    for (let i = 1; i <= Object.keys(unCard).length; i++) {
      id = `unknown-card${card.id}-${i}`;
      pendingCls = unCard[i].status ? `approved` : `pending`;

      rows.push(
        <div key={id} className="card-input-check">
          <input
            onChange={(e) => showUnknown(unCard[i], i)}
            type="checkbox"
            id={id}
            className={`card-check ${pendingCls}`}
            checked={true}
            defaultChecked={true}
          />
          <label htmlFor={id} className="card-check-label">
            ?/{card.total_cards}
          </label>
        </div>
      );
    }
    //unknown new
    id = `unknown-card${card.id}-new`;
    rows.push(
      <div className="card-input-check">
        <input
          onChange={(e) => e.target.checked && showUpload(card, "?", true, id)}
          type="checkbox"
          id={id}
          className={`card-check`}
        />
        <label htmlFor={id} className="card-check-label">
          ?/{card.total_cards}
        </label>
      </div>
    );
    //END - unknown
    setHtmlCardUploads(<div className="cards-container">{rows}</div>);
    Helper.openPopup("idCardUploads");
  };

  const showUnknown = (card, num) => {
    if (
      cardUploads[card.card_id] &&
      cardUploads[card.card_id]["unknown"] &&
      cardUploads[card.card_id]["unknown"][num] &&
      cardUploads[card.card_id]["unknown"][num].status
    ) {
      showPlayer(cardUploads[card.card_id]["unknown"][num]);
    } else {
      Helper.openPopup("pending-approval");
    }
  };

  const showPlayer = (card) => {
    setUploadedCard(card);
    Helper.openPopup("show-player");
  };

  const showUpload = (card, num, unknown, id) => {
    if (checkLogin()) {
      sessionStorage.setItem("selId", id);
      setSelCard(card);
      setCardNumber(num);
      setUploadUnknown(unknown);
      Helper.openPopup("upload-player");
    }
  };

  const setUploadId = (card, num) => {
    if (checkLogin()) {
      const id = `card${card.id}-${num}`;
      if (!!(cardUploads[card.id] && cardUploads[card.id][num])) {
        setCardNumber(num);
        if (cardUploads[card.id][num] && cardUploads[card.id][num].status) {
          showPlayer(cardUploads[card.id][num]);
        } else {
          Helper.openPopup("pending-approval");
        }
      } else {
        showUpload(card, num, false, id);
      }
    }
  };

  const resetForm = () => {
    setFrontPreview(frontImage[0].preview);
    setBackPreview(backImage[0].preview);
    setFrontImage([]);
    setBackImage([]);
    setNotes("");
  };

  async function saveImages() {
    let err = [];
    if (notes && notes.length > 200) {
      err.push(<p>Notes should not be more than 200 characters.</p>);
    }
    if (frontImage.length && backImage.length) {
      if (frontImage[0].size > maxFileSize) {
        err.push(<p className="error-text"> Front image size should not be more than {maxFileSizeMB} MB.</p>);
      }
      if (backImage[0].size > maxFileSize) {
        err.push(<p className="error-text">Serial number size should not be more than {maxFileSizeMB} MB.</p>);
      }
      if (!err.length) {
        setSaveLoader(true);

        const data = new FormData();
        data.append("front_image", frontImage[0]);
        data.append("back_image", backImage[0]);
        data.append("notes", notes);
        data.append(
          "card_number",
          uploadUnknown
            ? cardUploads[selCard.id] && cardUploads[selCard.id]["unknown"]
            ? Object.keys(
            cardUploads[selCard.id] && cardUploads[selCard.id]["unknown"]
            ).length + 1
            : 1
            : cardNumber
        );
        data.append("unknown", uploadUnknown ? 1 : 0);
        data.append("season_id", season.id);
        data.append("player_id", selPlayer.id);
        data.append("card_id", selCard.id);

        Common.axiosInstance()
          .post("card-uploads", data)
          .then((res) => {
            if (res.data.success) {
              getPlayerCards();
              resetForm();
              Helper.closePopup("upload-player");
              Helper.openPopup("success-popup");
              setSaveLoader(false);
            } else {
              setError(Common.parseErrors(res.data.error));
              Common.ScrollTop();
            }
            setSaveLoader(false);
          })
          .catch((error) => {
            Helper.closePopup("upload-player");
            Helper.openPopup("error-popup");
            console.error("Error adding document: ", error);
          });
      }
    } else {
      err.push(<p className="error-text">Upload images of the card's front and serial number</p>);
    }
    setError(err);
  }

  return (
    <>
      <div className="checklist-container">
        <div className="checklist-instruction">1. Select product</div>
        <div className="seasons-container">
        <div className="seasons">
          
          <SeasonSearch season={season} setSeasonId={setSeasonId} setShowFollow={setShowFollow} />
          {
            showFollow && <SeasonFollow season={season} following={following} follow={!following} setFollowing={setFollowing}
                                        setShowLoader={setShowLoader} />
          }
          {showLoader && (
            <img
              src={loader}
              alt={"Loading..."}
              className="pagination-loader"
            />
          )}
        </div>
        </div>

        <div className="season-details">
          <div className="seasonPlayerContainer">
          <div className="checklist-instruction">2. Pick athlete</div>
          <div className="season-players">
            {htmlPlayers}
            <div
              className="current-player"
              style={{background: `#000`}}
            >
              <div className="player-name">{selPlayer.name}</div>
            </div>
            <div className="search-player">
              <input
                type="text"
                id="search-player"
                value={playerKeyword}
                onChange={(e) => setPlayerKeyword(e.target.value)}
                placeholder="Athlete's name? (e.g. Luka Doncic)"
              />
              <div className="search-results">{htmlPlayerSearch}</div>
            </div>
            {/* <div className="see-all-text" onClick={(event) => Helper.openPopup("see-all-player")}>
              -See All-
            </div> */}
          </div>
          </div>
          <div className="seasonCardsContainer">
          <div className="checklist-instruction">3. Choose card</div>
          <div className="season-cards">
            <input
              type="text"
              name="search"
              id="search-card"
              placeholder="Search by card e.g. gold, logoman"
              onKeyUp={(e) => setCardKeyword(e.target.value)}
            />
            {/* <div className="see-all-text" onClick={(event) => Helper.openPopup("see-all-cards")}>-See All-</div> */}
            <div className="player-cards" ref={listCardRef}>{htmlCards}</div>
          </div>
          </div>
        </div>
        <Advertisement />
      </div>

      <LoginRequired page={'checklists3'} seasonId={season.id} playerId={selPlayer.id}/>

      <div className="popup-overlay" id="idCardUploads">
        <div className="season-player-popup">
          <div className="card-name">
          {selPlayer.name} - {selCard.name} #/{selCard.total_cards}
          </div>
          {htmlCardUploads}
          <div className="follow">
            <RWebShare
              data={{
                text: `Gemint - ${season.year} ${season.name}`,
                url: window.location.href,
                title: `Gemint - ${season.year} ${season.name}`,
              }}
            >
              <img src={shareIcon} alt="" className="shareImage"/>
            </RWebShare>

          </div>
          <button
            className="close-button"
            onClick={(event) => Helper.closePopup("idCardUploads")}
          >
            X
          </button>
        </div>
      </div>

      <div className="popup-overlay" id="error-popup">
        <div className="player-popup">
          <h2>Error</h2>
          <div className="card-upload-area">
            <div>
              There is some issue in uploading the images, please try after
              sometime.
            </div>
          </div>
          <button
            className="close-button"
            onClick={(event) => Helper.closePopup("error-popup")}
          >
            X
          </button>
        </div>
      </div>

      <div className="popup-overlay" id="success-popup">
        <div className="player-popup">
          <h2 className="card-name">Success!</h2>
          <div className="card-upload-area">
            <div id="bang">
              ✅ NICE! You checked off: {selPlayer.name} - {selCard.name} {cardNumber}/{selCard.total_cards}
              <div className="uploadedImages">
                <img src={frontPreview}  alt='' />
                <img src={backPreview}  alt='' />
              </div>
              Your points will appear in your profile once we verify a few things
              <div className="button-group">
               <a href={'javascript:void(0)'} onClick={()=>window.location.reload()}>Back to Checklists</a>
                <a href={"/profile"}>View Your Profile</a>
              </div>
            </div>
          </div>
          <button
            className="close-button"
            onClick={(event) => Helper.closePopup("success-popup")}
          >
            X
          </button>
        </div>
      </div>

      <div className="popup-overlay" id="show-player">
        <div className="player-popup">
          {uploadedCard.front_image ? (
            <>
              <h2>
                {uploadedCard.player_name} - {uploadedCard.card_name}{" "}
                {uploadedCard.unknown ? "?" : uploadedCard.card_number}/
                {uploadedCard.total_cards}
              </h2>
              <div className="popup-hit-image-container">
                <img
                  src={
                    process.env.REACT_APP_STORAGE_URL + uploadedCard.front_image
                  }
                  alt=""
                  className="uploaded-card"
                />
                <img
                  src={
                    process.env.REACT_APP_STORAGE_URL + uploadedCard.back_image
                  }
                  alt=""
                  className="uploaded-card"
                />
              </div>

              <div className="top-hits-card-details">
                {uploadedCard.player_name} <br/>
                {uploadedCard.card_name}{" "}
                {uploadedCard.unknown ? "?" : uploadedCard.card_number}/
                {uploadedCard.total_cards} <br/>
                published {Helper.timeSince(uploadedCard.created_at)} ago by @
                {uploadedCard.username} <br/>
              </div>
              <p className="submitted-notes">
                {uploadedCard.notes ? `Note: ${uploadedCard.notes}` : null}
              </p>
              <button
                className="close-button"
                onClick={(event) => Helper.closePopup("show-player")}
              >
                X
              </button>
            </>
          ) : null}
        </div>
      </div>

      <div className="popup-overlay" id="pending-approval">
        <div className="player-popup">
          <h2>
            {selPlayer.name} - {selCard.name} {cardNumber}/{selCard.total_cards}
          </h2>
          <h2>PENDING</h2>
          <div className="card-upload-area">
            <div id="pending">
              This hit will appear with a green checkmark once approved. Thank
              you!
            </div>
          </div>
          <button
            className="close-button"
            onClick={(event) => Helper.closePopup("pending-approval")}
          >
            X
          </button>
        </div>
      </div>

      <div className="popup-overlay" id="upload-player">
        <div className="player-popup upload-popup">
          <h2>
            {selPlayer.name} - {selCard.name} {cardNumber}/{selCard.total_cards}
          </h2>
          <div style={{color: "#fda130"}}>
            {error}
          </div>
          <div className="card-upload-area">
            <div>
              <CardImage
                files={frontImage}
                setFiles={setFrontImage}
                desc={"Upload Front"}
              />
            </div>
            <div>
              <CardImage
                files={backImage}
                setFiles={setBackImage}
                desc={"Serial Number"}
              />
            </div>
          </div>

          <textarea
            name="Card Notes"
            rows="5"
            className="card-notes"
            placeholder="Enter Details"
            onChange={(e) => setNotes(e.target.value)}
            value={notes}
          />
          <div style={{ color: "#FFF" }}>Max 200 characters</div>
          <div className="button-container">
            {saveLoader ? (
              <img
                src={loader}
                alt={"Loading..."}
                className="pagination-loader"
              />
            ) : null}
            <button className="submit-button" onClick={() => saveImages()}>
              Submit
            </button>
            <button
              className="ebay-popup-button"
              onClick={(event) => Helper.openPopup("ebay-coming-soon")}
            >
              Upload From Ebay
            </button>
            <button
              className="reset-button"
              onClick={() => Helper.closePopup("upload-player")}
            >
              Cancel
            </button>
          </div>

          <button
            className="close-button"
            onClick={(event) => Helper.closePopup("upload-player")}
          >
            X
          </button>
        </div>
      </div>

      <div className="popup-overlay" id="ebay-upload">
        <div className="player-popup upload-popup">
          <h2>Enter Ebay URL</h2>
          <div style={{color: "#fda130"}}>
            {error}
          </div>
          <div className="card-upload-area">
            <input
              type="text"
              className="ebay-link"
              placeholder="Enter Ebay Link"
            />
            <div className="uploaded-images">
              <img src={demoCard} alt=""/>
              <img src={demoCard} alt=""/>
            </div>
          </div>

          {/* <textarea
            name="Card Notes"
            rows="5"
            className="card-notes"
            placeholder="Enter Details"
            onChange={(e) => setNotes(e.target.value)}
            value={notes}
          /> */}
          {/* <div style={{ color: "#FFF" }}>Max 200 characters</div> */}
          <div className="button-container">
            {saveLoader ? (
              <img
                src={loader}
                alt={"Loading..."}
                className="pagination-loader"
              />
            ) : null}
            <button className="submit-button" onClick={() => saveImages()}>
              Submit
            </button>
            <button
              className="reset-button"
              onClick={() => Helper.closePopup("ebay-upload")}
            >
              Cancel
            </button>
          </div>

          <button
            className="close-button"
            onClick={(event) => Helper.closePopup("ebay-upload")}
          >
            X
          </button>
        </div>
      </div>

      <div className="popup-overlay" id="confirm-player">
        <div className="player-upload-popup">
          <h5 className="upload-desc">
            Confirm check-off? <br/>
            Joe Burrow <br/>
            Black Finite Auto /1
          </h5>
          <span className="or-choice">- OR -</span>
          <div className="player-upload-area">
            <div className="card-front">
              <img src={demoCard} alt=""/>
              <span>Front of card</span>
            </div>
            <div className="card-back">
              <img src={demoCard} alt=""/>
              <span>Serial number</span>
            </div>
          </div>
          <div className="upload-confirmation">
            <div
              className="confirm"
              onClick={(event) => Helper.openPopup("card-uploaded")}
            >
              <span>✅</span>
              Confirm Check Off
            </div>
            <div className="confirm">
              <span>❌</span>
              Change Photo
            </div>
          </div>
          <button
            className="close-button"
            onClick={(event) => Helper.closePopup("confirm-player")}
          >
            X
          </button>
        </div>
      </div>

      <div className="popup-overlay" id="card-uploaded">
        <div className="player-upload-popup">
          <h5 className="upload-desc">
            ✅ NICE! You checked off: <br/>
            Black Finite Auto /1 Joe Burrow 2022 Optic NFL #1/1
          </h5>
          <div className="player-upload-area">
            <div className="card-front">
              <img src={demoCard} alt=""/>
              <span>
                <br/>
                Your points will appear in your profile once we verify a few
                things.
              </span>
            </div>
          </div>
          <div className="post-upload-actions">
            <button className="back">Back to checklist</button>
            <button className="view">View Your Profile</button>
          </div>
          <button
            className="close-button"
            onClick={(event) => Helper.closePopup("card-uploaded")}
          >
            X
          </button>
        </div>
      </div>

      <div className="popup-overlay" id="ebay-coming-soon">
        <div className="player-upload-popup">
          <h5 className="upload-desc">
            Coming soon! <br/>Copy & Paste your eBay listing onto Gemint!
          </h5>

          <button
            className="close-button"
            onClick={(event) => Helper.closePopup("ebay-coming-soon")}
          >
            X
          </button>
        </div>
      </div>

      <div className="toast">
        <div className="toast-content">
          <i className="fas fa-solid fa-check check"/>
          <div className="message">
            <span className="text text-1">Success</span>
            <span className="text text-2">Player Link Copied Successfully.</span>
          </div>
        </div>
        <i className="fa-solid fa-xmark close"/>
        <div className="progress"/>
      </div>

      <div className="popup-overlay" id="see-all-cards">
        <div className="season-player-popup">
          <div className="card-name">
            All Athletes - Prizm Football 2022
          </div>
          <input
            type="text"
            name="search"
            id="search-card"
            placeholder="Search by card e.g. gold, logoman"
            onKeyUp={(e) => setCardKeyword(e.target.value)}
          />
          <div className="players-list">
            <ul>
              <li>
                Antonia Gibson
              </li>
              <li>
                Antonia Gibson
              </li>
              <li>
                Antonia Gibson
              </li>
              <li>
                Antonia Gibson
              </li>
              <li>
                Antonia Gibson
              </li>
              <li>
                Antonia Gibson
              </li>
              <li>
                Antonia Gibson
              </li>
            </ul>
          </div>
          <button
            className="close-button"
            onClick={(event) => Helper.closePopup("see-all-cards")}
          >
            X
          </button>
        </div>
      </div>

      <div className="popup-overlay" id="see-all-player">
        <div className="season-player-popup">
          <div className="card-name">
            All Athletes - Prizm Football 2022
          </div>
          <div className="search-player">
            <input
              type="text"
              id="search-player"
              value={playerKeyword}
              onChange={(e) => setPlayerKeyword(e.target.value)}
              placeholder="Athlete's name? (e.g. Luka Doncic)"
            />
            <div className="search-results">{htmlPlayerSearch}</div>
          </div>
          <div className="players-list">
            <ul>
              <li>
                Antonia Gibson
              </li>
              <li>
                Antonia Gibson
              </li>
              <li>
                Antonia Gibson
              </li>
              <li>
                Antonia Gibson
              </li>
              <li>
                Antonia Gibson
              </li>
              <li>
                Antonia Gibson
              </li>
              <li>
                Antonia Gibson
              </li>
            </ul>
          </div>
          <button
            className="close-button"
            onClick={(event) => Helper.closePopup("see-all-player")}
          >
            X
          </button>
        </div>
      </div>

      {/* <button
        onClick={(event) => Helper.openPopup("confirm-player")}
      >
        Confirm player ( TO be Openeed on click of submit)
      </button> */}
      
    </>
  );
};

export default Checklists;
