import React, {useContext} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import styled from "styled-components";
import {IoLogoInstagram} from "react-icons/io";
import globalContext from "../context/context";
import Logo from "../img/GemintLogo.svg";
import "./navbar.css";
import avatar from "../img/avatar.png";
import Profile from "./../pages/Profile/Profile";
import Login from "./../pages/Login/Login";
import Burger from "./Burger";
import checklistIcon from "../img/Checklist.svg";
import leaderIcon from "../img/Trophy.svg";
import profileIcon from "../img/G.svg";
import checkIcon from "../img/+1.svg";

const LoggedInUserWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  min-height: 50px;

  .navbar-brand {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px;
  }

  .navbar {
    margin-bottom: 0px;
  }
  .navbar.navbar-expand-lg {
    display: none;
  }
`;

const Nav = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .gemint-logo {
    max-width: 200px;
    width: 200px;
  }
`;

const HeaderActionWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: flex-start;

  a {
    border: 3px solid #3fd999;
    border-radius: 30px;
    padding: 10px 35px;
    color: #3fd999;
    cursor: pointer;
    margin-right: 10px;
    font-size: 20px;
  }
`;

export default function Navbar() {
  const {userState, auth, user, setUser, setUserState} =
    useContext(globalContext);
  let history = useHistory();

  const logOutUser = (e) => {
    e.preventDefault();

    auth.signOut().then(
      function () {
        // Sign-out successful.
        console.log("logoutuser called");
        setUser({});
        setUserState(false);
        localStorage.removeItem("user");
        history.push("/login");
        window.location.reload();
      },
      function (error) {
        // An error happened.
        console.log(error);
      }
    );
  };

  function AnonymousUser(props) {
    const location = useLocation();
    return (
      <LoggedInUserWrapper>
        
        <div className="split-menu">
          <div className="top-menu-bar">
          <div className="left-top-menubar">
          <Burger isNormal/>
            <Link to="/" className="navbar-brand">
              <img
                className="img-fluid"
                src={Logo}
                height="auto"
                alt="GEMINT"
              ></img>
            </Link>
            </div>
            
            <HeaderActionWrapper>
              <Link to="/signup" className="link">
                Signup
              </Link>
              <Link to="/login" className="link">
                Login
              </Link>
            </HeaderActionWrapper>
          </div>
          <div className="bottom-menu-bar">
            <div className="secondary-menu">
              <div className={`tab-option ${location.pathname === '/checkoff' || location.pathname === '/' ? 'active' : ''}`}>
                <Link to={"/checkoff"}>

                  <svg xmlns="http://www.w3.org/2000/svg" className="check-off-icon" fill="white" viewBox="0 -960 960 960"><path d="M480-80q-85 0-158-30.5T195-195q-54-54-84.5-127T80-480q0-84 30.5-157T195-764q54-54 127-85t158-31q75 0 140 24t117 66l-43 43q-44-35-98-54t-116-19q-145 0-242.5 97.5T140-480q0 145 97.5 242.5T480-140q37 0 71.5-7t66.5-21l45 46q-41 20-87 31t-96 11Zm290-90v-120H650v-60h120v-120h60v120h120v60H830v120h-60ZM421-298 256-464l45-45 120 120 414-414 46 45-460 460Z"/></svg>

                  Check-Offs
                </Link>
              </div>
              <div
                className={`tab-option ${location.pathname === `/profile` ? 'active' : ''}`}>
                <Link
                  to={`/profile`}
                  className="link"
                >
                  <svg id="fillIcon" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.79 100.09">
                    <defs></defs>
                    <path className="cls-1"
                          d="M21.9,29.6A31.41,31.41,0,0,1,28.28,27a48.75,48.75,0,0,1,6.81-1.44,67.72,67.72,0,0,1,7-.59c2.35-.07,4.65-.11,6.92-.11,1.81,0,3.73,0,5.74.08s4.07.18,6.15.39a56.83,56.83,0,0,1,6.21,1,32.63,32.63,0,0,1,5.85,1.81A25.32,25.32,0,0,1,78.1,31a17.43,17.43,0,0,1,4.07,4.27,15.7,15.7,0,0,1,2.68,6.29H63.29a3.71,3.71,0,0,0-1.47-1.3,12,12,0,0,0-2.34-.91,21.27,21.27,0,0,0-2.82-.59c-1-.14-2-.26-2.91-.34s-1.84-.15-2.68-.17l-2.08,0q-10.8,0-14.89,2.65a7,7,0,0,0-1.6,1.62,9.07,9.07,0,0,0-1.06,2,10.44,10.44,0,0,0-.59,2.21A14.82,14.82,0,0,0,30.67,49a15,15,0,0,0,.15,2,10,10,0,0,0,.54,2.2,9.41,9.41,0,0,0,1.06,2.13,7.06,7.06,0,0,0,1.68,1.77,11.24,11.24,0,0,0,3.21,1.4,26.53,26.53,0,0,0,3.91.79c1.36.17,2.72.29,4.07.34s2.59.09,3.7.09q12.93,0,16.13-3.8H53.28V46H85.11V71.55H73.4l-2-5.37a31.43,31.43,0,0,1-5.07,3.13,34.66,34.66,0,0,1-5.55,2.16,40.66,40.66,0,0,1-5.84,1.24,43.85,43.85,0,0,1-5.92.41c-2.27,0-4.57,0-6.92-.11s-4.67-.28-7-.59A48.75,48.75,0,0,1,28.28,71a31.44,31.44,0,0,1-6.38-2.64Q11.18,62,11.17,49T21.9,29.6Z"/>
                  </svg>
                  Profile
                </Link>
              </div>
              <div className={`tab-option ${location.pathname === '/leaders' ? 'active' : ''}`}>
                <Link to={"/leaders"}>
                  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.79 100.09">
                    <defs></defs>
                    <line className="cls-1" x1="65.81" y1="22.75" x2="65.81" y2="44.15"/>
                    <rect className="cls-1" x="27.38" y="16.57" width="41.53" height="6.18" rx="3.09"/>
                    <rect className="cls-1" x="27.38" y="76.83" width="41.53" height="6.18" rx="3.09"/>
                    <path className="cls-1"
                          d="M32.62,76.84a4,4,0,0,1-.86-2.48v-5.1a4,4,0,0,1,4-4H60.52a4,4,0,0,1,4,4v5.1a4,4,0,0,1-.86,2.48"/>
                    <polygon className="cls-1"
                             points="48.14 30.58 49.92 36.05 55.67 36.05 51.02 39.44 52.8 44.91 48.14 41.53 43.48 44.91 45.26 39.44 40.61 36.05 46.36 36.05 48.14 30.58"/>
                    <path className="cls-1"
                          d="M62.23,52.06,80.22,34a7.39,7.39,0,0,0-6.31-11.23H22.37A7.39,7.39,0,0,0,16.06,34l18,18.08A19.88,19.88,0,0,0,62.23,52.06Z"/>
                    <path className="cls-1"
                          d="M59.48,52.06,74,34c2.41-4.92-.44-11.22-5.08-11.22H27.4c-4.64,0-7.49,6.3-5.08,11.22L36.8,52.06C43.05,59.87,53.23,59.87,59.48,52.06Z"/>
                    <line className="cls-1" x1="30.03" y1="43.61" x2="30.03" y2="22.75"/>
                    <line className="cls-1" x1="40.32" y1="56.31" x2="39.46" y2="65.27"/>
                    <line className="cls-1" x1="55.18" y1="56.31" x2="56.03" y2="65.27"/>
                  </svg>
                  Leaders
                </Link>
              </div>
              <div
                className={`tab-option ${location.pathname === '/checklists' || location.pathname === '/checklists' ? 'active' : ''}`}>
                <Link to={"/checklists"}>

                  {/* Checklist Icon SVG */}

                  <svg className="checklistIcon" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 97.79 100.09">
                    <defs></defs>
                    <path className="cls-1"
                          d="M70.35,62.14v5.67A5.31,5.31,0,0,1,65,73.12H31.91a5.31,5.31,0,0,1-5.31-5.31V22.43a5.31,5.31,0,0,1,5.31-5.31H65a5.31,5.31,0,0,1,5.31,5.31V27.8"/>
                    <path className="cls-1"
                          d="M26.6,27H22.06a5.3,5.3,0,0,0-5.3,5.3V77.65A5.3,5.3,0,0,0,22.06,83H55.2a5.3,5.3,0,0,0,5.3-5.31V72"/>
                    <line className="cls-1" x1="34.94" y1="26.14" x2="62.18" y2="26.14"/>
                    <line className="cls-1" x1="34.94" y1="35.67" x2="51.37" y2="35.67"/>
                    <line className="cls-1" x1="34.94" y1="45.21" x2="48.48" y2="45.21"/>
                    <line className="cls-1" x1="34.94" y1="54.74" x2="52.01" y2="54.74"/>
                    <line className="cls-1" x1="34.94" y1="64.27" x2="62.18" y2="64.27"/>
                    <path className="cls-1"
                          d="M76.13,45.21A10.47,10.47,0,1,1,65.67,34.74,10.43,10.43,0,0,1,76.13,45.21Z"/>
                    <line className="cls-1" x1="81.5" y1="60.35" x2="73.21" y2="52.46"/>
                    <polyline className="cls-1" points="61.84 44.18 64.99 47.16 69.84 41.89"/>
                  </svg>

                  {/* SVG END */}

                  Checklists
                </Link>
              </div>
            </div>
          </div>
        </div>
      </LoggedInUserWrapper>
    );
  }

  function LoggedInUser() {
    const location = useLocation();
    return (
      <LoggedInUserWrapper className="logged-in-nav">
        <div className="split-menu">
          <div className="top-menu-bar">
            <div className="left-top-menubar">
            <Burger/>
            <Link to="/" className="navbar-brand">
              <img
                className="img-fluid"
                src={Logo}
                height="auto"
                alt="GEMINT"
              ></img>
            </Link>
            
            </div>
            <nav className="navbar navbar-expand-lg navbar-dark d-flex">
              <div className="navbar-nav ms-auto ">
                <div className="d-flex justify-content-between text-end">
                  <div className="nav-item dropdown">
                    <a
                      href="#"
                      id="user-dropdown"
                      data-toggle="dropdown"
                      className="nav-link dropdown-toggle user-action"
                      aria-expanded="false"
                    >
                      <img
                        id="user-dropdown-img"
                        src={user && user.photoUrl ? user.photoUrl : avatar}
                        className="avatar image-fluid"
                        alt="Avatar "
                      ></img>
                    </a>
                    <div className="dropdown-menu userdrop text-center">
                      {user && user.isAdmin ? (
                        <>
                          <div className="dropdown-menu-item">
                            <Link to="/cards/uploads/list">Uploads List</Link>
                          </div>
                          <div className="dropdown-menu-item">
                            <Link to="/seasons/list">Manage Seasons</Link>
                          </div>
                          <div className="dropdown-menu-item">
                            <Link to="/players/list">Manage Players</Link>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {/* <div className="dropdown-menu-item">
                        <Link to={`/user/profile/${user.username}`}>
                          My Profile{" "}
                        </Link>
                      </div> */}
                      <div className="dropdown-menu-item">
                        <Link to="/settings">Settings</Link>
                      </div>
                      <div
                        onClick={(e) => logOutUser(e)}
                        className="dropdown-menu-item"
                      >
                        Logout
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
          <div className="bottom-menu-bar">
            <div className="secondary-menu">
              <div className={`tab-option ${location.pathname === '/checkoff' || location.pathname === '/' ? 'active' : ''}`}>
                <Link to={"/checkoff"}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="check-off-icon" viewBox="0 -960 960 960"><path d="M480-80q-85 0-158-30.5T195-195q-54-54-84.5-127T80-480q0-84 30.5-157T195-764q54-54 127-85t158-31q75 0 140 24t117 66l-43 43q-44-35-98-54t-116-19q-145 0-242.5 97.5T140-480q0 145 97.5 242.5T480-140q37 0 71.5-7t66.5-21l45 46q-41 20-87 31t-96 11Zm290-90v-120H650v-60h120v-120h60v120h120v60H830v120h-60ZM421-298 256-464l45-45 120 120 414-414 46 45-460 460Z"/></svg>
                  Check-Offs
                </Link>
              </div>
              <div
                className={`tab-option ${location.pathname === `/profile` ? 'active' : ''}`}>
                <Link
                  to={`/profile`}
                  className="link"
                >
                  <svg id="fillIcon" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.79 100.09">
                    <defs></defs>
                    <path className="cls-1"
                          d="M21.9,29.6A31.41,31.41,0,0,1,28.28,27a48.75,48.75,0,0,1,6.81-1.44,67.72,67.72,0,0,1,7-.59c2.35-.07,4.65-.11,6.92-.11,1.81,0,3.73,0,5.74.08s4.07.18,6.15.39a56.83,56.83,0,0,1,6.21,1,32.63,32.63,0,0,1,5.85,1.81A25.32,25.32,0,0,1,78.1,31a17.43,17.43,0,0,1,4.07,4.27,15.7,15.7,0,0,1,2.68,6.29H63.29a3.71,3.71,0,0,0-1.47-1.3,12,12,0,0,0-2.34-.91,21.27,21.27,0,0,0-2.82-.59c-1-.14-2-.26-2.91-.34s-1.84-.15-2.68-.17l-2.08,0q-10.8,0-14.89,2.65a7,7,0,0,0-1.6,1.62,9.07,9.07,0,0,0-1.06,2,10.44,10.44,0,0,0-.59,2.21A14.82,14.82,0,0,0,30.67,49a15,15,0,0,0,.15,2,10,10,0,0,0,.54,2.2,9.41,9.41,0,0,0,1.06,2.13,7.06,7.06,0,0,0,1.68,1.77,11.24,11.24,0,0,0,3.21,1.4,26.53,26.53,0,0,0,3.91.79c1.36.17,2.72.29,4.07.34s2.59.09,3.7.09q12.93,0,16.13-3.8H53.28V46H85.11V71.55H73.4l-2-5.37a31.43,31.43,0,0,1-5.07,3.13,34.66,34.66,0,0,1-5.55,2.16,40.66,40.66,0,0,1-5.84,1.24,43.85,43.85,0,0,1-5.92.41c-2.27,0-4.57,0-6.92-.11s-4.67-.28-7-.59A48.75,48.75,0,0,1,28.28,71a31.44,31.44,0,0,1-6.38-2.64Q11.18,62,11.17,49T21.9,29.6Z"/>
                  </svg>
                  Profile
                </Link>
              </div>
              <div className={`tab-option ${location.pathname === '/leaders' ? 'active' : ''}`}>
                <Link to={"/leaders"}>
                  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.79 100.09">
                    <defs></defs>
                    <line className="cls-1" x1="65.81" y1="22.75" x2="65.81" y2="44.15"/>
                    <rect className="cls-1" x="27.38" y="16.57" width="41.53" height="6.18" rx="3.09"/>
                    <rect className="cls-1" x="27.38" y="76.83" width="41.53" height="6.18" rx="3.09"/>
                    <path className="cls-1"
                          d="M32.62,76.84a4,4,0,0,1-.86-2.48v-5.1a4,4,0,0,1,4-4H60.52a4,4,0,0,1,4,4v5.1a4,4,0,0,1-.86,2.48"/>
                    <polygon className="cls-1"
                             points="48.14 30.58 49.92 36.05 55.67 36.05 51.02 39.44 52.8 44.91 48.14 41.53 43.48 44.91 45.26 39.44 40.61 36.05 46.36 36.05 48.14 30.58"/>
                    <path className="cls-1"
                          d="M62.23,52.06,80.22,34a7.39,7.39,0,0,0-6.31-11.23H22.37A7.39,7.39,0,0,0,16.06,34l18,18.08A19.88,19.88,0,0,0,62.23,52.06Z"/>
                    <path className="cls-1"
                          d="M59.48,52.06,74,34c2.41-4.92-.44-11.22-5.08-11.22H27.4c-4.64,0-7.49,6.3-5.08,11.22L36.8,52.06C43.05,59.87,53.23,59.87,59.48,52.06Z"/>
                    <line className="cls-1" x1="30.03" y1="43.61" x2="30.03" y2="22.75"/>
                    <line className="cls-1" x1="40.32" y1="56.31" x2="39.46" y2="65.27"/>
                    <line className="cls-1" x1="55.18" y1="56.31" x2="56.03" y2="65.27"/>
                  </svg>
                  Leaders
                </Link>
              </div>
              <div className={`tab-option ${location.pathname === '/checklists' ? 'active' : ''}`}>
                <Link to={"/checklists"}>
                  {/* Checklist Icon SVG */}
                  <svg className="checklistIcon" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 97.79 100.09">
                    <defs></defs>
                    <path className="cls-1"
                          d="M70.35,62.14v5.67A5.31,5.31,0,0,1,65,73.12H31.91a5.31,5.31,0,0,1-5.31-5.31V22.43a5.31,5.31,0,0,1,5.31-5.31H65a5.31,5.31,0,0,1,5.31,5.31V27.8"/>
                    <path className="cls-1"
                          d="M26.6,27H22.06a5.3,5.3,0,0,0-5.3,5.3V77.65A5.3,5.3,0,0,0,22.06,83H55.2a5.3,5.3,0,0,0,5.3-5.31V72"/>
                    <line className="cls-1" x1="34.94" y1="26.14" x2="62.18" y2="26.14"/>
                    <line className="cls-1" x1="34.94" y1="35.67" x2="51.37" y2="35.67"/>
                    <line className="cls-1" x1="34.94" y1="45.21" x2="48.48" y2="45.21"/>
                    <line className="cls-1" x1="34.94" y1="54.74" x2="52.01" y2="54.74"/>
                    <line className="cls-1" x1="34.94" y1="64.27" x2="62.18" y2="64.27"/>
                    <path className="cls-1"
                          d="M76.13,45.21A10.47,10.47,0,1,1,65.67,34.74,10.43,10.43,0,0,1,76.13,45.21Z"/>
                    <line className="cls-1" x1="81.5" y1="60.35" x2="73.21" y2="52.46"/>
                    <polyline className="cls-1" points="61.84 44.18 64.99 47.16 69.84 41.89"/>
                  </svg>

                  {/* SVG END */}

                  Checklists
                </Link>
              </div>

            </div>
          </div>
        </div>
      </LoggedInUserWrapper>
    );
  }

  return (
    <Nav>
      {userState ? (
        <LoggedInUser/>
      ) : (
        <AnonymousUser page={window.location.pathname}/>
      )}
    </Nav>
  );
}
