import React, {useRef} from 'react'
import emailjs from "emailjs-com"
import "./Contact.css"

function Contact () {
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault()

        emailjs.sendForm(
            "service_hcstncd",
            "template_i0qj62o",
            form.current,
            "HgZzsSoxQHPo1_PCg"
        ).then(
            result => console.log(result.text),
            error => console.log(error.text)

        )
        e.target.reset()

        var sentMessage = document.querySelector('.email-success')
        sentMessage.style.display = 'flex'


    };

  

       
    return (
        <div className='contact-container'>
            <h1 className='contact-header'>CONTACT US </h1>
            <p className='contact-p'>
            Submit a story or inquire about promos and advertising. Fill out the form and get in touch with Gemint!
                        
</p>
{/* <p className='contact-p'>
Get in touch with the Gemint team by filling out the form below! 👇

</p> */}

<form className='contact-form' ref={form} onSubmit={sendEmail}>
{/* <label className='contact-label'>Name</label> */}
<input className='contact-input' placeholder='Your name*' type='text' name='user_name' />
{/* <label>Email</label> */}
<input className='contact-input' placeholder='Your email*' type='email' name='user_email' />
{/* <label>Message</label> */}
<textarea id='contact-textarea' className='contact-input' placeholder='Your message*' name='message' />
<div className='submit-wrapper'>
<input className='contact-submit-btn' type='submit' value="SEND"/>
<p className='email-success'>
Thanks for your message! We will get back to you via email as soon as possible. 
</p>
</div>

</form>

        </div>
    )
}

export default Contact;