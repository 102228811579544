import React, {useState, useContext, useEffect} from "react";
import ImageDrop from "../../components/ImageDrop";
import globalContext from "../../context/context";
import "./UserProfile.css";
import {Link, useHistory} from "react-router-dom";
import {useParams} from "react-router";
import loader from "../Hit/includes/loader.gif";
import * as Helper from "../Hit/includes/Helper";
import avatar from "../../img/avatar.png";
import * as Common from "../../components/Common";
import ShowCard from "../Hit/componenets/ShowCard";
import SeasonFollow from "../Hit/componenets/SeasonFollow";
import notloggedin from "../../img/notloggedin.JPG";
import Advertisement from "../../components/Advertisement";

function UserProfile() {
  const {db, firebase} = useContext(globalContext);
  const [cardData, setCardData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showLoaderFollowing, setShowLoaderFollowing] = useState(false);
  const [data, setData] = useState([]);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [bio, setBio] = useState(null);
  const [error, setError] = useState(null);
  const [errorPhoto, setErrorPhoto] = useState(null);
  const [total, setTotal] = useState(null);
  const [profile, setProfile] = useState(null);
  const [joined, setJoined] = useState(null);
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const [showCard, setShowCard] = useState({});
  const [htmlFollowing, setHtmlFollowing] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [following, setFollowing] = useState([]);
  const [followingId, setFollowingId] = useState([]);
  const [allSeasons, setAllSeasons] = useState([]);
  const [htmlSeasons, setHtmlSeasons] = useState(null);
  const [seasonKeyword, setSeasonKeyword] = useState(null);
  const [userName, setUserName] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const perPage = 5;

  let {username} = useParams();

  useEffect(() => {
    setShowLoader(true);
    setShowLoaderFollowing(true);
    //check if user profile is of logged in user
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        setNotFound(true);
      }
console.log('user email', user.uid)
      if (username || user) {
        let userQry = db.collection("users");
        if (username) {
          setUserName(username);
          userQry = userQry.where("username", "==", username);
          setIsLoggedInUser(false);
        } else if (user) {
          setIsLoggedInUser(true);
          userQry = userQry.where("userId", "==", user.uid);
        }
        userQry.limit(1)
          .get()
          .then((doc) => {
            console.log('doc', doc)
            if (doc.docs.length) {
              const d = doc.docs[0].data();
              setUserName(d.username);
              setProfile(d);
              const dt = new Date(d.createdAt.seconds * 1000);
              setFirstName(d.firstName);
              setLastName(d.lastName);
              setBio(d.bio);
              setJoined(dt);

              Common.axiosInstance()
                .get(`card-uploads/latest?limit=100&username=${d.username}`)
                .then((res) => {
                  setCardData(res.data);
                  setShowLoader(false);
                })
                .catch((error) => {
                  setShowLoader(false);
                });
              getUserFollowing();
              getSeasons();
            } else {
              alert("Invalid profile link.");
            }
          });
      }
    });
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (seasonKeyword) {
        searchSeason();
      } else {
        if (allSeasons.length) {
          setSeasons(allSeasons);
        }
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [seasonKeyword]);

  useEffect(() => {
    listSeasons();
  }, [seasons, followingId]);

  const getUserFollowing = () => {
    if (userName) {
      setShowLoaderFollowing(true);
      Common.axiosInstance()
        .get(`seasons/subscriptions?username=${userName}`)
        .then((res) => {
          setFollowing([...res.data.data]);
          setShowLoaderFollowing(false);
        })
        .catch((error) => {
          setShowLoaderFollowing(false);
        });
    }
  };

  const getSeasons = () => {
    setShowLoader(true);
    Common.axiosInstance()
      .get(`seasons/get-list`)
      .then((res) => {
        setSeasons(res.data.seasons);
        setAllSeasons(res.data.seasons);
        setFollowingId(res.data.following);
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  const listSeasons = () => {
    let rows = [];
    if (seasons.length) {
      seasons.map((s) => {
        rows.push(
          <SeasonFollow season={s} following={followingId} follow={!followingId.includes(s.id)}
                        setShowLoader={setShowLoader} setFollowing={setFollowingId} name={true}/>
        );
      })
    } else {
      rows.push(
        <li>We couldn't find that product.</li>
      );
    }
    setHtmlSeasons(<ul>{rows}</ul>);
  };

  const searchSeason = () => {
    setShowLoader(false);
    Common.axiosInstance()
      .get(`seasons/get-list?s=${seasonKeyword}`)
      .then((res) => {
        setSeasons(res.data.seasons);
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  function updateProfile() {
    let err = [];
    if (!firstName) {
      err.push(<p>Enter first name.</p>);
    }
    if (!lastName) {
      err.push(<p>Enter last name.</p>);
    }
    if (err.length) {
      setError(err);
    } else {
      let req = {
        firstName,
        lastName,
        bio,
      };
      db.collection("users").doc(profile.userId).update(req);
      setError(null);
      setProfile({...profile, ...req});
      Helper.closePopup("editprofile");
    }
  }

  useEffect(() => {
    getUserFollowing();
  }, [followingId, userName]);

  useEffect(() => {
    listFollowing();
  }, [following]);

  const listFollowing = () => {
    let rows = [];
    if (following.length) {
      following.map((s) => {
        rows.push(
          <li><Link
            to={`/checklists?sid=${s.id}`}>{s.year} {s.name}</Link></li>
        );
      })
    } else {
      rows.push(
        <li className="no-record">{isLoggedInUser  ?
            <div>You don't follow any checklists yet. <span className = "follow-checklist" onClick={(event) => Helper.openPopup("add-season-popup")}>Add checklists</span> to track hits out of your favorite products.</div>
          : <div>This user doesn't follow any checklists. Want to follow your own checklists? <Link to = "/checklists" className="checklist-link"> Click here to go to checklists page.</Link></div>}</li>
      );
    }
    setHtmlFollowing(<ul>{rows}</ul>);
  };

  const listCards = (filterName = null) => {
    let card = [];
    if (cardData.length) {
      for (let j = 0; j < cardData.length; j++) {
        card.push(
          <div className="hit-container" key={j}>
            <div className="hit-images">
              <img src={process.env.REACT_APP_STORAGE_URL + cardData[j].front_image} className="image" alt="hit"
                   onClick={() => {
                     setShowCard(cardData[j]);
                     Helper.openPopup("Card-show");
                   }}/>
              {/* <img src={process.env.REACT_APP_STORAGE_URL + cardData[j].back_image} className="image" alt="hit" /> */}
            </div>
            <div className="hit-info">
              <div className="season-name"> {cardData[j].season_name
                ? <Link
                  to={`/checklists?sid=${cardData[j].season_id}`}>{cardData[j].season_year} {cardData[j].season_name}</Link>
                : ''}</div>
              <div className="player-name"><Link
                to={`/checklists?sid=${cardData[j].season_id}&pid=${cardData[j].player_id}`}>{cardData[j].player_name}</Link>
              </div>
              <div
                className="card-name"> {cardData[j].card_name} ({cardData[j].card_number}/{cardData[j].total_cards})
              </div>
            </div>
          </div>
        );
      }
    }
    setData(card);
  };

  useEffect(() => {
    listCards();
  }, [cardData]);

  function uploadImage(file) {
    let err = [];
    const maxFileSize = 1024 * 1024 * 2; // 5 Mb

    if (file.size > maxFileSize) {
      err.push(<p>Image should not be more than 2MB.</p>);
    }
    if (err.length) {
      setErrorPhoto(err);
    } else {
      var storageRef = firebase.storage().ref();
      var userImagesRef = storageRef.child("users/" + file.name);
      userImagesRef.put(file).then((snapshot) => {
        console.log("Uploaded a blob or file!");
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("File available at", downloadURL);
          db.collection("users")
            .doc(profile.userId)
            .set(
              {
                photoUrl: downloadURL,
              },
              {merge: true}
            )
            .then(() => {
              setErrorPhoto(null);
              setProfile({
                ...profile,
                photoUrl: downloadURL,
              });
              console.log("Document successfully written!");
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        });
      });
    }
  }

  return (
    <>
      {
        userName || isLoggedInUser ?
          <div className="user-profile">
            <div className="account-panel">
              {profile ? (
                <div className="user-info">
                  <div className="user-profile-pic">
                    <div className="avatar-upload">
                      {isLoggedInUser && (
                        <div className="avatar-edit">
                          <input
                            type="file"
                            id="imageUpload"
                            accept=".png, .jpg, .jpeg"
                            onChange={(e) => uploadImage(e.target.files[0])}
                          />
                          <label htmlFor="imageUpload"></label>
                        </div>
                      )}

                      <div className="avatar-preview">
                        <img
                          id="imagePreview"
                          alt={"Profile"}
                          src={
                            profile && profile.photoUrl ? profile.photoUrl : avatar
                          }
                          width={200}
                        />
                      </div>
                      <div className="error-msg">{errorPhoto}</div>
                    </div>
                  </div>
                  <div className="top-account-info">
                    <div className="account-variables">
                      <div className="name">@{profile.username}</div>
                      <div className="join-date">
                        Joined {joined && joined.getMonth() + 1}/{joined && joined.getDate()}
                        /{joined && joined.getFullYear()}
                      </div>
                      {/* <div className="bio-head">Bio</div> */}
                      <div className="bio-content">{profile.bio}</div>
                      <div className="collectedPoints">💎{cardData.length} Points</div>
                    </div>

                    {isLoggedInUser && (
                      <button
                        onClick={(event) => Helper.openPopup("editprofile")}
                        className="hit-btn edit-profile-btn"
                      >
                        Edit Profile
                      </button>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="profile-details">
              <div className="profile-season-container">
                <div className="profile-actions">Lists | Follow</div>
                <div className="profile-seasons">
                  {
                    isLoggedInUser && <div className="cta-add" onClick={(event) => Helper.openPopup("add-season-popup")}>Add Checklists to Follow
                    </div>
                  }
                  <div className="seasons-list">
                    {!showLoaderFollowing && htmlFollowing}
                    {showLoaderFollowing ? (
                      <img
                        src={loader}
                        alt={"Loading..."}
                        className="pagination-loader"
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="profile-overview">
                <div className="profile-card-actions">
                  <span className="active">Overview</span>
                  <span>Badges</span>
                  <span>History</span>
                </div>
                <div className="border-box">
                  <h1 className="profile-head"> Profile Overview</h1>
                  <div className="hits-count">{cardData.length} hits checked off</div>
                  <div className="checklist-of-container">
                    {data}
                    {showLoader ? (
                      <img
                        src={loader}
                        alt={"Loading..."}
                        className="pagination-loader"
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <Advertisement />

          </div>
          :
          notFound ?
          <div className="user-profile not-logged-in">
            <div className="login-in-overlay-cta">
                <div className="login-desc"><Link to={`/signup?p=profile`}>Signup</Link> or <Link to={`/login?p=profile`}>Login</Link> to
                  follow
                  checklists & stay updated on what cards are LIVE in your favorite products!
                </div>
              </div>
          <div className="account-panel">
            <div className="user-info">
              <div className="user-profile-pic">
                <div className="avatar-upload">
                  <div className="avatar-preview">
                    <img
                      id="imagePreview"
                      alt="Profile"
                      src={notloggedin}
                      width={200}
                    />
                  </div>
                  <div className="error-msg" />
                </div>
              </div>
              <div className="top-account-info">
                <div className="account-variables">
                  <div className="name">@GemintAdmin</div>
                  <div className="join-date">Joined 12/22/2021</div>
                  <div className="bio-content">
                    🔋 Powering the Hobby Questions/Comments? 📩 Email us:
                    info@gemintcards.com
                  </div>
                  <div className="collectedPoints">💎18 Points</div>
                </div>
              </div>
            </div>
          </div>
          <div className="profile-details">
            <div className="profile-season-container">
              <div className="profile-actions">Lists | Follow</div>
              <div className="profile-seasons">
                <div className="seasons-list">
                  <ul>
                    <li className="no-record">
                      This user doesn't follow any checklists. Want to follow your own
                      checklists? Click here to go to checklists page.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="profile-overview">
              <div className="profile-card-actions">
                <span className="active">Overview</span>
                <span>Badges</span>
                <span>History</span>
              </div>
              <div className="border-box">
                <h1 className="profile-head"> Profile Overview</h1>
                <div className="hits-count">18 hits checked off</div>
                <div className="checklist-of-container">
                  <div className="hit-container">
                    <div className="hit-images">
                      <img
                        src="https://phpstack-966343-3382243.cloudwaysapps.com/storage/cards/IMG_8473_1685306296.jpg"
                        className="image"
                        alt="hit"
                      />
                    </div>
                    <div className="hit-info">
                      <div className="season-name">
                        {" "}
                        <a href="/checklists?sid=35">2022-23 Optic Basketball</a>
                      </div>
                      <div className="player-name">
                        <a href="/checklists?sid=35&pid=1540">Paolo Banchero </a>
                      </div>
                      <div className="card-name">
                        {" "}
                        Base Rated Rookies Gold Vinyl (1/1)
                      </div>
                    </div>
                  </div>
                  <div className="hit-container">
                    <div className="hit-images">
                      <img
                        src="https://phpstack-966343-3382243.cloudwaysapps.com/storage/cards/5F67007B-E52F-4323-9F6F-710821C99371_1685115527.png"
                        className="image"
                        alt="hit"
                      />
                    </div>
                    <div className="hit-info">
                      <div className="season-name">
                        {" "}
                        <a href="/checklists?sid=32">2022 Prizm Football</a>
                      </div>
                      <div className="player-name">
                        <a href="/checklists?sid=32&pid=1094">Kenny Pickett</a>
                      </div>
                      <div className="card-name"> Rookies Prizm Black Stars (1/1)</div>
                    </div>
                  </div>
                  <div className="hit-container">
                    <div className="hit-images">
                      <img
                        src="https://phpstack-966343-3382243.cloudwaysapps.com/storage/cards/7C4F5DBD-2D22-4B4C-B9DA-69B5742C62A4_1685115283.png"
                        className="image"
                        alt="hit"
                      />
                    </div>
                    <div className="hit-info">
                      <div className="season-name">
                        {" "}
                        <a href="/checklists?sid=32">2022 Prizm Football</a>
                      </div>
                      <div className="player-name">
                        <a href="/checklists?sid=32&pid=1094">Kenny Pickett</a>
                      </div>
                      <div className="card-name">
                        {" "}
                        Rookie Variations Prizm Black Finite (1/1)
                      </div>
                    </div>
                  </div>
                  <div className="hit-container">
                    <div className="hit-images">
                      <img
                        src="https://phpstack-966343-3382243.cloudwaysapps.com/storage/cards/Screenshot 2023-05-25 144029_1685050864.png"
                        className="image"
                        alt="hit"
                      />
                    </div>
                    <div className="hit-info">
                      <div className="season-name">
                        {" "}
                        <a href="/checklists?sid=35">2022-23 Optic Basketball</a>
                      </div>
                      <div className="player-name">
                        <a href="/checklists?sid=35&pid=1533">Jeremy Sochan</a>
                      </div>
                      <div className="card-name">
                        {" "}
                        Rookie Dominators Signatures Gold Vinyl (1/1)
                      </div>
                    </div>
                  </div>
                  <div className="hit-container">
                    <div className="hit-images">
                      <img
                        src="https://phpstack-966343-3382243.cloudwaysapps.com/storage/cards/Screenshot 2023-05-25 083947_1685029215.png"
                        className="image"
                        alt="hit"
                      />
                    </div>
                    <div className="hit-info">
                      <div className="season-name">
                        {" "}
                        <a href="/checklists?sid=35">2022-23 Optic Basketball</a>
                      </div>
                      <div className="player-name">
                        <a href="/checklists?sid=35&pid=573">Jimmy Butler</a>
                      </div>
                      <div className="card-name"> Base Gold Vinyl (1/1)</div>
                    </div>
                  </div>
                  <div className="hit-container">
                    <div className="hit-images">
                      <img
                        src="https://phpstack-966343-3382243.cloudwaysapps.com/storage/cards/Screenshot 2023-05-24 124945_1684957836.png"
                        className="image"
                        alt="hit"
                      />
                    </div>
                    <div className="hit-info">
                      <div className="season-name">
                        {" "}
                        <a href="/checklists?sid=40">
                          2022-23 Crown Royale Basketball
                        </a>
                      </div>
                      <div className="player-name">
                        <a href="/checklists?sid=40&pid=502">LeBron James</a>
                      </div>
                      <div className="card-name"> Hand Crafted Platinum (1/1)</div>
                    </div>
                  </div>
                  <div className="hit-container">
                    <div className="hit-images">
                      <img
                        src="https://phpstack-966343-3382243.cloudwaysapps.com/storage/cards/Screenshot 2023-05-24 123933_1684957204.png"
                        className="image"
                        alt="hit"
                      />
                    </div>
                    <div className="hit-info">
                      <div className="season-name">
                        {" "}
                        <a href="/checklists?sid=40">
                          2022-23 Crown Royale Basketball
                        </a>
                      </div>
                      <div className="player-name">
                        <a href="/checklists?sid=40&pid=1557">Tracy McGrady</a>
                      </div>
                      <div className="card-name"> Kaboom Gold (8/10)</div>
                    </div>
                  </div>
                  <div className="hit-container">
                    <div className="hit-images">
                      <img
                        src="https://phpstack-966343-3382243.cloudwaysapps.com/storage/cards/Screenshot 2023-05-24 123833_1684957132.png"
                        className="image"
                        alt="hit"
                      />
                    </div>
                    <div className="hit-info">
                      <div className="season-name">
                        {" "}
                        <a href="/checklists?sid=40">
                          2022-23 Crown Royale Basketball
                        </a>
                      </div>
                      <div className="player-name">
                        <a href="/checklists?sid=40&pid=489">Kevin Durant</a>
                      </div>
                      <div className="card-name"> Kaboom Gold (10/10)</div>
                    </div>
                  </div>
                  <div className="hit-container">
                    <div className="hit-images">
                      <img
                        src="https://phpstack-966343-3382243.cloudwaysapps.com/storage/cards/IMG_8365_1684886521.jpg"
                        className="image"
                        alt="hit"
                      />
                    </div>
                    <div className="hit-info">
                      <div className="season-name">
                        {" "}
                        <a href="/checklists?sid=32">2022 Prizm Football</a>
                      </div>
                      <div className="player-name">
                        <a href="/checklists?sid=32&pid=1419">Brock Purdy</a>
                      </div>
                      <div className="card-name">
                        {" "}
                        Rookie Autographs Prizm Green Shimmer (1/5)
                      </div>
                    </div>
                  </div>
                  <div className="hit-container">
                    <div className="hit-images">
                      <img
                        src="https://phpstack-966343-3382243.cloudwaysapps.com/storage/cards/Screenshot 2023-05-22 081926_1684768804.png"
                        className="image"
                        alt="hit"
                      />
                    </div>
                    <div className="hit-info">
                      <div className="season-name">
                        {" "}
                        <a href="/checklists?sid=40">
                          2022-23 Crown Royale Basketball
                        </a>
                      </div>
                      <div className="player-name">
                        <a href="/checklists?sid=40&pid=1540">Paolo Banchero </a>
                      </div>
                      <div className="card-name">
                        {" "}
                        Rookie Crown Autographs Platinum (1/1)
                      </div>
                    </div>
                  </div>
                  <div className="hit-container">
                    <div className="hit-images">
                      <img
                        src="https://phpstack-966343-3382243.cloudwaysapps.com/storage/cards/Fwf9QxfaIAA3L2Q_1684768418.png"
                        className="image"
                        alt="hit"
                      />
                    </div>
                    <div className="hit-info">
                      <div className="season-name">
                        {" "}
                        <a href="/checklists?sid=40">
                          2022-23 Crown Royale Basketball
                        </a>
                      </div>
                      <div className="player-name">
                        <a href="/checklists?sid=40&pid=535">Stephen Curry</a>
                      </div>
                      <div className="card-name"> Kaboom Gold (7/10)</div>
                    </div>
                  </div>
                  <div className="hit-container">
                    <div className="hit-images">
                      <img
                        src="https://phpstack-966343-3382243.cloudwaysapps.com/storage/cards/Fwf9QxlakAAxhZS_1684768378.png"
                        className="image"
                        alt="hit"
                      />
                    </div>
                    <div className="hit-info">
                      <div className="season-name">
                        {" "}
                        <a href="/checklists?sid=40">
                          2022-23 Crown Royale Basketball
                        </a>
                      </div>
                      <div className="player-name">
                        <a href="/checklists?sid=40&pid=535">Stephen Curry</a>
                      </div>
                      <div className="card-name"> Kaboom Gold (3/10)</div>
                    </div>
                  </div>
                  <div className="hit-container">
                    <div className="hit-images">
                      <img
                        src="https://phpstack-966343-3382243.cloudwaysapps.com/storage/cards/IMG_8189 (1)_1684278311.jpg"
                        className="image"
                        alt="hit"
                      />
                    </div>
                    <div className="hit-info">
                      <div className="season-name">
                        {" "}
                        <a href="/checklists?sid=35">2022-23 Optic Basketball</a>
                      </div>
                      <div className="player-name">
                        <a href="/checklists?sid=35&pid=1540">Paolo Banchero </a>
                      </div>
                      <div className="card-name">
                        {" "}
                        Rated Rookies Signatures Green (3/5)
                      </div>
                    </div>
                  </div>
                  <div className="hit-container">
                    <div className="hit-images">
                      <img
                        src="https://phpstack-966343-3382243.cloudwaysapps.com/storage/cards/Screenshot 2023-05-09 144707_1683668871.png"
                        className="image"
                        alt="hit"
                      />
                    </div>
                    <div className="hit-info">
                      <div className="season-name">
                        {" "}
                        <a href="/checklists?sid=35">2022-23 Optic Basketball</a>
                      </div>
                      <div className="player-name">
                        <a href="/checklists?sid=35&pid=1540">Paolo Banchero </a>
                      </div>
                      <div className="card-name">
                        {" "}
                        Base Rated Rookies Purple Stars FOTL (1/17)
                      </div>
                    </div>
                  </div>
                  <div className="hit-container">
                    <div className="hit-images">
                      <img
                        src="https://phpstack-966343-3382243.cloudwaysapps.com/storage/cards/001A_65628_0001F1_BC2202Bas_BC_5_1_1_1683668437.png"
                        className="image"
                        alt="hit"
                      />
                    </div>
                    <div className="hit-info">
                      <div className="season-name">
                        {" "}
                        <a href="/checklists?sid=32">2022 Prizm Football</a>
                      </div>
                      <div className="player-name">
                        <a href="/checklists?sid=32&pid=2063">Allen Robinson II</a>
                      </div>
                      <div className="card-name">
                        {" "}
                        Rookie Autographs Platinum Bar FOTL (1/1)
                      </div>
                    </div>
                  </div>
                  <div className="hit-container">
                    <div className="hit-images">
                      <img
                        src="https://phpstack-966343-3382243.cloudwaysapps.com/storage/cards/Screenshot 2023-05-08 161355_1683587689.png"
                        className="image"
                        alt="hit"
                      />
                    </div>
                    <div className="hit-info">
                      <div className="season-name">
                        {" "}
                        <a href="/checklists?sid=32">2022 Prizm Football</a>
                      </div>
                      <div className="player-name">
                        <a href="/checklists?sid=32&pid=1122">Patrick Mahomes II</a>
                      </div>
                      <div className="card-name"> Base Prizm Gold (6/10)</div>
                    </div>
                  </div>
                  <div className="hit-container">
                    <div className="hit-images">
                      <img
                        src="https://phpstack-966343-3382243.cloudwaysapps.com/storage/cards/Screenshot 2023-05-08 160149_1683586970.png"
                        className="image"
                        alt="hit"
                      />
                    </div>
                    <div className="hit-info">
                      <div className="season-name">
                        {" "}
                        <a href="/checklists?sid=35">2022-23 Optic Basketball</a>
                      </div>
                      <div className="player-name">
                        <a href="/checklists?sid=35&pid=1540">Paolo Banchero </a>
                      </div>
                      <div className="card-name">
                        {" "}
                        Rated Rookies Signatures Green (2/5)
                      </div>
                    </div>
                  </div>
                  <div className="hit-container">
                    <div className="hit-images">
                      <img
                        src="https://phpstack-966343-3382243.cloudwaysapps.com/storage/cards/001A_65628_0001F1_BC2202Bas_BC_5_1_1_1682345300.png"
                        className="image"
                        alt="hit"
                      />
                    </div>
                    <div className="hit-info">
                      <div className="season-name">
                        {" "}
                        <a href="/checklists?sid=34">2022 Prizm WWE</a>
                      </div>
                      <div className="player-name">
                        <a href="/checklists?sid=34&pid=2357">Becky Lynch</a>
                      </div>
                      <div className="card-name">
                        {" "}
                        Base Horizontal Prizms Black (1/1)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
            : null
      }

      <div className="popup-overlay" id="editprofile">
        <div className="user-info-popup">
          <div className="error-msg"> {error}</div>
          <label htmlFor="name">Edit First Name</label>
          <input
            type="text"
            defaultValue={profile ? profile.firstName : ""}
            onKeyUp={(e) => setFirstName(e.target.value)}
          />
          <label htmlFor="name">Edit Last Name</label>
          <input
            type="text"
            defaultValue={profile ? profile.lastName : ""}
            onKeyUp={(e) => setLastName(e.target.value)}
          />
          <label htmlFor="Bio">Edit Bio</label>
          <textarea
            id="Bio"
            rows="6"
            cols="50"
            defaultValue={profile ? profile.bio : ""}
            onKeyUp={(e) => setBio(e.target.value)}
          />
          <button className="save-info" onClick={updateProfile}>
            Update Profile
          </button>
          <button
            className="close-button"
            onClick={(event) => Helper.closePopup("editprofile")}
          >
            X
          </button>
        </div>
      </div>
      <div className="popup-overlay" id="add-season-popup">
        <div className="player-popup">
          <h2>Add Checklists to Follow</h2>
          <input
            type="search"
            className="season-search"
            id="search-card"
            placeholder="Search by product ..."
            value={seasonKeyword}
            onChange={(e) => setSeasonKeyword(e.target.value)}
          />
          <div className="labelling-text">-OR-
            <br/>Select from popular checklists
          </div>
          <div className="checklist-list">
            {htmlSeasons}
          </div>
          <button
            className="close-button"
            onClick={(event) => Helper.closePopup("add-season-popup")}
          >
            X
          </button>
        </div>
      </div>
      <ShowCard card={showCard}/>
    </>
  );
}

export default UserProfile;
