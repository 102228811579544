import React, {useState, useEffect, useContext} from "react";
import {useDropzone} from "react-dropzone";
import globalContext from "../../../context/context";
import styled from "styled-components";
import uploadIcon from "./uploadSymbol.svg";
import heic2any from 'heic2any';

const Container = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  .dropzone {
    color: black !important;
    display: inline-block;
    border-radius: 50px;
    margin-bottom: 20px;
  }
  .dropzone > p {
    color: black;
    padding: 30px 70px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-family: "Work Sans", sans-serif;
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 20px;

    @media (max-width: 768px) {
      padding: 5px 10px;
    }
  }

  .upload-image-button {
    margin-bottom: 10px;
  }

  .upload-image-button:disabled {
    border-color: gray;
    cursor: not-allowed;
  }

  .upload-icon {
    width: auto;
  }
`;

const UploadStatus = styled.div`
  color: white;
  text-align: center;
  font-size: 20px;
`;

const ThumbPreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const thumb = {
  display: "flex",
  borderRadius: 2,
  alignItems: "center",
  justifyContent: "center",
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function CardImage(props) {
  const {user, setUser, firebase, db} = useContext(globalContext);
  const [heicConverting, setHeicConverting] = useState(false);


  const {getRootProps, getInputProps} = useDropzone({
    accept: ["image/*", '.heic'],
    multiple: false,
    onDrop: (acceptedFiles) => {
      console.log('acceptedFiles', acceptedFiles)
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        if (file.type === "") {
          setHeicConverting(true);
          heic2any({
            blob: file,
            toType: 'image/jpeg'
          }).then(blob => {
            let newFile = new File([blob], file.name + '.jpg', {type: 'image/jpeg'});
            setHeicConverting(false);
            props.setFiles(
              [Object.assign(newFile, {
                preview: URL.createObjectURL(newFile),
              })]
            );
          }, error => {
            //handle errors
          });
        } else {
          props.setFiles(
            acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
        }
      }
    }
  });

  const thumbs = props.files.map((file) => (
    <div style={thumb} key={file.name}>
      <img alt="Preview" src={file.preview} style={img}/>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      props.files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [props.files]
  );

  return (
    <Container>
      <div {...getRootProps({className: "dropzone"})}>
        <input {...getInputProps()} name={props.name}/>
        <img src={uploadIcon} alt="" className="upload-icon"/>
        <p id="upload-card-desc">{props.desc}</p>
      </div>
      {
        heicConverting ?
          <span>Please wait, converting .heic image to .jpg...</span>
          : ""
      }
      <ThumbPreview>{thumbs}</ThumbPreview>
    </Container>
  );
}

export default CardImage;
