import React, { useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { useHistory } from "react-router-dom";

import globalContext from "../../context/context";
import { welcomeEmail } from "../../store/subscription";

import "./SignUp.css";
import * as Common from "../../components/Common";
import * as Storage from "../../components/Storage";

export default function SignUp() {
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [usernameHelperText, setUsernameHelperText] = useState(null);
  const [emailHelperText, setEmailHelperText] = useState(null);
  const [passwordHelperText, setPasswordHelperText] = useState(null);
  const [signUpDisable, setSignUpDiasble] = useState(false);

  const { username, setUsername, firebase, db } = useContext(globalContext);

  const Link = styled.a`
    color: #3fd999;
    text-decoration: underline;
    border-bottom: 1px solid #3fd999;
  `;

  let history = useHistory();

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  useEffect(() => {
    const usernameRegex = /^([A-Za-z0-9]|[-._](?![-._])){4,20}$/;
    const validUsername = usernameRegex.test(username);
    if (validUsername) {
      db.collection("usernames")
        .doc(username)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setUsernameHelperText("This username is not available.");
          } else {
            // doc.data() will be undefined in this case
            setUsernameHelperText("");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          setUsernameHelperText("Sorry, we had an error. Please try again.");
        });
    } else {
      if(username){
        setUsernameHelperText("Sorry, this username is invalid, try another");
      }
    }
  }, [username, db, email]);

  useEffect(() => {
    db.collection("users")
      .where("email", "==", email)
      .get()
      .then((doc) => {
        console.log("---------", doc.docs);
        if (doc.docs.length > 0) {
          setEmailHelperText("This email is already exists.");
        } else {
          // doc.data() will be undefined in this case
          setEmailHelperText("");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
        setEmailHelperText("Sorry, we had an error. Please try again.");
      });
  }, [email, db]);

  function passwordCheck(password) {
    setPassword(password);
    if (password.length < 6) {
      setPasswordHelperText("Password must be greater than 6 characters");
    } else setPasswordHelperText("");
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setSignUpDiasble(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const batch = db.batch();

        // TODO make this doc user ID instead of random
        const userId = userCredential.user.uid;
        const userRef = db.collection("users").doc(userId);
        batch.set(userRef, {
          firstName: firstName,
          lastName: lastName,
          username: username,
          email: email,
          userId: userId,
          plan: "free",
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

        const usernameRef = db.collection("usernames").doc(username);
        batch.set(usernameRef, {
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

        // Commit the batch
        batch
          .commit()
          .then(() => {
            console.log("Document successfully written!");
            const payload = {
              email: email,
              username: username,
              isFree: true,
            };
            Common.axiosInstance().post('login', {
              user_id: userId,
              email: email,
              first_name: firstName,
              last_name: lastName,
              username: username,
              photo: '',
            })
              .then((res) => {
                Storage.Set('user', JSON.stringify(res.data));
                dispatch(welcomeEmail(payload));
                if (params.p) {
                  window.location.href = `/${params.p}?sid=${params.sid ? params.sid : ''}&pid=${params.pid ? params.pid : ''}`;
                } else {
                  window.location.href = "/";
                }
              })
              .catch((error) => {
              });
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      })
      .catch((error) => {
        var errorCode = error.code;
        console.error("Error writing document: ", errorCode);
      });
  };

  return (
    <div className="sign-up-page container">
      <h1 className="sign-up-page-header">Create your account</h1>
      {/* <p>
        Join the Gemint waitlist to reserve your username and be entered to win
        free cards
      </p> */}
      <div class="sign-up-page-sub-header">
        Have an account? <Link href={`/login?p=${params.p ? params.p : ''}&sid=${params.sid ? params.sid : ''}&pid=${params.pid ? params.pid : ''}`}>Login</Link>
      </div>
      <form>
        <div className="sign-in-form-wrapper">
          <div>
            <div className="form-input-wrapper">
              <label className="label">Username</label>
              <input
                required
                type="text"
                name="username"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              ></input>
            </div>
            <label>{usernameHelperText}</label>

            <div className="form-input-wrapper">
              <label>First name</label>
              <input
                type="text"
                name="firstname"
                id="firstname"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              ></input>
            </div>

            <div className="form-input-wrapper">
              <label>Last name</label>
              <input
                type="text"
                name="lastname"
                id="lastname"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              ></input>
            </div>

            <div className="form-input-wrapper">
              <label>Email</label>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              ></input>
            </div>
            <label>{emailHelperText}</label>

            <div className="form-input-wrapper">
              <label>Password</label>
              <input
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={(e) => passwordCheck(e.target.value)}
                required
              ></input>
              <label>{passwordHelperText}</label>
            </div>
          </div>
          <button
            className="btn-primary"
            id="create-account-btn"
            onClick={(e) => onSubmit(e)}
            disabled={
              false
            }
          >
            Create account
          </button>
        </div>
      </form>
    </div>
  );
}
